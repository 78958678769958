import { useEffect, useState, useCallback } from 'react';
import SiteLayout from 'components/SiteLayout';
import { Pagination, Spin, Table, Button, Modal, notification, Typography, Tag, Drawer } from 'antd';
import type { PaginationProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGetAllUserQuery, useDeleteUserMutation } from 'app/services';
import UpdateUser from './updateUser';
import { useHistory } from 'react-router-dom';
import UserProfile from './userProfile';
import TooltipComponent from 'components/Tooltip';
import AddUser from './addUser';

const { Column } = Table;

// Interface definition for user details
export interface UserDetailType {
  key: string;
  name: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  userLastLogin: string;
  userRoleId: string;
}

const Admin = () => {
  const { confirm } = Modal;
  const [page, setPage] = useState(1); // State for current page in pagination
  const [tableData, setTableData] = useState<UserDetailType[]>([]); // State for table data
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [editItem, setEditItem] = useState({}); // State for item being edited
  const [userDetailDrawer, setUserDetailDrawer] = useState<boolean>(false); // State for user detail drawer visibility
  const [selectedUser, setSelectedUser] = useState<any>(null); // State for selected user
  const [openAddUserDrawer, setOpenAddUserDrawer] = useState<any>(false)
  const { push } = useHistory();
  const take = 10; // Number of items per page

  const loginUser = JSON.parse(localStorage.getItem('user') || '{}');

  // Redirect non-admin users to profile page
  useEffect(() => {
    if (loginUser.role !== 'admin') {
      push({ pathname: '/profile' });
    }
  }, [loginUser.role, push]);

  const { data: userInfo, isFetching, refetch } = useGetAllUserQuery({ page, take });

  // Clear localStorage items if a specific condition is not met
  useEffect(() => {
    if (!localStorage.getItem('isSwitchProfileOn')) {
      localStorage.removeItem('surveyId');
      localStorage.removeItem('surveyEmail');
    }
  }, []);

  // Populate table data with user information
  useEffect(() => {
    if (userInfo && userInfo.data) {
      let data: UserDetailType[] = [];
      Promise.all(
        userInfo.data.map((item) => {
          const options: any = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          };
          var today = new Date(item.userLastLogin);
          return data.push({
            key: item.id,
            name: item.firstname + " " + item.lastname,
            firstname: item.firstname,
            lastname: item.lastname,
            email: item.email,
            role: item.userRoles?.length ?
              item.userRoles[0]?.roleDetails?.name : '',
            userRoleId: item.userRoles?.length ?
              item.userRoles[0]?.id : '',
            userLastLogin: item.userLastLogin
              ? today.toLocaleDateString('en-US', options)
              : '',
          });
        }),
      );
      setTableData(data);
    }
  }, [userInfo]);

  const [deleteUser] = useDeleteUserMutation();

  // Show confirmation modal for deleting a user
  const showDeleteConfirm = useCallback(
    (deleteId: string) => {
      confirm({
        zIndex: 10000,
        title: 'Are you sure delete this user?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteUser(deleteId).finally(() => {
            notification.success({
              placement: 'topRight',
              message: 'User deleted successfully!',
            });
            refetch();
          });
        },
      });
    },
    [confirm, deleteUser, refetch],
  );

  // Handle pagination changes
  const onChange: PaginationProps['onChange'] = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <SiteLayout menuShow={true}>
      {/* Spinner to show loading state */}
      <Spin tip='Loading' size="large" spinning={isFetching}>
        <div className="admin-area">
          <h2>Manage Users</h2>
          <div style={{ marginBottom: 7, display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" size="large" onClick={() => setOpenAddUserDrawer(true)}>
              Add user
            </Button>
          </div>
          {/* Table to display user information */}
          <Table dataSource={tableData} pagination={false}>
            {/* Column for user name */}
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              render={(item, record: UserDetailType) => (
                <Typography.Text
                  onClick={() => {
                    setSelectedUser(record);
                    setUserDetailDrawer(true);
                  }}
                  className='user_name'
                >
                  {item}
                </Typography.Text>
              )}
            />
            {/* Column for user email */}
            <Column title="Email" dataIndex="email" key="email" />
            {/* Column for user last login time */}
            <Column title="Last Login" dataIndex="userLastLogin" key="userLastLogin" />
            {/* Column for user role */}
            <Column
              title="Role"
              dataIndex="role"
              key="role"
              render={(role) => (
                <Tag
                  color={
                    role === 'admin'
                      ? '#99c0ce'
                      : role === 'user'
                        ? '#87d068'
                        : '#108ee9'
                  }
                >
                  {role.charAt(0).toUpperCase() + role.slice(1)}
                </Tag>
              )}
            />
            {/* Column for actions (edit and delete) */}
            <Column
              title="Action"
              key="action"
              width={200}
              align="center"
              render={(_: any, record: UserDetailType) => (
                <>
                  <TooltipComponent
                    placement='top'
                    title='Edit User'
                    body={
                      <Button
                        size="middle"
                        onClick={() => {
                          setEditItem(record);
                          setIsModalOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                    }
                  />
                  {' '}
                  <TooltipComponent
                    placement='top'
                    title='Delete User'
                    body={
                      <Button
                        danger
                        size="middle"
                        onClick={() => {
                          showDeleteConfirm(record.key);
                        }}
                      >
                        Delete
                      </Button>
                    }
                  />
                </>
              )}
            />
          </Table>
          {/* Pagination controls */}
          <div style={{ textAlign: 'right', marginTop: '30px' }}>
            <Pagination
              defaultCurrent={page}
              current={page}
              onChange={onChange}
              total={userInfo && userInfo.pagination && userInfo.pagination.totalItems}
            />
          </div>
        </div>
      </Spin>
      {/* Modal for updating user information */}
      <UpdateUser
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        item={editItem}
        refetch={refetch}
      />
      {/* Drawer for displaying user profile */}
      <UserProfile
        open={userDetailDrawer}
        onClose={() => {
          setUserDetailDrawer(false);
          setSelectedUser(null);
        }}
        selectedUser={selectedUser}
        onEditClick={() => {
          setEditItem(selectedUser);
          setUserDetailDrawer(false);
          setIsModalOpen(true);
        }}
        onDeleteClick={() => {
          showDeleteConfirm(selectedUser.key);
        }}
      />
      <Drawer
        title={'Add User'}
        placement="right"
        width={550}
        onClose={() => setOpenAddUserDrawer(false)}
        open={openAddUserDrawer}
      >
        <AddUser closeDrawer={() => {
          setOpenAddUserDrawer(false)
          refetch()
        }} />
      </Drawer>
    </SiteLayout>
  );
};

export default Admin;
