import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { Link } from 'react-router-dom';
import { McCleeryLogo } from 'components/Icons';
import { useForgotMutation } from 'app/services';

// ForgotPassword component definition
export const ForgotPassword = () => {
  // Hook to manage the forgot password mutation
  const [forgotPassword, { data, isSuccess, isError }] = useForgotMutation();
  
  // State to manage the button disabled status
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Function to handle form submission
  const onFinish = async (values: { email: string }) => {
    await forgotPassword(values).unwrap();
  };

  // Effect to handle notification based on API response
  useEffect(() => {
    if (isError)
      notification.error({
        placement: 'topRight',
        message: data && data?.message.length === 1 ? data?.message[0] : data?.message || 'Invalid email',
        duration: 40,
      });

    if (isSuccess) {
      notification.success({
        placement: 'topRight',
        message: data && data?.message,
        duration: 40,
      });
      setIsButtonDisabled(true);
    }
  }, [data, isError, isSuccess]);

  return (
    // Container for the forgot password form
    <div className="authentication-form">
      <div className="authentication-form-container">
        {/* Logo section */}
        <div className="login-form-img">
          <div className="authentication-form-img">
            <McCleeryLogo />
          </div>
        </div>
        
        {/* Title and subtitle */}
        <h2 className="authentication-form-title">Forgot your password</h2>
        <p className="authentication-form-subtitle">
          Enter the email address associated with your account, and we'll send
          you a link to reset your password.
        </p>
        
        {/* Ant Design Form for email input */}
        <Form
          name="authentication"
          className="form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          {/* Form item for email input */}
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The entered E-mail is not valid E-mail!',
              },
            ]}
          >
            <Input
              placeholder="name@email.com"
              autoComplete="on"
              tabIndex={1}
            />
          </Form.Item>

          {/* Submit button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isButtonDisabled}
              tabIndex={3}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>

        {/* Link to return to sign in page */}
        <div className="return-to-sign-in">
          <Link to="/login">Return to sign in</Link>
        </div>
      </div>
    </div>
  );
};
