import { createApi } from '@reduxjs/toolkit/query/react';
import { USERROUTES, USERSROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import { Pagination } from 'types/parcels';

/**
 * Interface representing user information response.
 */
export interface UserInfoResponse {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  userRoles: any;
  userLastLogin: Date;
  isActive: boolean;
}
export interface UsersDataResponse {
  data: UserInfoResponse[];
  pagination: Pagination;
}

export const userApi = createApi({
  baseQuery,
  reducerPath: 'usersApi',
  endpoints: (builder) => ({
    getAllUser: builder.query<
      UsersDataResponse,
      { page: number; take: number }
    >({
      query: ({ page, take }) => ({
        url: `${USERSROUTES.base}?page=${page}&take=${take}`,
      }),
    }),
    /**
     * API configuration for user-related operations.
     * This API utilizes RTK Query's `createApi` to define endpoints for fetching current user data.
     * @remarks
     * Example usage:
     * ```typescript
     * const { data, error, isLoading } = useGetCurrentUserQuery();
     * ```
     */    
    getCurrentUser: builder.query<UserInfoResponse, string>({
      query: () => ({
        url: `${USERSROUTES.base}/currentUser`,
      }),
    }),
    chnageProfileInfo: builder.mutation<
      Partial<UserInfoResponse>,
      Partial<UserInfoResponse>
    >({
      query: (userInfo) => ({
        url: USERSROUTES.base,
        method: 'PUT',
        body: userInfo,
      }),
    }),
    /**
     * Endpoint for deleting a user by ID.
     * @returns An object containing the HTTP response from the delete user endpoint.
     */    
    deleteUser: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `${USERSROUTES.base}/${id}`,
        method: 'DELETE',
      }),
    }),
    /**
     * Endpoint for updating user roles.
     * @returns An object containing the HTTP response from the update user roles endpoint.
     */    
    updateUserRole: builder.mutation<any, any>({
      query: (info) => ({
        url: `${USERROUTES.base}/${info.userId}/roles`,
        method: 'PUT',
        body: info,
      }),
    }),
  }),
});

export const {
  useGetAllUserQuery,
  useGetCurrentUserQuery,
  useChnageProfileInfoMutation,
  useDeleteUserMutation,
  useUpdateUserRoleMutation,
} = userApi;
