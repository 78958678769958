import ChildProjectPopover from './ChildProjectPopover';
import { ParcelPopover } from './ParcelPopover';
import { ParentProjectPopover } from './ParentProjectPopover';

interface MatchedItemPopoverProps {
  information?: any;
  allData?: any;
  showParcelsLink?: any;
  onViewAllClick: () => void;
}
export const MatchedItemPopover = ({
  onViewAllClick,
  information,
  allData,
}: MatchedItemPopoverProps) => {
  /**
   * Renders a popover component based on the type of information.
   * - Renders `ParcelPopover` if `information.title` is 'Parcel'.
   * - Renders `ParentProjectPopover` if `information.title` is 'Project' and `allData.parent_project_id` is falsy.
   * - Renders `ChildProjectPopover` if `information.title` is 'Project' and `allData.parent_project_id` is truthy.
   */
  return (
    <div className="matched-item-popover">
      { information?.title === 'Parcel' && 
        <ParcelPopover 
          onViewAllClick={onViewAllClick}
          allData={allData}
          information={information}
        />}

      { information?.title === 'Project' && !allData.parent_project_id && 
        <ParentProjectPopover 
          onViewAllClick={onViewAllClick}
          allData={allData}
          information={information}
        />}

      { information?.title === 'Project' && allData.parent_project_id && 
        <ChildProjectPopover 
          onViewAllClick={onViewAllClick}
          allData={allData}
          information={information}
        />}
    </div>
  );
};
