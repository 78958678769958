/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, notification } from 'antd';
import {
  useRegisterMutation,
} from 'app/services';
import { useEffect } from 'react';

interface IRegister {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const AddUser = (props: any) => {

  const [register, { data: isRegister, isSuccess, isError, isLoading, error }] =
    useRegisterMutation();

  useEffect(() => {
    if (isRegister && isSuccess) {
      // Notification for successful registration
      notification.success({
        placement: 'topRight',
        message: 'User has been created successfully',
      });
    }
    if (!isRegister && isError && error) {
      // Notification for registration error
      const errors: any = error;
      notification.error({
        placement: 'topRight',
        message: errors?.data?.message,
      });
    }
    props.closeDrawer()
  }, [isError, isRegister, isSuccess, error]);


  const onFinish = async (values: IRegister) => {
    await register(values).unwrap();
  };

  return (
    <div>
      <Form
        name="authentication"
        className="form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        {/* First Name input */}
        <Form.Item label="First Name">
          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
            ]}
          >
            <Input tabIndex={1} />
          </Form.Item>
        </Form.Item>

        {/* Last Name input */}
        <Form.Item label="Last Name">
          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input tabIndex={2} />
          </Form.Item>
        </Form.Item>

        {/* Email input */}
        <Form.Item label="E-mail">
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'The entered E-mail is not valid E-mail!',
              },
            ]}
          >
            <Input
              placeholder="name@email.com"
              autoComplete="off"
              tabIndex={3}
            />
          </Form.Item>
        </Form.Item>

        {/* Password input */}
        <Form.Item label="Password">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password autoComplete="off" tabIndex={4} />
          </Form.Item>
        </Form.Item>

        {/* Confirm Password input */}
        <Form.Item label="Confirm Password">
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password tabIndex={5} />
          </Form.Item>
        </Form.Item>

        {/* Submit button and link to sign in */}
        <Form.Item>
          <Button
            type="primary"
            loading={isLoading}
            htmlType="submit"
            tabIndex={6}
          >
            Add user
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddUser;
