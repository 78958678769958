import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { notification } from 'antd';
import { resetSurveyFromLocalStorage } from 'utils/resetSurveyFromStorage';
import { BASEURL } from './apiConstants';
import { setAccessToken } from './services';
import { RootState } from './store';

/**
 * Base fetch function configured with RTK Query's `fetchBaseQuery`.
 * Includes headers preparation for authorization using access token.
 */
const curl = fetchBaseQuery({
  baseUrl: BASEURL,
  prepareHeaders: (headers, { getState }) => {
    const token =
      (getState() as RootState).auth.accessToken ||
      localStorage.getItem('accessToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await curl(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // logout
    api.dispatch(setAccessToken(null));
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    resetSurveyFromLocalStorage()
    window.location.href = '/login'
  }
  if (result.error && +result.error.status >= 500) {
    notification.error({
      placement: 'topRight',
      message: 'Oops!',
      description:'Something went wrong at our end',
      duration: 30,
    });
  }
  return result;
};

/**
 * Custom base query function for RTK Query.
 * Handles common error scenarios like unauthorized access (401) and server errors (500+).
 * If unauthorized, clears access token and redirects to login page.
 * If server error occurs, displays a notification to the user.
 */ 
export const LogOut = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
  resetSurveyFromLocalStorage()
};
