import { Checkbox, Collapse, Input, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Fragment, useMemo, useState, useEffect } from 'react';

interface CheckboxGroupProps {
  headerTitle: string;
  count: string;
  options: string[];
  preSelectedList: string[];
  keyValue: string;
  searchValue?: string;
  indexValue?: any;
  activeKeys?: any
  onChange: (value: string[], key: string) => void;
}

interface CollapseCheckboxGroupProps {
  headerTitle: string;
  count: string;
  options: string[];
  preSelectedList: string[];
  keyValue: string;
  searchValue?: string;
  indexValue?: any;
  activeKeys?: any
  optionsFetching?: any
  onChange: (value: string[], key: string) => void;
  onPanelClick: (value: any) => void;
}

export const CheckboxGroup = ({
  headerTitle,
  count,
  options,
  preSelectedList,
  indexValue,
  keyValue,
  onChange,
  searchValue,
  activeKeys,
  onPanelClick,
  optionsFetching
}: CollapseCheckboxGroupProps) => {
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(preSelectedList);

  const isCheckedAll = useMemo(
    () =>
        checkedList.length === options?.length &&
        options?.length > 0,
    [checkedList, options],
  );

  // useEffect(() => {
  //   console.log('checkedList', checkedList)
  // },[checkedList])

  const onChangeHandler = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    onChange(list as string[], keyValue);
  };

  const onSelectAllHandler = (e: CheckboxChangeEvent) => {
    onPanelClick([indexValue])
    const list = e.target.checked ? options : [];
    setCheckedList(list);
    onChange(list, keyValue);
  };

  const { Panel } = Collapse;

  return (
    <div className="properties-checkbox-group">
      <Collapse activeKey={activeKeys} onChange={(e) =>
        onPanelClick(e)}>
        <Panel header={+count && (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              onChange={onSelectAllHandler}
              checked={isCheckedAll}
              className="header-title font-normal font-semi-bold"
            >
              {headerTitle} <span style={{ marginLeft: 5, fontWeight: 'normal' }}>
                ( {count} )</span>
            </Checkbox>
          </div>
        )} key={indexValue}>

          {optionsFetching && !options?.length ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin tip='Loading' size="large"
                spinning={optionsFetching}
              // indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />}
              /> </div> :
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={onChangeHandler}
              className="column-sort"
              value={checkedList}
            >
              {options?.length > 0 &&
                options.map((option: string, index: number) => (
                  <Checkbox
                    key={index}
                    value={option}
                    checked={checkedList.some((item) => item === option)}
                    style={{
                      margin: '0',
                    }}
                  >
                    {option}
                  </Checkbox>
                ))}
            </Checkbox.Group>}
        </Panel>
      </Collapse>

    </div>
  );
};

export const DynamicSearchCollapseCheckboxGroupList =
  ({ data, title, openCollapse, onSearch, optionsFetching,
    titleFetching }: any) => {
    const [searchValue, setSearchValue] = useState<any>('')
    const [activeKeys, setActiveKeys] = useState<any>([]);


    const filteredData: CheckboxGroupProps[] = useMemo(() => {
      return data.reduce((accumulator: any, currentItem: any) => {
        accumulator.push({
          ...currentItem,
          options: currentItem.options,
        });
        return accumulator;
      }, [] as CheckboxGroupProps[]);
    }, [data]);

    useEffect(() => {
      if (filteredData?.length && searchValue) {
        const indices: any = []
        filteredData.forEach((dataElem, dataIndex) => {
          indices.push(dataIndex)
        })
        setActiveKeys(indices)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData])


    useEffect(() => {
      onSearch(searchValue)
      if (!searchValue) {
        setActiveKeys([])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    const onPanelClick = (e: any) => {
      const currentClickedIndex =
        e.filter((value: any) => !activeKeys.includes(value))
      setActiveKeys(e)
      if (currentClickedIndex?.length) {
        openCollapse(filteredData[currentClickedIndex[0]]?.headerTitle)
      }
    }

    // useEffect(() => {
    //   const searchVal = localStorage.getItem('zoningSearchValue')
    //   setSearchValue(searchVal || '')
    // }, [])

    return (
      <div className="search-checkbox">
        <div className="input-wrapper">
          <Input
            placeholder="Search ..."
            className="search-checkbox-input"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        {searchValue && !titleFetching ?
          <p style={{ marginLeft: 5, fontSize: 13 }}>
            {data?.filter((elem: any) => elem.options.
              find((opt: any) => opt.toLowerCase().
                includes(searchValue?.toLowerCase())) || elem.headerTitle
                  .toLowerCase().includes(searchValue?.toLowerCase()))?.
              length} {title?.toLowerCase()} matched for your current search</p>
          : null}
        <Spin tip='Loading' size="large"
          spinning={searchValue && titleFetching}
        >
          <div className="search-checkbox-wrapper">

            {filteredData.map(
              (
                { headerTitle, options, count,
                  preSelectedList, keyValue, onChange },
                index,
              ) => (
                <Fragment key={index}>
                  <CheckboxGroup
                    headerTitle={headerTitle}
                    count={count}
                    options={options}
                    preSelectedList={preSelectedList}
                    keyValue={keyValue}
                    indexValue={index}
                    onChange={onChange}
                    searchValue={searchValue}
                    activeKeys={activeKeys}
                    onPanelClick={onPanelClick}
                    optionsFetching={optionsFetching}
                  />
                </Fragment>
              ),
            )}


          </div>
        </Spin>
      </div>
    );
  };
