export const SurvayJson = [
  {
    name: 'Builder',
    url: 'https://questionpro.com/t/AVIdAZxwHU',
    image: '',
    matchingtype: 'builders',
    title: '',
    surveyname: 'Builder Company Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1Kv'
  },
  {
    name: 'New Build Homebuyer',
    url: 'https://questionpro.com/t/AVIdAZxwPX',
    image: '',
    matchingtype: 'newBuildHomebuyers',
    title: '',
    surveyname: 'New Build Home Buyer Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1Kw'
  },
  {
    name: 'Developer',
    url: 'https://questionpro.com/t/AVIdAZxwPL',
    image: '',
    matchingtype: 'developers',
    title: '',
    surveyname: 'Developer Company Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1K1'
  },
  {
    name: 'Parcels',
    url: 'https://questionpro.com/t/AVIdAZxwPf',
    image: '',
    matchingtype: 'landOwner',
    title: '',
    surveyname: 'Land Owner Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1K2',
    retakebutton: 'Add Parcel'
  },
  {
    name: 'Projects',
    url: 'https://questionpro.com/t/AVIdAZxwPl',
    image: '',
    matchingtype: 'projects',
    title: 'Add Project',
    surveyname: 'I Have a Project Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1K5',
    retakebutton: 'Add Project'
  },
  {
    name: 'Real Estate Clients',
    url: 'https://questionpro.com/t/AVIdAZyK6a',
    image: '',
    matchingtype: 'realEstateAgentsClient',
    title: 'Add Client',
    surveyname: 'Real Estate Agent Client Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1K8',
    retakebutton: 'Add Client'
  },
  {
    name: 'Investor',
    url: 'https://questionpro.com/t/AVIdAZxwPB',
    image: '',
    matchingtype: 'investors',
    title: '',
    surveyname: 'Investor Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1LB'
  },
  {
    name: 'Builder Clients',
    url: 'https://questionpro.com/t/AVIdAZxwPw',
    image: '',
    matchingtype: 'builders',
    title: 'Add Client',
    surveyname: 'Builder Client Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1LC',
    retakebutton: 'Add Client'
  },
  {
    name: 'Real Estate Agent',
    url: 'https://questionpro.com/t/AVIdAZxwPy',
    image: '',
    matchingtype: 'realEstateAgents',
    title: '',
    surveyname: 'Real Estate Agent Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1LD'
  },

  {
    name: 'Water Rights',
    url: 'https://questionpro.com/t/AVIdAZxwP5',
    image: '',
    matchingtype: 'waterRights',
    title: 'Add Water Right',
    surveyname: 'Water Right Survey',
    devUrl: 'https://questionpro.com/t/AVIdAZy1LH',
    retakebutton: 'Add Water Right'
  },
];
