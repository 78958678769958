import { selectCurrentAuthToken } from 'app/services';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { Login, Signup, ResetPassword, ForgotPassword } from 'views';
import Network from 'views/Network';
import Client from 'views/Client';
import Profile from 'views/Profile';
import Admin from 'views/Admin';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { MapPage } from 'views/MapPage/MapPage';
import { RegisteredUsersHome } from 'views/RegisteredUsersHome/RegisteredUsersHome';
import { TermsAndConditions } from 'views/TermsAndConditions';
import { PrivacyStatement } from 'views/PrivacyStatement';
import { CookieConsent } from 'components/CookieConsent';

export const Navigator = () => {
  const token = useSelector(selectCurrentAuthToken);
  const isAuthenticated = useMemo(() => {
    return !!token;
  }, [token]);

  useEffect(() => {
    localStorage.removeItem('parentProjectsData')
    localStorage.removeItem('showingChildProjectOnMap')
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/map" />

        <PrivateRoute path="/map" isAuthenticated={isAuthenticated}>
          <MapPage />
        </PrivateRoute>

        <PrivateRoute path="/network" isAuthenticated={isAuthenticated}>
          <Network />
        </PrivateRoute>

        <PrivateRoute path="/client" isAuthenticated={isAuthenticated}>
          <Client />
        </PrivateRoute>

        <PrivateRoute path="/profile" isAuthenticated={isAuthenticated}>
          <Profile />
        </PrivateRoute>

        <PrivateRoute path="/admin" isAuthenticated={isAuthenticated}>
          <Admin />
        </PrivateRoute>

        {/* <PrivateRoute path="/update-profile" isAuthenticated={isAuthenticated}>
          <UpdateProfile />
        </PrivateRoute> */}

        {/* <PrivateRoute path="/change-password" isAuthenticated={isAuthenticated}>
          <ChangePassword />
        </PrivateRoute> */}

        <PrivateRoute path="/home" isAuthenticated={isAuthenticated}>
          <RegisteredUsersHome />
        </PrivateRoute>

        {/* <PrivateRoute path="/projects" isAuthenticated={isAuthenticated}>
          <Projects />
        </PrivateRoute> */}

        <PublicRoute
          exact={true}
          path="/login"
          isAuthenticated={isAuthenticated}
        >
          <>
            <Login />
            <CookieConsent />
          </>
        </PublicRoute>

        <PublicRoute
          exact={true}
          path="/signup"
          isAuthenticated={isAuthenticated}
        >
          <Signup />
        </PublicRoute>

        <PublicRoute
          exact={true}
          path="/reset-password"
          isAuthenticated={isAuthenticated}
        >
          <ResetPassword />
        </PublicRoute>

        <PublicRoute
          exact={true}
          path="/forgot-password"
          isAuthenticated={isAuthenticated}
        >
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute
          exact={true}
          path="/terms-conditions"
          isAuthenticated={isAuthenticated}
        >
          <TermsAndConditions />
        </PublicRoute>
        <PublicRoute
          exact={true}
          path="/privacy-statement"
          isAuthenticated={isAuthenticated}
        >
          <PrivacyStatement />
        </PublicRoute>
      </Switch>
    </BrowserRouter>
  );
};
