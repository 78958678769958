import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SlidersOutlined } from '@ant-design/icons';
import {
  CriteriaDropdown,
  SearchCheckboxGroup,
  TabbedContent,
  CriteriaFooter,
  SearchCheckboxGroupList,
  MinMaxInput
} from 'components';

import { SelectedFilters } from './SelectedFilters';
import {
  handleSingleValue,
  HYPOTHETICALS,
  parseSearchString,
  PROJECTLOTSIZE,
  stringifySearchObject,
  STYLEOFLOTS,
} from 'utils';
import { setZoomValue, useGetAllCitiesByStateQuery } from 'app/services';
import { constructFilter } from 'utils/constructFilter';
import { message } from 'antd';
import { useResetFilters } from 'utils/resetFilters';
import { useDispatch } from 'react-redux';

export interface RefineCriteriaBoxProps {
  openAdvancedFiltersDrawer: () => void;
  closeInfoWindowOpen: () => void;
  showAllSelectedFilters: any;
}

export const RefineCriteriaBox = ({
  openAdvancedFiltersDrawer,
  closeInfoWindowOpen,
  showAllSelectedFilters
}: RefineCriteriaBoxProps) => {
  const { resetFilters } = useResetFilters()
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch()

  const onResetClick = () => {
    resetFilters()
    dispatch(setZoomValue(6))
    messageApi.info('All Filters Removed!');
  };
  const title = (
    <div className="font-large font-bold top-resetbtn">
      <span>Refine Criteria</span>

      <div
        className="reset-filters font-normal font-semi-bold"
        onClick={onResetClick}
        role="button"
        tabIndex={0}
      >
        <span>Reset all filters</span>
      </div>
    </div>
  );
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const { data: citiesOfUtah } = useGetAllCitiesByStateQuery('Utah');
  const params = useMemo(() => parseSearchString(search), [search]);


  // useEffect(() => {
  //   if (parcelStatus) {
  //     const data: any = []
  //     parcelStatus.forEach((elem: any) => data.push(...elem.propertyStatus))
  //     localStorage.setItem('parcelStatus', JSON.stringify(data))
  //     setParcelStatusArr(data)
  //   }
  // }, [parcelStatus])

  // useEffect(() => {
  //   const localParcelStatus = localStorage.getItem('parcelStatus')
  //   if (localParcelStatus) {
  //     setIsFetchingStatusFromLocal(false)
  //     setParcelStatusArr(JSON.parse(localParcelStatus))
  //   }
  // }, [])


  const [assignedParams, setAssignedParams] = useState<any>(params);
  useEffect(() => {
    setAssignedParams(params);
  }, [params]);
  // VisibilityState
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const isDropdownVisible = useCallback(
    (name: string) => name === activeDropdown,
    [activeDropdown],
  );

  const handleDropdownVisibility = (isVisible: boolean, name: string) => {
    if (isVisible) {
      setActiveDropdown(name);
      return;
    }
    setActiveDropdown(null);
  };

  const handleAfterVisibleChange = useCallback(
    (value: boolean) => {
      if (!value && !activeDropdown) setAssignedParams(params);
    },
    [activeDropdown, params],
  );

  const onChangeHandler = useCallback(
    (value: string[], key: string) => {
      setAssignedParams({ ...assignedParams, [key]: value });
    },
    [assignedParams],
  );

  const onResetClickHandler = (values: string[]) => {
    setAssignedParams((pv: any) => {
      values.forEach((value) => {
        delete pv[value];
      });
      return pv;
    });
  };

  const onSaveClick = useCallback(() => {
    let urlParams = assignedParams
    // localStorage.removeItem('totalParcels')
    const contructedFilter = constructFilter(urlParams)
    setAssignedParams(contructedFilter)
    replace({
      pathname,
      search: stringifySearchObject(contructedFilter),
    });
    setActiveDropdown(null);
    if (
      (!assignedParams.condos || assignedParams.condos.length === 0) &&
      (!assignedParams.acreage || assignedParams.acreage.length === 0) &&
      (!assignedParams.lotSize || assignedParams.lotSize.length === 0) &&
      (!assignedParams.hypotheticals || assignedParams.hypotheticals.length === 0)
    ) {
      messageApi.info('All Filters Removed!');
    } else {
      messageApi.success('Filters Applied!');
    }
  }, [assignedParams, pathname, replace, messageApi]);

  // Unique options
  const onlyUnique = (value: string, index: number, self: any) => {
    return self.indexOf(value) === index;
  };

  return (
    <div className="refine-criteria-container">
      <div className="refine-criteria-filters">
        <div style={{ width: '100%' }}>{title}</div>
        {contextHolder}
        {/* Area and Style Dropdown */}
        <CriteriaDropdown
          criteriaTitle="Area and Style"
          destroyTooltipOnHide
          open={isDropdownVisible('areaAndStyle')}
          onOpenChange={(visibility) =>
            handleDropdownVisibility(visibility, 'areaAndStyle')
          }
          afterOpenChange={handleAfterVisibleChange}
          content={
            <>
              <div className="popup-container">
                <TabbedContent
                  tabData={[
                    {
                      title: 'Number & Style of Lots',
                      content: (
                        <SearchCheckboxGroupList
                          data={STYLEOFLOTS.map((item) => ({
                            ...item,
                            preSelectedList: handleSingleValue(
                              assignedParams[item.keyValue],
                            ),
                            onChange: onChangeHandler,
                          }))}
                        />
                      ),
                    },
                    {
                      title: 'Cities',
                      content: (
                        <SearchCheckboxGroup
                          options={citiesOfUtah?.filter(onlyUnique) || []}
                          headerTitle="All"
                          preSelectedList={handleSingleValue(
                            assignedParams.cities,
                          )}
                          keyValue="cities"
                          onChange={onChangeHandler}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <CriteriaFooter
                onResetClick={() => {
                  onResetClickHandler([
                    'cities',
                    ...STYLEOFLOTS.map((item) => item.keyValue),
                  ]);
                  onSaveClick();
                }}
                onSaveClick={() => onSaveClick()}
                buttonText="Apply"
              />
            </>
          }
        />

        {/* Parcel Dropdown */}
        <CriteriaDropdown
          afterOpenChange={handleAfterVisibleChange}
          destroyTooltipOnHide
          criteriaTitle="Parcel"
          open={isDropdownVisible('parcel')}
          onOpenChange={(visibility) =>
            handleDropdownVisibility(visibility, 'parcel')
          }
          content={
            <>
              <div className="popup-container">
                <TabbedContent
                  tabData={[
                    // {
                    //   title: 'Status',
                    //   content:
                    //     isFetchingStatus && isFetchingStatusFromLocal ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}><Spin tip='Loading' size="large"
                    //       spinning={isFetchingStatus}
                    //     /> </div> : (
                    //       <SearchCheckboxGroup
                    //         options={parcelStatusArr}
                    //         headerTitle="All"
                    //         preSelectedList={handleSingleValue(
                    //           assignedParams.parcelStatus,
                    //         )}
                    //         keyValue="parcelStatus"
                    //         onChange={onChangeHandler}
                    //       />)
                    // },
                    {
                      title: 'Acreage',
                      content: (
                        <MinMaxInput
                          step={0.05}
                          minValue={
                            parseFloat(
                              handleSingleValue(assignedParams.acreage)[0],
                            ) || 0
                          }
                          maxValue={
                            parseFloat(
                              handleSingleValue(assignedParams.acreage)[1],
                            ) || 0
                          }
                          keyValue="acreage"
                          onChange={onChangeHandler}
                        />
                      ),
                    },
                    {
                      title: 'Hypotheticals',
                      content: (
                        <SearchCheckboxGroup
                          options={HYPOTHETICALS}
                          headerTitle="All"
                          preSelectedList={handleSingleValue(
                            assignedParams.hypotheticals,
                          )}
                          keyValue="hypotheticals"
                          onChange={onChangeHandler}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <CriteriaFooter
                onResetClick={() => {
                  onResetClickHandler([
                    'parcelStatus',
                    'hypotheticals',
                    'acreage',
                  ]);
                  onSaveClick();
                }}
                onSaveClick={() => onSaveClick()}
                buttonText="Apply"
              />
            </>
          }
        />

        {/* Project Dropdown */}
        <CriteriaDropdown
          afterOpenChange={handleAfterVisibleChange}
          destroyTooltipOnHide
          criteriaTitle="Project"
          open={isDropdownVisible('project')}
          onOpenChange={(visibility) =>
            handleDropdownVisibility(visibility, 'project')
          }
          content={
            <>
              <div className="popup-container">
                <TabbedContent
                  tabData={[
                    // {
                    //   title: 'Status',
                    //   content: (
                    //     <SearchCheckboxGroupList
                    //       data={[
                    //         {
                    //           options: PROJECTSTATUS,
                    //           headerTitle: 'All',
                    //           preSelectedList: handleSingleValue(
                    //             assignedParams.projectStatus,
                    //           ),
                    //           keyValue: 'projectStatus',
                    //           onChange: onChangeHandler,
                    //         },
                    //         // {
                    //         //   options: ['None'],
                    //         //   headerTitle: 'None',
                    //         //   preSelectedList: handleSingleValue(
                    //         //     assignedParams.projectStatus,
                    //         //   ),
                    //         //   keyValue: 'projectStatus',
                    //         //   onChange: onChangeHandler,
                    //         // },
                    //       ]}
                    //     />
                    //   ),
                    // },
                    {
                      title: 'Lot Size',
                      content: (
                        <SearchCheckboxGroup
                          options={PROJECTLOTSIZE}
                          headerTitle="All"
                          preSelectedList={handleSingleValue(
                            assignedParams.lotSize,
                          )}
                          keyValue="lotSize"
                          onChange={onChangeHandler}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <CriteriaFooter
                onResetClick={() => {
                  onResetClickHandler(['projectStatus', 'lotSize']);
                  onSaveClick();
                }}
                onSaveClick={() => onSaveClick()}
                buttonText="Apply"
              />
            </>
          }
        />
        <div
          className="more-filters-btn"
          onClick={openAdvancedFiltersDrawer}
          aria-hidden="true"
        >
          <SlidersOutlined />
          <span>More filters</span>
        </div>
      </div>
      <SelectedFilters closeInfoWindowOpen={closeInfoWindowOpen}
        showAllSelectedFilters={showAllSelectedFilters} />
    </div>
  );
};
