import { Route, Redirect } from 'react-router-dom';
import { DefaultRouteProps } from './types';

export const PrivateRoute = ({
  children,
  isAuthenticated,
  ...rest
}: DefaultRouteProps) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
