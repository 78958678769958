import { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { ActiveTabType } from 'types/common';
import { TABS } from 'utils';
import { defaultMapProps } from 'utils/mapHelper';

/**
 * Represents the state of common helper data used throughout the application.
 * This interface defines various properties related to geographic coordinates,
 * map settings, active tabs, selected project and parcel IDs, and other UI states.
 */
interface CommonHelperStoreState {
  coords: any;
  zoom: number;
  showAddressMarker: boolean;
  activeTab: ActiveTabType;
  projectsInnerActiveTab: string;
  projectId: number | string;
  parcelId: number | string;
  showParcelsType: any;
  showProjectsType: any;
  isAdvancedFiltersDrawerOpen: boolean;
  showingTotal: number;
  isCountLoading: boolean;
  viewOnMapParcelItem: any;
  mapCenterPoint: any;
  isSelectedResultDrawerOpen: boolean;
  selectedProjectType: string;
  infoWindowOpenValue: boolean;
  infoWindowPositionValue: any;
  dataTypeValue: string;
  isGridViewOpenValue: boolean;
  openParentProjectInfoDrawerValue: boolean;
  openChildProjectInfoDrawerValue: boolean;
  coordsPagination: any;
  selectedItemDetailsOnMap: any;
  resultsPagination: any
  parcelPageCoords: any;
  showPageCoords: any;
  parentProjectListData: any;
  showLoaderOnMap: boolean;
  selectedSurveyTakerDetails: any
  bookmarksDrawerWidth: any
}

/**
 * Slice for managing common helper store state, including map coordinates,
 * zoom level, active tabs, selected IDs, UI states, and other related data.
 */
const slice = createSlice({
  name: 'commonHelperStore',
  initialState: {
    coords: { ...defaultMapProps.center },
    zoom: defaultMapProps.zoom,
    showAddressMarker: false,
    activeTab: TABS[0],
    projectsInnerActiveTab: 'parentProject',
    projectId: '',
    parcelId: '',
    showParcelsType: '',
    showProjectsType: null,
    isAdvancedFiltersDrawerOpen: false,
    showingTotal: 0,
    isCountLoading: false,
    viewOnMapParcelItem: null,
    mapCenterPoint: null,
    isSelectedResultDrawerOpen: false,
    selectedProjectType: '',
    infoWindowOpenValue: false,
    infoWindowPositionValue: null,
    dataTypeValue: '',
    isGridViewOpenValue: false,
    openParentProjectInfoDrawerValue: false,
    openChildProjectInfoDrawerValue: false,
    selectedItemDetailsOnMap: null,
    coordsPagination: { page: 1, limit: 400 },
    resultsPagination: { page: 1, limit: 10 },
    parcelPageCoords: [],
    showPageCoords: true,
    parentProjectListData: [],
    showLoaderOnMap: false,
    selectedSurveyTakerDetails: null,
    bookmarksDrawerWidth: 1050,
  } as CommonHelperStoreState,
  reducers: {
    setCoordsValue(state, action) {
      state.coords = action.payload;
    },
    setZoomValue(state, action) {
      state.zoom = action.payload;
    },
    setShowAddressMarkerValue(state, action) {
      state.showAddressMarker = action.payload;
    },
    setActiveTabValue(state, action) {
      state.activeTab = action.payload;
    },
    setProjectsInnerActiveTabValue(state, action) {
      state.projectsInnerActiveTab = action.payload;
    },
    setProjectIdValue(state, action) {
      state.projectId = action.payload;
    },
    setParcelIdValue(state, action) {
      state.parcelId = action.payload;
    },
    setShowParcelsTypeValue(state, action) {
      state.showParcelsType = action.payload;
    },
    setShowProjectsTypeValue(state, action) {
      state.showProjectsType = action.payload;
    },
    setIsAdvancedFiltersDrawerOpenValue(state, action) {
      state.isAdvancedFiltersDrawerOpen = action.payload;
    },
    setShowingTotalValue(state, action) {
      state.showingTotal = action.payload;
    },
    setIsCountLoadingValue(state, action) {
      state.isCountLoading = action.payload;
    },
    setViewOnMapParcelItemValue(state, action) {
      state.viewOnMapParcelItem = action.payload;
    },
    setMapCenterPointValue(state, action) {
      state.mapCenterPoint = action.payload;
    },
    setIsSelectedResultDrawerOpenValue(state, action) {
      state.isSelectedResultDrawerOpen = action.payload;
    },
    setSelectedProjectTypeValue(state, action) {
      state.selectedProjectType = action.payload;
    },
    setInfoWindowOpenValue(state, action) {
      state.infoWindowOpenValue = action.payload;
    },
    setInfoWindowPositionValue(state, action) {
      state.infoWindowPositionValue = action.payload;
    },
    setDataTypeValue(state, action) {
      state.dataTypeValue = action.payload;
    },
    setIsGridViewOpenValue(state, action) {
      state.isGridViewOpenValue = action.payload;
    },
    setOpenParentProjectInfoDrawerValue(state, action) {
      state.openParentProjectInfoDrawerValue = action.payload;
    },
    setOpenChildProjectInfoDrawerValue(state, action) {
      state.openChildProjectInfoDrawerValue = action.payload;
    },
    setSelectedItemDetailsOnMap(state, action) {
      state.selectedItemDetailsOnMap = action.payload;
    },
    setCoordsPaginationValue(state, action) {
      state.coordsPagination = action.payload;
    },
    setResultsPaginationValue(state, action) {
      state.resultsPagination = action.payload;
    },
    setParcelPageCoordsValue(state, action) {
      state.parcelPageCoords = action.payload;
    },
    setShowPageCoordsValue(state, action) {
      state.showPageCoords = action.payload;
    },
    setParentProjectListDataValue(state, action) {
      state.parentProjectListData = action.payload;
    },
    setShowLoaderOnMap(state, action) {
      state.showLoaderOnMap = action.payload;
    },
    setSelectedSurveyTakerDetails(state, action) {
      state.selectedSurveyTakerDetails = action.payload;
    },
    setDrawerWidthValue(state, action) {
      state.bookmarksDrawerWidth = action.payload;
    },
  },
});

export const commonHelperStoreReducer = slice.reducer;
/**
 * Action creators for managing common helper store state.
 * These actions correspond to various state updates such as setting coordinates,
 * zoom level, active tabs, IDs, UI states, and other related data.
 */
export const {
  setCoordsValue,
  setZoomValue,
  setShowAddressMarkerValue,
  setActiveTabValue,
  setProjectsInnerActiveTabValue,
  setProjectIdValue,
  setParcelIdValue,
  setShowParcelsTypeValue,
  setShowProjectsTypeValue,
  setIsAdvancedFiltersDrawerOpenValue,
  setShowingTotalValue,
  setIsCountLoadingValue,
  setViewOnMapParcelItemValue,
  setMapCenterPointValue,
  setIsSelectedResultDrawerOpenValue,
  setSelectedProjectTypeValue,
  setInfoWindowOpenValue,
  setInfoWindowPositionValue,
  setDataTypeValue,
  setIsGridViewOpenValue,
  setOpenParentProjectInfoDrawerValue,
  setOpenChildProjectInfoDrawerValue,
  setCoordsPaginationValue,
  setSelectedItemDetailsOnMap,
  setResultsPaginationValue,
  setParcelPageCoordsValue,
  setShowPageCoordsValue,
  setParentProjectListDataValue,
  setShowLoaderOnMap,
  setSelectedSurveyTakerDetails,
  setDrawerWidthValue
} = slice.actions;

export const selectCoordsValue: any = (state: RootState) =>
  state.commonHelperStore.coords;
export const selectZoomValue: any = (state: RootState) =>
  state.commonHelperStore.zoom;
export const selectShowAddressMarkerValue: any = (state: RootState) =>
  state.commonHelperStore.showAddressMarker;
export const selectActiveTabValue: any = (state: RootState) =>
  state.commonHelperStore.activeTab;
export const selectProjectsInnerActiveTabValue: any = (state: RootState) =>
  state.commonHelperStore.projectsInnerActiveTab;
export const selectProjectIdValue: any = (state: RootState) =>
  state.commonHelperStore.projectId;
export const selectParcelIdValue: any = (state: RootState) =>
  state.commonHelperStore.parcelId;
export const selectShowParcelsTypeValue: any = (state: RootState) =>
  state.commonHelperStore.showParcelsType;
export const selectShowProjectsTypeValue: any = (state: RootState) =>
  state.commonHelperStore.showProjectsType;
export const selectIsAdvancedFiltersDrawerOpenValue: any = (state: RootState) =>
  state.commonHelperStore.isAdvancedFiltersDrawerOpen;
export const selectShowingTotalValue: any = (state: RootState) =>
  state.commonHelperStore.showingTotal;
export const selectIsCountLoadingValue: any = (state: RootState) =>
  state.commonHelperStore.isCountLoading;
export const selectViewOnMapParcelItemValue: any = (state: RootState) =>
  state.commonHelperStore.viewOnMapParcelItem;
export const selectMapCenterPointValue: any = (state: RootState) =>
  state.commonHelperStore.mapCenterPoint;
export const selectIsSelectedResultDrawerOpenValue: any = (state: RootState) =>
  state.commonHelperStore.isSelectedResultDrawerOpen;
export const selectSelectedProjectTypeValue: any = (state: RootState) =>
  state.commonHelperStore.selectedProjectType;
export const selectInfoWindowOpenValue: any = (state: RootState) =>
  state.commonHelperStore.infoWindowOpenValue;
export const selectInfoWindowPositionValue: any = (state: RootState) =>
  state.commonHelperStore.infoWindowPositionValue;
export const selectDataTypeValue: any = (state: RootState) =>
  state.commonHelperStore.dataTypeValue;
export const selectIsGridViewOpenValue: any = (state: RootState) =>
  state.commonHelperStore.isGridViewOpenValue;
export const selectOpenParentProjectInfoDrawerValue: any = (state: RootState) =>
  state.commonHelperStore.openParentProjectInfoDrawerValue;
export const selectOpenChildProjectInfoDrawerValue: any = (state: RootState) =>
  state.commonHelperStore.openChildProjectInfoDrawerValue;
export const selectSelectedItemDetailsOnMap: any = (state: RootState) =>
  state.commonHelperStore.selectedItemDetailsOnMap;
export const selectCoordsPaginationValue: any = (state: RootState) =>
  state.commonHelperStore.coordsPagination;
export const selectResultsPaginationValue: any = (state: RootState) =>
  state.commonHelperStore.resultsPagination;
export const selectParcelPageCoordsValue: any = (state: RootState) =>
  state.commonHelperStore.parcelPageCoords;
export const selectShowPageCoordsValue: any = (state: RootState) =>
  state.commonHelperStore.showPageCoords;
export const selectParentProjectListDataValue: any = (state: RootState) =>
  state.commonHelperStore.parentProjectListData;
export const selectShowLoaderOnMap: any = (state: RootState) =>
  state.commonHelperStore.showLoaderOnMap;
export const selectSelectedSurveyTakerDetails: any = (state: RootState) =>
  state.commonHelperStore.selectedSurveyTakerDetails;
export const selectDrawerWidthValue: any = (state: RootState) =>
  state.commonHelperStore.bookmarksDrawerWidth;
