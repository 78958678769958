import { Drawer, Row, Col, Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import { useGetSurveyResultsAllQuery } from 'app/services';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoreInfoPopup } from 'components/MoreInfoPopup';

interface IParamType {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  surveyid: string;
  count: any;
  matchingtype: string;
  data: any;
  title: string;
  color: string;
  initPage: number;
}

const ItemListModal: React.FC<IParamType> = ({
  visible,
  setVisible,
  surveyid,
  matchingtype,
  count,
  data,
  title,
  color,
  initPage,
}) => {
  const limit = 100;
  const [listItem, setListItem] = useState(data);
  const [page, setPage] = React.useState(initPage);
  const [hasMore, setHasMore] = useState(true);

  // Reset list items, page, and hasMore when matchingtype changes
  useEffect(() => {
    setListItem([]);
    setPage(1);
    setHasMore(true);
  }, [matchingtype]); // eslint-disable-line

  // Fetch survey results using the query hook
  const {
    data: surveyResult,
    isSuccess,
    isFetching,
  } = useGetSurveyResultsAllQuery(
    { surveyid, page, limit, matchingtype },
    {
      skip: surveyid === '' || matchingtype === '',
    },
  );

  // Update list items when survey results change
  useEffect(() => {
    if (
      surveyResult &&
      surveyResult?.length > 0 &&
      surveyResult[0][matchingtype]
    ) {
      setTimeout(
        () => {
          setListItem(listItem.concat(surveyResult[0][matchingtype]));
        },
        page === 1 ? 0 : 3000,
      );
    }
  }, [surveyResult && surveyResult[0].count, isSuccess]); // eslint-disable-line

  // Determine if there are more items to load
  useEffect(() => {
    page > 1 && setHasMore(count > page * limit ? true : false);
  }, [page]); // eslint-disable-line

  // Function to fetch more data (increment page number)
  const fetchMoreData = () => {
    setPage(page + 1);
  };

  // Function to handle closing the drawer
  const onClose = () => {
    setVisible(false);
    setListItem([]);
    setPage(1);
    setHasMore(true);
  };

  return (
    <Drawer
      placement="right"
      size="large"
      width={'100%'}
      zIndex={1005}
      onClose={onClose}
      visible={visible}
      className="item-list-modal"
    >
      {/* Header section with title */}
      <div
        style={{
          backgroundColor: color,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '26px',
          color: '#fff',
          height: '70px',
          alignItems: 'center',
          marginBottom: '30px',
          borderRadius: '6px',
        }}
      >
        {title}
      </div>

      {/* Infinite scroll component to load more data when scrolled */}
      <InfiniteScroll
        dataLength={listItem.length}
        next={fetchMoreData}
        hasMore={hasMore}
        height={'calc(100vh - 165px)'}
        loader={
          isFetching ? (
            <Loading3QuartersOutlined
              style={{ fontSize: 40, marginTop: 100 }}
              spin
            />
          ) : null
        }
        endMessage={''}
        style={{ overflowX: 'hidden', textAlign: 'center' }}
      >
        <Row gutter={16}>
          {listItem && listItem.length > 0 ? (
            // Display list items if available
            listItem.map((item: any, index: number) => (
              <Col span={6} key={index}>
                <div className="net-list-item">
                  <h4 className="ant-list-item-meta-title">
                    <strong>
                      {item.firstname} {item.lastname}
                    </strong>
                  </h4>
                  <div className="ant-list-item-meta-description">
                    <div>
                      <strong>Email: </strong>
                      {item.email}
                    </div>
                    <div>
                      <strong>Company: </strong>
                      {item.company}
                    </div>
                    <div>
                      <strong>Website: </strong>
                      <a href={item.website} target="_blank" rel="noreferrer">
                        {item.website}
                      </a>
                    </div>
                    <div>
                      <strong>Address: </strong>
                      {item.address}
                    </div>
                    <div>
                      <strong>Phone: </strong>
                      {item.phone && item.phone.length > 6 && item.phone}
                      {item.phoneNumber1 &&
                        item.phoneNumber1.length > 6 &&
                        `, ${item.phoneNumber1}`}
                      {item.phoneNumber2 &&
                        item.phoneNumber2.length > 6 &&
                        `, ${item.phoneNumber2}`}
                    </div>

                    <div className="popup-more-info">
                      <MoreInfoPopup title={title} allData={item} />
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            // Display empty state if no items
            <div className="popupEmptyMsg">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
      </InfiniteScroll>
    </Drawer>
  );
};

export default ItemListModal;
