import React from 'react';
import { Popconfirm } from 'antd';

type BookmarkPopoverProps = {
    okText: any,
    cancelText: any,
    title: any,
    onConfirm: any,
    body: any
}

const BookmarkPopover = ({ okText, cancelText, title, onConfirm, body }: BookmarkPopoverProps) => {
    return (
        <Popconfirm overlayClassName='bookmark-pop-confirm' okText={okText} cancelText={cancelText} title={title} onConfirm={onConfirm} >
            {body}
        </Popconfirm>
    )
}

export default BookmarkPopover