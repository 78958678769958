import { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';

/**
 * Interface representing the state structure for managing store-related information.
 * 
 * Defines the following properties:
 * - `total`: Number representing the total count of items in the store.
 * - `item`: Optional object containing details of a specific item, including its type,
 *   unique identifier (`id`), and additional optional attributes related to parcels or projects.
 * - `currentTab`: Indicates the currently active tab in the UI, which can be 'Parcels', 'Projects', or 'Both'.
 * - `companyId`: ID of the company associated with the store, or undefined if not set.
 */
interface BaseStoreState {
  total: number;
  item:
    | {
        type: 'Parcel' | 'Project' | 'Projects';
        id: string;
        parent_project_id?: any
        parcelId?: any
        formattedParcelId?: any
        id_serial?: any
        ownershipName?: string
        project_name_processed?: string
      }
    | undefined;
  currentTab: 'Parcels' | 'Projects' | 'Both';
  companyId: string | undefined;
}
/**
 * Redux slice for managing base store-related state.
 * 
 * Manages the following state:
 * - `total`: Number representing the total count of items in the store. Initialized to 0.
 * - `item`: Optional object containing details of a specific item (Parcel, Project, or Projects).
 * - `currentTab`: Indicates the currently active tab in the UI, initialized to 'Parcels'.
 * - `companyId`: ID of the company associated with the store, or undefined if not set.
 * 
 * Reducers:
 * - `setTotal`: Updates the `total` state with the provided payload.
 * - `setItem`: Updates the `item` state with the provided payload.
 * - `setCurrentTab`: Updates the `currentTab` state with the provided payload.
 * - `setCompanyId`: Updates the `companyId` state with the provided payload.
 */
const slice = createSlice({
  name: 'baseStore',
  initialState: {
    total: 0,
    item: undefined,
    currentTab: 'Parcels',
    companyId: undefined,
  } as BaseStoreState,
  reducers: {
    setTotal(state, action) {
      state.total = action.payload;
    },
    setItem(state, action) {
      state.item = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setCompanyId(state, action) {
      state.companyId = action.payload;
    },
  },
});

export const baseStoreReducer = slice.reducer;
export const { setTotal, setItem, setCurrentTab, setCompanyId } = slice.actions;

export const selectCurrentTotalItems = (state: RootState) =>
  state.baseStore.total;
export const selectCurrentTab = (state: RootState) =>
  state.baseStore.currentTab;
export const selectCurrentItem = (state: RootState) => state.baseStore.item;
export const selectCurrentCompanyId = (state: RootState) =>
  state.baseStore.companyId;
