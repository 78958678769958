import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Input, Modal, Typography } from 'antd';
import {
  setSelectedItemDetailsOnMap,
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
  useGetAllBookmarksQuery,
} from 'app/services';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDisclosure } from 'utils';

// Props interface for each Bookmark item
interface BookmarkProps {
  description: string;
  onDeleteClick: () => void;
  onBookmarkClick: () => void;
}

// Interface for the saved search item
interface SaveListType {
  description: string;
  id: string;
  url: string;
}

// Bookmark component to display individual saved searches
const Bookmark = ({
  description,
  onDeleteClick,
  onBookmarkClick,
}: BookmarkProps) => {
  const { Text } = Typography;

  return (
    <div className="bookmark-item">
      {/* Display bookmark description with ellipsis and tooltip */}
      <Text
        ellipsis={{ tooltip: description }}
        onClick={onBookmarkClick}
        className="bookmark-name"
      >
        {description}
      </Text>
      <div className="bookmark-actions">
        {/* Icon for deleting the bookmark */}
        <CloseCircleFilled
          onClick={onDeleteClick}
          style={{ fontSize: 10, color: '#707070' }}
        />
      </div>
    </div>
  );
};

// Component to manage and display the list of bookmarks
export const BookmarksList = () => {
  const { confirm } = Modal;
  const { replace } = useHistory();
  const { pathname, search } = useLocation();

  // Mutation hooks for deleting and creating bookmarks
  const [deleteBookmark] = useDeleteBookmarkMutation();
  const [createBookmark] = useCreateBookmarkMutation();

  // State variables for search text, new bookmark name, and list of saved searches
  const [serchText, setSerchText] = useState('');
  const [newBookmark, setNewBookmark] = useState('');
  const dispatch = useDispatch()
  const [saveList, setSaveList] = useState<SaveListType[]>([]);

  // Custom hook for managing modal visibility
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useDisclosure();

  // Query to fetch all bookmarks
  const { data } = useGetAllBookmarksQuery();

  // Effect to update saveList when data changes
  useEffect(() => {
    data && setSaveList(data);
  }, [data]);

  // Function to display confirmation modal before deleting a bookmark
  const showDeleteConfirm = useCallback(
    (deleteId: string) => {
      confirm({
        zIndex: 10000,
        title: 'Are you sure delete this saved search?', // Confirmation modal title
        icon: <ExclamationCircleOutlined />, // Warning icon
        okText: 'Yes', // Text for confirmation button
        okType: 'danger', // Button type for confirmation button
        cancelText: 'No', // Text for cancel button
        onOk() {
          deleteBookmark(deleteId); // Function to delete the bookmark on confirmation
        },
      });
    },
    [confirm, deleteBookmark],
  );

  // Function to handle clicking on a bookmark (navigate and update state)
  const onBookmarkClick = (url: string, description: any) => {
    replace({
      pathname,
      search: url,
    });
    dispatch(setSelectedItemDetailsOnMap({ viewType: 'Saved search', description }));
  };

  // Function to handle clicking on the "Save Search" button
  const onDoneClick = useCallback(() => {
    createBookmark({ description: newBookmark, url: search }); // Create a new bookmark
    closeModal(); // Close the modal after saving
    setNewBookmark(''); // Reset the new bookmark name
  }, [closeModal, createBookmark, newBookmark, search]);

  // Effect to filter saveList based on search text
  useEffect(() => {
    if (data && data.length > 0) {
      setSaveList(
        data.filter((str) => {
          return str.description.includes(serchText);
        }),
      );
    }
  }, [serchText, data]);

  // JSX return statement with comments
  return (
    <div className="naxxa-bookmarks">
      <div className="content font-normal">
        <div className="title font-semi-bold">Saved Searches List</div>
        <div className="search-checkbox">
          {/* Input field for searching bookmarks */}
          <div className="input-wrapper">
            <Input
              placeholder="Search ..."
              className="search-checkbox-input"
              value={serchText}
              onChange={(e) => setSerchText(e.target.value)}
            />
          </div>
        </div>
        <div className="items font-bold-normal">
          {/* Map through saveList to display each Bookmark */}
          {saveList &&
            saveList.length > 0 &&
            saveList.map(({ description, id, url }) => (
              <Bookmark
                key={id}
                description={description}
                onBookmarkClick={() => onBookmarkClick(url, description)}
                onDeleteClick={() => {
                  showDeleteConfirm(id); // Show confirmation modal on delete click
                }}
              />
            ))}
        </div>
      </div>
      <div className="footer">
        {/* Button to open modal for saving a new search */}
        <Button
          type="primary"
          onClick={() => {
            const today = new Date();
            const date =
              today.getFullYear() +
              '-' +
              (today.getMonth() + 1) +
              '-' +
              today.getDate();
            setNewBookmark(date); // Set default bookmark name to current date
            openModal(); // Open modal for saving a new search
          }}
        >
          Save Search
        </Button>
      </div>
      {/* Modal for entering new bookmark name */}
      <Modal
        title="Insert a name for the search"
        zIndex={10000}
        visible={isModalOpen}
        onOk={onDoneClick}
        onCancel={closeModal}
        okText="Done"
        cancelText="Cancel"
      >
        <Input
          value={newBookmark}
          onChange={(e) => setNewBookmark(e.target.value)}
        />
      </Modal>
    </div>
  );
};
