import { Input } from 'antd';
import { useCallback, useRef, useState } from 'react';

interface MinMax {
  min: number;
  max: number;
}

interface MinMaxInputProps {
  step: number;
  minValue?: number;
  maxValue?: number;
  keyValue: string;
  onChange: (value: string[], key: string) => void;
}

// TODO: refactor the onChange handle. simply i dont like what i have done with this
export const MinMaxInput = ({
  minValue,
  maxValue,
  keyValue,
  onChange,
  ...rest
}: MinMaxInputProps) => {
  const [minMaxInputValue, setMinMaxInputValue] = useState<MinMax>({
    min: minValue || 0,
    max: maxValue || 0,
  });
  const minMaxRef = useRef<HTMLDivElement>(null);
  const onChangeHandle = (min: number | string, max: number | string) => {
    onChange([min.toString(), max.toString()], keyValue);
  };
  const handleMinChange = useCallback(
    (value: string) => {
      return Number(value) <= minMaxInputValue.max
        ? Number(value)
        : minMaxInputValue.min;
    },
    [minMaxInputValue],
  );

  return (
    <div className="naxxa-slider-container" ref={minMaxRef}>
      <div className="naxxa-slider-inputs-wrapper">
        <div>
          <div className="header-title font-normal font-semi-bold">
            Min
          </div>
          <Input
            type="number"
            onChange={(e) => {
              setMinMaxInputValue((pv) => ({
                ...pv,
                min: handleMinChange(e.target.value),
              }));
              onChangeHandle(
                handleMinChange(e.target.value),
                minMaxInputValue.max,
              );
            }}
            value={minMaxInputValue.min}
            className="naxxa-slider-input"
          />
        </div>

        <span style={{ marginTop: 23 }}>-</span>
        <div>
          <div className="header-title font-normal font-semi-bold">
            Max
          </div>
          <Input
            type="number"
            onChange={(e) => {
              setMinMaxInputValue((pv) => ({
                ...pv,
                max: Number(e.target.value),
              }));
              onChangeHandle(minMaxInputValue.min, e.target.value);
            }}
            value={minMaxInputValue.max}
            className="naxxa-slider-input"
          />

        </div>
      </div>
    </div>
  );
};
