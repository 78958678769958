import { useCallback, useState } from 'react';

export const useDisclosure = (defaultIsOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(Boolean(defaultIsOpen));

  const close = useCallback(() => setIsOpen(false), []);

  const open = useCallback(() => setIsOpen(true), []);

  const toggle = useCallback(() => setIsOpen((prevIsOpen) => !prevIsOpen), []);

  return { isOpen, open, close, toggle };
};
