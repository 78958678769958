export const constructFilterFoPolygon = (urlParams: any) => {
  if (urlParams.coords) {
    if (
      urlParams.condos ||
      urlParams.townhouse ||
      urlParams.singleFamily ||
      urlParams.commercial ||
      urlParams.land ||
      urlParams.hypotheticals ||
      urlParams.acreage ||
      urlParams.parcelStatus || 
      urlParams.projectStatus ||
      urlParams.lotSize ||
      urlParams.specialProductTypes ||
      urlParams.name || 
      urlParams.landUse
    ) {
      let obj = {
        coords: urlParams.coords,
        condos: urlParams.condos,
        townhouse: urlParams.townhouse,
        singleFamily: urlParams.singleFamily,
        commercial: urlParams.commercial,
        land: urlParams.land,
        hypotheticals: urlParams.hypotheticals,
        acreage: urlParams.acreage,
        parcelStatus: urlParams.parcelStatus,
        projectStatus: urlParams.projectStatus,
        lotSize: urlParams.lotSize,
        specialProductTypes: urlParams.specialProductTypes,
        name: urlParams.name,
        landUse: urlParams.landUse
      };
      return obj;
    } else {
      return {
        coords: urlParams.coords
      };
    }
  } else {
    return urlParams;
  }
};
