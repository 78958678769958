import {
  CITIESFORSEARCH,
  CLIENTPAPERRESIDENTIAL,
  GENERALZONINGQUESTION,
  INVESTMENTAMOUNT,
  INVESTORORLENDER,
  PAPERRESIDENTIAL,
  PRODUCTSCLIENTBUILD,
  PROJECTSURVEYSTATUS,
  PROJECTTYPE,
  REALESTATELICENSETYPE,
  REALESTATEPRODUCTS,
  REALESTATEPRODUCTSCLIENTDEVELOP,
  REALESTATEPRODUCTSDEVELOP,
  REALESTATESPECIALTY,
  SurveyProductsBuild,
  TYPESTYLEOFHOME,
  UTAHAREATAGS,
  customBuilder,
  surveyAmountOfCondos,
  surveyAmountOfSingleFamily,
  surveyAmountOfTownhouse,
  surveyAmountOfTownhouseWithMore,
} from './constants';

/* eslint-disable max-len */
export const SurveyResponseMapping = (data: any) => {
  const { id, name, response, questionProID, clientId, contactId, projectId } =
    data;
  let formattedResponse: any = [];
  if (name === 'Builder Company Survey') {
    formattedResponse = [
      {
        label: 'Which of these areas do you like to work in?',
        value: response.utahAreaTags,
        joinFinalValue: true,
        criteria: 'utahAreaTags',
        options: UTAHAREATAGS,
        type: 'multi_select',
        questionProID,
        questionProField: 'utah_area_tags',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'Which of these products do you build?',
        value: response.whichOfTheseProductsDoYouBuild?.split(';'),
        joinFinalValue: true,
        criteria: 'whichOfTheseProductsDoYouBuild',
        type: 'multi_select',
        options: SurveyProductsBuild,
        questionProID,
        questionProField: 'which_of_these_products_do_you_build_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'Minimum amount of Single Family Lots',
        value:
          response.whatIsTheMinimumAmountOfSingleFamilyLotsYourCompanyIsWillingToPurchasePerSubdivisio,
        criteria:
          'whatIsTheMinimumAmountOfSingleFamilyLotsYourCompanyIsWillingToPurchasePerSubdivisio',
        type: 'select',
        options: surveyAmountOfSingleFamily,
        questionProID,
        questionProField:
          'what_is_the_minimum_amount_of_single_family_lots_your_company_is_willing_to_purchase_per_subdivisio',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Single Family')
          ? false
          : true,
      },
      {
        label: 'Maximum amount of Single Family Lots',
        value:
          response.whatIsTheMaximumAmountOfSingleFamilyLotsYourCompanyIsWillingToPurchasePerSubdivisio,
        criteria:
          'whatIsTheMaximumAmountOfSingleFamilyLotsYourCompanyIsWillingToPurchasePerSubdivisio',
        type: 'select',
        options: surveyAmountOfSingleFamily,
        questionProID,
        questionProField:
          'what_is_the_maximum_amount_of_single_family_lots_your_company_is_willing_to_purchase_per_subdivisio',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Single Family')
          ? false
          : true,
      },
      {
        label: 'Are you a custom builder?',
        value: response.areYouACustomBuilder,
        criteria: 'areYouACustomBuilder',
        type: 'select',
        options: customBuilder,
        questionProID,
        questionProField: 'are_you_a_custom_builder_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Single Family')
          ? false
          : true,
      },
      {
        label: 'Minimum amount of Apartment/Condo units',
        value:
          response.whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToPurchasePerS,
        criteria:
          'whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToPurchasePerS',
        type: 'select',
        options: surveyAmountOfCondos,
        questionProID,
        questionProField:
          'what_is_the_minimum_amount_of_apartment_condo_units_your_company_would_be_willing_to_purchase_per_s',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Condos/Apartments')
          ? false
          : true,
      },
      {
        label: 'Maximum amount of Apartment/Condo units',
        value:
          response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToPurchasePerS,
        criteria:
          'whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToPurchasePerS',
        type: 'select',
        options: surveyAmountOfCondos,
        questionProID,
        questionProField:
          'what_is_the_maximum_amount_of_apartment_condo_units_your_company_would_be_willing_to_purchase_per_s',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Condos/Apartments')
          ? false
          : true,
      },
      {
        label: 'Minimum amount of Townhome pads',
        value:
          response.whatIsTheMinimumAmountOfTownhomePadsYourCompanyIsWillingToPurchasePerSubdivisionAt,
        criteria:
          'whatIsTheMinimumAmountOfTownhomePadsYourCompanyIsWillingToPurchasePerSubdivisionAt',
        type: 'select',
        options: surveyAmountOfTownhouse,
        questionProID,
        questionProField:
          'what_is_the_minimum_amount_of_townhome_pads_your_company_is_willing_to_purchase_per_subdivision_at_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Townhomes')
          ? false
          : true,
      },
      {
        label: 'Maximum amount of Townhome pads',
        value:
          response.whatIsTheMaximumAmountOfTownhomePadsYourCompanyIsWillingToPurchasePerSubdivisionAt,
        criteria:
          'whatIsTheMaximumAmountOfTownhomePadsYourCompanyIsWillingToPurchasePerSubdivisionAt',
        type: 'select',
        options: surveyAmountOfTownhouse,
        questionProID,
        questionProField:
          'what_is_the_maximum_amount_of_townhome_pads_your_company_is_willing_to_purchase_per_subdivision_at_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whichOfTheseProductsDoYouBuild
          ?.split(';')
          ?.includes('Townhomes')
          ? false
          : true,
      },
    ];
  } else if (name === 'Investor Survey') {
    formattedResponse = [
      {
        label: 'What real estate product does your company invest in?',
        value: response.whatRealEstateProductDoesYourCompanyInvestIn,
        joinFinalValue: true,
        criteria: 'whatRealEstateProductDoesYourCompanyInvestIn',
        options: REALESTATEPRODUCTS,
        type: 'multi_select',
        questionProID,
        questionProField:
          'what_real_estate_product_does_your_company_invest_in_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'What areas in Utah do you like to invest in/lend in?',
        value: response.utahAreaInvestmentTags,
        joinFinalValue: true,
        criteria: 'utahAreaInvestmentTags',
        options: UTAHAREATAGS,
        type: 'multi_select',
        questionProID,
        questionProField: 'utah_area_investment_tags',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: "What's your minimum investment/loan amount?",
        value: response.whatSYourMinimumInvestmentAmount,
        criteria: 'whatSYourMinimumInvestmentAmount',
        options: INVESTMENTAMOUNT,
        type: 'select',
        questionProID,
        questionProField: 'what_s_your_minimum_investment_amount_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: "What's your maximum investment/loan amount?",
        value: response.whatSYourMaximumInvestmentAmount,
        criteria: 'whatSYourMaximumInvestmentAmount',
        options: INVESTMENTAMOUNT,
        type: 'select',
        questionProID,
        questionProField: 'what_s_your_maximum_investment_amount_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'Are you an investor or a lender?',
        value: response.areYouAnInvestorOrALender,
        criteria: 'areYouAnInvestorOrALender',
        options: INVESTORORLENDER,
        type: 'select',
        questionProID,
        questionProField: 'are_you_an_investor_or_a_lender_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
    ];
  } else if (name === 'Developer Company Survey') {
    formattedResponse = [
      {
        label: 'What real estate product do you develop?',
        value: response.whatRealEstateProductDoYouDevelop.split(';'),
        joinFinalValue: true,
        criteria: 'whatRealEstateProductDoYouDevelop',
        options: REALESTATEPRODUCTSDEVELOP,
        type: 'multi_select',
        questionProID,
        questionProField: 'what_real_estate_product_do_you_develop_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'Minimum amount of Apartment/Condo units',
        value:
          response.whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerSu ||
          response.whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerS,
        criteria:
          response.whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerSu
            ? 'whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerSu'
            : 'whatIsTheMinimumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerS',
        type: 'select',
        options: surveyAmountOfCondos,
        questionProID,
        questionProField:
          'what_is_the_minimum_amount_of_apartment_condo_units_your_company_would_be_willing_to_develop_per_su',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whatRealEstateProductDoYouDevelop
          ?.split(';')
          ?.includes('Apartments/Condos')
          ? false
          : true,
      },
      {
        label: 'Maximum amount of Apartment/Condo units',
        value:
          response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerSu ||
          response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerS,
        criteria:
          response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerSu
            ? 'whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerSu'
            : response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourCompanyWouldBeWillingToDevelopPerS,
        type: 'select',
        options: surveyAmountOfCondos,
        questionProID,
        questionProField:
          'what_is_the_maximum_amount_of_apartment_condo_units_your_company_would_be_willing_to_develop_per_su',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whatRealEstateProductDoYouDevelop
          ?.split(';')
          ?.includes('Apartments/Condos')
          ? false
          : true,
      },
      {
        label: 'Minimum amount of Single Family Lots',
        value:
          response.whatIsTheMinimumAmountOfSingleFamilyLotsYourCompanyWouldDevelopPerSubdivisionAtOnce,
        criteria:
          'whatIsTheMinimumAmountOfSingleFamilyLotsYourCompanyWouldDevelopPerSubdivisionAtOnce',
        type: 'select',
        options: surveyAmountOfSingleFamily,
        questionProID,
        questionProField:
          'what_is_the_minimum_amount_of_single_family_lots_your_company_would_develop_per_subdivision_at_once',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        hide: response.whatRealEstateProductDoYouDevelop
          ?.split(';')
          ?.includes('Single family')
          ? false
          : true,
      },
      {
        label: 'Maximum amount of Single Family Lots',
        value:
          response.whatIsTheMaximumAmountOfSingleFamilyLotsYourCompanyIsWillingToDevelopPerSubdivision,
        criteria:
          'whatIsTheMaximumAmountOfSingleFamilyLotsYourCompanyIsWillingToDevelopPerSubdivision',
        type: 'select',
        options: surveyAmountOfSingleFamily,
        questionProID,
        questionProField:
          'what_is_the_maximum_amount_of_single_family_lots_your_company_is_willing_to_develop_per_subdivision',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whatRealEstateProductDoYouDevelop
          ?.split(';')
          ?.includes('Single family')
          ? false
          : true,
      },
      {
        label: 'Minimum amount of Townhome pads',
        value:
          response.whatIsTheMinimumAmountOfTownhomePadsYourCompanyIsWillingToDevelopPerSubdivisionAtO,
        criteria:
          'whatIsTheMinimumAmountOfTownhomePadsYourCompanyIsWillingToDevelopPerSubdivisionAtO',
        type: 'select',
        options: surveyAmountOfTownhouseWithMore,
        questionProID,
        questionProField:
          'what_is_the_minimum_amount_of_townhome_pads_your_company_is_willing_to_develop_per_subdivision_at_o',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whatRealEstateProductDoYouDevelop
          ?.split(';')
          ?.includes('Townhomes')
          ? false
          : true,
      },
      {
        label: 'Maximum amount of Townhome pads',
        value:
          response.whatIsTheMaximumAmountOfTownhomePadsYourCompanyIsWillingToDevelopPerSubdivisionAtO,
        criteria:
          'whatIsTheMaximumAmountOfTownhomePadsYourCompanyIsWillingToDevelopPerSubdivisionAtO',
        type: 'select',
        options: surveyAmountOfTownhouseWithMore,
        questionProID,
        questionProField:
          'what_is_the_maximum_amount_of_townhome_pads_your_company_is_willing_to_develop_per_subdivision_at_o',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        id,
        name,
        hide: response.whatRealEstateProductDoYouDevelop
          ?.split(';')
          ?.includes('Townhomes')
          ? false
          : true,
      },
      {
        label:
          'What areas in Utah does your company have interest in and are open to?',
        value: response.utahAreaTags,
        joinFinalValue: true,
        criteria: 'utahAreaTags',
        options: UTAHAREATAGS,
        type: 'multi_select',
        questionProID,
        questionProField: 'utah_area_tags',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label:
          'Do you buy paper residential lots/pads or paper commercial pads?',
        value: response.doYouBuyPaperSubdivisionsOrPaperCommercialPads,
        criteria: 'doYouBuyPaperSubdivisionsOrPaperCommercialPads',
        options: PAPERRESIDENTIAL,
        type: 'select',
        questionProID,
        questionProField:
          'do_you_buy_paper_subdivisions_or_paper_commercial_pads_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
      },
    ];
  } else if (name === 'Land Owner Survey') {
    formattedResponse = [
      {
        label: 'How many acres is your land?',
        value: response.howManyAcresIsYourLand,
        criteria: 'howManyAcresIsYourLand',
        type: 'input',
        questionProID,
        questionProField: 'how_many_acres_is_your_land_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },

      {
        label: 'What is your land generally zoned for currently?',
        value: response.landOwnerGeneralZoningQuestion,
        criteria: 'landOwnerGeneralZoningQuestion',
        options: GENERALZONINGQUESTION,
        type: 'multi_select',
        questionProID,
        questionProField: 'landOwnerGeneralZoningQuestion',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'What City is your property located in?',
        value: response.propertyLocatedInCity,
        criteria: 'propertyLocatedInCity',
        type: 'search_select',
        options: CITIESFORSEARCH,
        questionProID,
        questionProField: 'property_located_in_city',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
    ];
  } else if (name === 'New Build Home Buyer Survey') {
    formattedResponse = [
      {
        label: 'Which cities would you like to build in?',
        value: response.newBuildHomeBuyerDesiredCities,
        criteria: 'newBuildHomeBuyerDesiredCities',
        type: 'search_select',
        questionProID,
        options: CITIESFORSEARCH,
        questionProField: 'new_build_home_buyer_desired_cities',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'What Type/Style of Home are you looking for?',
        value: response.typeStyleOfHome.split(';'),
        joinFinalValue: true,
        criteria: 'typeStyleOfHome',
        options: TYPESTYLEOFHOME,
        type: 'multi_select',
        questionProID,
        questionProField: 'type_style_of_home',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
    ];
  } else if (name === 'Real Estate Agent Survey') {
    formattedResponse = [
      {
        label: 'What is your real estate specialty?',
        value: response.whatIsYourRealEstateSpecialty?.split(';'),
        joinFinalValue: true,
        criteria: 'whatIsYourRealEstateSpecialty',
        options: REALESTATESPECIALTY,
        type: 'multi_select',
        questionProID,
        questionProField: 'what_is_your_real_estate_specialty_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'What is your license type?',
        value: response.whatIsYourLicenseType,
        criteria: 'whatIsYourLicenseType',
        options: REALESTATELICENSETYPE,
        type: 'select',
        questionProID,
        questionProField: 'what_is_your_license_type_',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'Which areas of Utah do you work in?',
        value: response.utahAreaTags,
        joinFinalValue: true,
        criteria: 'utahAreaTags',
        options: UTAHAREATAGS,
        type: 'multi_select',
        questionProID,
        questionProField: 'utah_area_tags',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
    ];
  } else if (name === 'I Have a Project Survey') {
    formattedResponse = [
      {
        label: 'Project City',
        value: response.tempCity,
        criteria: 'tempCity',
        type: 'search_select',
        options: CITIESFORSEARCH,
        questionProID,
        questionProField: 'temp_city',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'What stage in the development process is the project at?',
        value: response.tempProjectDevelopmentStage,
        criteria: 'tempProjectDevelopmentStage',
        options: PROJECTSURVEYSTATUS,
        type: 'select',
        questionProID,
        questionProField: 'temp_project_development_stage',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      // {
      //   label: 'What size of lot do you want?',
      //   value: response.whatSizeOfLotDoYouWant?.split(';'),
      //   joinFinalValue: true,
      //   criteria: 'whatSizeOfLotDoYouWant',
      //   options: LOTSIZE,
      //   type: 'multi_select',
      //   questionProID,
      //   questionProField: 'what_size_of_lot_do_you_want_',
      //   clientId,
      //   contactId,
      //   projectId,
      //   email: response.email || response.surveyTaker,
      //   id,
      //   name,
      //   firstname: response.firstname,
      //   lastname: response.lastname,
      //   company: response.company,
      // },
      {
        label: 'What Type of Project is this?',
        value: response.tempTypeOfProject?.split(';'),
        joinFinalValue: true,
        criteria: 'tempTypeOfProject',
        options: PROJECTTYPE,
        type: 'multi_select',
        questionProID,
        questionProField: 'temp_type_of_project',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
      {
        label: 'How many single family lots?',
        value: response.howManySingleFamilyLots,
        criteria: 'howManySingleFamilyLots',
        type: 'input',
        questionProID,
        questionProField: 'how_many_single_family_lots',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        hide: response.tempTypeOfProject
          ?.split(';')
          ?.includes('Single Family Residential')
          ? false
          : true,
      },
      {
        label: 'How many townhome pads?',
        value: response.howManyTownhomePads,
        criteria: 'howManyTownhomePads',
        type: 'input',
        questionProID,
        questionProField: 'how_many_townhome_pads',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        hide: response.tempTypeOfProject?.split(';')?.includes('Town Homes')
          ? false
          : true,
      },
      {
        label: 'How many apartments/condos?',
        value: response.howManyApartmentsCondos,
        criteria: 'howManyApartmentsCondos',
        type: 'input',
        questionProID,
        questionProField: 'how_many_apartments_condos',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        hide: response.tempTypeOfProject
          ?.split(';')
          ?.includes('Apartments/Condos')
          ? false
          : true,
      },
    ];
  } else if (name === 'Water Right Survey') {
    formattedResponse = [
      {
        label: 'Location of Water Right by City',
        value: response.tempCityOfWaterRight,
        criteria: 'tempCityOfWaterRight',
        type: 'search_select',
        options: CITIESFORSEARCH,
        questionProID,
        questionProField: 'temp_city_of_water_right',
        clientId,
        contactId,
        projectId,
        email: response.email || response.surveyTaker,
        id,
        name,
        firstname: response.firstname,
        lastname: response.lastname,
        company: response.company,
        required: true,
      },
    ];
  } else if (name === 'Real Estate Agent Client Survey') {
    if (response?.clientType === 'Property Seller') {
      formattedResponse = [
        {
          label: "What city is your client's property in?",
          value: response.clientPropertyLocatedInCity,
          criteria: 'clientPropertyLocatedInCity',
          type: 'search_select',
          options: CITIESFORSEARCH,
          questionProID,
          questionProField: 'client_property_located_in_city',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: "How many acres is your client's land?",
          value: response.clientHowManyAcres,
          criteria: 'clientHowManyAcres',
          type: 'input',
          questionProID,
          questionProField: 'client_how_many_acres',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: "What is your client's land generally zoned for currently?",
          value: response.clientSellerCurrentLandZoning?.split(';'),
          joinFinalValue: true,
          criteria: 'clientSellerCurrentLandZoning',
          options: GENERALZONINGQUESTION,
          type: 'multi_select',
          questionProID,
          questionProField: 'client_seller_current_land_zoning',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: 'Which of these areas do you like to work in?',
          value: response.utahAreaTags?.split(';'),
          joinFinalValue: true,
          criteria: 'utahAreaTags',
          options: UTAHAREATAGS,
          type: 'multi_select',
          questionProID,
          questionProField: 'client_utah_area_tags',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          required: true,
        },
      ];
    } else if (response?.clientType === 'Land Buyer') {
      formattedResponse = [
        {
          label: "What is your client's real estate specialty?",
          value: response.whatIsYourClientRealEstateSpecialty?.split(';'),
          joinFinalValue: true,
          criteria: 'whatIsYourClientRealEstateSpecialty',
          options: REALESTATESPECIALTY,
          type: 'multi_select',
          questionProID,
          questionProField: 'what_is_your_client_real_estate_specialty_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: "What is your client's license type?",
          value: response.whatIsYourClientLicenseType,
          criteria: 'whatIsYourClientLicenseType',
          options: REALESTATELICENSETYPE,
          type: 'select',
          questionProID,
          questionProField: 'what_is_your_client_license_type_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: 'Which of these areas do you like to work in?',
          value: response.utahAreaTags?.split(';'),
          joinFinalValue: true,
          criteria: 'utahAreaTags',
          options: UTAHAREATAGS,
          type: 'multi_select',
          questionProID,
          questionProField: 'client_utah_area_tags',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          required: true,
        },
      ];
    } else if (response?.clientType === 'Investor') {
      formattedResponse = [
        {
          label: 'What real estate product does your client invest in?',
          value:
            response.whatRealEstateProductDoesYourClientInvestIn?.split(';'),
          joinFinalValue: true,
          criteria: 'whatRealEstateProductDoesYourClientInvestIn',
          options: REALESTATEPRODUCTS,
          type: 'multi_select',
          questionProID,
          questionProField:
            'what_real_estate_product_does_your_client_invest_in_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: 'What geographic areas in Utah does your client invest in?',
          value: response.clientUtahAreaInvestmentTags?.split(';'),
          joinFinalValue: true,
          criteria: 'clientUtahAreaInvestmentTags',
          options: UTAHAREATAGS,
          type: 'multi_select',
          questionProID,
          questionProField: 'client_utah_area_investment_tags',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          required: true,
        },
        {
          label: "What's your clients minimum investment/loan amount?",
          value: response.clientMinimumInvestmentAmount,
          criteria: 'clientMinimumInvestmentAmount',
          options: INVESTMENTAMOUNT,
          type: 'select',
          questionProID,
          questionProField: 'client_minimum_investment_amount',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          required: true,
        },
        {
          label: "What's your clients maximum investment/loan amount?",
          value: response.clientMaximumInvestmentAmount,
          criteria: 'clientMaximumInvestmentAmount',
          options: INVESTMENTAMOUNT,
          type: 'select',
          questionProID,
          questionProField: 'client_maximum_investment_amount',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
      ];
    } else if (response?.clientType === 'Builder') {
      formattedResponse = [
        {
          label: 'What geographic areas in Utah does your Client Build in?',
          value: response.utahAreaTags?.split(';'),
          joinFinalValue: true,
          criteria: 'utahAreaTags',
          options: UTAHAREATAGS,
          type: 'multi_select',
          questionProID,
          questionProField: 'client_utah_area_tags',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          required: true,
        },
        {
          label: 'Which of these products does your client build?',
          value: response.whichOfTheseProductsDoesYourClientBuild?.split(';'),
          joinFinalValue: true,
          criteria: 'whichOfTheseProductsDoesYourClientBuild',
          options: PRODUCTSCLIENTBUILD,
          type: 'multi_select',
          questionProID,
          questionProField: 'which_of_these_products_does_your_client_build_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: 'Minimum amount of Single Family Lots',
          value:
            response.whatIsTheMinimumAmountOfSingleFamilyLotsYourClientIsWillingToPurchasePerSubdivision,
          criteria:
            'whatIsTheMinimumAmountOfSingleFamilyLotsYourClientIsWillingToPurchasePerSubdivision',
          type: 'select',
          options: surveyAmountOfSingleFamily,
          questionProID,
          questionProField:
            'what_is_the_minimum_amount_of_single_family_lots_your_client_is_willing_to_purchase_per_subdivision',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          hide: response.whichOfTheseProductsDoesYourClientBuild
            ?.split(';')
            ?.includes('Single Family')
            ? false
            : true,
        },
        {
          label: 'Maximum amount of Single Family Lots',
          value:
            response.whatIsTheMaximumAmountOfSingleFamilyLotsYourClientIsWillingToPurchasePerSubdivision,
          criteria:
            'whatIsTheMaximumAmountOfSingleFamilyLotsYourClientIsWillingToPurchasePerSubdivision',
          type: 'select',
          options: surveyAmountOfSingleFamily,
          questionProID,
          questionProField:
            'what_is_the_maximum_amount_of_single_family_lots_your_client_is_willing_to_purchase_per_subdivision',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          hide: response.whichOfTheseProductsDoesYourClientBuild
            ?.split(';')
            ?.includes('Single Family')
            ? false
            : true,
        },
        {
          label: 'Is your client a custom builder?',
          value: response.clientCustomBuilder,
          criteria: 'clientCustomBuilder',
          type: 'select',
          options: customBuilder,
          questionProID,
          questionProField: 'client_custom_builder',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
        },
        {
          label: 'Minimum amount of Townhome pads',
          value:
            response.whatIsTheMinimumAmountOfTownhomePadsYourClientIsWillingToPurchasePerSubdivisionAtO,
          criteria:
            'whatIsTheMinimumAmountOfTownhomePadsYourClientIsWillingToPurchasePerSubdivisionAtO',
          type: 'select',
          options: surveyAmountOfTownhouse,
          questionProID,
          questionProField:
            'what_is_the_minimum_amount_of_townhome_pads_your_client_is_willing_to_purchase_per_subdivision_at_o',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whichOfTheseProductsDoesYourClientBuild
            ?.split(';')
            ?.includes('Townhomes')
            ? false
            : true,
        },
        {
          label: 'Maximum amount of Townhome pads',
          value:
            response.whatIsTheMaximumAmountOfTownhomePadsYourClientIsWillingToPurchasePerSubdivisionAtO,
          criteria:
            'whatIsTheMaximumAmountOfTownhomePadsYourClientIsWillingToPurchasePerSubdivisionAtO',
          type: 'select',
          options: surveyAmountOfTownhouse,
          questionProID,
          questionProField:
            'what_is_the_maximum_amount_of_townhome_pads_your_client_is_willing_to_purchase_per_subdivision_at_o',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whichOfTheseProductsDoesYourClientBuild
            ?.split(';')
            ?.includes('Townhomes')
            ? false
            : true,
        },
        {
          label: 'Minimum amount of Apartment/Condo units',
          value:
            response.whatIsTheMinimumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToPurchasePerSu,
          criteria:
            'whatIsTheMinimumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToPurchasePerSu',
          type: 'select',
          options: surveyAmountOfCondos,
          questionProID,
          questionProField:
            'what_is_the_minimum_amount_of_apartment_condo_units_your_client_would_be_willing_to_purchase_per_su',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whichOfTheseProductsDoesYourClientBuild
            ?.split(';')
            ?.includes('Condos/Apartments')
            ? false
            : true,
        },
        {
          label: 'Maximum amount of Apartment/Condo units',
          value:
            response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToPurchasePerSu,
          criteria:
            'whatIsTheMaximumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToPurchasePerSu',
          type: 'select',
          options: surveyAmountOfCondos,
          questionProID,
          questionProField:
            'what_is_the_maximum_amount_of_apartment_condo_units_your_client_would_be_willing_to_purchase_per_su',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whichOfTheseProductsDoesYourClientBuild
            ?.split(';')
            ?.includes('Condos/Apartments')
            ? false
            : true,
        },
      ];
    } else if (response?.clientType === 'Developer') {
      formattedResponse = [
        {
          label:
            'What areas in Utah does your client have interest in and is open to?',
          value: response.utahAreaTags?.split(';'),
          joinFinalValue: true,
          criteria: 'utahAreaTags',
          options: UTAHAREATAGS,
          type: 'multi_select',
          questionProID,
          questionProField: 'client_utah_area_tags',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          required: true,
        },
        {
          label: 'What real estate product does your client develop?',
          value:
            response.whatRealEstateProductDoesYourClientDevelop?.split(';'),
          joinFinalValue: true,
          criteria: 'whatRealEstateProductDoesYourClientDevelop',
          options: REALESTATEPRODUCTSCLIENTDEVELOP,
          type: 'multi_select',
          questionProID,
          questionProField:
            'what_real_estate_product_does_your_client_develop_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname || response.recommenderFirstName,
          lastname: response.lastname || response.recommenderLastName,
          company: response.company,
          clientCodeName: response.clientCodeName,
          clientType: response.clientType,
          required: true,
        },
        {
          label: 'Minimum amount of Single Family Lots',
          value:
            response.whatIsTheMinimumAmountOfSingleFamilyLotsYourClientWouldDevelopPerSubdivisionAtOnce,
          criteria:
            'whatIsTheMinimumAmountOfSingleFamilyLotsYourClientWouldDevelopPerSubdivisionAtOnce',
          type: 'select',
          options: surveyAmountOfSingleFamily,
          questionProID,
          questionProField:
            'what_is_the_minimum_amount_of_single_family_lots_your_client_would_develop_per_subdivision_at_once_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          hide: response.whatRealEstateProductDoesYourClientDevelop
            ?.split(';')
            ?.includes('Single Family')
            ? false
            : true,
        },
        {
          label: 'Maximum amount of Single Family Lots',
          value:
            response.whatIsTheMaximumAmountOfSingleFamilyLotsYourClientIsWillingToDevelopPerSubdivision,
          criteria:
            'whatIsTheMaximumAmountOfSingleFamilyLotsYourClientIsWillingToDevelopPerSubdivision',
          type: 'select',
          options: surveyAmountOfSingleFamily,
          questionProID,
          questionProField:
            'what_is_the_maximum_amount_of_single_family_lots_your_client_is_willing_to_develop_per_subdivision_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          hide: response.whatRealEstateProductDoesYourClientDevelop
            ?.split(';')
            ?.includes('Single Family')
            ? false
            : true,
        },
        {
          label: 'Minimum amount of Townhome pads',
          value:
            response.whatIsTheMinimumAmountOfTownhomePadsYourClientIsWillingToDevelopPerSubdivisionAtOn,
          criteria:
            'whatIsTheMinimumAmountOfTownhomePadsYourClientIsWillingToDevelopPerSubdivisionAtOn',
          type: 'select',
          options: surveyAmountOfTownhouse,
          questionProID,
          questionProField:
            'what_is_the_minimum_amount_of_townhome_pads_your_client_is_willing_to_develop_per_subdivision_at_on',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whatRealEstateProductDoesYourClientDevelop
            ?.split(';')
            ?.includes('Townhomes')
            ? false
            : true,
        },
        {
          label: 'Maximum amount of Townhome pads',
          value:
            response.whatIsTheMaximumAmountOfTownhomePadsYourClientIsWillingToDevelopPerSubdivisionAtOn,
          criteria:
            'whatIsTheMaximumAmountOfTownhomePadsYourClientIsWillingToDevelopPerSubdivisionAtOn',
          type: 'select',
          options: surveyAmountOfTownhouse,
          questionProID,
          questionProField:
            'what_is_the_maximum_amount_of_townhome_pads_your_client_is_willing_to_develop_per_subdivision_at_on',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whatRealEstateProductDoesYourClientDevelop
            ?.split(';')
            ?.includes('Townhomes')
            ? false
            : true,
        },
        {
          label: 'Minimum amount of Apartment/Condo units',
          value:
            response.whatIsTheMinimumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToDevelopPerSub,
          criteria:
            'whatIsTheMinimumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToDevelopPerSub',
          type: 'select',
          options: surveyAmountOfCondos,
          questionProID,
          questionProField:
            'what_is_the_minimum_amount_of_apartment_condo_units_your_client_would_be_willing_to_develop_per_sub',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whatRealEstateProductDoesYourClientDevelop
            ?.split(';')
            ?.includes('Apartments/Condos')
            ? false
            : true,
        },
        {
          label: 'Maximum amount of Apartment/Condo units',
          value:
            response.whatIsTheMaximumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToDevelopPerSub,
          criteria:
            'whatIsTheMaximumAmountOfApartmentCondoUnitsYourClientWouldBeWillingToDevelopPerSub',
          type: 'select',
          options: surveyAmountOfCondos,
          questionProID,
          questionProField:
            'what_is_the_maximum_amount_of_apartment_condo_units_your_client_would_be_willing_to_develop_per_sub',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
          clientType: response.clientType,
          clientCodeName: response.clientCodeName,
          id,
          name,
          hide: response.whatRealEstateProductDoesYourClientDevelop
            ?.split(';')
            ?.includes('Apartments/Condos')
            ? false
            : true,
        },
        {
          label:
            'Does your client buy paper residential lots/pads or paper commercial pads?',
          value:
            response.doesYourClientBuyPaperResidentialLotsPadsOrPaperCommercialPads,
          criteria:
            'doesYourClientBuyPaperResidentialLotsPadsOrPaperCommercialPads',
          options: CLIENTPAPERRESIDENTIAL,
          type: 'select',
          questionProID,
          questionProField:
            'does_your_client_buy_paper_residential_lots_pads_or_paper_commercial_pads_',
          clientId,
          contactId,
          projectId,
          email: response.email || response.surveyTaker,
          id,
          name,
          firstname: response.firstname,
          lastname: response.lastname,
          company: response.company,
        },
      ];
    }
  }
  return formattedResponse;
};
