import { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { authApi, User } from './auth.api';

type AuthState = {
  user: User | null;
  accessToken: string | null;
};

/**
 * Redux slice for managing authentication state.
 * 
 * Manages the following state:
 * - `user`: Holds user information. Initialized to `null`.
 * - `accessToken`: Holds the access token for authenticated requests. Initialized to `null`.
 * 
 * Reducers:
 * - `setAccessToken`: Updates the accessToken state with the payload value.
 * 
 * Extra Reducers:
 * - Handles login success (`authApi.endpoints.login.matchFulfilled`):
 *   - Updates `accessToken` and `user` state based on the payload from the login API response.
 *   - Stores `accessToken` and serialized `user` data in localStorage for persistence.
 */
const slice = createSlice({
  name: 'auth',
  initialState: { user: null, accessToken: null } as AuthState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.user = payload.user;
        localStorage.setItem('accessToken', payload.accessToken);
        localStorage.setItem('user', JSON.stringify(payload.loginUser));
      },
    );
  },
});

export const authReducer = slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentAuthToken = (state: RootState) =>
  state.auth.accessToken || localStorage.getItem('accessToken');
export const { setAccessToken } = slice.actions;
