export const PARCELSTATUS = ['For Sale', 'Under Contract'];

export const PARCELLOTSIZE = [
  'Condos',
  'Townhomes',
  '.02-.08 Acres',
  '.08-.18 Acres',
  'Quarter Acres (.18-.28)',
  'Third Acres (.28-.38)',
  'High Third Acres (.38-.48)',
  'Half Acres (.42-.7)',
  'Acres (.7-1.2)',
  'Acres+ (1.2 +)',
  'Mobile Home',
  'Commercial Office',
  'Commercial Retail',
  'Commercial Industrial/Manufacturing',
  'Commercial Hospitality',
  'Commercial Storage',
].sort();

export const HYPOTHETICALS = ['Neighbors', 'Proxy', 'Zoning'];

export const LANDUSE = [
  'Apartments/Condos',
  'Single Family',
  'Townhomes',
  'Land',
  'Hospitality',
  'Storage Units',
  'Industrial & Manufacturing',
  'Commercial Office',
  'Commercial Retail',
  'Hospitals',
  'Mobile Parks',
].sort();
