import { ContactInterface } from 'types/contacts';
import { ParcelInterface } from 'types/parcels';
import { ProjectInterface } from 'types/projects';
import { convertToPascalCase } from './convertToPascalCase';
import { getHypotheticalsTitle } from './getHypotheticalsTitle';
import { joinHypotheticals } from './joinHypotheticals';

export const createMetaData = (data: {
  item: ParcelInterface | ProjectInterface | ContactInterface;
  type: string;
}, from?: string) => {
  if (data.type === 'Parcel') {
    const parcel = data.item as ParcelInterface;
    return [
      {
        name: 'Parcel-Id',
        value: parcel.formattedParcelId || parcel.id_serial,
      },
      {
        name: from === 'matching' ? 'Hypotheticals' : getHypotheticalsTitle(),
        value: joinHypotheticals(
          parcel?.possibleNumberAndStyleOfLots,
          parcel?.possibleNumberAndStyleOfLotsNeighbors,
          parcel?.possibleNumberAndStyleOfLotsProxy,
          parcel?.possibleNumberAndStyleOfLotsZoning,
        ),
      },
      { name: 'Acreage', value: parcel.acreage?.toFixed(2) },
      { name: 'City', value: parcel.city_by_geometry || parcel.city },
      { name: 'County', value: parcel.county_by_geometry || parcel.county },
    ];
  } else if (
    data.type === 'Builder' ||
    data.type === 'Investor' ||
    data.type === 'Developer' ||
    data.type === 'Real Estate Agent' ||
    data.type === 'New Build Homebuyers'
  ) {
    const builder = data.item as ContactInterface;
    return [
      {
        name: 'Name',
        value:
          // (builder.firstname && convertToPascalCase(builder.firstname)) ||
          builder.name && convertToPascalCase(builder.name),
      },
      { name: 'Email', value: builder.email },
      {
        name: 'City',
        value: builder.city?.length && builder.city?.join(', '),
      },
      {
        name: 'Contact Type',
        value: builder?.Type ? builder?.Type?.join(', ') : '',
      },
    ];
  }
  if (data.type === 'Water Right') {
    const waterRight: any = data.item;
    return [
      { name: 'WRCHEX', value: waterRight.WRCHEX },
      {
        name: 'Name',
        value: waterRight.OWNER_FIRST_NAME + waterRight.OWNER_LAST_NAME,
      },
      { name: 'Email', value: waterRight.OWNER_EMAIL_ADDRESS },
    ];
  }
  const project = data.item as ProjectInterface;
  const projectData = [
    { name: 'Name', value: project.project_name_processed },

    { name: 'City', value: project.city_by_geometry ? project.city_by_geometry : project.city },
  ];

  if (
    project.BuildableNumberAndStyleOfLots?.length ||
    project.desiredNumberAndStyleOfLots?.length ||
    project.desirednumberandstyleoflots?.length
  ) {
    projectData.push({
      name: project.BuildableNumberAndStyleOfLots?.length
        ? 'Buildable number and style of lots'
        : project.desiredNumberAndStyleOfLots?.length ||
          project.desirednumberandstyleoflots?.length
        ? 'Desired number and style of lots'
        : '',
      value: project.BuildableNumberAndStyleOfLots?.length
        ? Array.isArray(project.BuildableNumberAndStyleOfLots)
          ? project.BuildableNumberAndStyleOfLots.join(', ')
          : project.BuildableNumberAndStyleOfLots
        : project.BuildableNumberAndStyleOfLots?.length
        ? Array.isArray(project.BuildableNumberAndStyleOfLots)
          ? project.BuildableNumberAndStyleOfLots.join(', ')
          : project.BuildableNumberAndStyleOfLots
        : project.desiredNumberAndStyleOfLots?.length
        ? Array.isArray(project.desiredNumberAndStyleOfLots)
          ? project.desiredNumberAndStyleOfLots.join(', ')
          : project.desiredNumberAndStyleOfLots
        : project.desirednumberandstyleoflots?.length
        ? Array.isArray(project.desirednumberandstyleoflots)
          ? project.desirednumberandstyleoflots.join(', ')
          : project.desirednumberandstyleoflots
        : null,
    });
  } else {
    projectData.push({
      name: 'Buildable number and style of lots',
      value: ''
    })
  }

  projectData.push({
    name: project.city_by_geometry ? 'View Child Projects' : 'View Parent Projects',
    value: ''
  })
  return projectData;
};


export const findValidValue = (values: string[]) => {
  return values.reduce((accumulator, currentValue, index, array) => {
    if (currentValue && currentValue !== 'None') {
      array.splice(index + 1);
      return currentValue;
    }
    return accumulator;
  }, '');
};
