import { createApi } from '@reduxjs/toolkit/query/react';
import { ROLESROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
/**
 * API configuration for fetching roles data.
 * This API utilizes RTK Query's `createApi` to define an endpoint for retrieving all roles.
 */
export const rolesApi = createApi({
  baseQuery,
  reducerPath: 'rolesApi',
  endpoints: (builder) => ({
    getAllRoles: builder.query<any, void>({
      query: () => ({
        url: `${ROLESROUTES.base}`,
      }),
    }),
  }),
});

export const { useGetAllRolesQuery } = rolesApi;
