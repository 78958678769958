import { createApi } from '@reduxjs/toolkit/query/react';
import { CITIESROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';

export const citiesApi = createApi({
  baseQuery,
  reducerPath: 'citiesApi',
  /**
   * Retrieves all cities for a given state.
   * @param state - The state for which cities are requested.
   * Usage:
   * ```typescript
   * const { data } = useGetAllCitiesByStateQuery(state);
   * ```
   */
  endpoints: (builder) => ({
    getAllCitiesByState: builder.query<string[], string>({
      query: (state) => ({
        url: `${CITIESROUTES.base}/${state}`,
      }),
    }),
  }),
});

export const { useGetAllCitiesByStateQuery } = citiesApi;
