import {
  FacebookOutlined,
  LeftOutlined,
  LinkedinOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { Col, Divider, Drawer, Rate, Row, Spin, Tabs, Typography } from 'antd';
import {
  selectCurrentCompanyId,
  useGetCompanyMatchedPropertiesQuery,
} from 'app/services';
import { Color, ResultCard } from 'components';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Company } from 'types/company';
import { ParcelInterface } from 'types/parcels';
import { ProjectInterface } from 'types/projects';
import { createMetaData, findValidValue } from 'utils/metaDataHelpers';

interface ViewMoreCompanyProps {
  isViewMoreCompanyOpen: boolean;
  closeViewMoreCompany: () => void;
}

interface TabProps {
  property: string;
  total: number;
}
type TabNames = 'ongoingProjects' | 'latestProjects' | 'matched';

const Tab = ({ property, total }: TabProps) => (
  <div className="tab-title">
    <div className="name">
      <span>{property}</span>
    </div>
    <div className="total">
      <span>{total}</span>
    </div>
  </div>
);
export const ViewMoreCompany = ({
  isViewMoreCompanyOpen,
  closeViewMoreCompany,
}: ViewMoreCompanyProps) => {
  const { TabPane } = Tabs;
  const companyId = useSelector(selectCurrentCompanyId);

  const { Paragraph } = Typography;
  const {
    data,
    isFetching: isFetchingCompany,
    // isSuccess: isSuccessCompany,
  } = useGetCompanyMatchedPropertiesQuery(companyId || '', {
    skip: !isViewMoreCompanyOpen && !companyId,
  });
  const mapping = {
    ongoingProjects: 'Ongoing Projects',
    latestProjects: 'Last 2 Years Projects',
    matched: 'Matched Projects & Parcels',
  };
  const [currentSelectedProperty, setCurrentSelectedProperty] =
    useState<TabNames>('ongoingProjects');

  const companyData: [
    Company | undefined,
    {
      ongoingProjects: { type: string; item: ProjectInterface }[];
      latestProjects: { type: string; item: ProjectInterface }[];
      matched: {
        type: string;
        item: ParcelInterface | ProjectInterface;
      }[];
    },
    ParcelInterface[],
    ProjectInterface[],
  ] = useMemo(() => {
    return [
      data?.company,
      {
        ongoingProjects:
          data?.ongoingProjects.map((item) => ({
            type: 'Project',
            item,
          })) || [],
        latestProjects:
          data?.latestProjects.map((item) => ({
            type: 'Project',
            item,
          })) || [],
        matched: [
          ...(data?.parcels.map((item) => ({ type: 'Parcel', item })) || []),
          ...(data?.projects.map((item) => ({ type: 'Project', item })) || []),
        ],
      },
      data?.parcels || [],
      data?.projects || [],
    ];
  }, [data]);

  const [info, properties, parcels, projects] = companyData;

  return (
    <Drawer
      className="view-more-company-drawer"
      placement="right"
      onClose={closeViewMoreCompany}
      visible={isViewMoreCompanyOpen}
      closeIcon={
        <div className="view-more-company-drawer-close font-large font-semi-bold">
          <LeftOutlined />
          <span>Back</span>
        </div>
      }
    >
      <Tabs
        className="view-more-company-drawer-content"
        defaultActiveKey={currentSelectedProperty}
        onChange={(key) => setCurrentSelectedProperty(key as TabNames)}
        tabPosition="left"
        tabBarExtraContent={{
          left: (
            <div className="company-summary font-large">
              {info && (
                <>
                  <Paragraph className="font-semi-bold">
                    <span>{info?.name}</span>
                  </Paragraph>
                  <div className="company-main-info">
                    {/* <img className="company-image" src={ivory} alt="" /> */}
                    {findValidValue([info?.website]) && (
                      <p>
                        <a
                          href={findValidValue([info?.website])}
                          target="_blank"
                          rel="noreferrer"
                        >
                          website
                        </a>
                      </p>
                    )}
                    <div className="company-rating">
                      <p>
                        Google Reviews
                        <Rate
                          disabled
                          allowHalf
                          defaultValue={3}
                          className="company-rating-value"
                        />
                      </p>
                    </div>
                    <div className="company-social-media">
                      {findValidValue([
                        info?.facebookUrl,
                        info?.facebookCompanyPage,
                      ]) && (
                        <FacebookOutlined
                          onClick={() =>
                            window.open(
                              findValidValue([
                                info?.facebookUrl,
                                info?.facebookCompanyPage,
                              ]),
                            )
                          }
                        />
                      )}
                      {findValidValue([
                        info?.linkedinUrl,
                        info?.linkedinCompanyPage,
                      ]) && (
                        <LinkedinOutlined
                          onClick={() =>
                            window.open(
                              findValidValue([
                                info?.linkedinUrl,
                                info?.linkedinCompanyPage,
                              ]),
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                  <Divider />

                  <div className="company-secondary-info">
                    {/* <p>
                        Matched Project in “City”: <b>{totalCityProjects}</b>
                      </p> */}
                    <p>
                      Matched Projects: <b>{projects.length}</b>
                    </p>
                    <p>
                      Matched Parcels: <b>{parcels.length}</b>
                    </p>
                  </div>
                </>
              )}
            </div>
          ),
        }}
      >
        {data &&
          Object.entries(properties || {})?.map(([property, dataset]) => (
            <TabPane
              tab={
                <Tab
                  property={mapping[property as TabNames]}
                  total={dataset?.length}
                />
              }
              key={property}
            >
              <div className="title">{`${mapping[property as TabNames]}  (${
                dataset?.length
              })`}</div>
              <Spin
                spinning={isFetchingCompany}
                indicator={
                  <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
                }
              >
                <Row
                  className="company-content"
                  gutter={[
                    { xs: 6, sm: 12, md: 16, lg: 24 },
                    { xs: 6, sm: 12, md: 16, lg: 24 },
                  ]}
                >
                  {properties &&
                    properties[currentSelectedProperty].map((result, index) => (
                      <Col key={index}>
                        <ResultCard
                          title={result?.type}
                          titleColor={
                            result?.type === 'Parcel' ? Color.navy : Color.green
                          }
                          data={createMetaData(result)}
                        />
                      </Col>
                    ))}
                </Row>
              </Spin>
            </TabPane>
          ))}
      </Tabs>
    </Drawer>
  );
};
