import { Badge, Button, Col, Empty, Pagination, Row, Spin, Tabs } from 'antd';
import {
  selectCurrentItem,
  setCoordsPaginationValue,
  setParcelIdValue,
  setSelectedProjectTypeValue,
  setSelectedItemDetailsOnMap,
  setShowParcelsTypeValue,
  setZoomValue,
  useGetAllSingleParcelMatchedDataQuery,
  useGetCompanyMatchingDataQuery,
  useGetParcelInfoQuery,
  selectSelectedItemDetailsOnMap
} from 'app/services';
import { useGetMatchingDataQuery } from 'app/services';
import { Color, ResultCard } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noImageAvailable from 'styles/images/noImageAvailable.png';
import { ContactInterface } from 'types/contacts';
import { ParcelInterface } from 'types/parcels';
import { createMetaData } from 'utils/metaDataHelpers';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { ParcelCard } from 'components/MetaData/ParcelCard';
import TooltipComponent from 'components/Tooltip';
interface TabProps {
  property: string;
  total: any;
}

interface ViewAllMatchedProps {
  closeSelectedResultDrawer: () => void;
  isSelectedResultDrawerOpen: boolean;
  openViewMoreCompany: () => void;
}

const ResultsGrid = ({
  dataSource,
  currentPage,
  isLoading,
  limit,
  totalItems,
  setCurrentPage,
  setLimit,
  refetchParcels
}: {
  dataSource:
  | { type: string; item: ParcelInterface | ContactInterface }[]
  | undefined;
  currentPage: number;
  isLoading: any;
  limit: number;
  totalItems: number;
  setCurrentPage: (page: number) => void;
  setLimit: (page: number) => void;
  refetchParcels: () => void;
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  useEffect(() => {
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <div>
      <Row
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={result.item.id}>
              <ResultCard
                refetchParcels={refetchParcels}
                from='matching'
                title={result?.type}
                titleColor={result?.type === 'Parcel' ? Color.navy : Color.green}
                data={createMetaData(result, 'matching')}
                allData={result}
                id_serial={result?.item?.id_serial}
              />
            </Col>
          ))}
      </Row>
      <div className="pagination-container" style={{ left: "unset", width: '722px' }}>
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={limit}
          showTotal={(total, range) => isLoading ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
          onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
          onChange={(pg) => setCurrentPage(pg)} />
      </div>
    </div>
  );
};

export const SelectedResultContent = ({
  isSelectedResultDrawerOpen,
  openViewMoreCompany,
}: ViewAllMatchedProps) => {
  const { TabPane } = Tabs;
  const selectedItem = useSelector(selectCurrentItem);
  const selectedDetailsOnMap: any = useSelector(selectSelectedItemDetailsOnMap)

  const [currentSelectedProperty, setCurrentSelectedProperty] = useState('');
  const [currentSelectedInnerTab, setCurrentSelectedInnerTab] = useState('parent projects')
  const [contactsTotal, setContactsTotal] = useState<any>(0);
  const [showingItems, setShowingItems] = useState<
    { type: string; item: ParcelInterface | ContactInterface }[]
  >([]);

  const { search } = useLocation();

  const { data: selectedParcel } =
    useGetParcelInfoQuery(
      {
        id: selectedItem?.id_serial || '',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
      },
    );

  const Tab = ({ property, total }: TabProps) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
      <div className="total" style={{ marginLeft: 6 }}>
        {/* <span>{total}</span> */}
        <Badge count={total} color='#1C7A92' overflowCount={100000} />
      </div>
    </div>
  );

  const data = useMemo(() => {
    if (selectedItem?.type === 'Parcel') {
      return selectedParcel
        ? {
          information: {
            title: selectedItem?.type || '',
            titleColor: Color.navy,
            image: noImageAvailable,
            allData: selectedParcel.allData,
            id_serial: selectedParcel.id_serial,
            data: createMetaData({
              item: {
                id: selectedItem?.id || '',
                ...selectedParcel,
              } as ParcelInterface,
              type: 'Parcel',
            }),
          },
          matches: {},
        }
        : null;
    }
  }, [selectedItem?.id, selectedItem?.type, selectedParcel]);

  const [parcelsMatchingData, setParcelsMatchingData] = useState<any>()
  const [parcelsMatchesLoading, setParcelsMatchesLoading] = useState(true)

  const { data: parcelToParcelMatches, isSuccess: isParcelToParcelSuccess,
    isFetching: isFetchingParcelToParcel,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'parcels',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToBuilderMatches, isSuccess: isParcelToBuilderSuccess,
    isFetching: isFetchingParcelToBuilder,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'builders',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToInvestorMatches, isSuccess: isParcelToInvestorSuccess,
    isFetching: isFetchingParcelToInvestor,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'investors',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToDeveloperMatches, isSuccess: isParcelToDeveloperSuccess,
    isFetching: isFetchingParcelToDeveloper,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'developers',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToRealEstateAgentMatches,
    isSuccess: isParcelToRealEstateAgentSuccess,
    isFetching: isFetchingParcelToRealEstateAgent,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'realEstateAgents',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToNewBuildHomebuyerMatches,
    isSuccess: isParcelToNewBuildHomebuyerSuccess,
    isFetching: isFetchingParcelToNewBuildHomebuyer,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'newBuildHomebuyers',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToWaterRightsMatches,
    isSuccess: isParcelToWaterRightsSuccess,
    isFetching: isFetchingParcelToWaterRights,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'waterRights',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );

  const { data: parcelToProjectMatches,
    isSuccess: isParcelToProjectSuccess,
    isFetching: isFetchingParcelToProject,
  } =
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: selectedParcel?.id_serial || '',
        matchingType: 'projects',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !selectedParcel?.id_serial
      },
    );



  useEffect(() => {
    const matches = { ...parcelsMatchingData }
    if (!isFetchingParcelToParcel && parcelToParcelMatches?.Parcels) {
      matches.Parcels = parcelToParcelMatches?.Parcels
    }
    if (!isFetchingParcelToBuilder && parcelToBuilderMatches?.Builders) {
      matches.Builders = parcelToBuilderMatches?.Builders
    }
    if (!isFetchingParcelToInvestor && parcelToInvestorMatches?.Investors) {
      matches.Investors = parcelToInvestorMatches?.Investors
    }
    if (!isFetchingParcelToDeveloper && parcelToDeveloperMatches?.Developers) {
      matches.Developers = parcelToDeveloperMatches?.Developers
    }
    if (!isFetchingParcelToRealEstateAgent && parcelToRealEstateAgentMatches?.['Real Estate Agents']) {
      matches['Real Estate Agents'] = parcelToRealEstateAgentMatches['Real Estate Agents']
    }
    if (!isFetchingParcelToNewBuildHomebuyer && parcelToNewBuildHomebuyerMatches?.['New Build Homebuyers']) {
      matches['New Build Homebuyers'] = parcelToNewBuildHomebuyerMatches['New Build Homebuyers']
    }
    if (!isFetchingParcelToWaterRights && parcelToWaterRightsMatches?.['Water Rights']) {
      matches['Water Rights'] = parcelToWaterRightsMatches['Water Rights']
    }
    if (!isFetchingParcelToProject && parcelToProjectMatches?.Projects) {
      matches.Projects = parcelToProjectMatches.Projects
    }
    if (matches) {
      setParcelsMatchingData(Object.entries(matches))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingParcelToParcel, parcelToParcelMatches,
    isFetchingParcelToBuilder, parcelToBuilderMatches,
    isFetchingParcelToInvestor, parcelToInvestorMatches,
    isFetchingParcelToDeveloper, parcelToDeveloperMatches,
    isFetchingParcelToRealEstateAgent, parcelToRealEstateAgentMatches,
    isFetchingParcelToNewBuildHomebuyer, parcelToNewBuildHomebuyerMatches,
    isFetchingParcelToWaterRights, parcelToWaterRightsMatches,
    isFetchingParcelToProject, parcelToProjectMatches
  ])

  useEffect(() => {
    if (!isFetchingParcelToParcel && isParcelToParcelSuccess &&
      !isFetchingParcelToBuilder && isParcelToBuilderSuccess &&
      !isFetchingParcelToInvestor && isParcelToInvestorSuccess &&
      !isFetchingParcelToDeveloper && isParcelToDeveloperSuccess &&
      !isFetchingParcelToRealEstateAgent && isParcelToRealEstateAgentSuccess &&
      !isFetchingParcelToNewBuildHomebuyer && isParcelToNewBuildHomebuyerSuccess
      && !isFetchingParcelToWaterRights && isParcelToWaterRightsSuccess
      && !isFetchingParcelToProject && isParcelToProjectSuccess
    ) {
      setParcelsMatchesLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParcelToParcelSuccess,
    isParcelToBuilderSuccess,
    isParcelToInvestorSuccess,
    isParcelToDeveloperSuccess,
    isParcelToRealEstateAgentSuccess,
    isParcelToNewBuildHomebuyerSuccess,
    isParcelToWaterRightsSuccess,
    isParcelToProjectSuccess
  ])

  // const entries: any[] = useMemo(() => {
  //   return (data && Object.entries(data.matches)) || [];
  // }, [data]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const parcelPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&parcelId=${selectedParcel?.id_serial
      }&matchingType=${currentSelectedProperty}
      &hypothetical=${search && qs.parse(search)?.hypotheticals
        ? qs.parse(search)?.hypotheticals
        : '' || ''
      }
      `,
    [limit, currentPage, currentSelectedProperty, selectedParcel, search],
  );

  const contactsPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&id=${selectedItem?.id_serial
      }&matchingType=${currentSelectedProperty}&hypothetical=${search && qs.parse(search)?.hypotheticals
        ? qs.parse(search)?.hypotheticals
        : '' || ''
      }
      `,
    [limit, currentPage, currentSelectedProperty, selectedItem, search],
  );

  const waterRightssPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&id=${selectedItem?.id_serial
      }&matchingType=${currentSelectedProperty}
      `,
    [limit, currentPage, currentSelectedProperty, selectedItem],
  );

  const projectsPaginatedQuerySearch = useMemo(
    () =>
      `?page=${currentPage}&take=${limit}&id=${selectedItem?.id_serial
      }&matchingType=${currentSelectedProperty}&hypothetical=${search && qs.parse(search)?.hypotheticals
        ? qs.parse(search)?.hypotheticals
        : '' || ''
      }
      `,
    [limit, currentPage, currentSelectedProperty, selectedItem, search],
  );

  const getQuery = () => {
    return currentSelectedProperty === 'Builders' || currentSelectedProperty === 'Investors' || currentSelectedProperty === 'Developers'
      || currentSelectedProperty === 'Real Estate Agents' || currentSelectedProperty === 'New Build Homebuyers'
      ? contactsPaginatedQuerySearch
      : currentSelectedProperty === 'Water Rights'
        ? waterRightssPaginatedQuerySearch : currentSelectedProperty === 'Projects' ? projectsPaginatedQuerySearch
          : parcelPaginatedQuerySearch;
  };
  const getParams = () => {
    return currentSelectedProperty === 'Builders' ||
      currentSelectedProperty === 'Investors' ||
      currentSelectedProperty === 'Developers' ||
      currentSelectedProperty === 'Real Estate Agents'
      ? {
        refetchOnReconnect: true,
        skip:
          !selectedParcel
      }
      : currentSelectedProperty === 'New Build Homebuyers' ||
        currentSelectedProperty === 'Water Rights' || currentSelectedProperty === 'Projects'
        ? {
          refetchOnReconnect: true,
          skip:
            !selectedParcel ||
            !selectedParcel.city
        }
        : {
          refetchOnReconnect: true,
          skip:
            !selectedParcel ||
            (!selectedParcel.city_by_geometry && !selectedParcel.city) ||
            currentSelectedProperty !== 'Parcels',
        };
  };
  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
    refetch: refetchParcels
  } = useGetMatchingDataQuery(getQuery(), getParams());

  const listData = useMemo(() => {
    if (currentSelectedProperty === 'Builders') {
      return parcelsData?.data.map((item) => ({ item, type: 'Builder' })) || [];
    } else if (currentSelectedProperty === 'Investors') {
      return (
        parcelsData?.data.map((item) => ({ item, type: 'Investor' })) || []
      );
    } else if (currentSelectedProperty === 'Developers') {
      return (
        parcelsData?.data.map((item) => ({ item, type: 'Developer' })) || []
      );
    } else if (currentSelectedProperty === 'Real Estate Agents') {
      return (
        parcelsData?.data.map((item) => ({
          item,
          type: 'Real Estate Agent',
        })) || []
      );
    } else if (currentSelectedProperty === 'New Build Homebuyers') {
      return (
        parcelsData?.data.map((item) => ({
          item,
          type: 'New Build Homebuyers',
        })) || []
      );
    } else if (currentSelectedProperty === 'Water Rights') {
      return (
        parcelsData?.data.map((item) => ({
          item,
          type: 'Water Right',
        })) || []
      );
    } else if (currentSelectedProperty === 'Projects') {
      return (
        parcelsData?.data.map((item) => ({
          item,
          type: 'Projects',
        })) || []
      );
    } else {
      return parcelsData?.data.map((item) => ({ item, type: 'Parcel' })) || [];
    }
  }, [parcelsData, currentSelectedProperty]);

  useEffect(() => {
    if (!isFetchingParcels &&
      isSuccessParcels) {
      setContactsTotal(parcelsData?.pagination?.totalItems)
      setShowingItems(listData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingParcels, isSuccessParcels, listData]);

  // companies
  const [currentCompanyPage, setCurrentCompanyPage] = useState<any>(1);
  const [companiesTotal, setCompaniesTotal] = useState<any>(0)
  const [showingCompanyItems, setShowingCompanyItems] = useState<any>([])

  const {
    data: companyMatchingData,
    isFetching: isFetchingCompanyMatches,
    isSuccess: isSuccessCompanyMatches,
    refetch: refetchCompanyMatches
  } = useGetCompanyMatchingDataQuery(`?page=${currentCompanyPage}&take=${limit}&id=${selectedItem?.id_serial
    }&matchingType=${currentSelectedProperty}&hypothetical=${search && qs.parse(search)?.hypotheticals
      ? qs.parse(search)?.hypotheticals
      : '' || ''
    }
  `, {
    refetchOnReconnect: true,
    skip:
      !selectedParcel || !currentSelectedProperty || currentSelectedProperty === 'Parcels'
      || currentSelectedProperty === 'Water Rights'
  });

  const companyListData = useMemo(() => {
    if (currentSelectedProperty === 'Builders') {
      return companyMatchingData?.data?.map((item) => ({ item, type: 'Builder' })) || [];
    } else if (currentSelectedProperty === 'Investors') {
      return (
        companyMatchingData?.data?.map((item) => ({ item, type: 'Investor' })) || []
      );
    } else if (currentSelectedProperty === 'Developers') {
      return (
        companyMatchingData?.data?.map((item) => ({ item, type: 'Developer' })) || []
      );
    } else if (currentSelectedProperty === 'Real Estate Agents') {
      return (
        companyMatchingData?.data?.map((item) => ({
          item,
          type: 'Real Estate Agent',
        })) || []
      );
    } else if (currentSelectedProperty === 'New Build Homebuyers') {
      return (
        companyMatchingData?.data?.map((item) => ({
          item,
          type: 'New Build Homebuyers',
        })) || []
      );
    }
    else if (currentSelectedProperty === 'Projects') {
      return (
        companyMatchingData?.data?.map((item) => ({
          item,
          type: 'Projects',
        })) || []
      );
    }
  }, [companyMatchingData, currentSelectedProperty]);

  useEffect(() => {
    if (!isFetchingCompanyMatches &&
      isSuccessCompanyMatches) {
      setCompaniesTotal(companyMatchingData?.pagination?.totalItems)
      setShowingCompanyItems(companyListData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingCompanyMatches, isSuccessCompanyMatches, companyListData]);
  // companies

  const propertyChangeHandler = (key: string) => {
    setShowingItems([]);
    setShowingCompanyItems([]);
    setCurrentPage(1);
    setLimit(20);
    setCurrentCompanyPage(1)
    setCurrentSelectedProperty(key);
  };
  const dispatch = useDispatch()

  const innerTabChangeHandler = (e: any) => {
    if (e === 'parent projects') {
      dispatch(setSelectedProjectTypeValue('parent'))
    } else {
      dispatch(setSelectedProjectTypeValue('child'))
    }
    setCurrentSelectedInnerTab(e)
  }

  useEffect(() => {
    if (selectedDetailsOnMap && selectedDetailsOnMap?.currentSelectedProperty) {
      setCurrentSelectedProperty(selectedDetailsOnMap?.currentSelectedProperty)
    } else if (parcelsMatchingData?.length) {
      setCurrentSelectedProperty(parcelsMatchingData[0][0]);
    }
    if (selectedDetailsOnMap && selectedDetailsOnMap?.currentSelectedInnerTab) {
      setCurrentSelectedInnerTab(selectedDetailsOnMap?.currentSelectedInnerTab)
    }
    if (selectedDetailsOnMap?.currentPage) {
      setCurrentPage(selectedDetailsOnMap?.currentPage)
    }
    if (selectedDetailsOnMap?.limit) {
      setLimit(selectedDetailsOnMap?.limit)
    }
    if (selectedDetailsOnMap?.currentCompanyPage) {
      setCurrentCompanyPage(selectedDetailsOnMap?.currentCompanyPage)
    }
  }, [selectedDetailsOnMap, parcelsMatchingData])
  return (
    <>
      {/* {isFetchingSelectedParcel && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '26%' }}>
        <Spin tip='Loading' size="large"
          spinning={isFetchingSelectedParcel}
        /> </div>} */}
      {data?.information ? (
        <Tabs
          className={`selected-result-drawer-content`}
          activeKey={currentSelectedProperty}
          onChange={(key) => propertyChangeHandler(key)}
          tabPosition="left"
          tabBarExtraContent={{
            left: (
              <>
                <div className="result-summary">
                  {data && <ParcelCard {...data.information} />}
                </div>
              </>
            ),
          }}
        >
          {parcelsMatchingData?.length && !parcelsMatchesLoading ? (
            parcelsMatchingData?.map(([property, { count: total }]: any,
              index: any) => (
              <TabPane
                tab={<Tab property={property}
                  total={parcelsMatchingData[index][1]} />}
                key={property}
              >
                <div className="title matches-title">
                  {/* {`${property}  (${parcelsMatchingData[index][1]})`} */}
                  <span>
                    {property}{' '}
                    <Badge count={parcelsMatchingData.find((elem: any) => elem[0] === currentSelectedProperty)?.[1]} color='#1C7A92' overflowCount={100000} />
                  </span>
                  {property === 'Parcels' &&
                    <TooltipComponent
                      placement='top'
                      title='View all on map'
                      body={<Button className='title-btn' type="primary" onClick={() => {
                        if (selectedItem) {
                          dispatch(setParcelIdValue(selectedItem.id_serial))
                          dispatch(setShowParcelsTypeValue('allMatchingParcels'))
                          // dispatch(setIsSelectedResultDrawerOpenValue(false))
                          dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
                          dispatch(setZoomValue(6))
                          dispatch(setSelectedItemDetailsOnMap({ selectedType: 'parcel', id: selectedItem?.formattedParcelId, matchingType: 'Parcel to parcels', name: selectedItem?.formattedParcelId, totalCount: parcelToParcelMatches?.Parcels, currentSelectedProperty, currentSelectedInnerTab, currentPage, limit }))
                        }
                      }}>View all on map</Button>}
                    />
                  }
                </div>
                {currentSelectedProperty === 'Builders' || currentSelectedProperty === 'Investors' || currentSelectedProperty === 'Developers'
                  || currentSelectedProperty === 'Real Estate Agents' || currentSelectedProperty === 'New Build Homebuyers' ?
                  <div className='matching-inner-tab'>
                    <Tabs type='card'>
                      <TabPane
                        key="contacts"
                        tab={
                          <Tab
                            property="Contacts "
                            total={isFetchingParcels ? `(Loading..)` : contactsTotal ? `(${contactsTotal})` : `(0)`}
                          />
                        }
                      >
                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingParcels}
                        >
                          <ResultsGrid
                            limit={limit}
                            isLoading={isFetchingParcels}
                            dataSource={showingItems}
                            currentPage={currentPage}
                            totalItems={parcelsData?.pagination?.totalItems || 0}
                            setLimit={setLimit}
                            setCurrentPage={setCurrentPage}
                            refetchParcels={refetchParcels}
                          />
                        </Spin>
                      </TabPane>
                      <TabPane
                        key="companies"
                        tab={
                          <Tab
                            property="Companies "
                            total={isFetchingCompanyMatches ? `(Loading..)` : companiesTotal ? `(${companiesTotal})` : `(0)`}
                          />
                        }
                      >
                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingCompanyMatches}
                        >
                          <ResultsGrid
                            isLoading={isFetchingCompanyMatches}
                            limit={limit}
                            dataSource={showingCompanyItems}
                            currentPage={currentCompanyPage}
                            totalItems={companyMatchingData?.pagination?.totalItems || 0}
                            setLimit={setLimit}
                            setCurrentPage={setCurrentCompanyPage}
                            refetchParcels={refetchCompanyMatches}
                          />
                        </Spin>
                      </TabPane>
                    </Tabs>
                  </div>
                  : null
                }
                {currentSelectedProperty === 'Projects' ?
                  <div className='matching-inner-tab'>
                    <Tabs type='card' defaultActiveKey={currentSelectedInnerTab} onChange={(e) => innerTabChangeHandler(e)}>
                      <TabPane
                        key="parent projects"
                        tab={
                          <Tab
                            property="Parent Projects "
                            total={isFetchingCompanyMatches ? `(Loading..)` : companiesTotal ? `(${companiesTotal})` : `(0)`}
                          />
                        }
                      >

                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingCompanyMatches}
                        >
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '18px' }}>
                              <TooltipComponent
                                placement='top'
                                title='View all on map'
                                body={<Button className='title-btn' type="primary" disabled={!companyMatchingData?.pagination?.totalItems} onClick={() => {
                                  if (selectedItem) {
                                    dispatch(setParcelIdValue(selectedItem.id_serial))
                                    dispatch(setSelectedProjectTypeValue('parent'))
                                    // dispatch(setIsSelectedResultDrawerOpenValue(false))
                                    dispatch(setCoordsPaginationValue({ page: currentCompanyPage, limit }))
                                    dispatch(setZoomValue(6))
                                    dispatch(setSelectedItemDetailsOnMap({ selectedType: 'parcel', id: selectedItem?.formattedParcelId, matchingType: 'Parcel to parent projects', name: selectedItem?.formattedParcelId, totalCount: companyMatchingData?.pagination?.totalItems, currentSelectedProperty, currentSelectedInnerTab, currentCompanyPage, limit }))
                                  }
                                }}>View all on map</Button>}
                              />
                            </div>
                            <ResultsGrid
                              isLoading={isFetchingCompanyMatches}
                              limit={limit}
                              dataSource={showingCompanyItems}
                              currentPage={currentCompanyPage}
                              totalItems={companyMatchingData?.pagination?.totalItems || 0}
                              setCurrentPage={setCurrentCompanyPage}
                              setLimit={setLimit}
                              refetchParcels={refetchCompanyMatches}
                            />
                          </div>
                        </Spin>
                      </TabPane>
                      <TabPane
                        key="child projects"
                        tab={
                          <Tab
                            property="Child Projects "
                            total={isFetchingParcels ? `(Loading..)` : contactsTotal ? `(${contactsTotal})` : `(0)`}
                          />
                        }
                      >
                        <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                          spinning={isFetchingParcels}
                        >
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '18px' }}>
                              <TooltipComponent
                                placement='top'
                                title='View all on map'
                                body={<Button disabled={!parcelsData?.pagination?.totalItems} className='title-btn' type="primary" onClick={() => {
                                  if (selectedItem) {
                                    dispatch(setParcelIdValue(selectedItem.id_serial))
                                    dispatch(setSelectedProjectTypeValue('child'))
                                    // dispatch(setIsSelectedResultDrawerOpenValue(false))
                                    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
                                    dispatch(setZoomValue(6))
                                    dispatch(setSelectedItemDetailsOnMap({ selectedType: 'parcel', id: selectedItem?.formattedParcelId, matchingType: 'Parcel to child project', name: selectedItem?.formattedParcelId, totalCount: parcelsData?.pagination?.totalItems, currentSelectedProperty, currentSelectedInnerTab, currentPage, limit }))
                                  }
                                }}>View all on map</Button>}
                              />
                            </div>
                            <ResultsGrid
                              isLoading={isFetchingParcels}
                              limit={limit}
                              dataSource={showingItems}
                              currentPage={currentPage}
                              totalItems={parcelsData?.pagination?.totalItems || 0}
                              setLimit={setLimit}
                              setCurrentPage={setCurrentPage}
                              refetchParcels={refetchParcels}
                            />
                          </div>
                        </Spin>
                      </TabPane>

                    </Tabs>
                  </div> : currentSelectedProperty === 'Parcels' || currentSelectedProperty === 'Water Rights' ?
                    <Spin tip='Loading' size="large" style={{ margin: -35, marginTop: 30 }}
                      spinning={isFetchingParcels}
                    >

                      <ResultsGrid
                        isLoading={isFetchingParcels}
                        limit={limit}
                        dataSource={showingItems}
                        currentPage={currentPage}
                        totalItems={parcelsData?.pagination?.totalItems || 0}
                        setCurrentPage={setCurrentPage}
                        setLimit={setLimit}
                        refetchParcels={refetchParcels}
                      />
                    </Spin> : null
                }
              </TabPane>
            ))
          ) : (
            <Empty className="selected-result-drawer-content-empty" />
          )}
        </Tabs>
      ) : null}
    </>
  );
};