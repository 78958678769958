import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Form, Select } from 'antd';
import SiteLayout from 'components/SiteLayout';
import { Link, useHistory } from 'react-router-dom';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetSurveyResultsAllQuery,
  useGetClientCodeNamesQuery,
  useGetCurrentUserQuery,
} from 'app/services';
import { SurveysDataResponse } from 'types/surveys';
import { skipToken } from '@reduxjs/toolkit/query';
import CountUp from 'react-countup';
import Item from './Item';
import ItemListModal from './ItemListModal';

const { Option } = Select;

const Network = () => {
  // Fetching current user information
  const { data: userInfo, isFetching } = useGetCurrentUserQuery('');

  // Fetching client code names based on user email
  const { data: codeOptions, isFetching: isFetchingCode } =
    useGetClientCodeNamesQuery(
      {
        email: userInfo && userInfo.email ? userInfo.email : '',
        code: '',
      },
      {
        skip: typeof userInfo === 'undefined' || userInfo.email === '',
      },
    );

  // State variables for component state management
  const [surveyid, setSurveyid] = useState('');
  const [matchingtype, setMatchingtype] = useState('');
  const [visibleModel, setVisibleModel] = useState(false);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [selectedType, setSelectedType] = useState<any>({});
  const { push } = useHistory();

  // Fetch logged in user information from local storage
  const loginUser =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user') as string);

  // Fetch survey results for different matching types
  let {
    data: builderResult,
    isSuccess: isBuilderSurvey,
    isFetching: isBuilderFetching,
  } = useGetSurveyResultsAllQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'builders',
    },
    { skip: !surveyid },
  );

  let {
    data: investorResult,
    isSuccess: isInvestorSurvey,
    isFetching: isInvestorFetching,
  } = useGetSurveyResultsAllQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'investors',
    },
    { skip: !surveyid },
  );

  let {
    data: developerResult,
    isSuccess: isDeveloperSurvey,
    isFetching: isDeveloperFetching,
  } = useGetSurveyResultsAllQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'developers',
    },
    { skip: !surveyid },
  );

  let {
    data: realEstateAgentResult,
    isSuccess: isRealEstateAgentSurvey,
    isFetching: isRealEstateAgentFetching,
  } = useGetSurveyResultsAllQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'realEstateAgents',
    },
    { skip: !surveyid },
  );

  let {
    data: newBuildHomebuyerResult,
    isSuccess: isNewBuildHomebuyerSurvey,
    isFetching: isNewBuildHomebuyerFetching,
  } = useGetSurveyResultsAllQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'newBuildHomebuyers',
    },
    { skip: !surveyid },
  );

  let {
    data: waterRightsResult,
    isSuccess: isWaterRightsSurvey,
    isFetching: isWaterRightsFetching,
  } = useGetSurveyResultsAllQuery(
    {
      surveyid: surveyid ? surveyid : skipToken,
      page: 1,
      limit: 10,
      matchingtype: 'waterRights',
    },
    { skip: !surveyid },
  );

  // Utility functions to get count and details of surveys
  const getCount = (survey: any, type: string) => {
    if (survey && survey.length > 0) {
      const surveyData = survey.filter(
        (src: SurveysDataResponse) => src.matchingtype === type,
      );
      return surveyData.length > 0 ? surveyData[0].count : 0;
    } else {
      return 0;
    }
  };

  const getSurveyDetails = (survey: any, type: string) => {
    if (survey && survey.length > 0) {
      const surveyData = survey.filter(
        (src: SurveysDataResponse) => src.matchingtype === type,
      );
      return surveyData.length > 0 ? surveyData[0][type] : [];
    } else {
      return [];
    }
  };

  // Function to set survey id
  const onSetSurveyid = (id: any) => {
    setSurveyid(id);
  };

  // Redirect registered user to home page
  useEffect(() => {
    loginUser.role === 'registered' && push({ pathname: '/home' });
  }, [loginUser.role, push]);

  return (
    <SiteLayout menuShow={true} setSurveyid={onSetSurveyid}>
      {/* Spinner to show loading state */}
      <Spin
        spinning={
          isFetching ||
          isFetchingCode ||
          isBuilderFetching ||
          isInvestorFetching ||
          isDeveloperFetching ||
          isRealEstateAgentFetching ||
          isNewBuildHomebuyerFetching ||
          isWaterRightsFetching
        }
        indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />}
      >
        <div className="network-area">
          {/* Client code name selection */}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row clientLebel" span={12}>
              <Form.Item name="codeName" label="Client code name">
                <Select
                  placeholder="Select client code name"
                  onChange={(value: string) => setSurveyid(value)}
                  allowClear
                >
                  {codeOptions &&
                    codeOptions.length > 0 &&
                    codeOptions.map((option, index: number) => (
                      <Option value={option.surveyid} key={index}>
                        {option.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Matching type selection */}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {/* Builders */}
            <Col className="gutter-row" span={8}>
              <div
                className="matching-type"
                style={{ backgroundColor: 'rgb(202,178,159)' }}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setVisibleModel(true);
                    setMatchingtype('builders');
                    setTitle('Builders');
                    setColor('rgb(202,178,159)');
                    setSelectedType({
                      isSurvey: isBuilderSurvey,
                      surveyResult: builderResult,
                    });
                  }}
                  className="type-color"
                >
                  Builders
                </Link>
              </div>
              {isBuilderFetching ? (
                <div className="skeleton-div">
                  <Skeleton height={42} count={1} />
                  <Skeleton height={160} count={2} />
                </div>
              ) : (
                <div
                  className="matching-count"
                  style={{ color: 'rgb(202,178,159)' }}
                >
                  <CountUp
                    end={isBuilderSurvey && getCount(builderResult, 'builders')}
                  />
                  <Item
                    items={
                      isBuilderSurvey
                        ? getSurveyDetails(builderResult, 'builders')
                        : []
                    }
                    count={
                      isBuilderSurvey && getCount(builderResult, 'builders')
                    }
                    surveyid={surveyid}
                    matchingtype={'builders'}
                  />
                </div>
              )}
            </Col>

            {/* Investors */}
            <Col className="gutter-row" span={8}>
              <div
                className="matching-type"
                style={{ backgroundColor: 'rgb(141,196,148)' }}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setVisibleModel(true);
                    setMatchingtype('investors');
                    setTitle('Investors');
                    setColor('rgb(141,196,148)');
                    setSelectedType({
                      isSurvey: isInvestorSurvey,
                      surveyResult: investorResult,
                    });
                  }}
                  className="type-color"
                >
                  Investors
                </Link>
              </div>
              {isInvestorFetching ? (
                <div className="skeleton-div">
                  <Skeleton height={42} count={1} />
                  <Skeleton height={160} count={2} />
                </div>
              ) : (
                <div
                  className="matching-count"
                  style={{ color: 'rgb(141,196,148)' }}
                >
                  <CountUp
                    end={
                      isInvestorSurvey && getCount(investorResult, 'investors')
                    }
                  />
                  <Item
                    items={
                      isInvestorSurvey
                        ? getSurveyDetails(investorResult, 'investors')
                        : []
                    }
                    count={
                      isInvestorSurvey && getCount(investorResult, 'investors')
                    }
                    surveyid={surveyid}
                    matchingtype={'investors'}
                  />
                </div>
              )}
            </Col>

            {/* Developers */}
            <Col className="gutter-row" span={8}>
              <div
                className="matching-type"
                style={{ backgroundColor: 'rgb(232,186,69)' }}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setVisibleModel(true);
                    setMatchingtype('developers');
                    setTitle('Developers');
                    setColor('rgb(232,186,69)');
                    setSelectedType({
                      isSurvey: isDeveloperSurvey,
                      surveyResult: developerResult,
                    });
                  }}
                  className="type-color"
                >
                  Developers
                </Link>
              </div>
              {isDeveloperFetching ? (
                <div className="skeleton-div">
                  <Skeleton height={42} count={1} />
                  <Skeleton height={160} count={2} />
                </div>
              ) : (
                <div
                  className="matching-count"
                  style={{ color: 'rgb(232,186,69)' }}
                >
                  <CountUp
                    end={
                      isDeveloperSurvey &&
                      getCount(developerResult, 'developers')
                    }
                  />
                  <Item
                    items={
                      isDeveloperSurvey
                        ? getSurveyDetails(developerResult, 'developers')
                        : []
                    }
                    count={
                      isDeveloperSurvey &&
                      getCount(developerResult, 'developers')
                    }
                    surveyid={surveyid}
                    matchingtype={'developers'}
                  />
                </div>
              )}
            </Col>

            {/* Real Estate Agents */}
            <Col className="gutter-row" span={8}>
              <div
                className="matching-type"
                style={{ backgroundColor: 'rgb(124,67,67)' }}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setVisibleModel(true);
                    setMatchingtype('realEstateAgents');
                    setTitle('Real Estate Agents');
                    setColor('rgb(124,67,67)');
                    setSelectedType({
                      isSurvey: isRealEstateAgentSurvey,
                      surveyResult: realEstateAgentResult,
                    });
                  }}
                  className="type-color"
                >
                  Real Estate Agents
                </Link>
              </div>
              {isRealEstateAgentFetching ? (
                <div className="skeleton-div">
                  <Skeleton height={42} count={1} />
                  <Skeleton height={160} count={2} />
                </div>
              ) : (
                <div
                  className="matching-count"
                  style={{ color: 'rgb(124,67,67)' }}
                >
                  <CountUp
                    end={
                      isRealEstateAgentSurvey &&
                      getCount(realEstateAgentResult, 'realEstateAgents')
                    }
                  />
                  <Item
                    items={
                      isRealEstateAgentSurvey
                        ? getSurveyDetails(
                            realEstateAgentResult,
                            'realEstateAgents',
                          )
                        : []
                    }
                    count={
                      isRealEstateAgentSurvey &&
                      getCount(realEstateAgentResult, 'realEstateAgents')
                    }
                    surveyid={surveyid}
                    matchingtype={'realEstateAgents'}
                  />
                </div>
              )}
            </Col>

            {/* New Build Homebuyers */}
            <Col className="gutter-row" span={8}>
              <div
                className="matching-type"
                style={{ backgroundColor: 'rgb(28,122,146)' }}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setVisibleModel(true);
                    setMatchingtype('newBuildHomebuyers');
                    setTitle('Home Buyer');
                    setColor('rgb(28,122,146)');
                    setSelectedType({
                      isSurvey: isNewBuildHomebuyerSurvey,
                      surveyResult: newBuildHomebuyerResult,
                    });
                  }}
                  className="type-color"
                >
                  Home Buyer
                </Link>
              </div>
              {isNewBuildHomebuyerFetching ? (
                <div className="skeleton-div">
                  <Skeleton height={42} count={1} />
                  <Skeleton height={160} count={2} />
                </div>
              ) : (
                <div
                  className="matching-count"
                  style={{ color: 'rgb(28,122,146)' }}
                >
                  <CountUp
                    end={
                      isNewBuildHomebuyerSurvey &&
                      getCount(newBuildHomebuyerResult, 'newBuildHomebuyers')
                    }
                  />
                  <Item
                    items={
                      isNewBuildHomebuyerSurvey
                        ? getSurveyDetails(
                            newBuildHomebuyerResult,
                            'newBuildHomebuyers',
                          )
                        : []
                    }
                    count={
                      isNewBuildHomebuyerSurvey &&
                      getCount(newBuildHomebuyerResult, 'newBuildHomebuyers')
                    }
                    surveyid={surveyid}
                    matchingtype={'newBuildHomebuyers'}
                  />
                </div>
              )}
            </Col>

            {/* Water Rights */}
            <Col className="gutter-row" span={8}>
              <div
                className="matching-type"
                style={{ backgroundColor: 'rgb(116,97,96)' }}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setVisibleModel(true);
                    setTitle('Water Rights');
                    setMatchingtype('waterRights');
                    setColor('rgb(116,97,96)');
                    setSelectedType({
                      isSurvey: isWaterRightsSurvey,
                      surveyResult: waterRightsResult,
                    });
                  }}
                  className="type-color"
                >
                  Water Rights
                </Link>
              </div>
              {isWaterRightsFetching ? (
                <div className="skeleton-div">
                  <Skeleton height={42} count={1} />
                  <Skeleton height={160} count={2} />
                </div>
              ) : (
                <div
                  className="matching-count"
                  style={{ color: 'rgb(116,97,96)' }}
                >
                  <CountUp
                    end={
                      isWaterRightsSurvey &&
                      getCount(waterRightsResult, 'waterRights')
                    }
                  />
                  <Item
                    items={
                      isWaterRightsSurvey
                        ? getSurveyDetails(waterRightsResult, 'waterRights')
                        : []
                    }
                    count={
                      isWaterRightsSurvey &&
                      getCount(waterRightsResult, 'waterRights')
                    }
                    surveyid={surveyid}
                    matchingtype={'waterRights'}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
        {/* Modal for showing detailed information */}
        <ItemListModal
          visible={visibleModel}
          setVisible={setVisibleModel}
          surveyid={surveyid}
          matchingtype={matchingtype}
          count={
            selectedType.isSurvey &&
            getCount(selectedType.surveyResult, matchingtype)
          }
          data={[]}
          title={title}
          color={color}
          initPage={1}
        />
      </Spin>
    </SiteLayout>
  );
};

export default Network;
