/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Input } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useState, useCallback, useEffect } from 'react';
import { CriteriaDropdown } from 'components';
import {
  setSelectedSurveyTakerDetails,
  useGetClientCodeNamesWithSurveyNameQuery,
} from 'app/services';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { resetSurveyFromLocalStorage } from 'utils/resetSurveyFromStorage';
import { getLatestSurveyContacts, getSurveyContacts } from 'services/surveys.service';
import { useDispatch } from 'react-redux';


interface CheckboxGroupProps {
  options: any;
  checkedList: CheckboxValueType[];
  setCheckedList: (value: CheckboxValueType[]) => void;
  setSurveyEmail?: (value: any) => void;
  setSearchValue?: (value: any) => void;
  from?: string;
  setDropdownTitle: (value: any) => void;
}
interface ICheckBox {
  setSurveyid?: (value: string) => void;
  onSurveyTakerChange?: (value: string) => void;
  onClientCodeChange?: (value: string) => void;
}

export const CheckboxGroup = ({
  options,
  checkedList,
  setCheckedList,
  from,
  setSurveyEmail,
  setSearchValue,
  setDropdownTitle
}: CheckboxGroupProps) => {
  return (
    <div className="properties-checkbox-group">
      <Checkbox.Group
        style={{ width: '100%' }}
        className="column-sort"
        value={checkedList}
      >
        {options &&
          options.length > 0 &&
          options.map((option: any, index: number) => (
            <Checkbox
              key={index}
              value={option?.criteria && option?.criteria === 'clientCodeName' ? option.surveyid : option.email}
              onClick={(e: any) => {
                if (checkedList?.length && checkedList[0] === e.target.value) {
                  setCheckedList([])
                  if (setSurveyEmail) {
                    setSurveyEmail('')
                  }
                  if (setSearchValue) {
                    setSearchValue('')
                  }
                  setDropdownTitle('');
                  resetSurveyFromLocalStorage()
                  window.dispatchEvent(new Event("storageUncheck"));
                } else {
                  setCheckedList([e.target.value])
                  if (option?.criteria === 'clientCodeName') {
                    setDropdownTitle(option.value)
                    localStorage.setItem('surveyId', e.target.value)
                    window.dispatchEvent(new Event("storage"));
                  } else {
                    resetSurveyFromLocalStorage()
                    window.dispatchEvent(new Event("storageUncheck"));
                    setDropdownTitle(e.target.value);
                    localStorage.setItem('surveyEmail', e.target.value)
                  }
                }
              }}
              style={{ margin: '0' }}
            >
              {option.firstname && option.lastname && (
                <>
                  {option.firstname} {option.lastname}
                  <br></br>
                </>
              )}

              {!option.firstname && !option.lastname &&
                option.recommender_first_name && option.recommender_last_name
                && (
                  <>
                    {option.recommender_first_name}
                    {option.recommender_last_name}
                    <br></br>
                  </>
                )}

              {option.value}
            </Checkbox>
          ))}
      </Checkbox.Group>
    </div>
  );
};

const ProfileSearchCheckboxGroup: React.FC<ICheckBox> = ({ setSurveyid,
  onSurveyTakerChange, onClientCodeChange }) => {
  const [searchValue, setSearchValue] = useState('');
  const [clientCode, setClientCode] = useState('');
  const [checkedList, setCheckedList] = useState<any>([]);
  const [surveyEmail, setSurveyEmail] = useState('');
  const [surveyName, setSurveyName] = useState('');
  const [checkedListCode, setCheckedListCode] = useState<any>([]);
  const [showClientCode, setShowClientCode] = useState<any>(false)
  const [dropdownTitle, setDropdownTitle] = useState('')
  const [options, setOptions] = useState<any>([])
  const [clientCodeTitle, setClientCodeTitle] = useState<any>('')

  useEffect(() => {
    window.addEventListener('resetSearch', () => {
      setCheckedList([])
    })
  }, [])
  


  const loginUser =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user') as string);

  // VisibilityState
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const isDropdownVisible = useCallback(
    (name: string) => name === activeDropdown,
    [activeDropdown],
  );
  const { search } = useLocation();
  const dispatch = useDispatch()


  // useEffect(() => {
  //   if (loginUser.role !== 'admin') {
  //     setSurveyEmail(loginUser.email);
  //   }
  // }, [loginUser]);

  useEffect(() => {
    setTimeout(() => setActiveDropdown(null), 100);
    setClientCode('');
    setCheckedListCode([]);
    setClientCodeTitle('')
  }, [checkedList]);



  const handleDropdownVisibility = (isVisible: boolean, name: string) => {
    if (isVisible) {
      setActiveDropdown(name);
      return;
    }
    setActiveDropdown(null);
  };

  // const { data: options } = useGetSurveyContactsQuery(
  //   searchValue ? searchValue : skipToken,
  // );

  const getSurveyContactsFromServer = async () => {
    if (searchValue) {
      const res = await getSurveyContacts(searchValue)
      setOptions(res.data)
    } else {
      const res = await getLatestSurveyContacts()
      setOptions(res.data)
    }
  }

  useEffect(() => {
    getSurveyContactsFromServer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const { data: codeOptions } = useGetClientCodeNamesWithSurveyNameQuery(
    {
      email: surveyEmail,
      code: clientCode ? clientCode : '',
      surveyName,
    },
    {
      skip: !surveyEmail || !surveyName,
    },
  );

  useEffect(() => {
    if (checkedListCode?.length && codeOptions?.length) {
      const codeOptionSelected: any = codeOptions
        .find((elem: any) => elem.surveyid === checkedListCode[0])?.value
      setClientCodeTitle(codeOptionSelected)
      localStorage.setItem('clientCodeName', codeOptionSelected)
    } else {
      setClientCodeTitle(localStorage.getItem('clientCodeName') || '')
    }
  }, [checkedListCode, codeOptions])

  // useEffect(() => {
  //   if (checkedList && checkedList[0]) {
  //     const itemData = options?.filter(
  //       (item) => item.surveyid === checkedList[0],
  //     );
  //     if (itemData && itemData?.length > 0) {
  //       setSurveyEmail(itemData[0].value);
  //     }
  //   }
  // }, [options, checkedList]);

  // useEffect(() => {
  //   if (
  //     checkedList &&
  //     checkedList.length > 0 &&
  //     checkedList[0] &&
  //     checkedListCode.length === 0 &&
  //     typeof setSurveyid !== 'undefined'
  //   ) {
  //     setSurveyid(checkedList[0].toString());
  //   } else if (
  //     checkedList.length > 0 &&
  //     checkedListCode.length > 0 &&
  //     checkedListCode[0] &&
  //     typeof setSurveyid !== 'undefined'
  //   ) {
  //     setSurveyid(checkedListCode[0].toString());
  //   } else if (
  //     codeOptions &&
  //     codeOptions.length > 0 &&
  //     checkedListCode[0] &&
  //     typeof setSurveyid !== 'undefined'
  //   ) {
  //     setSurveyid(checkedListCode[0].toString());
  //   }
  // }, [checkedList, checkedListCode, setSurveyid, codeOptions]);

  useEffect(() => {
    if (onSurveyTakerChange) {
      if (options?.length && checkedList?.length) {
        const surveyTakerDetails = options.find((elem: any) => elem.email === checkedList[0])
        dispatch(setSelectedSurveyTakerDetails(surveyTakerDetails))
      } else {
        dispatch(setSelectedSurveyTakerDetails(null))
      }
      onSurveyTakerChange(checkedList)
      setSurveyEmail(checkedList[0])
    }
  }, [checkedList])

  useEffect(() => {
    if (onClientCodeChange) {
      onClientCodeChange(checkedListCode)
      // setSurveyEmail(checkedList[0])
    }
  }, [checkedListCode])

  useEffect(() => {
    if (surveyName) {
      setShowClientCode(true)
    } else {
      setShowClientCode(false)
    }
  }, [surveyName])

  const setCurrentSurveyNameAndClientCode = () => {
    const currentSurveyName = localStorage.getItem('surveyName') || ''
    const currentSurveyId = localStorage.getItem('surveyId') || ''
    if (currentSurveyName && (currentSurveyName === 'Real Estate Agent Client Survey'
      || currentSurveyName === 'Builder Client Survey')) {
      setSurveyName(currentSurveyName)
      setCheckedListCode(currentSurveyId.split(';'))
    } else {
      setSurveyName('')
    }
  }

  useEffect(() => {
    let currentSurveyTaker = localStorage.getItem('surveyEmail')
    if (currentSurveyTaker) {
      setCheckedList(currentSurveyTaker?.split(';'))
    } else {
      setCheckedList([])
      setSearchValue('')
      setSurveyEmail('')
    }
    setCurrentSurveyNameAndClientCode()
    setDropdownTitle(localStorage.getItem('surveyEmail') || '')
    setClientCodeTitle(localStorage.getItem('clientCodeName') || '')
    window.addEventListener('storage', () => {
      setCurrentSurveyNameAndClientCode()
      setDropdownTitle(localStorage.getItem('surveyEmail') || '')
      setClientCodeTitle(localStorage.getItem('clientCodeName') || '')
    })
  }, [])

  useEffect(() => {
    if (search) {
      const surveyTakerFromQuery: any = qs.parse(search)?.surveyTaker
      if (surveyTakerFromQuery) {
        setCheckedList(surveyTakerFromQuery.split(';'))
        setSearchValue(surveyTakerFromQuery)
      }
    }
    // setSelectedSurveyTaker()
  }, [search])


  return (
    <>
      {loginUser.role === 'admin' && (
        <CriteriaDropdown
          className="left-drawer-dropdown"
          criteriaTitle={dropdownTitle ? dropdownTitle : checkedList?.length ? checkedList[0] : "Survey Taker"}
          destroyTooltipOnHide
          open={isDropdownVisible('areaAndStyle')}
          onOpenChange={(visibility) =>
            handleDropdownVisibility(visibility, 'areaAndStyle')
          }
          content={
            <>
              <div className="popup-container taker-popover">
                <div className="search-checkbox">
                  <div className="input-wrapper">
                    <Input
                      placeholder="Search ..."
                      className="search-checkbox-input"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                  </div>
                  <div className="search-checkbox-wrapper">
                    <CheckboxGroup
                      from="surveyTaker"
                      options={options}
                      checkedList={checkedList}
                      setCheckedList={setCheckedList}
                      setSurveyEmail={setSurveyEmail}
                      setSearchValue={setSearchValue}
                      setDropdownTitle={setDropdownTitle}
                    />
                  </div>
                </div>
              </div>
            </>
          }
        />
      )}

      {codeOptions && codeOptions.length > 0 && showClientCode && (
        <div style={{ marginTop: '15px' }}>
          <CriteriaDropdown
            className="left-drawer-dropdown"
            criteriaTitle={clientCodeTitle || "Client code name"}
            destroyTooltipOnHide
            open={isDropdownVisible('areaAnd')}
            onOpenChange={(visibility) =>
              handleDropdownVisibility(visibility, 'areaAnd')
            }
            content={
              <>
                <div className="popup-container taker-popover">
                  <div className="search-checkbox">
                    <div className="input-wrapper">
                      <Input
                        placeholder="Search ..."
                        className="search-checkbox-input"
                        value={clientCode}
                        onChange={(e) => setClientCode(e.target.value)}
                      />
                    </div>
                    <div className="search-checkbox-wrapper">
                      <CheckboxGroup
                        options={codeOptions}
                        checkedList={checkedListCode}
                        setCheckedList={(val) => {
                          checkedListCode.length > 0 &&
                            checkedListCode[0] === val[0]
                            ? setCheckedListCode([])
                            : setCheckedListCode(val);
                        }}
                        setDropdownTitle={(val) => {
                          localStorage.setItem('clientCodeName', val)
                          setClientCodeTitle(val)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}
    </>
  );
};

export default ProfileSearchCheckboxGroup;
