export const resetSurveyFromLocalStorage = () => {
   localStorage.removeItem('isSwitchProfileOn')
   localStorage.removeItem('profileCriterias')
   localStorage.removeItem('surveyId')
   localStorage.removeItem('surveyName')
   localStorage.removeItem('surveyMessage')
   localStorage.removeItem('surveyInnerDetails')
   localStorage.removeItem('surveyEmail')
   localStorage.removeItem('clientCodeName')
   window.dispatchEvent(new Event("storage"));
}