/* eslint-disable react-hooks/exhaustive-deps */
import { LeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Drawer, Spin } from 'antd';
import { selectActiveTabValue, selectIsCountLoadingValue, selectShowingTotalValue, setActiveTabValue, setCoordsPaginationValue, setSelectedItemDetailsOnMap } from 'app/services';
import {
  MatchingResultsTotalText,
  TabbedContent,
} from 'components';

import { useState } from 'react';
import { TAB } from 'utils';
import { ActiveTabType } from 'types/common';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelGridView } from './ParcelGridView';
import { ProjectGridView } from './ProjectGridView';
import { ParcelProjectGridView } from './ParcelProjectGridView';

export interface GridViewProps {
  isViewAllOpen: boolean;
  closeViewAll: () => void;
}

export const GridView = ({
  isViewAllOpen,
  closeViewAll,
}: GridViewProps) => {
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);
  const [projectsTotal, setProjectsTotal] = useState(0);
  const [parcelsTotal, setParcelsTotal] = useState(0);
  const activeTab: ActiveTabType = useSelector(selectActiveTabValue);
  const isCountLoading: any = useSelector(selectIsCountLoadingValue);
  const dispatch = useDispatch()

  return (
    <Drawer
      width={1050}
      className="view-all-drawer"
      placement="left"
      onClose={closeViewAll}
      open={isViewAllOpen}
      closeIcon={<LeftOutlined />}
      destroyOnClose
      title={`Grid view of ${activeTab}`}
      push={false}
      mask={false}
      style={{ position: 'absolute' }}
      getContainer={false}
    >
      <TabbedContent
        centered={false}
        activeKey={activeTab}
        onTabClick={(activeKey) => {
          dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))
          dispatch(setActiveTabValue(activeKey as ActiveTabType));
          dispatch(setSelectedItemDetailsOnMap(null))
        }}
        tabBarExtraContent={
          <MatchingResultsTotalText
            projectsTotal={projectsTotal}
            parcelsTotal={parcelsTotal}
            from='viewAll'
            isCountLoading={isCountLoading}
            total={showingTotal} tabTitle={activeTab} />
        }
        tabData={TAB.map((title) => ({
          title,
          content: (
            <Spin
              spinning={false}
              indicator={
                <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
              }
            >
              { activeTab === 'Parcels' && <ParcelGridView key={1} closeViewAll={closeViewAll} setParcelsTotal={setParcelsTotal} /> }
              { activeTab === 'Projects' && <ProjectGridView key={2} closeViewAll={closeViewAll} setProjectsTotal={setProjectsTotal} /> }
              { activeTab === 'Both' && <ParcelProjectGridView key={3} closeViewAll={closeViewAll} setParcelsTotal={setParcelsTotal} setProjectsTotal={setProjectsTotal} /> }
            </Spin>
          ),
        }))}
      />
    </Drawer>
  );
};
