import { CaretDownFilled } from '@ant-design/icons';
import { Button, Popover, PopoverProps, Typography } from 'antd';
import React, { ReactElement } from 'react';

interface CriteriaDropdownProps extends PopoverProps {
  precedingIcon?: ReactElement | null;
  criteriaTitle: string;
  extraClassName?: string;
  content?: ReactElement;
}

interface FooterProps {
  onSaveClick: () => void;
  onResetClick: (e: any) => void;
  buttonText: string;
}
const { Text } = Typography;

export const CriteriaDropdown = ({
  precedingIcon = null,
  criteriaTitle,
  extraClassName = '',
  content,
  ...rest
}: CriteriaDropdownProps) => (
  <Popover
    content={content}
    trigger="click"
    placement="bottom"
    overlayClassName={`naxxa-property ${extraClassName}`}
    {...rest}
  >
    <div className={`naxxa-property wrapper font-normal ${extraClassName}`}>
      {precedingIcon}
      <Text>{criteriaTitle}</Text>
      {criteriaTitle !== 'Save Search' &&
        <CaretDownFilled />}
    </div>

  </Popover>
);

export const CriteriaFooter = ({
  onSaveClick,
  buttonText,
  onResetClick,
}: FooterProps) => {
  return (
    <div className="popup-footer font-normal">
      <div className="popup-footer-link-reset" onClick={onResetClick}>
        <span className="popup-footer-link-reset-text">Reset</span>
      </div>
      <div className="pop-button-apply">
        <Button className="popup-button" type="primary" onClick={onSaveClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
