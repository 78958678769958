import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse, Switch, Typography } from "antd";
import { useState } from "react";
const { Panel } = Collapse;
const { Link } = Typography;

export const ManageConsentPreference = () => {
  /**
   * Component managing state for different types of cookies and rendering expand icons.
   */
  const [targetingCookies, setTargetingCookies] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(false);
  const [functionalCookies, setFunctionalCookies] = useState(false);

  const setExpandIcon = (isActive: any) => (
    isActive ? <MinusOutlined /> : <PlusOutlined />
  )
  /**
   * Renders a Collapse component displaying different types of cookies with switches for user preferences.
   * Manages state for targeting, performance, and functional cookies.
   */
  return (
    <Collapse className="preferences" expandIcon={({ isActive }) => setExpandIcon(isActive) }>
      <Panel 
        key="1"
        header='Strictly Necessary Cookies'
        extra={<Link strong>Always Active</Link>}
      >
        <p>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
      </Panel>
      <Panel 
        key="2"
        header="Targeting Cookies" 
        extra={<Switch checked={targetingCookies} onChange={(value, e) => { setTargetingCookies(value); e.stopPropagation() }} />} 
      >
        <p>These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.    They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</p>
      </Panel>
      <Panel 
        key="3"
        header="Performance Cookies" 
        extra={<Switch checked={performanceCookies} onChange={(value, e) => { setPerformanceCookies(value); e.stopPropagation() }} />} 
      >
        <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.    All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>
      </Panel>
      <Panel 
        key="4"
        header="Functional Cookies" 
        extra={<Switch checked={functionalCookies} onChange={(value, e) => { setFunctionalCookies(value); e.stopPropagation() }} />} 
      >
        <p>These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages.    If you do not allow these cookies then some or all of these services may not function properly.</p>
      </Panel>
    </Collapse>
  );
}