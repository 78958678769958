/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Spin, Button, Form, Input, notification } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useChangePasswordMutation } from 'app/services';

const ChangePassword = (props:any) => {

  const [
    changePassword,
    { data: isPasswordChanged, isSuccess, isError, error, isLoading },
  ] = useChangePasswordMutation();

  const onFinish = async (values: any) => {
    await changePassword(values).unwrap();
  };

  useEffect(() => {
    if (isPasswordChanged && isSuccess) {
      notification.success({
        placement: 'topRight',
        message: 'You have successfully change your password.',
      });
      props.closeDrawer()
    }
    if (!isPasswordChanged && isError && error) {
      const errors: any = error;

      notification.error({
        placement: 'topRight',
        message: errors?.data?.message,
      });
    }
  }, [isError, isPasswordChanged, isSuccess, error]);

  return (
    <Spin
      spinning={isLoading}
      indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />}
    >
      <div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: 'Please input your olf password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmNewPassword"
            label="Confirm New Password"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Update Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
export default ChangePassword;
