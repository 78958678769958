import {
  CriteriaDropdown,
  CriteriaFooter,
  MatchingResultsTotalText,
  MenuNavigatedContent,
  SearchCheckboxGroup,
  // SearchCheckboxGroupList,
  TabbedContent,
} from 'components';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  handleSingleValue,
  stringifySearchObject,
  UTAHAREATAGS,
  ZIPS,
} from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Input, message } from 'antd';
import { ActiveTabType } from 'types/common';
import { selectShowingTotalValue, useGetAllCountiesByStateQuery, useGetAllZoningCountiesQuery, useGetDistinctChildProjectNamesQuery, useGetDistinctParentProjectNamesQuery, useGetOptionsByCitiesQuery, useGetOptionsByCountiesQuery } from 'app/services';
import { constructFilter } from 'utils/constructFilter';
import { useGetAllZoningCitiesQuery } from 'app/services';
import { DynamicSearchCollapseCheckboxGroupList } from 'components/DynamicCollapseCheckboxGroup';
import { useSelector } from 'react-redux';

const { Search } = Input;

// Interface definition for FilterItemProps
interface FilterItemProps {
  filterComponent: ReactElement;
  title: string;
}

// Interface definition for AdvancedFiltersContentProps
interface AdvancedFiltersContentProps {
  closeAdvancedFiltersDrawer: () => void;
  activeTab: ActiveTabType;
}

// Component for rendering a filter item with a dropdown
export const FilterItemDropdown = ({
  filterComponent,
  title,
}: FilterItemProps) => (
  <div className="filter-item-wrapper">
    <div className="title">
      <span>{title}</span>
    </div>
    <div className="font-small font-bold-normal">{filterComponent}</div>
  </div>
);

// Main component for advanced filters content
export const AdvancedFiltersContent = ({
  closeAdvancedFiltersDrawer,
  activeTab,
}: AdvancedFiltersContentProps) => {
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);

  // Component for displaying a "Coming Soon" message
  const ComingSoon = ({ title }: { title: string }) => (
    <FilterItemDropdown title={title} filterComponent={<h3>Coming Soon</h3>} />
  );

  // State management and API calls
  const [messageApi, contextHolder] = message.useMessage();
  const params: any = useMemo(() => qs.parse(search), [search]);
  const [assignedParams, setAssignedParams] = useState<any>(params);
  const [dummyState, setDummyState] = useState<any>({});
  const { data: countiesOfUtah } = useGetAllCountiesByStateQuery('Utah');
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [zoningCitiesArr, setZoningCitiesArr] = useState<any>([])
  const [cityName, setCityName] = useState('')
  const [citySearchValue, setCitySearchValue] = useState('')

  const [zoningCountiesArr, setZoningCountiesArr] = useState<any>([])
  const [countyName, setCountyName] = useState('')
  const [countySearchValue, setCountySearchValue] = useState('')
  const [parcelIdInput, setParcelIdInput] = useState('')
  const [projectNameOptions, setProjectNameOptions] = useState<any>([])
  // eslint-disable-next-line
  const [childProjectNameOptions, setChildProjectNameOptions] = useState<any>([])
  const [projectNameSearch, setProjectNameSearch] = useState<any>('')
  const [childProjectNameSearch, setChildProjectNameSearch] = useState<any>('')


  const { data: zoningCities, isFetching: isZoningCitiesFetching } =
    useGetAllZoningCitiesQuery(citySearchValue);

  const { data: zoningCitiesOptions, isFetching: isCitiesOptionsFetching } =
    useGetOptionsByCitiesQuery(cityName, {
      skip: !cityName
    });

  const { data: zoningCounties, isFetching: isZoningCountiesFetching } =
    useGetAllZoningCountiesQuery(countySearchValue);

  const { data: zoningCountiesOptions, isFetching: isCountiesOptionsFetching } =
    useGetOptionsByCountiesQuery(countyName, {
      skip: !countyName
    });

  const { data: projectNames, isFetching: isProjectNamesFetching } =
    useGetDistinctParentProjectNamesQuery({ searchValue: projectNameSearch }, {
      skip: !projectNameSearch
    });

  const { data: childProjectNames, isFetching: isChildProjectNamesFetching } =
    useGetDistinctChildProjectNamesQuery({ searchValue: childProjectNameSearch }, {
      skip: !childProjectNameSearch
    });

  // Effect to handle project name options when data changes
  useEffect(() => {
    if (!isProjectNamesFetching) {
      if (projectNames?.length) {
        const names =
          projectNames.map((elem: any) => elem.project_name_processed)
        setProjectNameOptions(names)
      } else {
        setProjectNameOptions([])
      }
    }
  }, [projectNames, isProjectNamesFetching])

  // Effect to handle child project name options when data changes
  useEffect(() => {
    if (!isChildProjectNamesFetching) {
      if (childProjectNames?.length) {
        const names =
          childProjectNames.map((elem: any) => elem.project_name_processed)
        setChildProjectNameOptions(names)
      } else {
        setChildProjectNameOptions([])
      }
    }
  }, [childProjectNames, isChildProjectNamesFetching])

  // Effect to update zoning cities options
  useEffect(() => {
    const selectedCityIndex = zoningCitiesArr?.findIndex((elem: any) =>
      elem.headerTitle === cityName)
    const allZoningCities = [...zoningCitiesArr]
    if (allZoningCities?.length) {
      allZoningCities[selectedCityIndex].options = zoningCitiesOptions
      setZoningCitiesArr(allZoningCities)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoningCitiesOptions])

  // Effect to update zoning counties options
  useEffect(() => {
    const selectedCountyIndex = zoningCountiesArr?.findIndex((elem: any) =>
      elem.headerTitle === countyName)
    const allZoningCounties = [...zoningCountiesArr]
    if (allZoningCounties?.length) {
      allZoningCounties[selectedCountyIndex].options = zoningCountiesOptions
      setZoningCountiesArr(allZoningCounties)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoningCountiesOptions])

  // Effect to set zoning cities array when data changes
  useEffect(() => {
    const withAddedOptions: any = []
    if (zoningCities?.length) {
      zoningCities.forEach((elem: any) => {
        const existingFromZoningCitiesArr =
          zoningCitiesArr?.find((obj: any) => obj.headerTitle
            === elem.headerTitle)
        let obj = {
          options: [],
          headerTitle: '',
          count: '',
          keyValue: ''
        }
        obj.headerTitle = elem.headerTitle
        obj.keyValue = `currentZoningCity ${elem.headerTitle}`
        obj.count = elem.count
        obj.options = elem.options?.length ?
          elem.options : existingFromZoningCitiesArr?.options ?
            existingFromZoningCitiesArr?.options : []
        withAddedOptions.push(obj)
      })
      setZoningCitiesArr(withAddedOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoningCities, isZoningCitiesFetching])

  // Effect to set zoning counties array when data changes
  useEffect(() => {
    const withAddedOptions: any = []
    if (zoningCounties?.length) {
      zoningCounties.forEach((elem: any) => {
        const existingFromZoningCountiesArr =
          zoningCountiesArr?.find((obj: any) => obj.headerTitle
            === elem.headerTitle)
        let obj = {
          options: [],
          headerTitle: '',
          count: '',
          keyValue: ''
        }
        obj.headerTitle = elem.headerTitle
        obj.keyValue = `currentZoningCounty ${elem.headerTitle}`
        obj.count = elem.count
        obj.options = elem.options?.length ?
          elem.options : existingFromZoningCountiesArr?.options ?
            existingFromZoningCountiesArr?.options : []
        withAddedOptions.push(obj)
      })
      setZoningCountiesArr(withAddedOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoningCounties, isZoningCountiesFetching])

  // State management for active project name tab
  let [projectNameActiveTab, setProjectNameActiveTab] = useState('Parent')

  // Effect to update params when they change
  useEffect(() => {
    setAssignedParams(params);
    setDummyState(params)
    if (!params || !params?.name) {
      setProjectNameSearch('')
    }
    if (!params || !params?.childName) {
      setChildProjectNameSearch('')
    }

  }, [params]);

  // Effect to set default project name tab on component mount
  useEffect(() => {
    setProjectNameActiveTab('Parent')
  }, [])

  const isDropdownVisible = useCallback(
    (name: string) => name === activeDropdown,
    [activeDropdown],
  );
  
  const handleDropdownVisibility = (isVisible: boolean, name: string) => {
    if (isVisible) {
      setActiveDropdown(name);
      return;
    }
    setActiveDropdown(null);
  };
  
  const onChangeHandler = (value: string[], key: string) => {
    setDummyState({ ...dummyState, [key]: value });
  };
  
  // const onCurrentZoningChange = useCallback(
  //   (value: string[], key: string) => {
  //     setAssignedParams({ ...assignedParams, [key]: value });
  //   },
  //   [assignedParams],
  // );
  
  const onResetClickHandler = (values: string[]) => {
    // Reset specific filters and UI states
    setCitySearchValue('');
    setCountySearchValue('');
    setAssignedParams((pv: any) => {
      // Remove selected values for specified filter keys
      values.forEach((value) => {
        delete pv[value];
      });
      return pv;
    });
    setActiveDropdown(null);
    onSaveClick(''); // Trigger save action after resetting
    // messageApi.info("All Filters Removed")
  };
  
  const handleJoinedText = (
    value: null | string | string[],
    joinedString: string = ', ',
    defaultValue: string = '',
  ) => {
    // Handle formatting of joined text values
    if (!value) return defaultValue;
    if (typeof value === 'string') {
      if (value.includes('|')) return value.replace(/\|/g, ', ');
      return value;
    }
    return value.join(joinedString);
  };
  
  const onSaveClick = useCallback(
    (key: string) => {
      // Prepare updated URL parameters with applied filters
      let urlParams = { ...assignedParams, [key]: dummyState[key] };
      // if (key === 'name' && projectNameActiveTab === 'Child') {
      //   urlParams.childName = dummyChildState.childName
      // }
      if (key === 'currentZoning') {
        // If applying filters for current zoning, merge all zoning filters
        urlParams = {
          ...urlParams,
          ...dummyState
        };
      }
      const contructedFilter = constructFilter(urlParams); // Construct filter object
      setAssignedParams(contructedFilter); // Update assigned parameters with constructed filter
      replace({ // Replace current URL with updated parameters
        pathname,
        search: stringifySearchObject(contructedFilter),
      });
      setActiveDropdown(null); // Reset active dropdown state
      setTimeout(closeAdvancedFiltersDrawer, 100); // Close advanced filters drawer with a slight delay
      // messageApi.info("Filters Applied")
      // if (
      //   (!contructedFilter.counties || contructedFilter.counties.length === 0) &&
      //   (!contructedFilter.utahAreaTags || contructedFilter.utahAreaTags.length === 0) &&
      //   (!contructedFilter.postalCodes || contructedFilter.postalCodes.length === 0)
      //   && !contructedFilter.currentZoning && !contructedFilter.name && !contructedFilter.childName) {
      //   messageApi.info('All Filters Removed!');
      // } else {
      //   messageApi.success('Filters Applied!');
      // }
    },
    [assignedParams,
      dummyState,
      closeAdvancedFiltersDrawer,
      pathname,
      replace,
    ],
  );
  
  const onSearch = useCallback(
    (key, value: string) => {
      // Update assigned parameters with search input values
      let urlParams = { ...assignedParams, [key]: value };
      const contructedFilter = constructFilter(urlParams); // Construct filter object based on updated parameters
      setAssignedParams(contructedFilter); // Update assigned parameters with constructed filter
    },
    [assignedParams],
  );
  
  useEffect(() => {
    // Update parcel ID input value when assigned parameters change
    if (assignedParams?.parcelId) {
      setParcelIdInput(assignedParams.parcelId);
    } else {
      setParcelIdInput('');
    }
  }, [assignedParams]);
  
  const onDoneClick = useCallback(() => {
    // Update URL with stringified search object parameters upon clicking done
    replace({
      pathname,
      search: stringifySearchObject(assignedParams),
    });
  }, [assignedParams, pathname, replace]);
  
  useEffect(() => {
    onDoneClick(); // Trigger onDoneClick effect when assigned parameters change
    // eslint-disable-next-line
  }, [assignedParams]);
  
  // Define project-related filters and components
  const projects = [
    {
      title: 'Project Characteristics',
      menuComponents: [
        // <FilterItemDropdown
        //   title="Special Product Types"
        //   key="Special Product Types"
        //   filterComponent={
        //     <CriteriaDropdown
        //       criteriaTitle={handleJoinedText(
        //         assignedParams.specialProductTypes,
        //       )}
        //       destroyTooltipOnHide
        //       visible={isDropdownVisible('specialProductTypes')}
        //       onVisibleChange={(visibility) =>
        //         handleDropdownVisibility(visibility, 'specialProductTypes')
        //       }
        //       extraClassName="advanced-filters-dropdown"
        //       content={
        //         <>
        //           <div className="popup-container">
        //             <SearchCheckboxGroup
        //               options={SPECIALPRODUCTTYPE}
        //               headerTitle="All"
        //               preSelectedList={handleSingleValue(
        //                 assignedParams.specialProductTypes,
        //               )}
        //               keyValue="specialProductTypes"
        //               onChange={onChangeHandler}
        //               searchValue=""
        //             />
        //           </div>
        //           <CriteriaFooter
        //             onResetClick={() => {
        //               onResetClickHandler(['specialProductTypes']);
        //             }}
        //             onSaveClick={() => onSaveClick('specialProductTypes')}
        //             buttonText="Apply"
        //           />
        //         </>
        //       }
        //     />
        //   }
        // />,
        <FilterItemDropdown
          title="Project Name"
          key="Project Name"
          filterComponent={
            <CriteriaDropdown
              criteriaTitle={`${handleJoinedText(assignedParams.name) || ''}${assignedParams.name && assignedParams.childName ? ', ' : ''}${handleJoinedText(assignedParams.childName) || ''}` || 'Any'}
              
              open={isDropdownVisible('name')}
              onOpenChange={(visibility) =>
                handleDropdownVisibility(visibility, 'name')
              }
              extraClassName="advanced-filters-dropdown"
              content={
                <>
                  <div className="popup-container">
  
                    <TabbedContent
                      onTabClick={(e) =>
                        setProjectNameActiveTab(e)
                      }
                      tabData={[
                        {
                          title: 'Parent',
                          content: (
                            <SearchCheckboxGroup
                              options={projectNameSearch ?
                                projectNameOptions : []}
                              headerTitle="Parent"
                              preSelectedList={handleSingleValue(
                                assignedParams.name,
                              )}
                              keyValue="name"
                              onInputChange={(value) =>
                                setProjectNameSearch(value)}
                              onChange={onChangeHandler}
                              optionsFetching={isProjectNamesFetching}
                              searchValue={projectNameSearch}
                            />
                          ),
                        },
                        {
                          title: 'Child',
                          content: (
                            <SearchCheckboxGroup
                              options={childProjectNameSearch ?
                                childProjectNameOptions : []}
                              headerTitle="Child"
                              preSelectedList={handleSingleValue(
                                assignedParams.childName,
                              )}
                              keyValue="childName"
                              onInputChange={(value) =>
                                setChildProjectNameSearch(value)}
                              onChange={onChangeHandler}
                              optionsFetching={isChildProjectNamesFetching}
                              searchValue={childProjectNameSearch}
                            />
                          ),
                        },
                      ]}
                    />
  
  
                  </div>
                  <CriteriaFooter
                    onResetClick={() => {
                      if (projectNameActiveTab === 'Parent') {
                        onResetClickHandler(['name']);
                        setProjectNameSearch('')
                        messageApi.info("Parent projects name filters Removed!")
                      } else {
                        onResetClickHandler(['childName']);
                        setChildProjectNameSearch('')
                        messageApi.info("Child projects name filters Removed!")
                      }
                    }}
                    onSaveClick={() => {
                      onSaveClick(projectNameActiveTab === 'Parent' ? 'name' : 'childName')
                      setProjectNameActiveTab('Parent')
                      messageApi.success('Filters Applied!');
                    }}
                    buttonText="Apply"
                  />
                </>
              }
            />
          }
        />,
  
        // <FilterItemDropdown
        //   title="Project Name"
        //   key="Project Name"
        //   filterComponent={
        //     <Input
        //       className="advanced-filters-input"
        //       value={handleJoinedText(assignedParams.name, '', '')}
        //       onChange={(e) =>
        //         setAssignedParams({
        //           ...assignedParams,
        //           name: e.target.value,
        //         })
        //       }
        //     />
        //   }
        // />,
        <ComingSoon key="5" title="Current Zoning" />,
      ],
    },
    // {
    //   title: 'Home Specs',
    //   menuComponents: [
    //     <ComingSoon key="0" title="House Style" />,
    //     <ComingSoon key="1" title="House Square Footage" />,
    //     <ComingSoon key="2" title="Bedrooms" />,
    //     <ComingSoon key="3" title="Bathrooms" />,
    //     <ComingSoon key="4" title="Garage" />,
    //   ],
    // },
  ];
  
  // Define parcel-related filters and components
  const parcels = [
    {
      title: 'Parcel Characteristics',
      menuComponents: [
        <CriteriaDropdown
          className='current-zoning'
          key="Current Zoning"
          criteriaTitle="Current Zoning"
          destroyTooltipOnHide
          open={isDropdownVisible('areaAndStyle')}
          onOpenChange={(visibility) =>
            handleDropdownVisibility(visibility, 'areaAndStyle')
          }
          content={
            <>
              <div className="popup-container current-zoning">
                <TabbedContent
                  tabData={[
                    {
                      title: 'Cities',
                      content: (
                        <>
                          <DynamicSearchCollapseCheckboxGroupList
                            openCollapse={(name: any) => setCityName(name)}
                            onSearch={(value: any) => setCitySearchValue(value)}
                            titleFetching={isZoningCitiesFetching}
                            optionsFetching={isCitiesOptionsFetching}
                            title='Cities'
                            data={zoningCitiesArr?.map((item: any) => ({
                              ...item,
                              preSelectedList: handleSingleValue(
                                assignedParams[item.keyValue],
                              ),
                              onChange: onChangeHandler,
                            }))}
                          />
                        </>),
                    },
                    {
                      title: 'Counties',
                      content: (
                        <DynamicSearchCollapseCheckboxGroupList
                          openCollapse={(name: any) => setCountyName(name)}
                          onSearch={(value: any) => setCountySearchValue(value)}
                          titleFetching={isZoningCountiesFetching}
                          optionsFetching={isCountiesOptionsFetching}
                          title='Counties'
                          data={zoningCountiesArr?.map((item: any) => ({
                            ...item,
                            preSelectedList: handleSingleValue(
                              assignedParams[item.keyValue],
                            ),
                            onChange: onChangeHandler,
                          }))}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <CriteriaFooter
                onResetClick={() => {
                  onResetClickHandler([
                    ...zoningCountiesArr.map((item: any) => item.keyValue),
                    ...zoningCitiesArr.map((item: any) => item.keyValue),
                  ]);
                  messageApi.info("Zoning filters Removed!")
                }}
                onSaveClick={() => {
                  onSaveClick('currentZoning')
                  messageApi.success('Filters Applied!');
                }}
                buttonText="Apply"
              />
            </>
  
          } />
        // <FilterItemDropdown
        //   title="Land Use"
        //   key="Land Use"
        //   filterComponent={
        //     <CriteriaDropdown
        //       destroyTooltipOnHide
        //       extraClassName="advanced-filters-dropdown"
        //       criteriaTitle={handleJoinedText(assignedParams.landUse)}
        //       visible={isDropdownVisible('landUse')}
        //       onVisibleChange={(visibility) =>
        //         handleDropdownVisibility(visibility, 'landUse')
        //       }
        //       content={
        //         <>
        //           <div className="popup-container">
        //             <SearchCheckboxGroup
        //               options={LANDUSE}
        //               headerTitle="All"
        //               preSelectedList={handleSingleValue(
        //                 assignedParams.landUse,
        //               )}
        //               keyValue="landUse"
        //               onChange={onChangeHandler}
        //               searchValue=""
        //             />
        //           </div>
        //           <CriteriaFooter
        //             onResetClick={() => {
        //               onResetClickHandler(['landUse']);
        //             }}
        //             onSaveClick={() => onSaveClick('landUse')}
        //             buttonText="Apply"
        //           />
        //         </>
        //       }
        //     />
        //   }
        // />,
        // <ComingSoon key="0" title="Current Zoning" />,
        // <ComingSoon key="3" title="Super Parcel" />,
        // <ComingSoon key="4" title="Master Plan Area" />,
      ],
    },
    // {
    //   title: 'Scores',
    //   menuComponents: [
    //     <ComingSoon key="0" title="Disposition Score" />,
    //     <ComingSoon key="1" title="Ownership Workability Score" />,
    //     <ComingSoon key="2" title="Ownership Reasonability Score" />,
    //     <ComingSoon key="3" title="Road Score" />,
    //   ],
    // },
  ];
  
  // Define location-related filters and components
  const location = [
    {
      title: 'Locations',
      menuComponents: [
        <FilterItemDropdown
          title="Counties"
          key="Counties"
          filterComponent={
            <CriteriaDropdown
              criteriaTitle={handleJoinedText(assignedParams.counties) || 'Any'}
              destroyTooltipOnHide
              visible={isDropdownVisible('counties')}
              onVisibleChange={(visibility) =>
                handleDropdownVisibility(visibility, 'counties')
              }
              extraClassName="advanced-filters-dropdown"
              content={
                <>
                  <div className="popup-container">
                    <SearchCheckboxGroup
                      options={countiesOfUtah || []}
                      headerTitle="All"
                      preSelectedList={handleSingleValue(
                        assignedParams.counties,
                      )}
                      keyValue="counties"
                      onChange={onChangeHandler}
                      searchValue=""
                    />
                  </div>
                  <CriteriaFooter
                    onResetClick={() => {
                      onResetClickHandler(['counties']);
                      messageApi.info("County filters Removed!")
                    }}
                    onSaveClick={() => {
                      onSaveClick('counties')
                      messageApi.success("Filters Applied!")
                    }}
                    buttonText="Apply"
                  />
                </>
              }
            />
          }
        />,
        <FilterItemDropdown
          title="Areas"
          key="Areas"
          filterComponent={
            <CriteriaDropdown
              criteriaTitle={handleJoinedText(assignedParams.utahAreaTags) || 'Any'}
              destroyTooltipOnHide
              visible={isDropdownVisible('utahAreaTags')}
              onVisibleChange={(visibility) =>
                handleDropdownVisibility(visibility, 'utahAreaTags')
              }
              extraClassName="advanced-filters-dropdown"
              content={
                <>
                  <div className="popup-container">
                    <SearchCheckboxGroup
                      options={UTAHAREATAGS}
                      headerTitle="All"
                      preSelectedList={handleSingleValue(
                        assignedParams.utahAreaTags,
                      )}
                      keyValue="utahAreaTags"
                      onChange={onChangeHandler}
                      searchValue=""
                    />
                  </div>
                  <CriteriaFooter
                    onResetClick={() => {
                      onResetClickHandler(['utahAreaTags']);
                      messageApi.info("Area tags filters Removed!")
                    }}
                    onSaveClick={() => {
                      onSaveClick('utahAreaTags')
                      messageApi.success("Filters applied!")
                    }}
                    buttonText="Apply"
                  />
                </>
              }
            />
          }
        />,
        <FilterItemDropdown
          title="Zip Codes"
          key="Zip Codes"
          filterComponent={
            <CriteriaDropdown
              destroyTooltipOnHide
              extraClassName="advanced-filters-dropdown"
              criteriaTitle={handleJoinedText(assignedParams.postalCodes) || 'Any'}
              visible={isDropdownVisible('postalCodes')}
              onVisibleChange={(visibility) =>
                handleDropdownVisibility(visibility, 'postalCodes')
              }
              content={
                <>
                  <div className="popup-container">
                    <SearchCheckboxGroup
                      options={ZIPS}
                      headerTitle="All"
                      preSelectedList={handleSingleValue(
                        assignedParams.postalCodes,
                      )}
                      keyValue="postalCodes"
                      onChange={onChangeHandler}
                      searchValue=""
                    />
                  </div>
                  <CriteriaFooter
                    onResetClick={() => {
                      onResetClickHandler(['postalCodes']);
                      messageApi.info("Postal code filters Removed!")
                    }}
                    onSaveClick={() => {
                      onSaveClick('postalCodes')
                      messageApi.success("Filters applied!")
                    }}
                    buttonText="Apply"
                  />
                </>
              }
            />
          }
        />,
        <FilterItemDropdown
          title="Parcel ID"
          key="Parcel ID"
          filterComponent={
            <Search
              value={parcelIdInput}
              onChange={(e) => setParcelIdInput(e.target.value)}
              className="parcelid-search"
              onSearch={(value) => {
                onSearch('parcelId', value);
              }}
              enterButton
            />
          }
        />,
      ],
    },
    // {
    //   title: 'Ownership',
    //   menuComponents: [
    //     <ComingSoon key="0" title="Length of Ownership" />,
    //     <ComingSoon key="1" title="Distressed" />,
    //     <ComingSoon key="2" title="Owned Outright" />,
    //     <ComingSoon key="3" title="Ownership Company" />,
    //     <ComingSoon key="4" title="Previous Denied Project?" />,
    //   ],
    // },
    // {
    //   title: 'Schools',
    //   menuComponents: [
    //     <ComingSoon key="0" title="Elementary Schools" />,
    //     <ComingSoon key="1" title="Middle Schools" />,
    //     <ComingSoon key="2" title="High Schools" />,
    //   ],
    // },
    // {
    //   title: 'Water Rights',
    //   menuComponents: [<ComingSoon key="0" title="Include Water Rights" />],
    // },
  ];
  
  return (
    <div className="naxxa-advanced-filters-tabs">
      {contextHolder} {/* Render context holder */}
      <TabbedContent
        centered={false}
        maxedLayout={false}
        tabBarExtraContent={
          <MatchingResultsTotalText total={showingTotal} tabTitle={activeTab} />
        }
        tabData={[
          {
            title: 'Location',
            content: (
              <MenuNavigatedContent
                navigationMenuOptions={location} // Render location-related menu options
                onDoneClick={onDoneClick} // Handle done click action
              />
            ),
          },
          {
            title: 'Parcels',
            content: (
              <MenuNavigatedContent
                navigationMenuOptions={parcels} // Render parcel-related menu options
                onDoneClick={onDoneClick} // Handle done click action
              />
            ),
          },
          {
            title: 'Projects',
            content: (
              <MenuNavigatedContent
                navigationMenuOptions={projects} // Render project-related menu options
                onDoneClick={onDoneClick} // Handle done click action
              />
            ),
          },
        ]}
      />
    </div>
  );
};  