import { createApi } from '@reduxjs/toolkit/query/react';
import { SURVEYSROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import {
  SurveysDataResponse,
  SurveysContactsResponse,
  SurveysDataRequest,
} from 'types/surveys';

export interface UpdateSurveyCriteriaRequest {
  surveyid: string;
  isPrivate: boolean;
}

export const surveyApi = createApi({
  baseQuery,
  tagTypes: ['SurveyContacts', 'SurveyCriteriaUpdate'],
  reducerPath: 'surveyApi',
  endpoints: (builder) => ({
    /**
     * Endpoint for fetching URL parameters from a survey by its ID.
     * @returns An object containing the URL string fetched from the survey endpoint.
     */    
    getUrlParamsFromSurvey: builder.query<{ url: string }, string>({
      query: (id) => ({
        url: `${SURVEYSROUTES.getUrlById}/${id}`,
      }),
    }),
    /**
     * Endpoint for fetching survey results by survey ID.
     * @returns An array containing the survey results data fetched from the endpoint.
     */    
    getSurveyResults: builder.query<[SurveysDataResponse], string>({
      query: (surveyid) => ({
        url: `${SURVEYSROUTES.base}/${surveyid}/results`,
      }),
    }),
    /**
     * Endpoint for fetching survey information by email.
     * @returns An array containing the survey information data fetched from the endpoint.
     */    
    getSurveyInfo: builder.query<[SurveysDataResponse], string>({
      query: (email) => ({
        url: `${SURVEYSROUTES.base}/info?email=${email}`,
      }),
    }),
    /**
     * Endpoint for fetching all survey results with specific criteria.
     * @returns An object containing the HTTP response from the survey results endpoint.
     */    
    getSurveyResultsAll: builder.query<any, SurveysDataRequest>({
      query: ({ surveyid, page, limit, matchingtype, isRecalculating }) => ({
        url: `${SURVEYSROUTES.base}/${surveyid}/results?matchingRecordsPage=${page}&matchingRecordsLimit=${limit}&matchingType=${matchingtype}&isRecalculating=${isRecalculating}`,
      }),
      providesTags: ['SurveyCriteriaUpdate'],
    }),
    /**
     * Endpoint for fetching company-specific survey results with specific criteria.
     * @returns An object containing the HTTP response from the company-specific survey results endpoint.
     */    
    getCompanySurveyResultsAll: builder.query<any, SurveysDataRequest>({
      query: ({ surveyid, page, limit, matchingtype, isRecalculating }) => ({
        url: `${SURVEYSROUTES.base}/${surveyid}/companyresults?matchingRecordsPage=${page}&matchingRecordsLimit=${limit}&matchingType=${matchingtype}&isRecalculating=${isRecalculating}`,
      }),
      providesTags: ['SurveyCriteriaUpdate'],
    }),
    /**
     * Endpoint for fetching survey results count with specific criteria.
     * @returns An object containing the HTTP response from the survey results count endpoint.
     */    
    getSurveyResutsCounts: builder.query<any, SurveysDataRequest>({
      query: ({ surveyid, matchingtype, isRecalculating }) => ({
        url: `${SURVEYSROUTES.base}/${surveyid}/resultsCount?matchingType=${matchingtype}&isRecalculating=${isRecalculating}`,
      }),
    }),
    /**
     * Endpoint for fetching survey contacts by email.
     * @returns An array containing the survey contacts data fetched from the endpoint.
     */    
    getSurveyContacts: builder.query<SurveysContactsResponse[], string>({
      query: (email) => ({
        url: `${SURVEYSROUTES.base}/contacts?email=${email}`,
      }),
      providesTags: ['SurveyContacts'],
    }),
    getClientCodeNames: builder.query<
      SurveysContactsResponse[],
      { email: string; code: string }
    >({
      query: ({ email, code }) => ({
        url: `${SURVEYSROUTES.base}/clientcodenames?email=${email}&clientcode=${code}`,
      }),
    }),
    getClientCodeNamesWithSurveyName: builder.query<
      SurveysContactsResponse[],
      { email: string; code: string; surveyName: string }
    >({
      query: ({ email, code, surveyName }) => ({
        url: `${SURVEYSROUTES.base}/clientcodenames?email=${email}&clientcode=${code}&surveyName=${surveyName}`,
      }),
    }),
    /**
     * Endpoint for fetching survey criteria by survey ID.
     * @returns An object containing the HTTP response from the survey criteria endpoint.
     */    
    getSurveyCriteria: builder.query<any, { surveyid: any }>({
      query: ({ surveyid }) => ({
        url: `${SURVEYSROUTES.surveyCriteria}?surveyid=${surveyid}`,
      }),
    }),
    /**
     * Endpoint for fetching survey criteria by survey ID.
     * @returns An object containing the HTTP response from the survey criteria endpoint.
     */    
    getSurveyCriteriasBySurveyId: builder.query<any, { surveyid: any }>({
      query: ({ surveyid }) => ({
        url: `${SURVEYSROUTES.surveyCriteria}/${surveyid}`,
      }),
    }),
    /**
     * Endpoint for fetching survey projects with pagination.
     * @returns An object containing the HTTP response from the survey projects endpoint.
     */    
    getSurveyProjects: builder.query<any, any>({
      query: ({ page, take }) => ({
        url: `${SURVEYSROUTES.base}/projects?page=${page}&limit=${take}`,
      }),
    }),
    /**
     * Endpoint for fetching survey criteria by value, email, and role with pagination.
     * @returns An object containing the HTTP response from the survey criteria by value endpoint.
     */    
    getSurveyCriteriasByValue: builder.query<any, any>({
      query: ({ page, take, value, email, role }) => ({
        url: `${SURVEYSROUTES.surveyCriteria}/surveys?page=${page}&limit=${take}&value=${value}&email=${email}&role=${role}`,
      }),
    }),
    updateSurveyCriteria: builder.mutation<
      boolean,
      UpdateSurveyCriteriaRequest
    >({
      query: (body) => ({
        url: SURVEYSROUTES.surveyCriteria,
        method: 'PUT',
        body,
      }),
    }),
    /**
     * Endpoint for deleting survey criteria by ID with delete status.
     * @returns An object containing the HTTP response from the delete survey criteria endpoint.
     */    
    deleteSurveyCriteria: builder.mutation<any, any>({
      query: ({ id, deleteStatus }) => ({
        url: `${SURVEYSROUTES.base}/${id}?deleteStatus=${deleteStatus}`,
        method: 'DELETE',
      }),
    }),
    /**
     * Endpoint for fetching survey details by survey ID.
     * @returns An object containing the HTTP response from the survey details endpoint.
     */    
    getSurveyById: builder.query<any, any>({
      query: ({ id }) => ({
        url: `${SURVEYSROUTES.base}/${id}/response`,
      }),
      providesTags: ['SurveyCriteriaUpdate'],
    }),
    /**
     * Endpoint for fetching survey queries by ID with recalculating status.
     * @returns An object containing the HTTP response from the survey queries endpoint.
     */    
    getSurveyQueries: builder.query<any, any>({
      query: ({ id, isRecalculating }) => ({
        url: `${SURVEYSROUTES.base}/getQueries/${id}/return?isRecalculating=${isRecalculating}`,
        method: 'GET',
      }),
    }),
    updateSurveyResponse: builder.mutation<
      boolean,
      UpdateSurveyCriteriaRequest
    >({
      query: (body) => ({
        url: SURVEYSROUTES.base,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SurveyCriteriaUpdate'],
    }),
    updateRecalculateResponse: builder.mutation<
      boolean,
      UpdateSurveyCriteriaRequest
    >({
      query: (body) => ({
        url: `${SURVEYSROUTES.base}/recalculate_response`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SurveyCriteriaUpdate'],
    }),
  }),
});

export const {
  useGetUrlParamsFromSurveyQuery,
  useGetSurveyResultsQuery,
  useGetSurveyResultsAllQuery,
  useGetSurveyResutsCountsQuery,
  useGetCompanySurveyResultsAllQuery,
  useGetSurveyContactsQuery,
  useGetClientCodeNamesQuery,
  useGetClientCodeNamesWithSurveyNameQuery,
  useGetSurveyCriteriaQuery,
  useGetSurveyInfoQuery,
  useGetSurveyCriteriasBySurveyIdQuery,
  useGetSurveyProjectsQuery,
  useGetSurveyCriteriasByValueQuery,
  useUpdateSurveyCriteriaMutation,
  useDeleteSurveyCriteriaMutation,
  useGetSurveyByIdQuery,
  useGetSurveyQueriesQuery,
  useUpdateSurveyResponseMutation,
  useUpdateRecalculateResponseMutation,
} = surveyApi;

// export const resetDataByTags = () => {
//   surveyApi.util.invalidateTags([{ type: 'SurveyContacts' }]);
// };

// export const resetApiState = () => {
//   // Clear the cache for each endpoint
//   surveyApi.endpoints.getSurveyContacts.initiate('test');
//   // getSurveyContacts.initiate();
//   // surveyApi.dispatch();
//   // surveyApi.reducerPath.resetApiState();

//   // Dispatch an action to reset the API state
//   // surveyApi.reducerPath.resetApiState();
// };
