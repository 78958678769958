// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { SURVEYSROUTES } from 'app/apiConstants';

export const getSurveyContacts = (email: any) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SURVEYSROUTES.base}/contacts?email=${email}`,
  );
};
export const getSurveyCriteria = (surveyid: any) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SURVEYSROUTES.surveyCriteria}?surveyid=${surveyid}`,
  );
};

export const getLatestSurveyContacts = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SURVEYSROUTES.base}/contacts`,
  );
};

export const getSurveyCriteriasByValue = (
  page: any,
  take: any,
  value: any,
  email: any,
) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SURVEYSROUTES.surveyCriteria}/surveys?page=${page}&limit=${take}&value=${value}&email=${email}`,
  );
};
