import { createApi } from '@reduxjs/toolkit/query/react';
import { ZONINGROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';

export const zoningApi = createApi({
  baseQuery,
  reducerPath: 'zoningApi',
  endpoints: (builder) => ({
    /**
     * Endpoint for fetching all zoning cities based on search value.
     * @returns An array of strings containing zoning city names.
     */    
    getAllZoningCities: builder.query<string[], string>({
      query: (searchValue) => ({
        url: `${ZONINGROUTES.base}/cities?searchValue=${searchValue}`,
      }),
    }),
    /**
     * Endpoint for fetching options by city name.
     * @returns An array of strings containing options related to the specified city.
     */    
    getOptionsByCities: builder.query<string[], any>({
      query: (cityName) => ({
        url: `${ZONINGROUTES.base}/city?name=${cityName}`,
      }),
    }),
    /**
     * Endpoint for fetching all zoning counties based on search value.
     * @returns An array of strings containing zoning county names.
     */    
    getAllZoningCounties: builder.query<string[], string>({
      query: (searchValue) => ({
        url: `${ZONINGROUTES.base}/counties?searchValue=${searchValue}`,
      }),
    }),
    /**
     * Endpoint for fetching options by county name.
     * @returns An array of strings containing options related to the specified county.
     */       
    getOptionsByCounties: builder.query<string[], any>({
      query: (county) => ({
        url: `${ZONINGROUTES.base}/county?name=${county}`,
      }),
    }),
  }),
});

export const {
  useGetAllZoningCitiesQuery,
  useGetOptionsByCitiesQuery,
  useGetAllZoningCountiesQuery,
  useGetOptionsByCountiesQuery,
} = zoningApi;
