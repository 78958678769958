import { createApi } from '@reduxjs/toolkit/query/react';
import { BOOKMARKROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import { BookmarkInterface, CreateBookmarkArgs } from 'types/bookmark';

export const bookmarkApi = createApi({
  baseQuery,
  reducerPath: 'bookmarkApi',
  tagTypes: ['Bookmark'],
  /**
   * Endpoints for managing bookmarks.
   */
  endpoints: (builder) => ({
     /**
     * Retrieves all bookmarks.
     * Usage:
     * ```typescript
     * const { data } = useGetAllBookmarksQuery();
     * ```
     */
    getAllBookmarks: builder.query<BookmarkInterface[], void>({
      query: () => ({
        url: BOOKMARKROUTES.base,
      }),
      providesTags: ['Bookmark'],
    }),
    /**
     * Creates a new bookmark.
     * @param body - The bookmark data to create.
     * Usage:
     * ```typescript
     * const { mutate } = useCreateBookmarkMutation();
     * mutate({ url, title });
     * ```
     */
    createBookmark: builder.mutation<BookmarkInterface, CreateBookmarkArgs>({
      query: (body) => ({
        url: BOOKMARKROUTES.base,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Bookmark'],
    }),
    /**
     * Deletes a bookmark by ID.
     * @param id - The ID of the bookmark to delete.
     * Usage:
     * ```typescript
     * const { mutate } = useDeleteBookmarkMutation();
     * mutate(bookmarkId);
     * ```
     */
    deleteBookmark: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `${BOOKMARKROUTES.base}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Bookmark'],
    }),
  }),
});

export const {
  useCreateBookmarkMutation,
  useGetAllBookmarksQuery,
  useDeleteBookmarkMutation,
} = bookmarkApi;
