import { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';

interface AutoCompleteStoreState {
  inputValue: string;
}

/**
 * Redux slice for managing autocomplete input state.
 * 
 * Manages the following state:
 * - `inputValue`: Holds the current value of the autocomplete input field.
 * 
 * Reducers:
 * - `setAutocompleteInput`: Updates the `inputValue` state with the provided payload.
 */
const slice = createSlice({
  name: 'autocompleteStore',
  initialState: {
    inputValue: '',
  } as AutoCompleteStoreState,
  reducers: {
    setAutocompleteInput(state, action) {
      state.inputValue = action.payload;
    }
  },
});

export const autoCompleteStoreReducer = slice.reducer;
export const { setAutocompleteInput } = slice.actions;

export const selectInputValue = (state: RootState) =>
  state.autoCompleteStore.inputValue;
// export const selectCurrentTab = (state: RootState) =>
//   state.baseStore.currentTab;
// export const selectCurrentItem = (state: RootState) => state.baseStore.item;
// export const selectCurrentCompanyId = (state: RootState) =>
//   state.baseStore.companyId;
