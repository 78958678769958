import React from 'react';

export const McCleeryLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="196.114"
      height="177.506"
      viewBox="0 0 196.114 177.506"
    >
      <g
        id="Group_1553"
        data-name="Group 1553"
        transform="translate(-12201 -13495)"
      >
        <g
          id="Group_1543"
          data-name="Group 1543"
          transform="translate(12201 13607.99)"
        >
          <path
            id="Path_1162"
            data-name="Path 1162"
            d="M144.8-570.623h1.93l6.152,9.216,6.152-9.216h1.932v16.888H159.06v-13.679l-6.152,9.047h-.1l-6.152-9.022v13.655H144.8Z"
            transform="translate(-144.801 570.913)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1163"
            data-name="Path 1163"
            d="M248.5-564.753v-.035a6.087,6.087,0,0,1,6.11-6.248,6.285,6.285,0,0,1,4.711,1.915l-.93,1a5.26,5.26,0,0,0-3.8-1.657,4.738,4.738,0,0,0-4.679,4.954v.034A4.769,4.769,0,0,0,254.6-559.8a5.245,5.245,0,0,0,3.9-1.743l.9.881a6.3,6.3,0,0,1-4.834,2.124A6.028,6.028,0,0,1,248.5-564.753Z"
            transform="translate(-220.073 571.212)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1164"
            data-name="Path 1164"
            d="M328.2-562.9v-.049a8.508,8.508,0,0,1,8.541-8.733A8.787,8.787,0,0,1,343.327-569l-1.3,1.4a7.339,7.339,0,0,0-5.31-2.318,6.621,6.621,0,0,0-6.538,6.925v.047a6.664,6.664,0,0,0,6.538,6.974,7.337,7.337,0,0,0,5.455-2.438l1.254,1.23a8.792,8.792,0,0,1-6.756,2.968A8.423,8.423,0,0,1,328.2-562.9Z"
            transform="translate(-277.918 571.68)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1165"
            data-name="Path 1165"
            d="M431.141-570.623h1.905V-555.5h9.506v1.76H431.141Z"
            transform="translate(-352.638 570.913)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1166"
            data-name="Path 1166"
            d="M520.865-570.623h12.209v1.736h-10.3v5.767h9.216v1.738h-9.216v5.911h10.421v1.736H520.865Z"
            transform="translate(-417.763 570.913)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1167"
            data-name="Path 1167"
            d="M615.094-570.623H627.3v1.736H617v5.767h9.218v1.738H617v5.911h10.424v1.736H615.094Z"
            transform="translate(-486.158 570.913)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1168"
            data-name="Path 1168"
            d="M709.314-570.623h7.264a6.647,6.647,0,0,1,4.8,1.689,4.662,4.662,0,0,1,1.3,3.329v.047c0,2.8-1.93,4.441-4.584,4.922l5.187,6.9h-2.342l-4.9-6.562h-4.824v6.562h-1.908Zm7.095,8.612c2.534,0,4.343-1.3,4.343-3.474v-.049c0-2.075-1.593-3.329-4.318-3.329h-5.212v6.852Z"
            transform="translate(-554.547 570.913)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1169"
            data-name="Path 1169"
            d="M808.889-560.418l-7.021-10.205h2.317l5.693,8.443,5.742-8.443h2.219l-7.021,10.181v6.707h-1.93Z"
            transform="translate(-621.726 570.913)"
            fill="#5a5c5f"
          />
        </g>
        <g
          id="Group_1544"
          data-name="Group 1544"
          transform="translate(12263.234 13636.717)"
        >
          <path
            id="Path_1170"
            data-name="Path 1170"
            d="M367.667-458.549a3.12,3.12,0,0,0,1.063-.189,2.8,2.8,0,0,0,.984-.646l.638.651a4.333,4.333,0,0,1-1.3.872,3.548,3.548,0,0,1-1.387.287,3.61,3.61,0,0,1-1.461-.3,3.723,3.723,0,0,1-1.2-.818,3.836,3.836,0,0,1-.813-1.227,3.883,3.883,0,0,1-.3-1.522,3.636,3.636,0,0,1,.3-1.466,3.79,3.79,0,0,1,.813-1.2,3.905,3.905,0,0,1,1.2-.805,3.611,3.611,0,0,1,1.461-.3,3.669,3.669,0,0,1,1.387.277,4.82,4.82,0,0,1,1.3.8l-.638.638a3.745,3.745,0,0,0-.992-.54,3.063,3.063,0,0,0-1.056-.2,2.96,2.96,0,0,0-1.141.219,2.734,2.734,0,0,0-.909.594,2.74,2.74,0,0,0-.6.889,2.748,2.748,0,0,0-.216,1.093,3,3,0,0,0,.216,1.149,2.8,2.8,0,0,0,.6.921,2.737,2.737,0,0,0,.909.606A3.01,3.01,0,0,0,367.667-458.549Z"
            transform="translate(-363.901 465.674)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1171"
            data-name="Path 1171"
            d="M407.8-457.555a3.725,3.725,0,0,1-1.517-.307,3.8,3.8,0,0,1-1.213-.83,3.828,3.828,0,0,1-.8-1.213,3.72,3.72,0,0,1-.292-1.471,3.888,3.888,0,0,1,.292-1.517,3.772,3.772,0,0,1,.8-1.218,3.633,3.633,0,0,1,1.213-.805,3.888,3.888,0,0,1,1.517-.294,3.721,3.721,0,0,1,1.471.294,3.872,3.872,0,0,1,1.22.805,3.888,3.888,0,0,1,.832,1.218,3.728,3.728,0,0,1,.309,1.517,3.569,3.569,0,0,1-.309,1.471,3.948,3.948,0,0,1-.832,1.213,4.064,4.064,0,0,1-1.22.83A3.567,3.567,0,0,1,407.8-457.555Zm0-6.69a2.923,2.923,0,0,0-1.132.216,2.789,2.789,0,0,0-.911.6,2.774,2.774,0,0,0-.6.908,3.013,3.013,0,0,0-.216,1.144,2.845,2.845,0,0,0,.216,1.093,2.868,2.868,0,0,0,.6.911,2.964,2.964,0,0,0,.911.621,2.763,2.763,0,0,0,1.132.233,2.657,2.657,0,0,0,1.093-.233,3.015,3.015,0,0,0,.908-.621,3.02,3.02,0,0,0,.623-.911,2.648,2.648,0,0,0,.233-1.093,2.8,2.8,0,0,0-.233-1.144,2.848,2.848,0,0,0-.628-.908,2.911,2.911,0,0,0-.913-.6A2.8,2.8,0,0,0,407.8-464.245Z"
            transform="translate(-392.993 465.674)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1172"
            data-name="Path 1172"
            d="M448.486-458.361v-7.9l3.28,3.4q.552-.552,1.1-1.109c.361-.373.724-.749,1.093-1.132.167-.189.353-.388.557-.6s.393-.4.557-.575v7.915h-.952v-5.531l-2.337,2.4-2.34-2.416v5.544Z"
            transform="translate(-425.296 466.448)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1173"
            data-name="Path 1173"
            d="M491.179-460.718v3.248h-.911v-7.579h2.057a2.21,2.21,0,0,1,.8.152,2.179,2.179,0,0,1,.7.437,2.219,2.219,0,0,1,.5.695,2.129,2.129,0,0,1,.189.9,2.126,2.126,0,0,1-.13.688,2.246,2.246,0,0,1-.4.7,2.262,2.262,0,0,1-.673.543,1.965,1.965,0,0,1-.952.216Zm1.168-.975a1.1,1.1,0,0,0,.493-.108,1.17,1.17,0,0,0,.368-.275,1.289,1.289,0,0,0,.226-.381,1.123,1.123,0,0,0,.081-.405,1.23,1.23,0,0,0-.123-.569,1.218,1.218,0,0,0-.309-.378,1.221,1.221,0,0,0-.385-.211,1.127,1.127,0,0,0-.351-.064h-1.168v2.391Z"
            transform="translate(-455.623 465.557)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1174"
            data-name="Path 1174"
            d="M527.7-458.826h-1.041l-.66-1.483h-3.486l-.648,1.483h-1.029q.195-.456.4-.921t.39-.921c.439-1.024.881-2.06,1.326-3.1s.886-2.089,1.326-3.135Zm-2.089-2.435c-.233-.557-.454-1.085-.668-1.589s-.435-1.034-.665-1.6c-.1.253-.206.5-.314.736s-.214.479-.314.724c-.123.268-.246.547-.373.839s-.248.587-.363.884Z"
            transform="translate(-477.813 466.903)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1175"
            data-name="Path 1175"
            d="M569.082-458.35l-4.829-6v5.531h-.889V-466.9l4.841,5.934v-5.426h.876Z"
            transform="translate(-508.679 466.903)"
            fill="#5a5c5f"
          />
          <path
            id="Path_1176"
            data-name="Path 1176"
            d="M605.182-461.357l-.1.12v3.766h-.962v-3.756l-.53-.715c-.238-.324-.484-.648-.731-.97s-.5-.648-.742-.979l-.876-1.159h1.19l2.188,2.87.994-1.321.273-.381.9-1.168h1.191Z"
            transform="translate(-536.172 465.557)"
            fill="#5a5c5f"
          />
        </g>
        <path
          id="Path_1177"
          data-name="Path 1177"
          d="M393.878-974.261l-15.873-9.164a2.889,2.889,0,0,0-2.89,0l-16.373,9.454-2.388,1.378-17.819,10.288-6.743,3.893a2.89,2.89,0,0,1-2.889,0l-6.743-3.893-17.818-10.288-2.388-1.378-16.375-9.454a2.889,2.889,0,0,0-2.89,0l-15.873,9.164a2.889,2.889,0,0,0-1.443,2.5h0v72.521a2.89,2.89,0,0,0,1.443,2.5l15.883,9.169a6.149,6.149,0,0,0,5.7.037l13.559-7.828,18.762-10.832a2.889,2.889,0,0,0,1.443-2.5V-930.4l-15.873-9.164a2.889,2.889,0,0,0-4.333,2.5v16.708a2.89,2.89,0,0,1-1.445,2.5l-10.6,6.118a2.889,2.889,0,0,1-4.334-2.5v-40.849a2.889,2.889,0,0,1,4.334-2.5l12.04,6.952,26.952,15.515a2.889,2.889,0,0,0,2.882,0l26.953-15.515,12.04-6.952a2.889,2.889,0,0,1,4.333,2.5v40.849a2.889,2.889,0,0,1-4.333,2.5l-10.6-6.118a2.889,2.889,0,0,1-1.443-2.5v-16.708a2.89,2.89,0,0,0-4.334-2.5L338.535-930.4v21.712a2.89,2.89,0,0,0,1.445,2.5l18.762,10.832,13.495,7.791a6.148,6.148,0,0,0,5.7.032l15.938-9.2a2.89,2.89,0,0,0,1.445-2.5v-72.521h0A2.889,2.889,0,0,0,393.878-974.261Z"
          transform="translate(11968.8 14478.813)"
          fill="#7bb5c6"
        />
        <g
          id="Group_1551"
          data-name="Group 1551"
          transform="translate(12231.433 13664.979)"
        >
          <g
            id="Group_1545"
            data-name="Group 1545"
            transform="translate(0 0.548)"
          >
            <path
              id="Path_1178"
              data-name="Path 1178"
              d="M254.783-355.951v2.6h-.727v-6.073H255.7a1.774,1.774,0,0,1,.638.121,1.754,1.754,0,0,1,.565.351,1.77,1.77,0,0,1,.4.555,1.7,1.7,0,0,1,.152.725,1.717,1.717,0,0,1-.1.551,1.841,1.841,0,0,1-.322.56,1.819,1.819,0,0,1-.538.433,1.586,1.586,0,0,1-.763.173Zm.938-.781a.872.872,0,0,0,.4-.087.932.932,0,0,0,.295-.221.965.965,0,0,0,.182-.3.891.891,0,0,0,.066-.325.957.957,0,0,0-.1-.455.926.926,0,0,0-.248-.3A1,1,0,0,0,256-358.6a.952.952,0,0,0-.282-.052h-.938v1.918Z"
              transform="translate(-254.056 359.455)"
              fill="#939494"
            />
            <path
              id="Path_1179"
              data-name="Path 1179"
              d="M281.581-353.412a3,3,0,0,1-1.215-.246,3.064,3.064,0,0,1-.97-.664,3.094,3.094,0,0,1-.643-.972,2.983,2.983,0,0,1-.233-1.179,3.121,3.121,0,0,1,.233-1.215,3.028,3.028,0,0,1,.643-.976,2.921,2.921,0,0,1,.97-.647,3.151,3.151,0,0,1,1.215-.233,3.015,3.015,0,0,1,1.181.233,3.142,3.142,0,0,1,.977.647,3.128,3.128,0,0,1,.668.976,3,3,0,0,1,.246,1.215,2.869,2.869,0,0,1-.246,1.179,3.2,3.2,0,0,1-.668.972,3.307,3.307,0,0,1-.977.664A2.875,2.875,0,0,1,281.581-353.412Zm0-5.361a2.352,2.352,0,0,0-.906.173,2.226,2.226,0,0,0-.729.481,2.208,2.208,0,0,0-.481.729,2.384,2.384,0,0,0-.174.916,2.258,2.258,0,0,0,.174.876,2.26,2.26,0,0,0,.481.729,2.371,2.371,0,0,0,.729.5,2.212,2.212,0,0,0,.906.186,2.127,2.127,0,0,0,.876-.186,2.433,2.433,0,0,0,.729-.5,2.392,2.392,0,0,0,.5-.729,2.106,2.106,0,0,0,.186-.876,2.223,2.223,0,0,0-.186-.916,2.306,2.306,0,0,0-.5-.729,2.315,2.315,0,0,0-.731-.481A2.241,2.241,0,0,0,281.581-358.774Z"
              transform="translate(-271.812 359.546)"
              fill="#939494"
            />
            <path
              id="Path_1180"
              data-name="Path 1180"
              d="M321.08-359.421l-2.646,6.282-1.274-2.9-.41.937c-.137.313-.282.643-.432.993s-.292.673-.425.969l-2.656-6.282h.825l1.839,4.252c.069-.151.142-.315.221-.495s.15-.345.214-.495l.044-.087.363-.815c-.1-.243-.2-.481-.294-.712s-.2-.469-.295-.712l-.39-.937h.85l.548,1.363c.091-.22.186-.449.285-.686s.192-.463.277-.676h.835l-.982,2.369c.152.329.292.643.425.941s.275.611.427.941l.935-2.179.893-2.073Z"
              transform="translate(-297.011 359.455)"
              fill="#939494"
            />
            <path
              id="Path_1181"
              data-name="Path 1181"
              d="M356.1-358.689v1.874h2.057v.711H356.1v1.97h2.482v.763h-3.253v-6.082h3.253v.763Z"
              transform="translate(-327.566 359.478)"
              fill="#939494"
            />
            <path
              id="Path_1182"
              data-name="Path 1182"
              d="M384.613-353.348l-1.684-2.6H382v2.6h-.719v-6.073h1.64a1.761,1.761,0,0,1,.636.121,1.728,1.728,0,0,1,.565.351,1.818,1.818,0,0,1,.4.555,1.707,1.707,0,0,1,.152.725,1.664,1.664,0,0,1-.253.9,1.733,1.733,0,0,1-.695.625l1.8,2.794Zm-1.667-3.384a.845.845,0,0,0,.39-.087.945.945,0,0,0,.292-.221,1.062,1.062,0,0,0,.182-.3.886.886,0,0,0,.064-.325.961.961,0,0,0-.1-.455.962.962,0,0,0-.248-.3,1.028,1.028,0,0,0-.307-.169.934.934,0,0,0-.275-.052H382v1.918Z"
              transform="translate(-346.402 359.455)"
              fill="#939494"
            />
            <path
              id="Path_1183"
              data-name="Path 1183"
              d="M410.127-358.689v1.874h2.055v.711h-2.055v1.97h2.48v.763h-3.253v-6.082h3.253v.763Z"
              transform="translate(-366.777 359.478)"
              fill="#939494"
            />
            <path
              id="Path_1184"
              data-name="Path 1184"
              d="M436.853-359.421a5.439,5.439,0,0,1,.641.043,3.713,3.713,0,0,1,.717.164,3.381,3.381,0,0,1,.7.335,2.3,2.3,0,0,1,.609.555,2.824,2.824,0,0,1,.425.829,3.736,3.736,0,0,1,.16,1.154,3.306,3.306,0,0,1-.194,1.2,2.654,2.654,0,0,1-.5.833,2.446,2.446,0,0,1-.69.525,3.9,3.9,0,0,1-.756.286,4.068,4.068,0,0,1-.695.121c-.214.017-.381.026-.5.026h-1.647v-6.073Zm-.089,5.344a3.136,3.136,0,0,0,1.039-.161,2.222,2.222,0,0,0,.788-.455,1.983,1.983,0,0,0,.5-.711,2.339,2.339,0,0,0,.179-.938,2.79,2.79,0,0,0-.165-.981,2.076,2.076,0,0,0-.474-.741,2.041,2.041,0,0,0-.759-.469,3.064,3.064,0,0,0-1.024-.16h-.945v4.615Z"
              transform="translate(-385.477 359.455)"
              fill="#939494"
            />
            <path
              id="Path_1185"
              data-name="Path 1185"
              d="M488.346-359.39a1.463,1.463,0,0,1,.656.144,1.621,1.621,0,0,1,.493.368,1.653,1.653,0,0,1,.312.5,1.442,1.442,0,0,1,.108.543,1.215,1.215,0,0,1-.086.478,1.693,1.693,0,0,1-.191.356,1.728,1.728,0,0,1-.277.312,1.775,1.775,0,0,1,.219.13,1.732,1.732,0,0,1,.324.3,1.943,1.943,0,0,1,.3.483,1.606,1.606,0,0,1,.13.668,1.767,1.767,0,0,1-.16.767,1.72,1.72,0,0,1-.417.56,1.79,1.79,0,0,1-.572.344,1.826,1.826,0,0,1-.628.117h-1.824v-6.065Zm-.859.738v1.692h.911a.614.614,0,0,0,.238-.056.94.94,0,0,0,.26-.165.878.878,0,0,0,.209-.269.81.81,0,0,0,.083-.369.851.851,0,0,0-.061-.308.924.924,0,0,0-.169-.269.839.839,0,0,0-.265-.186.83.83,0,0,0-.339-.069Zm1.068,4.572a.91.91,0,0,0,.42-.1,1.025,1.025,0,0,0,.317-.243,1.067,1.067,0,0,0,.2-.334,1.042,1.042,0,0,0,.071-.368,1.067,1.067,0,0,0-.088-.43,1.132,1.132,0,0,0-.233-.356,1.24,1.24,0,0,0-.334-.243.864.864,0,0,0-.378-.091h-1.041v2.16Z"
              transform="translate(-422.941 359.433)"
              fill="#939494"
            />
            <path
              id="Path_1186"
              data-name="Path 1186"
              d="M515.9-356.463l-.078.1v3.02h-.771v-3.011l-.427-.572c-.189-.261-.385-.519-.584-.777s-.4-.519-.594-.785l-.7-.928h.955l1.753,2.3.8-1.058.219-.3.719-.937h.955Z"
              transform="translate(-441.822 359.455)"
              fill="#939494"
            />
          </g>
          <g
            id="Group_1546"
            data-name="Group 1546"
            transform="translate(85.478 0)"
          >
            <path
              id="Path_1187"
              data-name="Path 1187"
              d="M574.262-363.5v6.926l-1.506-1.031-5.415-3.705v4.18a.594.594,0,0,1-.626.555h-.255a.594.594,0,0,1-.626-.555v-6.9L567.342-363l5.415,3.705V-363.5a.594.594,0,0,1,.626-.555h.255A.594.594,0,0,1,574.262-363.5Z"
              transform="translate(-565.835 364.071)"
              fill="#5a5c5f"
            />
            <path
              id="Path_1188"
              data-name="Path 1188"
              d="M648.569-360.3l2.469,2.837a.553.553,0,0,1-.5.895h-.315a.657.657,0,0,1-.5-.216l-2.109-2.427-2.109,2.427a.657.657,0,0,1-.5.216H644.7a.553.553,0,0,1-.5-.895l2.465-2.837-2.485-2.859a.553.553,0,0,1,.5-.895H645a.657.657,0,0,1,.5.216l2.129,2.449,2.129-2.449a.657.657,0,0,1,.5-.216h.311a.553.553,0,0,1,.5.895Zm-5.159,3.732h.02l-.02.022Zm8.422.018-.015-.018h.015Z"
              transform="translate(-622.142 364.071)"
              fill="#5a5c5f"
            />
            <path
              id="Path_1189"
              data-name="Path 1189"
              d="M685.887-360.3l2.469,2.837a.553.553,0,0,1-.5.895h-.315a.657.657,0,0,1-.5-.216l-2.109-2.427-2.109,2.427a.657.657,0,0,1-.5.216h-.316a.553.553,0,0,1-.5-.895l2.465-2.837-2.485-2.859a.554.554,0,0,1,.5-.895h.316a.657.657,0,0,1,.5.216l2.129,2.449,2.129-2.449a.657.657,0,0,1,.5-.216h.311a.554.554,0,0,1,.5.895Zm-5.159,3.732h.02l-.02.022Zm8.422.018-.015-.018h.015Z"
              transform="translate(-649.229 364.071)"
              fill="#5a5c5f"
            />
            <path
              id="Path_1190"
              data-name="Path 1190"
              d="M611.85-361.816l2.459,4.889a.639.639,0,0,0,.555.3h.3a.561.561,0,0,0,.557-.807l-3.317-6.385a.664.664,0,0,0-1.115,0l-3.317,6.385a.561.561,0,0,0,.558.807h.3a.639.639,0,0,0,.558-.3Z"
              transform="translate(-596.372 364.119)"
              fill="#5a5c5f"
            />
            <path
              id="Path_1191"
              data-name="Path 1191"
              d="M722.747-361.816l2.459,4.889a.638.638,0,0,0,.555.3h.3a.561.561,0,0,0,.558-.807l-3.317-6.385a.664.664,0,0,0-1.115,0l-3.317,6.385a.561.561,0,0,0,.557.807h.3a.639.639,0,0,0,.557-.3Z"
              transform="translate(-676.866 364.119)"
              fill="#5a5c5f"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
