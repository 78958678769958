import { Drawer } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ActiveTabType } from 'types/common';
import { resetSurveyFromLocalStorage } from 'utils/resetSurveyFromStorage';
import { AdvancedFilterHeader } from './AdvancedFilterHeader';
import { AdvancedFiltersContent } from './AdvancedFiltersContent';
import { selectActiveTabValue } from 'app/services';
import { useSelector } from 'react-redux';

// Interface for component props
interface AdvancedFilterDrawerProps {
  isOpen: boolean;
  closeAdvancedFiltersDrawer: () => void;
}

// AdvancedFilterDrawer component to manage advanced filter options
export const AdvancedFilterDrawer = ({
  isOpen,
  closeAdvancedFiltersDrawer,
}: AdvancedFilterDrawerProps) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const activeTab: ActiveTabType = useSelector(selectActiveTabValue);

  // Function to handle reset button click
  const onResetClick = () => {
    resetSurveyFromLocalStorage()
    replace({
      pathname,
      search: '',
    });
    closeAdvancedFiltersDrawer();
  };

  return (
    // Drawer component to display advanced filter options
    <Drawer
      open={isOpen}
      placement="left"
      title={<AdvancedFilterHeader onResetClick={onResetClick} />}
      className="advanced-filter-drawer"
      onClose={closeAdvancedFiltersDrawer}
    >
      {/* Content of the advanced filter drawer */}
      <AdvancedFiltersContent
        closeAdvancedFiltersDrawer={closeAdvancedFiltersDrawer}
        activeTab={activeTab}
      />
    </Drawer>
  );
};
