import React, { ReactElement, useCallback, useState } from 'react';
import { Collapse, Menu } from 'antd';

interface MenuNavigatedContentProps {
  navigationMenuOptions: { title: string; menuComponents: ReactElement[] }[];
  onDoneClick: () => void;
}

const { Panel } = Collapse;

export const MenuNavigatedContent = ({
  navigationMenuOptions,
  onDoneClick,
}: MenuNavigatedContentProps) => {
  const [openedTabs, setOpenedTabs] = useState<string[]>([]);

  const handleNavTabClick = useCallback(
    (key: any) => {
      const isAlreadyOpen = openedTabs.includes(key);

      if (isAlreadyOpen) {
        setOpenedTabs(openedTabs.filter((value) => key !== value));
        return;
      }

      setOpenedTabs([...openedTabs, key]);
    },
    [openedTabs],
  );

  return (
    <div className="naxxa-advanced-filters-content">
      <div className="left-navbar">
        {/* SideBar */}
        <Menu defaultSelectedKeys={['1']} mode="inline">
          {navigationMenuOptions?.map((item) => (
            <Menu.Item
              key={item.title}
              onClick={() => {
                handleNavTabClick(item.title.toString());
              }}
            >
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
      </div>

      <div className="content">
        {/* Content */}
        <Collapse
          activeKey={openedTabs}
          onChange={(keys) =>
            setOpenedTabs(typeof keys === 'string' ? [keys] : keys)
          }
        >
          {navigationMenuOptions?.map((item) => (
            <Panel
              key={item.title}
              className="font-normal font-semi-bold"
              header={
                <span className="font-normal font-semi-bold">{item.title}</span>
              }
            >
              {item.menuComponents.map((component, key) => component)}
            </Panel>
          ))}
        </Collapse>

        {/* <div className="advanced-filter-footer font-normal">
          <Button type="primary" onClick={onDoneClick}>
            Done
          </Button>
        </div> */}
      </div>
    </div>
  );
};
