import { Button } from "antd"
import { CookiesSettingsDrawer } from "./Settings"
import { useEffect, useState } from "react";

export const CookieConsent = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCookieConset, setIsCookieConsent] = useState(false);
  /**
   * useEffect hook to check if cookie consent is required and update state accordingly.
   * Sets `isCookieConsent` to `true` if `localStorage` does not contain 'cookieConsent'.
   */
  useEffect(() => {
    if (window !== undefined && !localStorage.getItem('cookieConsent')) {
      setIsCookieConsent(true);
    }
  }, [])

  /**
 * Sets a cookie with the specified name, value, and expiration period in days.
 * @param name The name of the cookie.
 * @param value The value to be stored in the cookie.
 * @param days Number of days until the cookie expires.
 */
  function setCookie(name: string, value: string, days: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
  }

  /**
   * Sets cookies for analytics, records the user's consent choice in localStorage,
   * and updates state to indicate cookie consent has been accepted.
   */
  function acceptAllCookies() {
    setCookie('analytics', 'true', 365);

    // Record the user's consent choice
    localStorage.setItem('cookieConsent', 'true');

    setIsCookieConsent(false);
  }

  /**
   * Records the user's rejection of all cookies by updating localStorage,
   * clearing all existing cookies, and updating state to reflect cookie rejection.
   */
  function rejectAllCookies() {
    // Record the user's rejection choice
    localStorage.setItem('cookieConsent', 'false');
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    setIsCookieConsent(false);
  }

  /**
   * Render function for displaying cookie consent message and options.
   * Shows a message and buttons for accepting or rejecting all cookies,
   * as well as a button to open cookie settings.
   */
  return (
    <>
      {isCookieConset &&
        <div className="cookie-consent-wrapper">
          <p>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
          <Button className="cookie-accept-button" type="primary" size="middle" onClick={acceptAllCookies} block>
            Accept All Cookies
          </Button>
          <Button className="cookie-accept-button" type="primary" size="middle" onClick={rejectAllCookies} block>
            Reject All
          </Button>
          <Button type="link" size="middle" block onClick={() => setIsDrawerOpen(true)}>Cookies Settings</Button>

          {isDrawerOpen &&
            <CookiesSettingsDrawer isOpenDrawer={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} onAllowAll={acceptAllCookies} />
          }
        </div>}
    </>
  )
}