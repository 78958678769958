/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import { List, Empty } from 'antd';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetCompanySurveyResultsAllQuery, useGetSurveyResultsAllQuery } from 'app/services';
import CompanyInfoPopover from './CompanyInfoPopover';
import ContactInfoPopover from './ContactInfoPopover';
interface IItem {
  items?: any;
  count: any;
  surveyid: string;
  matchingtype: string;
  selectedCountCard?: string;
  isRecalculating?: boolean;
}
const Item: React.FC<IItem> = ({ items, count, surveyid,
  matchingtype, selectedCountCard, isRecalculating }) => {
  const [listItem, setListItem] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [hasMoreCompany, setHasMoreCompany] = useState(true);
  const [companyPage, setCompanyPage] = useState(1);

  const [visibleCompaniesModel, setVisibleCompaniesModel] = useState(false);
  const [companyName, setCompanyName] = useState('')

  const [visibleContactsModel, setVisibleContactsModel] = useState(false);
  const [contactInfo, setContactInfo] = useState<any>()

  const limit = 10;

  const { data: surveyResult, isFetching } =
    useGetSurveyResultsAllQuery(
      { surveyid, page, limit, matchingtype, isRecalculating },
      {
        refetchOnReconnect: true,
        skip: !surveyid || !selectedCountCard || selectedCountCard === 'companies',
      },
    );

  const { data: companySurveyResult,
    isFetching: isCompanyResutFetching } =
    useGetCompanySurveyResultsAllQuery(
      { surveyid, page: companyPage, limit, matchingtype, isRecalculating },
      {
        refetchOnReconnect: true,
        skip: !surveyid || !selectedCountCard || selectedCountCard !== 'companies',
      },
    );

  useEffect(() => {
    if (
      surveyResult &&
      surveyResult?.length > 0 &&
      surveyResult[0][matchingtype]
    ) {
      const data = [...listItem]
      data.push(...surveyResult[0][matchingtype]);
      setListItem(data)
    }
  }, [surveyResult]); // eslint-disable-line

  useEffect(() => {
    if (
      companySurveyResult &&
      companySurveyResult?.length > 0 &&
      companySurveyResult[0][matchingtype]
    ) {
      const data = [...listItem]
      data.push(...companySurveyResult[0][matchingtype]);
      setListItem(data)
    }
  }, [companySurveyResult]); // eslint-disable-line


  useEffect(() => {
    setHasMore(
      count == listItem.length || count < listItem.length ? false : true,
    );
    setHasMoreCompany(
      count == listItem.length || count < listItem.length ? false : true,
    );
  }, [listItem, count]);

  const fetchMoreData = () => {
    if (selectedCountCard === 'companies') {
      setCompanyPage(companyPage + 1)
    } else {
      setPage(page + 1);
    }
  };

  // useEffect(() => {
  //   // setListItem(items);
  //   if (selectedCountCard === 'companies') {
  //     setHasMoreCompany(count > 10 ? true : false);
  //   } else {
  //     setHasMore(count > 10 ? true : false);
  //   }
  // }, [count, selectedCountCard]);


  return (
    <List>
      {(isFetching && page == 1) ||
        (isCompanyResutFetching && companyPage === 1) ?
        <div className="skeleton-div">
          <Skeleton height={42} count={1} />
          <Skeleton height={160} count={2} />
        </div> :
        <InfiniteScroll
          dataLength={listItem.length}
          next={fetchMoreData}
          hasMore={hasMore || hasMoreCompany}
          loader={<h4>Loading...</h4>}
          height={310}
          endMessage={''}
        >
          {listItem && listItem.length > 0 ? (
            listItem.map((item: any, index: number) => (
              matchingtype === 'projects' ?
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <div>
                        <strong>
                          {item.name}
                        </strong>
                      </div>
                    }
                    description={
                      <div>
                        <div>
                          <strong>Address: </strong>
                          {item.address}
                        </div>
                        <div>
                          <strong>City: </strong>
                          {item.city}
                        </div>
                        <div>
                          <strong>State: </strong>
                          {item.state}
                        </div>
                        <div>
                          <strong>County: </strong>
                          {item.county}
                        </div>
                      </div>
                    }
                  />
                </List.Item> : selectedCountCard === 'companies' ?
                  <List.Item key={index}>
                    <List.Item.Meta
                      title={
                        <div>
                          <strong>
                            {item.name}
                          </strong>
                        </div>
                      }
                      description={
                        <div>
                          <div>
                            <strong>Email: </strong>
                            {item?.email?.length && Array.isArray(item.email) ?
                              item?.email?.map((elem: any, i: any) => {
                                return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                              }) : item?.email ? <a href={'mailto:' + item.email}>{item.email}</a> : null}
                          </div>
                          <div>
                            <strong>Contacts: </strong>
                            {item?.contacts?.length &&
                              item?.contacts?.
                                map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a onClick={() => {
                                    setContactInfo(elem);
                                    setVisibleContactsModel(true)
                                  }} key={elem.id}>{elem.name}</a></> :
                                    <a onClick={() => {
                                      setContactInfo(elem);
                                      setVisibleContactsModel(true)
                                    }} key={elem.id}>{elem.name}</a>
                                })}
                          </div>
                          <div>
                            <strong>Website: </strong>
                            {item?.website?.length &&
                              item?.website?.
                                map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a target='blank' key={elem}
                                    href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                                })}
                          </div>
                          <div>
                            <strong>Address: </strong>
                            {item.address}
                          </div>
                          <div>
                            <strong>Phone: </strong>
                            {item?.phoneNumber?.length &&
                              item?.phoneNumber?.map((elem: any, i: any) => {
                                return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                              })}
                          </div>
                        </div>
                      }
                    />
                  </List.Item> :
                  <List.Item key={index}>
                    <List.Item.Meta
                      title={
                        <div>
                          <strong>
                            {item.firstname} {item.lastname}
                          </strong>
                        </div>
                      }
                      description={
                        <div>
                          <div>
                            <strong>Email: </strong>
                            {item?.email?.length && Array.isArray(item.email) ?
                              item?.email?.map((elem: any, i: any) => {
                                return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                              }) : item?.email ? <a href={'mailto:' + item.email}>{item.email}</a> : null}
                          </div>
                          <div>
                            <strong>Company: </strong>
                            {item?.company?.length &&
                              item?.company?.
                                map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a onClick={() => {
                                    setCompanyName(elem);
                                    setVisibleCompaniesModel(true)
                                  }} key={elem}>{elem}</a></> :
                                    <a onClick={() => {
                                      setCompanyName(elem);
                                      setVisibleCompaniesModel(true)
                                    }} key={elem}>{elem}</a>
                                })}
                          </div>
                          <div>
                            <strong>Website: </strong>
                            {item?.website?.length &&
                              item?.website?.
                                map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a target='blank' key={elem}
                                    href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                                })}
                          </div>
                          <div>
                            <strong>Address: </strong>
                            {item.address}
                          </div>
                          <div>
                            <strong>Phone: </strong>
                            {item?.phoneNumber?.length &&
                              item?.phoneNumber?.map((elem: any, i: any) => {
                                return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                              })}
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          <CompanyInfoPopover
            visible={visibleCompaniesModel}
            setVisible={setVisibleCompaniesModel}
            companyName={companyName}
            setCompanyName={setCompanyName}
          />

          <ContactInfoPopover
            visible={visibleContactsModel}
            setVisible={setVisibleContactsModel}
            data={contactInfo}
          />
        </InfiniteScroll>}
    </List>
  );
};

export default Item;
