import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BOOKMARKROUTES,
  CHILDPROJECTROUTES,
  PARENTPROJECTROUTES,
  PROJECTROUTES,
} from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import {
  CreateProjectBookmarkArgs,
  ProjectsBookmarkInterface,
} from 'types/bookmark';
import { Pagination } from 'types/parcels';
import { projectApi } from './project.api';
import { parcelApi } from './parcel.api';

export const parentProjectApi = createApi({
  baseQuery,
  reducerPath: 'parentProjectApi',
  tagTypes: ['ProjectsBookmark', 'AllParentProjects', 'AllParentProjectsMatching', 'ParentProjectsData'],
  endpoints: (builder) => ({
    /**
     * Retrieves child projects associated with a parent project specified by ID.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.base, appending
     * @param {any} id - The ID of the parent project for which child projects are requested.
     * @param {any} page - The page number of child projects to retrieve.
     * @param {any} take - The number of child projects per page.
     * @returns {Promise<any>} - A Promise resolving to the child projects data.
     */    
    getChildProjects: builder.query<any, { id: any; page: any; take: any }>({
      query: ({ id, page, take }) => ({
        url: `${PARENTPROJECTROUTES.base}/${id}/children?page=${page}&take=${take}`,
      }),
      providesTags: ['ProjectsBookmark'],
    }),
    /**
     * Retrieves geographical data for a parent project based on the provided search criteria.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.geoData, using
     * @param {string} search - The search query string containing parameters for fetching geo data.
     * @returns {Promise<any>} - A Promise resolving to the geographical data.
     */    
    getParentProjectGeoData: builder.query<any, string>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.geoData}${search}`,
      }),
      keepUnusedDataFor: 1,
    }),
    /**
     * Retrieves all parent projects based on the provided search criteria.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.base, using
     * @param {string} search - The search query string containing parameters for filtering parent projects.
     * @returns {Promise<any>} - A Promise resolving to the parent projects data.
     */    
    getAllParentProjects: builder.query<any, string>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.base}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        return response.data.map((data: any) => {
          data.dataArgs = args;
          return data;
        });
      },
      providesTags: ['AllParentProjects'],
    }),
    /**
     * Retrieves the count of parent projects based on the provided search criteria.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.count, using
     * @param {string} search - The search query string containing parameters for counting parent projects.
     * @returns {Promise<Pagination>} - A Promise resolving to the pagination data.
     */    
    getParentProjectsCount: builder.query<Pagination, string>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.count}${search}`,
      }),
    }),
    /**
     * Retrieves the center point information of a parent project based on the provided search criteria.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.centerPoint, using
     * @param {string} search - The search query string containing parameters for fetching center point data.
     * @returns {Promise<any>} - A Promise resolving to the center point data.
     */    
    getParentProjectCenterPoint: builder.query<any, string>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.centerPoint}${search}`,
      }),
    }),
    /**
     * Retrieves detailed information of a parent project based on the provided ID.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.info, appending
     * @param {any} id - The ID of the parent project for which detailed information is requested.
     * @param {boolean} isChild - Optional flag indicating whether the project is a child project.
     * @returns {Promise<any>} - A Promise resolving to the detailed project information.
     */    
    getParentProjectInfo: builder.query<any, any>({
      query: ({ id, isChild = false }) => ({
        url: `${PARENTPROJECTROUTES.info}/${id}?isChild=${isChild}`,
      }),
      providesTags: ['ProjectsBookmark'],
    }),
    /**
     * Retrieves all data (including detailed information) of a parent project based on the provided ID.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.allData, appending
     * @param {any} id - The ID of the parent project for which all data is requested.
     * @param {boolean} isChild - Optional flag indicating whether the project is a child project.
     * @returns {Promise<any>} - A Promise resolving to all data of the parent project.
     */    
    getParentProjectInfoAllData: builder.query<any, { id: any; isChild: any }>({
      query: ({ id, isChild = false }) => ({
        url: `${PARENTPROJECTROUTES.allData}/${id}?isChild=${isChild}`,
      }),
      providesTags: ['ProjectsBookmark'],
    }),
    getAllSingleParentProjectMatchedData: builder.query<
      any,
      { id: string; matchingType?: any; parentName?: any; isChild?: any }
    >({
      query: ({ id, matchingType = null, isChild = false }) => ({
        url: `${PARENTPROJECTROUTES.allMatchesCount}/${id}?matchingType=${matchingType}&isChild=${isChild}`,
      }),
    }),
    /**
     * Retrieves matching data for all parent projects based on the provided search criteria.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.allMatchingData,
     * using the provided search parameters as part of the URL. Transforms the response data
     * @param {any} search - The search query string containing parameters for filtering matching data.
     * @returns {Promise<any>} - A Promise resolving to the matching data of all parent projects.
     */    
    getAllParentProjectMatchingData: builder.query<any, any>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.allMatchingData}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        response.data = response.data.map((data: any) => {
          data.dataArgs = args;
          return data;
        });
        return response;
      },
      providesTags: ['AllParentProjectsMatching']
    }),
    /**
     * Retrieves matching data between all parent projects and their child projects based on the provided search criteria.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.allMatchingData,
     * using the provided search parameters as part of the URL. Transforms the response data
     * to include the query parameters used.
     * 
     * @param {any} search - The search query string containing parameters for filtering matching data.
     * @returns {Promise<any>} - A Promise resolving to the matching data between parent and child projects.
     */    
    getAllParentProjectToChildProjectsMatchingData: builder.query<any, any>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.allMatchingData}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        response.data = response.data.map((data: any) => {
          data.dataArgs = args;
          return data;
        });
        return response;
      },
      providesTags: ['AllParentProjectsMatching']
    }),
    getAllParentProjectToCompanyMatchingData: builder.query<any, any>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.companyMatchedData}${search}`,
      }),
    }),
    /**
     * Retrieves matching queries for a parent project based on the provided parameters.
     * 
     * Fetches data from an API endpoint specified by PARENTPROJECTROUTES.matchingQueries,
     * using the provided serial ID, matching type, and optional flags (isChild and viewQuery)
     * as query parameters in the URL.
     */     
    getParentProjectQueries: builder.query<any, any>({
      query: ({
        serialId,
        matchingType,
        isChild = false,
        viewQuery = false,
      }) => ({
        url: `${PARENTPROJECTROUTES.matchingQueries}/${serialId}?matchingType=${matchingType}&isChild=${isChild}&viewQuery=${viewQuery}`,
        method: 'GET',
      }),
    }),
    getParentProjects: builder.query<any, { id: any, page:any, take:any }>({
      query: ({ id, page, take }) => ({
        url: `${CHILDPROJECTROUTES.base}/${id}/parent?page=${page}&take=${take}`,
      }),
      providesTags: ['ParentProjectsData']
    }),
    /**
     * Deletes a bookmark record associated with a project by its ID.
     * 
     * Sends a DELETE request to the API endpoint specified by BOOKMARKROUTES.projects,
     * appending the project ID to the URL. Handles state updates and cache invalidation
     * based on the context of the bookmark removal, including updating related queries
     * and notifying components about data changes.
     * 
     * @param {any} id - The ID of the project bookmark to delete.
     */    
    deleteProjectBookmarkByProjectId: builder.mutation<unknown, any>({
      query: ({ id }) => ({
        url: `${BOOKMARKROUTES.projects}/project/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, dataArgs, type, from }, { queryFulfilled, dispatch, getState }) {
        try {
          const { data: removedBookmarkRecord } = await queryFulfilled;
          if(from === 'moreInfoPopup') {
            dispatch(parcelApi.util.invalidateTags(["ParcelsBookmark"]))
            if(type !== 'child') {
              for (const { endpointName, originalArgs } of parentProjectApi.util.selectInvalidatedBy(getState(), [{ type: "AllParentProjects" }, { type: "ParentProjectsData" }])) { 
                // we only want to update `getPosts` here
                if ( endpointName === 'getAllParentProjects') {
                  dispatch(
                    parentProjectApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
                       // find in list & update
                       const dataIndex = draft?.findIndex((x: any) => x.id === id)
                        if(dataIndex >= 0){
                          draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: false }
                        }
                    })
                  )
                } else if ( endpointName === 'getParentProjects') {
                  dispatch(
                    parentProjectApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
                       // find in list & update
                       console.log('getParentProjects draft', JSON.stringify(draft))
                       const dataIndex = draft?.findIndex((x: any) => x.id === id)
                       console.log('dataIndex', dataIndex)
                        if(dataIndex >= 0){
                          draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: false }
                        }
                    })
                  )
                };
              }
            }
          }
          console.log('removedBookmarkRecord =>>', removedBookmarkRecord);
          if(!dataArgs) {
            return;
          }
          if(type === 'child'){
            dispatch(
              projectApi.util.updateQueryData('getAllProjects', dataArgs, (draft) => {
                console.log('1 draft =>', JSON.stringify(draft))
                const dataIndex = draft?.data?.findIndex((x: any) => x.id === id)
                if(dataIndex >= 0){
                  draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: false }
                }
              })
            )
            dispatch(
              parcelApi.util.updateQueryData('getMatchingData', dataArgs, (draft) => {
                const dataIndex = draft?.data?.findIndex((x: any) => x.id === id)
                if(dataIndex >= 0){
                  draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: false }
                }
              })
            )
          } else {
            dispatch(
              parentProjectApi.util.updateQueryData('getAllParentProjectMatchingData', dataArgs, (draft) => {
                if(draft.data){
                  const dataIndex = draft.data?.findIndex((x: any) => x.id === id)
                  if(dataIndex >= 0){
                    draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: false }
                  }
                }
              })
            )
            dispatch(
              parentProjectApi.util.updateQueryData('getAllParentProjects', dataArgs, (draft) => {
                // console.log('1 draft =>', JSON.stringify(draft))
                const dataIndex = draft?.findIndex((x: any) => x.id === id)
                // console.log('dataIndex =>>', dataIndex)
                if(dataIndex >= 0){
                  draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: false }
                }
              })
            )
            dispatch(
              parcelApi.util.updateQueryData('getCompanyMatchingData', dataArgs, (draft) => {
                if(draft.data){
                  const dataIndex = draft.data?.findIndex((x: any) => x.id === id)
                  if(dataIndex >= 0){
                    draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: false }
                  }
                }
              })
            )
          }
        } catch (error) {
          console.log('create bookmark api error', error)
        }
      },
      invalidatesTags: ['ProjectsBookmark', 'AllParentProjectsMatching'],
    }),
    /**
     * Deletes a project bookmark by its ID from the bookmark drawer.
     * 
     * Sends a DELETE request to the API endpoint specified by BOOKMARKROUTES.projects,
     * appending the project ID to the URL. Handles cache invalidations and updates related
     * to parcels and project data. Notifies components about data changes and updates
     * the state accordingly.
     * 
     * @param {any} id - The ID of the project bookmark to delete.
     */    
    deleteProjectBookmarkByProjectIdFromBookmarkDrawer: builder.mutation<unknown, any>({
      query: ({ id }) => ({
        url: `${BOOKMARKROUTES.projects}/project/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, dataArgs }, { queryFulfilled, dispatch }) {
        try {
          const { data: removedBookmarkRecord } = await queryFulfilled;
          dispatch(parcelApi.util.invalidateTags(["ParcelsBookmark"]))
          dispatch(projectApi.util.invalidateTags(["ParentProjectsData"]))
          dispatch(projectApi.util.invalidateTags(["GetAllProjects"]))
          console.log('removedBookmarkRecord', removedBookmarkRecord)
          if(dataArgs){
            dispatch(
              parentProjectApi.util.updateQueryData('getAllParentProjects', dataArgs, (draft) => {
                // console.log('1 draft =>', JSON.stringify(draft))
                const dataIndex = draft?.findIndex((x: any) => x.id === id)
                // console.log('dataIndex =>>', dataIndex)
                if(dataIndex >= 0){
                  draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: false }
                }
              })
            )
          }
        } catch (error) {
          console.log('create bookmark api error', error)
        }
      },
      invalidatesTags: ['AllParentProjects', 'ProjectsBookmark', 'AllParentProjectsMatching'],
    }),
    createProjectBookmark: builder.mutation<
      ProjectsBookmarkInterface,
      CreateProjectBookmarkArgs
    >({
      query: (body) => ({
        url: BOOKMARKROUTES.projects,
        method: 'POST',
        body,
      }),
      /**
       * Handles operations when a project bookmark creation query starts.
       * 
       * Executes actions based on the outcome of the bookmark creation query,
       * including cache invalidations and updates for parcels, parent projects,
       * and related data based on different contexts (`type` and `from`).
       */       
      async onQueryStarted({ dataArgs, project_id, type, from }, { queryFulfilled, dispatch, getState }) {
        try {
          const { data: createdProject } = await queryFulfilled;
          console.log('type =>>', type)
          console.log('from =>>', from)
          if(from === 'moreInfoPopup') {
            dispatch(parcelApi.util.invalidateTags(["ParcelsBookmark"]))
            if(type !== 'child') {
              for (const { endpointName, originalArgs } of parentProjectApi.util.selectInvalidatedBy(getState(), [{ type: "AllParentProjects" }, { type: "ParentProjectsData" }])) { 
                // we only want to update `getPosts` here
                if ( endpointName === 'getAllParentProjects') {
                  dispatch(
                    parentProjectApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
                       // find in list & update
                       console.log('getAllParentProjects draft =>>', JSON.stringify(draft))
                       const dataIndex = draft?.findIndex((x: any) => x.id === project_id)
                        if(dataIndex >= 0){
                          draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: true }
                        }
                    })
                  )
                };
                if ( endpointName === 'getParentProjects') {
                  dispatch(
                    parentProjectApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
                       // find in list & update
                       console.log('getParentProjects draft', JSON.stringify(draft))
                       const dataIndex = draft?.findIndex((x: any) => x.id === project_id)
                       console.log('dataIndex', dataIndex)
                        if(dataIndex >= 0){
                          draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: true }
                        }
                    })
                  )
                };
              }
            }
          }
          console.log('createdProject =>>', createdProject);
          if(!dataArgs) {
            return;
          }
          if(type === 'child'){
            dispatch(
              projectApi.util.updateQueryData('getAllProjects', dataArgs, (draft) => {
                console.log('1 draft =>', JSON.stringify(draft))
                const dataIndex = draft?.data?.findIndex((x: any) => x.id === project_id)
                if(dataIndex >= 0){
                  draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: true }
                }
              })
            )
            dispatch(
              parcelApi.util.updateQueryData('getMatchingData', dataArgs, (draft) => {
                const dataIndex = draft?.data?.findIndex((x: any) => x.id === project_id)
                if(dataIndex >= 0){
                  draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: true }
                }
              })
            )
          } else {
            dispatch(
              parentProjectApi.util.updateQueryData('getAllParentProjectMatchingData', dataArgs, (draft) => {
                if(draft.data){
                  const dataIndex = draft.data?.findIndex((x: any) => x.id === project_id)
                  if(dataIndex >= 0){
                    draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: true }
                  }
                }
              })
            )
            dispatch(
              parentProjectApi.util.updateQueryData('getAllParentProjects', dataArgs, (draft) => {
                const dataIndex = draft?.findIndex((x: any) => x.id === project_id)
                if(dataIndex >= 0){
                  draft[dataIndex] = { ...draft[dataIndex], existsInBookmarks: true }
                }
              })
            )
            dispatch(
              parcelApi.util.updateQueryData('getCompanyMatchingData', dataArgs, (draft) => {
                if(draft.data){
                  const dataIndex = draft.data?.findIndex((x: any) => x.id === project_id)
                  if(dataIndex >= 0){
                    draft.data[dataIndex] = { ...draft.data[dataIndex], existsInBookmarks: true }
                  }
                }
              })
            )
          }
        } catch (error) {
          console.log('create bookmark api error', error)
        }
      },
      invalidatesTags: ['ProjectsBookmark', 'AllParentProjectsMatching'],
    }),
    /**
     * Retrieves all project bookmarks.
     * 
     * Fetches project bookmarks from the API endpoint specified by BOOKMARKROUTES.projects,
     * appending any search parameters to the URL. Transforms the response data to include
     * additional arguments (`args`) and provides cache tags for bookmarks related to projects.
     * 
     * @param {any} search - Search parameters to append to the URL query.
     */    
    getAllProjectBookmarks: builder.query<any, any>({
      query: (search) => ({
        url: `${BOOKMARKROUTES.projects}${search}`,
      }),
      transformResponse: (response: any, meta, args) => {
        response.data = response.data.map((data: any) => {
          data.dataArgs = args
          return data
        })
        return response
      },
      providesTags: ['ProjectsBookmark'],
    }),
    /**
     * Retrieves all project bookmarks with coordinates.
     * 
     * Fetches project bookmarks along with their coordinates from the API endpoint specified
     * by BOOKMARKROUTES.projectsBookmarks, using pagination parameters (page, limit) and
     * optionally filtering by whether the project is a child project.
     * 
     * @param {object} params - Parameters object containing page, limit, and isChild.
     * @param {number} params.page - Page number for pagination.
     * @param {number} params.limit - Number of items to fetch per page.
     * @param {boolean} params.isChild - Flag indicating if the project is a child project.
     */    
    getAllProjectBookmarksCoordinates: builder.query<any, any>({
      query: ({ page, limit, isChild }) => ({
        url: `${BOOKMARKROUTES.projectsBookmarks}?page=${page}&take=${limit}&isChild=${isChild}`,
      }),
    }),
    /**
     * Retrieves distinct parent project names.
     * 
     * Fetches distinct parent project names from the API endpoint specified by PARENTPROJECTROUTES.names,
     * filtered by the provided searchValue.
     * 
     * @param {object} params - Parameters object containing searchValue.
     * @param {string} params.searchValue - The value to search for among parent project names.
     */    
    getDistinctParentProjectNames: builder.query<any, any>({
      query: ({ searchValue }) => ({
        url: `${PARENTPROJECTROUTES.names}?name=${searchValue}`,
        method: 'GET',
      }),
    }),
    /**
     * Retrieves distinct child project names.
     * 
     * Fetches distinct child project names from the API endpoint specified by PROJECTROUTES.childNames,
     * filtered by the provided searchValue.
     * 
     * @param {object} params - Parameters object containing searchValue.
     * @param {string} params.searchValue - The value to search for among child project names.
     */    
    getDistinctChildProjectNames: builder.query<any, any>({
      query: ({ searchValue }) => ({
        url: `${PROJECTROUTES.childNames}?name=${searchValue}`,
        method: 'GET',
      }),
    }),
    /**
     * Retrieves parent projects based on a query string.
     * 
     * Fetches parent projects from the API endpoint specified by PARENTPROJECTROUTES.getQuery,
     * using the provided search parameters.
     * 
     * @param {any} search - Search parameters to append to the URL query.
     */    
    getParentProjectsQuery: builder.query<any, any>({
      query: (search) => ({
        url: `${PARENTPROJECTROUTES.getQuery}${search}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetChildProjectsQuery,
  useGetParentProjectGeoDataQuery,
  useGetAllParentProjectsQuery,
  useGetParentProjectsCountQuery,
  useGetParentProjectCenterPointQuery,
  useGetParentProjectInfoQuery,
  useGetParentProjectInfoAllDataQuery,
  useGetAllSingleParentProjectMatchedDataQuery,
  useGetAllParentProjectMatchingDataQuery,
  useGetAllParentProjectToChildProjectsMatchingDataQuery,
  useGetAllParentProjectToCompanyMatchingDataQuery,
  useGetParentProjectQueriesQuery,
  useDeleteProjectBookmarkByProjectIdMutation,
  useDeleteProjectBookmarkByProjectIdFromBookmarkDrawerMutation,
  useCreateProjectBookmarkMutation,
  useGetAllProjectBookmarksQuery,
  useGetAllProjectBookmarksCoordinatesQuery,
  useGetDistinctParentProjectNamesQuery,
  useGetDistinctChildProjectNamesQuery,
  useGetParentProjectsQueryQuery,
  useGetParentProjectsQuery,
} = parentProjectApi;
