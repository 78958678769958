import React from 'react';
import { Drawer, Descriptions, Divider, Tag, Button } from 'antd';

// Define the props interface for the UserProfile component
interface UserProfileProps {
  onClose: any;
  open: any;
  selectedUser: any;
  onEditClick: any;
  onDeleteClick: any;
}

// UserProfile component definition
const UserProfile = ({ open, onClose, selectedUser, onEditClick, onDeleteClick }: UserProfileProps) => {

  return (
    // Drawer component to show user profile details
    <Drawer 
      width={500} 
      placement="right" 
      closable={false} 
      onClose={onClose} 
      open={open} 
      className='user-drawer'
    >
      {/* Descriptions component to show basic user information */}
      <Descriptions title="User Profile" column={2}>
        <Descriptions.Item label="Firstname">{selectedUser?.firstname}</Descriptions.Item>
        <Descriptions.Item label="Lastname">{selectedUser?.lastname}</Descriptions.Item>
        <Descriptions.Item label="Email">{selectedUser?.email}</Descriptions.Item>
      </Descriptions>
      
      <Divider />

      {/* Descriptions component to show company-related user information */}
      <Descriptions title="Company Profile" column={1}>
        <Descriptions.Item label="Role">
          {selectedUser?.role === 'admin' ? (
            <Tag color='#99c0ce'>{selectedUser?.role.charAt(0).toUpperCase() + selectedUser?.role.slice(1)}</Tag>
          ) : selectedUser?.role === 'user' ? (
            <Tag color="#87d068">{selectedUser?.role.charAt(0).toUpperCase() + selectedUser?.role.slice(1)}</Tag>
          ) : (
            <Tag color="#108ee9">{selectedUser?.role.charAt(0).toUpperCase() + selectedUser?.role.slice(1)}</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Last Login">{selectedUser?.userLastLogin}</Descriptions.Item>
      </Descriptions>
      
      <Divider />

      {/* Descriptions component to show actions for editing or deleting user */}
      <Descriptions title="Actions" column={6}>
        <Descriptions.Item>
          <Button size="middle" onClick={onEditClick}>Edit</Button>{' '}
        </Descriptions.Item>
        <Descriptions.Item>
          <Button danger size="middle" onClick={onDeleteClick}>Delete</Button>
        </Descriptions.Item>
      </Descriptions>
      
      <Divider />

    </Drawer>
  );
};

export default UserProfile;
