// Settings for the map goes inside this file

// import mapStyles from './mapStyles';

export const containerStyle = {
  width: '100%',
  height: '100vh',
};

// Disable default UI
export const NaxxaMapOptions: google.maps.MapOptions = {
  // styles: mapStyles,
  // disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
  clickableIcons: false,
  mapTypeControlOptions: {
    position: 2.0,
    style: 1.0,
  },
  streetViewControl: true,
};

export const libraries: (
  | 'drawing'
  | 'geometry'
  | 'places'
  | 'visualization'
)[] = ['places', 'drawing'];
