import { Color } from 'components';
import { ParcelCard } from './ParcelCard';
import { ParentProjectCard } from './ParentProjectCard';
import { ChildProjectCard } from './ChildProjectCard';

// import { Counties } from './CountyInfo';

export interface MetaDataProps {
  title: string;
  titleColor: Color;
  data: { name: any; value: any | undefined }[];
  showMore?: boolean;
  allData?: any;
  information?: any;
  closeViewAll?: any;
  id?: string | any;
  parcelId?: string;
  formattedParcelId?: string;
  from?: string;
  showBookmark?: boolean;
  idSerial?: any;
  id_serial?: any;
  showParcelsLink?: any;
  item?: any;
  selectedChildProjectId?: any;
  showChildProjectsLink?: any;
  onClickResultCard?: () => void
  setCurrentPage?: (value: any) => any
  setProjectId?: (value: any) => any
  refetchParcels?: () => void
  closeDrawer?: () => void
  onParenChildBadgeClick?: (item: any, type: any) => void
  onBookMarkIconClick?: (item: any, type: any) => void
}

export const MetaData = ({
  title,
  titleColor,
  data,
  allData,
  id_serial,
  formattedParcelId,
  onClickResultCard,
  setCurrentPage,
  closeViewAll,
  parcelId,
  from,
  onBookMarkIconClick,
  id
}: MetaDataProps) => {
  /**
   * Conditional renderer for displaying different types of cards based on 'title' and 'from' props.
   * Renders ParcelCard, ParentProjectCard, or ChildProjectCard components based on conditions.
   */
  return (
    <>
      { title === 'Parcel' && ['viewAll', 'matching', 'bookmark-drawer'].includes(from || '') ? 
        <ParcelCard 
          title={title}
          allData={allData}
          titleColor={titleColor}
          data={data}
          from={from}
          closeViewAll={closeViewAll}
          id={id}
          parcelId={parcelId}
          id_serial={id_serial}
          formattedParcelId={formattedParcelId}
          onClickResultCard={onClickResultCard}
          setCurrentPage={setCurrentPage}
          onBookMarkIconClick={onBookMarkIconClick}
        /> 
        :
        ((title === 'Projects' || title === 'Project' || from !== 'child-project') && ['viewAll', 'matching', 'bookmark-drawer', 'parent-project'].includes(from || '')) ?
        <ParentProjectCard
          title={title}
          allData={allData}
          titleColor={titleColor}
          data={data}
          from={from}
          closeViewAll={closeViewAll}
          id={id}
          parcelId={parcelId}
          id_serial={id_serial}
          formattedParcelId={formattedParcelId}
          onClickResultCard={onClickResultCard}
          setCurrentPage={setCurrentPage}
          onBookMarkIconClick={onBookMarkIconClick} 
        />
        :
        ((title === 'Projects' || title === 'Project') && from === 'child-project') &&
          <ChildProjectCard 
            title={title}
            allData={allData}
            titleColor={titleColor}
            data={data}
            from={from}
            id={id}
            parcelId={parcelId}
            id_serial={id_serial}
            onClickResultCard={onClickResultCard}
            setCurrentPage={setCurrentPage}
            onBookMarkIconClick={onBookMarkIconClick} 
          />
      }
    </>
  );
};
