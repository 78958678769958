/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Spin, Button, Form, Input, notification } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import {
  useGetCurrentUserQuery,
  useChnageProfileInfoMutation,
} from 'app/services';

const UpdateProfile = (props: any) => {
  const [form] = Form.useForm();

  const { data: userInfo, isFetching } = useGetCurrentUserQuery('');
  const [updateProfile, { data: isChanged, isSuccess, isError, isLoading }] =
    useChnageProfileInfoMutation();

  useEffect(() => {
    form.setFieldsValue({
      firstname: userInfo?.firstname,
      lastname: userInfo?.lastname,
      email: userInfo?.email,
    });
    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    if (isChanged && isSuccess) {
      notification.success({
        placement: 'topRight',
        message: 'Update Successful',
        description:'You have successfully updated your profile.',
      });
      props.closeDrawer()
    }
    if (!isChanged && isError) {
      notification.error({
        placement: 'topRight',
        message:'Oops!',
        description:'Something went wrong while changing your profile. Please try again later.',
      });
    }
  }, [isError, isChanged, isSuccess]);

  const onFinish = async (values: any) => {
    await updateProfile(values).unwrap();
  };

  return (
    <Spin
      spinning={isFetching || isLoading}
      indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />}
    >
      <div>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Update Profile
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
export default UpdateProfile;
