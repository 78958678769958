import { Spin, Button, Empty, Badge } from 'antd';
import { setProjectIdValue, useGetAllSingleParentProjectMatchedDataQuery } from 'app/services'
import { ChildProjectCard } from 'components/MetaData/ChildProjectCard';
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

interface ChildProjectPopoverProps {
  information?: any;
  allData?: any;
  onViewAllClick: () => void;
}

export const ChildProjectPopover = ({
  information,
  allData,
  onViewAllClick
}: ChildProjectPopoverProps) => {
  const dispatch = useDispatch()

  // Project matches
  const [projectsMatchingData, setProjectsMatchingData] = useState<any>()
  const [projectsMatchesLoading, setProjectsMatchesLoading] = useState(true)

  console.log('information', information)
  const { data: projectToParcelMatches, isSuccess: isProjectToParcelSuccess,
    isFetching: isFetchingProjectToParcel,
  } =
  /**
   * Fetches data related to single parent project matched data for parcels,
   * based on the provided ID and matching type.
   * Conditionally skips fetching if ID is not available.
   */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'parcels',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToBuilderMatches, isSuccess: isProjectToBuilderSuccess,
    isFetching: isFetchingProjectToBuilder,
  } =
  /**
   * Fetches data related to single parent project matched data for builders,
   * based on the provided ID and matching type.
   * Conditionally skips fetching if ID is not available.
   */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'builders',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToInvestorMatches, isSuccess: isProjectToInvestorSuccess,
    isFetching: isFetchingProjectToInvestor,
  } =
    /**
     * Fetches data related to single parent project matched data for investors,
     * based on the provided ID and matching type.
     * Conditionally skips fetching if ID is not available.
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'investors',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToDeveloperMatches,
    isSuccess: isProjectToDeveloperSuccess,
    isFetching: isFetchingProjectToDeveloper,
  } =
    /**
     * Fetches data related to single parent project matched data for developers,
     * based on the provided ID and matching type.
     * Conditionally skips fetching if ID is not available.
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'developers',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToRealEstateAgentMatches,
    isSuccess: isProjectToRealEstateAgentSuccess,
    isFetching: isFetchingProjectToRealEstateAgent,
  } =
    /**
     * Fetches data related to single parent project matched data for real estate agents,
     * based on the provided ID and matching type.
     * Conditionally skips fetching if ID is not available.
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'realEstateAgents',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToNewBuildHomebuyerMatches,
    isSuccess: isProjectToNewBuildHomebuyerSuccess,
    isFetching: isFetchingProjectToNewBuildHomebuyer,
  } =
    /**
     * Fetches data related to single parent project matched data for new build homebuyers,
     * based on the provided ID and matching type.
     * Conditionally skips fetching if ID is not available.
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'newBuildHomebuyers',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToWaterRightsMatches,
    isSuccess: isProjectToWaterRightsSuccess,
    isFetching: isFetchingProjectToWaterRights,
  } =
    /**
     * Fetches data related to single parent project matched data for water rights,
     * based on the provided ID and matching type.
     * Conditionally skips fetching if ID is not available.
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'waterRights',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );

  const { data: projectToProjectMatches,
    isSuccess: isProjectToProjectSuccess,
    isFetching: isFetchingProjectToProject,
  } =
    /**
     * Fetches data related to single parent project matched data for projects,
     * based on the provided ID and matching type.
     * Conditionally skips fetching if ID is not available.
     */
    useGetAllSingleParentProjectMatchedDataQuery(
      {
        id: information?.id || '',
        matchingType: 'projects',
        isChild: true
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id
      },
    );
  /**
   * Updates the projects matching data based on fetching statuses of various types,
   * and manages loading state accordingly. Deletes matching data if fetching is complete
   * for each type. Runs whenever dependencies related to fetching statuses or `information`
   * change.
   */
  useEffect(() => {
    if(isFetchingProjectToParcel || isFetchingProjectToBuilder ||
      isFetchingProjectToInvestor || isFetchingProjectToDeveloper ||
      isFetchingProjectToRealEstateAgent || isFetchingProjectToNewBuildHomebuyer ||
      isFetchingProjectToWaterRights || isFetchingProjectToProject) {
        setProjectsMatchesLoading(true)
      } else {
        setProjectsMatchesLoading(false)
      }
    const data = { ...projectsMatchingData }
    if (!isFetchingProjectToParcel && projectToParcelMatches?.Parcels) {
      data.Parcels = projectToParcelMatches?.Parcels
    } else {
      delete data.Parcels;
    }
    if (!isFetchingProjectToBuilder && projectToBuilderMatches?.Builder) {
      data.Builder = projectToBuilderMatches?.Builder
    } else {
      delete data.Builder;
    }
    if (!isFetchingProjectToInvestor && projectToInvestorMatches?.Investors) {
      data.Investors = projectToInvestorMatches?.Investors
    } else {
      delete data.Investors;
    }
    if (!isFetchingProjectToDeveloper &&
      projectToDeveloperMatches?.Developer) {
      data.Developer = projectToDeveloperMatches?.Developer
    } else {
      delete data.Developer;
    }
    if (!isFetchingProjectToRealEstateAgent &&
      projectToRealEstateAgentMatches?.['Real Estate Agent']) {
      data['Real Estate Agent'] = projectToRealEstateAgentMatches?.['Real Estate Agent']
    } else {
      delete data['Real Estate Agent']
    }
    if (!isFetchingProjectToNewBuildHomebuyer &&
      projectToNewBuildHomebuyerMatches?.['New Build Homebuyer']) {
      data['New Build Homebuyer'] = projectToNewBuildHomebuyerMatches?.['New Build Homebuyer']
    } else {
      delete data['New Build Homebuyer']
    }
    if (!isFetchingProjectToWaterRights &&
      projectToWaterRightsMatches?.['Water Rights']) {
      data['Water Rights'] = projectToWaterRightsMatches?.['Water Rights']
    } else {
      delete data['Water Rights']
    }
    if (!isFetchingProjectToProject &&
      projectToProjectMatches?.Projects) {
      data.Projects = projectToProjectMatches?.Projects
    } else {
      delete data.Projects;
    }
    setProjectsMatchingData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingProjectToParcel, isFetchingProjectToBuilder,
    isFetchingProjectToInvestor, isFetchingProjectToDeveloper,
    isFetchingProjectToRealEstateAgent, isFetchingProjectToNewBuildHomebuyer,
    isFetchingProjectToWaterRights, isFetchingProjectToProject, information
  ])
  /**
   * Sets `projectsMatchesLoading` to `false` when all project matching operations
   * have successfully completed. Monitors success flags for each type of project
   * match operation to determine completion.
   */
  useEffect(() => {
    if (!isFetchingProjectToParcel && isProjectToParcelSuccess
      && !isFetchingProjectToBuilder && isProjectToBuilderSuccess
      && !isFetchingProjectToInvestor && isProjectToInvestorSuccess
      && !isFetchingProjectToDeveloper && isProjectToDeveloperSuccess
      && !isFetchingProjectToRealEstateAgent &&
      isProjectToRealEstateAgentSuccess
      && !isFetchingProjectToNewBuildHomebuyer &&
      isProjectToNewBuildHomebuyerSuccess
      && !isFetchingProjectToWaterRights &&
      isProjectToWaterRightsSuccess
      && !isFetchingProjectToProject &&
      isProjectToProjectSuccess
    ) {
      setProjectsMatchesLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectToParcelSuccess, isProjectToBuilderSuccess,
    isProjectToInvestorSuccess, isProjectToDeveloperSuccess,
    isProjectToRealEstateAgentSuccess, isProjectToNewBuildHomebuyerSuccess,
    isProjectToWaterRightsSuccess, isProjectToProjectSuccess
  ])
  // Project matches

  return (
    <div>
      <div className="matched-item-popover-main font-small">
        {information && (
          <ChildProjectCard
            setProjectId={ (projectIdVal) => {
              dispatch(setProjectIdValue(projectIdVal))
            }}
            title={information?.title}
            titleColor={information?.titleColor}
            data={information?.data}
            information={information}
            allData={allData}
            showMore={true}
            showParcelsLink={true}
            id_serial={information?.id_serial}
          />
        )}
      </div>
      
      {information?.title === 'Project' ?
        projectsMatchingData &&
          Object.keys(projectsMatchingData).length ? (
          <>
            <div className="matched-item-popover-list font-small">
              <ul>
                {Object.entries(projectsMatchingData).map(([key, value]) => (
                  <li key={key}>
                    <p>{key}</p>
                    {/* <p>{value}</p> */}
                    <Badge count={value} color='#1C7A92' overflowCount={100000} />
                  </li>
                ))}
              </ul>
            </div>
            {projectsMatchesLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
              <Spin
                spinning={projectsMatchesLoading}
                tip='Loading' size="large"
              ></Spin>
            </div> : null}
            <div className="matched-item-popover-btn font-normal">
              <Button onClick={onViewAllClick} type="primary" disabled={projectsMatchesLoading}>
                View Matches
              </Button>
            </div>
          </>
        ) : !projectsMatchesLoading ?
          <Empty
            className="matched-item-popover-no-results"
            description="No Matched Results"
          />
          : null : null}

      {( projectsMatchesLoading && (projectsMatchingData && !Object.keys(projectsMatchingData).length)) ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <Spin
          spinning={projectsMatchesLoading}
          tip='Loading' size="large"
        ></Spin>
      </div> : null}
    </div>
  )
}

export default ChildProjectPopover