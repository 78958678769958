import { Empty, Spin, Tabs, Typography } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { selectCurrentItem, selectSelectedProjectTypeValue, useGetProjectsParcelsQueryQuery } from "app/services";
import { useEffect } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { useSelector } from "react-redux";

const { Title } = Typography;


export const ViewShowParcelsQueryContent = (props:any) => {
  const { search } = useLocation();
  const selectedItem: any = useSelector(selectCurrentItem);
  const selectedProjectTypeValue = useSelector(selectSelectedProjectTypeValue)

  const {
    data: ParcelsQuery,
    isSuccess: isParcelsQuerySuccess,
    isFetching: isParcelsQueryFetching,
  } = useGetProjectsParcelsQueryQuery({
    projectId: selectedItem?.id,
    isChild: selectedProjectTypeValue === 'child' ? true : false,
    isProjectNameSearch: qs.parse(search)?.name || qs.parse(search)?.childName ? true : false
  }, {
    refetchOnReconnect: true
  });

  useEffect(() => {
    console.log('selectedItem', selectedItem)
    console.log('search', search)
    console.log('selectedProjectTypeValue', selectedProjectTypeValue)
  }, [search, selectedItem, selectedProjectTypeValue])


  function camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    const data = result.charAt(0).toUpperCase() + result.slice(1);
    return data.includes('CompaniesQuery')
      ? 'Companies'
      : data.includes('ContactsQuery')
        ? 'Contacts'
        : data;
  }

  const Tab = ({ property, count }: any) => (
    <div className="tab-title">
      <div className="name">
        <span>
          {camelCaseToWords(property)} ({count})
          {/* {camelCaseToWords(property)} */}
        </span>
        {/* {!count && <span>{camelCaseToWords(property)}</span>} */}
      </div>
    </div>
  );

  return (isParcelsQueryFetching) ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spin spinning={isParcelsQueryFetching} tip="Loading" size="large"></Spin>
    </div>
  ) : (
    <>
      <Tabs>
        <TabPane
          tab={<Tab property="Buildable Parcels" count={props.count.buildableParcels} />}
          key="Buildable Parcels"
        >
          {isParcelsQuerySuccess && ParcelsQuery?.buildableParcelsQuery ?
            <>
              <div style={{ marginTop: 10 }}>
                <Title level={5}>Query</Title>
                <CopyBlock
                  language="sql"
                  text={isParcelsQuerySuccess ? ParcelsQuery?.buildableParcelsQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
            </> : <div className="view-query-empty"><Empty
              className="matched-item-popover-no-results"
              description="No Query Available"
            />
            </div>
          }
        </TabPane>
        <TabPane
          tab={<Tab property="Unbuildable Parcels" count={props.count.alreadyBuildedParcels} />}
          key="Unbuildable Parcels"
        >
          {isParcelsQuerySuccess && ParcelsQuery?.alreadyBuildedParcelsQuery ?
            <>
              <div style={{ marginTop: 10 }}>
                <Title level={5}>Query</Title>
                <CopyBlock
                  language="sql"
                  text={isParcelsQuerySuccess ? ParcelsQuery?.alreadyBuildedParcelsQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
            </> : <div className="view-query-empty"><Empty
              className="matched-item-popover-no-results"
              description="No Query Available"
            />
            </div>
          }
        </TabPane>
      </Tabs>
    </>
  );
}
