export const PROJECTSTATUS = [
  'Concept',
  'Seeking Preliminary from Planning Commission',
  'Seeking Preliminary from City Council',
  'Prelim Rejected by City Council',
  'Seeking Final',
  'Final Rejected by City Council',
  'Abandoned',
  'Papered',
  'Rezone Accepted',
  'Coming Soon',
  'For Sale',
  'For Rent',
  'Sold Out',
  'Human Check Needed',
];

export const PROJECTLOTSIZE = [
  'Condos',
  'Townhomes',
  'Commercial',
  '.02-.08 Acres',
  '.08-.18 Acres',
  '< .02 Acres',
  'Acres (.7-1.2)',
  'Acres+ (1.2 +)',
  'Quarter Acres (.18-.28)',
  'Third Acres (.28-.38)',
  'Half Acres (.42-.7)',
  'Half Acres (.48-.7)',
  'High Third Acres (.38-.48)',
].sort();

export const SPECIALPRODUCTTYPE = [
  'Ranches',
  'Cabin',
  'Tiny Houses',
  'Resort',
  'Assisted Living',
  '55+ Communities',
];
