import { CloseCircleFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';

interface SelectedFilterOptionProps {
  text: string;
  label: string;
  onDeleteClick: () => void;
  visible: boolean;
  inputValue: string;
}

export const SelectedFilterOption = ({
  text,
  onDeleteClick,
  visible,
  label,
  inputValue
}: SelectedFilterOptionProps) => {
  const isLocationPayload = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?,.*$/.test(text);

  return (
  <Tag
    className="naxxa-selected-filter-option"
    closable
    visible={visible}
    onClose={onDeleteClick}
    closeIcon={<CloseCircleFilled />}
  >
    {isLocationPayload ? inputValue : text[0] === '[' ? 'Selected Polygon Area' : `${label} - ${text}`}
  </Tag>
);
}
