import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Drawer } from 'antd';
import React, { useState } from 'react';
import {
  AutocompleteSearchInput,
  AutocompleteSearchInputProps,
} from './AutocompleteSearchInput';
import { MatchedResultBox, MatchedResultBoxProps } from './MatchedResultBox';
import { RefineCriteriaBox, RefineCriteriaBoxProps } from './RefineCriteriaBox';

interface MainFilterDrawerProps
  extends AutocompleteSearchInputProps,
    RefineCriteriaBoxProps,
    MatchedResultBoxProps {
  isMainFiltersDrawerOpen: boolean;
  toggleMainFiltersDrawer: () => void;
  closeInfoWindowOpen: () => void;
  openBookmarksView?: () => void
  isSelectedResultDrawerOpen?: boolean;
}

export const MainFilterDrawer = ({
  isMainFiltersDrawerOpen,
  toggleMainFiltersDrawer,
  openBookmarksView,
  onPlaceChanged,
  onLoad,

  openAdvancedFiltersDrawer,

  openViewAll,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  isSelectedResultDrawerOpen
}: MainFilterDrawerProps) => {
  const [showAllSelectedFilters, setShowSelectedFilters] = useState('0')
  const onOpenSelectedFilters = () => {
    setShowSelectedFilters('1')
  }
  return (
    <div className="leftDrawer">
      {/* Left side Open drawer button */}
      {!isMainFiltersDrawerOpen && (
        <CaretRightOutlined
          className="open-drawer-btn"
          onClick={toggleMainFiltersDrawer}
        />
      )}
      <Drawer
        className={`main-drawer ${!isMainFiltersDrawerOpen && 'close-menu'}`}
        title="Main drawer"
        placement="left"
        onClose={toggleMainFiltersDrawer}
        visible={isMainFiltersDrawerOpen}
        closeIcon={<CloseOutlined />}
        push={false}
        mask={false}
        width={560}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        {isMainFiltersDrawerOpen ? (
          <CaretLeftOutlined
            className="close-drawer-btn"
            onClick={toggleMainFiltersDrawer}
          />
        ) : null}

        {/* Google autocomplete and Search bar */}
        <AutocompleteSearchInput
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
        />

        {/* Elements below the Search bar */}
        <RefineCriteriaBox
          showAllSelectedFilters={showAllSelectedFilters}
          openAdvancedFiltersDrawer={openAdvancedFiltersDrawer}
          closeInfoWindowOpen={closeInfoWindowOpen}
        />
        <MatchedResultBox
          openViewAll={openViewAll}
          openBookmarksView={openBookmarksView}
          handleOnViewAllClick={handleOnViewAllClick}
          isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
          closeInfoWindowOpen={closeInfoWindowOpen}
          onOpenSelectedFilters={onOpenSelectedFilters}
        />
      </Drawer>
    </div>
  );
};
