import { createApi } from '@reduxjs/toolkit/query/react';
import { COMPANYROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import {
  GetCompanyMatchedPropertiesResponse,
} from 'types/company';

/**
 * API slice for interacting with company-related endpoints.
 * This slice includes queries to retrieve company data by name and matched properties.
 */
export const companyApi = createApi({
  baseQuery,
  reducerPath: 'companyApi',
  endpoints: (builder) => ({
    getCompanyByName: builder.query<any, string>({
      query: (companiesString) => ({
        url: `${COMPANYROUTES.companyByName}?names=${companiesString}`,
      }),
    }),
    getCompanyMatchedProperties: builder.query<
      GetCompanyMatchedPropertiesResponse,
      string
    >({
      query: (id) => ({
        url: `${COMPANYROUTES.singleCompanyMatchedProperties}${id}`,
      }),
    }),
  }),
});

export const {
  useGetCompanyByNameQuery,
  useGetCompanyMatchedPropertiesQuery,
} = companyApi;
