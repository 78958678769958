export const filterLabelHelper: any = {
  cities: 'City',
  counties: 'County',
  condos: 'Number & Style of Lots',
  singleFamily: 'Number & Style of Lots',
  townhouse: 'Number & Style of Lots',
  land: 'Number & Style of Lots',
  commercial: 'Number & Style of Lots',
  acreage: 'Parcel Acreage',
  parcelStatus: 'Parcel Status',
  hypotheticals: 'Parcel Hypotheticals',
  projectStatus: 'Project Status',
  lotSize: 'Project Lot Size',
  postalCodes: 'Postal Codes',
  utahAreaTags: 'Areas',
  parcelId: 'Parcel ID',
  currentZoningCity: 'Parcels Current Zoning City',
  specialProductTypes: 'Projects Special Product Types',
  name: 'Project Name',
  childName: 'Child Project Name',
  houseStyle: 'House Style'
};
