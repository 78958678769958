import { Col, Row } from "antd"

export const PrivacyStatement = () => {

    return (
        <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <h1 style={{ fontWeight: 'bold', fontSize: 25, marginTop: '20px', marginBottom: '40px' }}>Privacy Statement</h1>
            </div>
            <Row style={{ fontSize: 18 }}>
                <Col lg={3}> </Col>
                <Col lg={18}>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Effective Date:
                        </span> January 2023</p>

                    <p>
                        When you use Naxxa Group services to find, buy, rent,
                        or sell your home, get a mortgage, or connect to a
                        real estate pro, we know you’re trusting us with
                        your data. We also know we have a responsibility
                        to respect your privacy, and we work hard to do
                        just that. This Privacy Notice explains what
                        personal data we collect, why we use it, who we
                        share it with, and how we protect it, along with
                        the tools you can use to manage your privacy. We
                        periodically revise this Notice to reflect new
                        and evolving laws that govern privacy. This
                        Privacy Notice is not a contract and does
                        not create any legal rights or obligations.
                    </p>
                    <p>
                        <span>Who We Are: </span>
                        Naxxa Group offers a wide range of offerings
                        that focus on all stages of your home journey:
                        searching, renting, buying, selling,
                        financing, and home improvement.
                    </p>
                    <p>

                        We know that everyone’s home journey
                        is different, and that deciding on the
                        right place to call home is an intimate,
                        personal experience. Along with a wealth
                        of information about homes, neighborhoods,
                        market conditions, local real estate
                        professionals, and financing options,
                        we also use the information you give
                        us to provide our services and help you
                        find your next home.
                    </p>
                    <p>
                        We carefully evaluate how we use data to
                        make sure that we’re using your information
                        to provide value for you. On this page, we
                        aim to explain everything you need to know
                        about your privacy at Naxxa. If you have
                        any questions that we haven’t answered
                        here, you can always contact us at privacy@Naxxa.com.

                    </p>
                    <p>
                        When we use the terms “Naxxa Group”, “we”,
                        “us”, or “our” in this Privacy Notice,
                        we are referring to Naxxa,
                        Inc. and its affiliated brands.

                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Our Offerings:
                        </span>
                        When we use the terms “offerings”
                        or “services,” we are referring to our
                        Internet websites, mobile applications,
                        and other services that link to this Privac
                        y Notice and that we offer and manage on
                        our own behalf, including:

                    </p>
                    <p>
                        <ul>
                            <li>
                                Our corporate websites that
                                link to this Privacy Notice;
                            </li>
                            <li>
                                Rental tools;
                            </li><li>

                                Connections (such as connecting
                                you to a lender or agent); and
                            </li><li>

                                Other offerings we may make
                                available through our sites and apps.
                            </li>

                        </ul>
                    </p>

                    <p>This Privacy Notice does not cover or address:
                    </p>
                    <ul>
                        <li>
                            Our business-facing brands,
                            such as dotloop, ShowingTime,
                            ShowingTime+, Mortech, and Bridge
                            Interactive, all of which have
                            their own Privacy Notices;

                        </li>
                        <li>
                            Naxxa Home Loans and Naxxa
                            Closing Services, each of which
                            has its own Privacy Notice;

                        </li>
                        <li>
                            Disclosures or rights we may provide
                            to you in relation to the United
                            States Fair Credit Reporting Act (FCRA) or
                        </li>
                        <li>
                            the United States Gramm-Leach-Bliley Act (GLBA);

                        </li>
                        <li>
                            Disclosures or rights we may provide
                            to you in relation to U.S.
                            state-specific financial privacy laws;

                        </li>
                        <li>
                            Offerings that we provide solely on
                            behalf of our business partners; and

                        </li>
                        <li>
                            Personal data and privacy practices
                            relating to job applicants, employees
                            and other Naxxa Group personnel.

                        </li>
                    </ul>
                    <p>
                        We separately provide notices required
                        under the GLBA, containing GLBA- and state-specific
                        financial privacy law disclosures in
                        connection with GLBA-covered services.
                        If you would like to obtain another copy of
                        a GLBA notice we previously provided to
                        you, please contact us at privacy@Naxxa.com.
                    </p>
                    <p>
                        For offerings provided on behalf of our
                        customers who are real estate professionals
                        (such as real estate agents, builders,
                        third-party lenders, and landlords),
                        please refer to the privacy notice of
                        the real estate professional with whom
                        you have a relationship for information
                        on how they engage us to process your
                        personal data.For individuals based outside
                        the United States and Canada, this Privacy
                        Notice applies solely to their browsing and
                        actions on our website(s) accessible
                        at: www.Naxxa.com; www.trulia.com;
                        www.hotpads.com; www.Naxxagroup.com;
                        www.streeteasy.com, and www.outeast.com.
                    </p>
                    <p style={{ fontWeight: 'bold' }}> What is Personal Data?
                    </p>
                    <p>
                        When we use the term “personal data”
                        in this Privacy Notice, we mean information
                        that identifies, relates to, describes, is
                        reasonably capable of being associated with,
                        or could reasonably be linked, directly or indirectly,
                        to an individual or household. It does not include
                        aggregated or de-identified information that
                        is maintained in a form that is not capable
                        of being associated with or reasonably
                        linked to an individual.

                    </p>

                    <p style={{ fontWeight: 'bold' }}> Our Collection and Use of Personal Data</p>
                    <p>
                        The personal data we collect,
                        the way we collect it, and how we
                        use it will depend on how you are
                        interacting with us and the type of
                        services you use.

                    </p>
                    <p style={{ fontWeight: 'bold' }}>Information We
                        Collect As You Use Our Services
                    </p>
                    <p>
                        Collection of Personal Data
                    </p>
                    <p>
                        As is true of most digital
                        platforms, we and our third-party
                        providers collect certain personal
                        data automatically when you visit
                        or interact with our websites,
                        mobile apps, and other online services.
                        This includes things like your home
                        search history, homes you view,
                        purchase activity, what you’ve
                        clicked on and other uses of our
                        websites, and the amount of time
                        you spend looking at different
                        parts of our websites.

                    </p>
                    <p>

                        Specifically, we and our third party
                        partners may use tracking technologies
                        to automatically collect commercial
                        information, preferences, and internet,
                        network and device information, including:
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Information about how you access our services,</span>
                        such as the website from which you came
                        and the website to which you are going
                        when you leave our websites, how frequently
                        you access the services, when and whether
                        you open emails or click the links contained
                        in emails, whether you access the services
                        from multiple devices and other actions
                        you take on the services.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Information about how you use the services,</span>

                        such as the pages you visit, the
                        links you click, the ads you view
                        and click on, purchase information
                        and your checkout process, your location
                        when you access or interact with
                        our services, and other similar actions.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Information about the computer,
                            tablet, smartphone or other device you use,</span>

                        such as your IP address, browser type,
                        Internet service provider, platform type,
                        device type/model/manufacturer, operating
                        system, date and time stamp, a unique ID
                        that allows us to uniquely identify
                        your browser, mobile device, or your
                        account (including, e.g., a persistent device
                        identifier or an Ad ID), and other such
                        information (referred to herein as “Log Data”).
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Analytics Data,</span>
                        Analytics Data, such as information about your
                        activity when you visit our sites or use
                        our apps; this can include clicks, mouse
                        movements, forms you fill out, and similar
                        information. We use this to better
                        understand what features and parts of
                        the website are most useful, and
                        which ones may need to be improved.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Cookies, pixels, and other tracking:

                        </span>
                        We and our partners use various tools
                        to collect data when you visit our sites
                        and apps, including cookies, pixel tags,
                        and other similar technologies. Cookies
                        are bits of electronic data that can be
                        transferred to your computer or other
                        device to identify your browser. When
                        you use Naxxa, we and our partners may
                        use cookies and other tools to gather
                        information about how you view and use our
                        services and content, and to connect your
                        activity with other data we store about you.
                        The use of cookies helps us serve you better
                        by understanding what you’re interested in,
                        tracking trends, measuring the effectiveness
                        of ads, saving your preferences, and storing
                        information you may want to retrieve on a
                        regular basis, such as your favorite homes.
                        We also allow approved partners to collect
                        data from your browser or device for a
                        dvertising and measurement purposes using
                        their own cookies or similar tools; these
                        are categorized as “Advertising Cookies,”
                        and you can choose whether to disable them
                        via either our Privacy Center or the
                        “Cookie Preference” link at the bottom
                        of our websites.
                    </p>

                    <p>
                        This information allows us to improve your
                        customer experience. For example, we may
                        use this information to enhance and personalize
                        your user experience, to monitor and improve
                        our websites and services, and for other
                        internal purposes.  We may also use this
                        information to: (a) remember information
                        so that you will not have to re-enter it
                        during your visit or the next time you visit
                        the Sites; (b) provide custom, personalized
                        content, and information; (c) identify and
                        contact you across multiple devices; (d)
                        provide and monitor the effectiveness of
                        our services; (e) perform analytics and
                        detect usage patterns on our services;
                        (f) diagnose or fix technology problems;
                        (g) detect or prevent fraud or other harmful
                        activities; and (h) otherwise to plan for
                        and enhance our services.
                    </p>
                    <p>

                        We and our third party partners may collect
                        information through tracking technologies
                        for personalized advertising purposes. To
                        learn more about our advertising practices
                        and your choices relating to advertising,
                        please see “Third-Party Tracking and
                        Interest-Based Advertising.”
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Information You Give Us or Create Using Our Services
                        </span>
                        Collection of Personal Data<br></br>
                        When you use our services, websites, or apps,
                        we collect personal data from you. We might
                        also collect personal data about you from
                        our business partners, payment processors,
                        service providers, and other third parties
                        who collect personal data on our behalf.
                        This information might include:

                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Account Registration and Profile Information:
                        </span>
                        If you register for an account through our services
                        or complete a Naxxa profile, we collect account
                        identifiers (including a username and password,
                        as well as internal identifiers we assign
                        to individual accounts to allow our systems
                        to connect account information stored in
                        different databases) and account history
                        and records (including services you’ve used
                        or interacted with, the date and type of
                        account creation, account status, log in
                        activity, transactions, the services we
                        provide, messages received in connection
                        with the services, and your activity on
                        our websites and mobile applications). We

                        use this information to create and administer
                        your account, provide you with the relevant
                        services and information, communicate
                        with you regarding your account and your
                        use of the services, for customer
                        support purposes, and to send you
                        communications by email, push notification,
                        or by text message, in accordance with your preferences.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Customer Service and Communication History:
                        </span>
                        Including name, address, email, customer
                        service requests, Account Identifiers,
                        Account History and the contents of requests
                        and correspondence with us
                        (including recordings of phone calls,
                        where permitted by law).
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Feedback Information:
                        </span>
                        If you submit a review, feedback or other
                        comments, we collect your contact information
                        (such as first and last name, email address,
                        and phone number) and any feedback and
                        ratings relating to our services and products,
                        including reviews you create of real estate pros.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Inquiries and Communication:
                        </span>
                        If you communicate with us or otherwise
                        submit an inquiry or question (e.g., via webform),
                        we collect your contact information
                        (such as first and last name, email address,
                        and phone number), account identifiers
                        and history (described above), and any
                        other personal data you choose to provide
                        in the content of your message or communication.
                        We use this information to investigate
                        and respond to your inquiries, to
                        facilitate communication with us, to
                        provide you with requested information,
                        to enhance the services we offer, and
                        to manage and grow our business.  If you
                        represent a company or agency interested
                        in partnering with us, we will also
                        collect your professional and employment
                        information in order to respond to your
                        inquiries, communicate with you, to
                        manage and grow our organization and
                        to facilitate a business relationship.
                        Our websites and online services provide
                        you the ability to connect with us through
                        an online web form that collects contact
                        information (such as first and last name,
                        email address, phone number, company name,
                        and title with company), area(s) of
                        interest or concern, and a custom message.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Location Data:
                        </span>
                        Including general geographic location reflected
                        in the Log Data we collect or more precise location
                        when you choose to share it with
                        us through your device or browser settings.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Newsletters and Email Communication:
                        </span>
                        Many of our websites and online services
                        provide you an opportunity to sign up for
                        our newsletters and email communications by
                        providing your email address. We use your email
                        address to communicate with you about our
                        services and exciting developments at Naxxa
                        Group, which may include marketing communications.
                        Please see the “Choices About Your Data”
                        section below for additional information
                        about opting out of our marketing communications.
                    </p>

                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Payment Information:
                        </span>
                        We and our third-party service providers
                        collect information relating to your
                        transactions with us, including details
                        regarding payments you’ve made through our
                        sites or apps. We use third-party payment
                        tools to process and store payment information
                        like credit card numbers or bank account
                        information. We don’t store this
                        information ourselves.
                    </p>
                    <p>

                        Although we often collect the personal
                        data described above directly from you,
                        we may also collect certain information
                        through our business partners, service
                        providers, and other third parties that
                        collect it on our behalf, such as communications
                        providers, data brokers, payment processors,
                        payment system providers, and information
                        technology providers.
                    </p><br>
                    </br>

                    Please note that we link the personal data we
                    collect in connection with our services with the
                    other personal data that we collect and
                    may use it for the purposes we describe
                    in more detail in the other sections of
                    this Privacy Notice.
                    <p style={{ fontWeight: 'bold' }}>
                        Other Collection of Personal Data
                    </p>
                    <br>
                    </br>


                    In addition to the personal data collected
                    above, we may also collect personal
                    data as follows:
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Affiliates:

                        </span>
                        We may receive information about you
                        collected by other Naxxa Group companies,
                        businesses, brands, and affiliated
                        entities in our family of companies,
                        so that information you provide to
                        one brand may be used by us to better
                        provide you services and communicate with you.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Business Partners:

                        </span>
                        Our business partners, such as agent
                        partners, lending partners, builders,
                        property managers, and other real
                        estate professionals collect personal
                        data in connection with their services
                        and often share some or all of this
                        information with us. For example, we
                        receive information about transactions
                        you complete with partners with whom
                        we’ve connected you through our services.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Business Representatives:

                        </span>
                        We collect professional personal data
                        about representatives of third-party
                        businesses, including representatives
                        of our customers and business partners,
                        in connection with our services and
                        the operation of our business, which may include:
                    </p>
                    <p>
                        <span > Contact Information:

                        </span>
                        Including full name, email address,
                        mailing address, and telephone number.
                    </p>
                    <p>
                        <span >  Professional Information:

                        </span>
                        Including job title, job function,
                        company name and characteristics,
                        professional background, and nature
                        of the relationship with us.
                    </p>
                    <p>
                        <span >Tax and Payment Information:

                        </span>
                        Including a personal tax identification
                        number, bank account information,
                        and payment information where you
                        are a representative of a sole
                        proprietor or similar legal structure
                        that uses personal tax and account
                        information in lieu of separate business information.
                    </p>
                    <p>
                        <span >Inquiry Information:

                        </span>
                        Including the contents of emails, texts,
                        and other communications and,
                        where permitted by law, recordings of calls with us.
                    </p>
                    <p>
                        <span >Feedback Information,

                        </span>
                        including information provided in
                        response to surveys we may conduct
                        with customers or business partners,
                        or unsolicited feedback received
                        regarding our services and business.
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>
                            Office and Event Visitors:
                        </span>
                        We collect personal data about visitors
                        to our physical offices and events, which may include:
                    </p>
                    <p>

                        Contact Information:

                        Including full name, email address,
                        mailing address, and telephone number.
                    </p>
                    <p>
                        Professional Information: Including

                        job title, job function, company name,
                        professional background, and nature
                        of the relationship with us.
                    </p>
                    <p>
                        Visit Information: Including the purpose
                        of the visit and any restrictions or
                        preferences while on premise (such as
                        dietary restrictions).
                    </p>
                    <p>
                        Security Information: Including a copy
                        of a government ID (such as a driver’s
                        license) and a record of the visitor’s
                        access to our office or event, any
                        use by the visitor of our computer systems,
                        and images or video recordings of the
                        visitor while on premises (where permitted by law).
                    </p>
                    <p>

                        Service Providers: Our service providers,
                        such as payment processors and marketing
                        providers, collect personal data and often
                        share some or all of this information with
                        us. For example, we receive personal data
                        from payment processors to confirm that
                        an individual’s payment for the services
                        was accepted. We use this information to
                        comply with our legal obligations, to
                        monitor activity to identify and provide
                        you with promotions and offers, and to
                        prevent fraud, protect our rights and the
                        rights of others, to inform our marketing
                        and advertising activities, and to help
                        provide our services.
                    </p>

                    <p>

                        Information Providers: We may from time
                        to time obtain information from third-party
                        information providers to correct or
                        supplement personal data we collect.
                        For example, we may obtain updated contact
                        information from third-party information
                        providers to reconnect with you.
                    </p>
                    <p>

                        Publicly Available Information: We may
                        collect personal data from publicly available
                        sources, such as information you publicly post
                        or tag us in on social media sites or elsewhere
                        online, and information contained in public
                        records databases, such as government records
                        or public review websites, to supplement the
                        personal data identified above. We will use
                        this information to conduct market research,
                        verify your identity, prevent fraud,
                        and improve our services.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>

                        Other Uses of Personal Data
                    </p>

                    In addition to the uses identified above,
                    we use the personal data we collect to:
                    <ul>
                        <li>

                            Facilitate our day-to-day business operations,
                            such as helping you find your next home,
                            or connect with a real estate agent,
                            lender, landlord, or other real estate professional;
                        </li>
                        <li>

                            Create and maintain the services,
                            develop new products and services,
                            and improve existing products and services;
                        </li>
                        <li>

                            Aggregate information we receive
                            from you and third parties to help understand
                            individual needs, customize our services
                            and offerings, and provide everyone better service;
                        </li>
                        <li>

                            Conduct research and analytics designed
                            to understand our customer base and
                            improve and grow our business, products,
                            services, and customer experience;
                        </li>
                        <li>

                            Communicate with you to provide
                            technical or administrative support;
                        </li>
                        <li>

                            Prevent, investigate, and defend against
                            fraud or unlawful or criminal activity,
                            access to, or use of personal data
                            and our data system services;
                        </li>
                        <li>

                            Enforce, investigate, and resolve
                            disputes and security issues and to
                            enforce our Terms of Service
                            and any other contracts;
                        </li>
                        <li>

                            Comply with legal obligations
                            and other governmental demands;
                        </li>
                        <li>

                            Understand your preferences and
                            interests to better serve you on
                            your home-finding journey;
                        </li>
                        <li>

                            Personalize your individual experience,
                            including providing educational resources
                            and pointing you to homes you might be
                            interested in or offerings of ours
                            that might help you find your next home; and
                        </li>
                        <li>

                            For any other lawful, legitimate business purpose.
                        </li>
                    </ul>

                    <p style={{ fontWeight: 'bold' }}>Our Disclosure of Personal Data
                    </p>
                    <p>

                        We may disclose your personal data in the instances
                        described below.  For further information on your
                        choices regarding your information, see the
                        “Choices About Your Data” section below.
                    </p>
                    We disclose your personal data in the following ways:
                    <p><span style={{ fontWeight: 'bold' }}>Within Naxxa Group: </span>
                        We are able to offer the products and services
                        we make available because of the hard work of
                        the entire Naxxa Group team. Naxxa Group entities
                        disclose your personal data to other Naxxa Group
                        entities for purposes and uses that are consistent
                        with this Privacy Notice and applicable law. For
                        example, one part of Naxxa might share your personal
                        data with another in order to ensure that all
                        the people helping in your home journey are
                        working together for you.

                    </p>
                    <p><span style={{ fontWeight: 'bold' }}> Business Partners:  </span>
                        At your direction, we may share your personal
                        data with our business partners in order
                        to provide you with our products and services.
                        For example, if you ask us to, we’ll share
                        your contact information with a real
                        estate agent or mortgage lender.

                    </p>
                    <p><span style={{ fontWeight: 'bold' }}> Marketing Partners:  </span>
                        We coordinate and share your personal
                        data with our marketing partners, including
                        advertising networks, social networks,
                        and marketing communication providers,
                        in order to communicate with you about our
                        products and services and market our
                        products and services to you. We may also
                        share aggregated demographic information
                        with third parties interested in advertising
                        on our online services to assist them in
                        understanding the audience they would be
                        reaching, but this information is not
                        designed to identify any specific individual.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Service Providers:  </span>
                        We share information with third
                        party vendors and service providers
                        that perform services for or on our
                        behalf, which may include identifying
                        and serving targeted advertisements,
                        providing mailing or email services, tax
                        and accounting services, customer service,
                        product fulfillment, payments processing,
                        photo sharing, data processing and enhancement
                        services, fraud prevention, web hosting,
                        analytic services, or other online functionality,
                        subject to appropriate contractual terms
                        protecting the confidentiality and use of such
                        data. We never allow service providers to
                        use your personal data for their own purposes.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Business Transaction or Reorganization:  </span>
                        We may take part in or be involved with
                        a corporate business transaction, such
                        as a merger, acquisition, joint venture,
                        or financing or sale of company assets.
                        We may disclose your personal data to a
                        third party during negotiation of, in
                        connection with, or as an asset in such a
                        corporate business transaction. Your
                        personal data may also be disclosed in
                        the event of insolvency, bankruptcy, or receivership.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Legal Obligations and Rights:  </span>
                        We may disclose your personal data to
                        third parties, such as legal advisors
                        and law enforcement:
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Legal Obligations and Rights:  </span>
                        We may disclose your personal data to
                        third parties, such as legal advisors
                        and law enforcement:
                    </p>
                    <ul>
                        <li>

                            In connection with the establishment,
                            exercise, or defense of legal claims;
                        </li><li>

                            To comply with laws and regulations or
                            to respond to lawful requests and legal process;
                        </li><li>

                            To protect our rights and property and the
                            rights and property of our agents, customers,
                            and others, including to enforce our
                            agreements, policies, and terms of use;
                        </li><li>

                            To detect, suppress, or prevent fraud;
                        </li><li>

                            To reduce credit risk and collect
                            debts owed to us;
                        </li><li>

                            To protect the health and safety of
                            us, our customers, or any person; or
                        </li><li>

                            As otherwise required by applicable law.
                        </li><li>

                            For Naxxa’s policy on government and
                            civil requests for information,
                            please see Government and Civil
                            Information Requests.
                        </li>
                    </ul>
                    <p><span style={{ fontWeight: 'bold' }}>Otherwise with Consent or At Your Direction:</span>
                        We may disclose your personal data
                        to certain other third parties or
                        publicly with your consent or direction.
                        If you post a comment or review our
                        website or a comment on our social media
                        sites, the information you provide may
                        be displayed publicly online for others to view.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>Choices About Your Data</p>
                    <p><span style={{ fontWeight: 'bold' }}>Profile Access and Data Sharing.</span>
                        You may access and update your profile
                        information, such as your user name,
                        address, or billing information, and
                        may change some of your data sharing
                        preferences on your account page.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Location and Device Permissions.</span>
                        You may control location tracking by
                        adjusting your location services options
                        on the “Settings” app on your mobile device.
                        We may continue to approximate your
                        location based on your IP address when
                        you access the services through a computer
                        or device. If you would like to update
                        your device content access permissions,
                        such as permissions to access your camera,
                        you can do so in the “Settings” app on
                        your mobile device.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Promotional Messages.</span>
                        You can stop receiving promotional email
                        communications from us by following the
                        “unsubscribe” instructions provided in
                        such communications.  We make every effort
                        to promptly process all unsubscribe requests.
                        You may still receive service-related
                        communications, including account verification,
                        transactional communications, changes/updates
                        to features of the services, and
                        technical and security notices.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>Third Party Tracking and Interest-Based Advertising.</span>
                        We participate in interest-based advertising
                        and use third party advertising companies to
                        serve you targeted advertisements based on
                        your browsing history. To learn more about
                        our advertising practices and your choices
                        relating to advertising, please see “Third-Party
                        Tracking and Interest-Based Advertising.”
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}> Third-Party Tracking
                        and Interest-Based Advertising.</span>
                    </p>
                    <p>

                        We may participate in interest-based
                        advertising and use third party advertising
                        companies to serve you targeted advertisements
                        based on your browsing history. We may permit
                        third party online advertising networks, social
                        media companies, and other third-party services
                        to collect information about your use of our
                        websites, including our mobile apps, over time
                        so that they may play or display ads on our
                        services, on other websites, apps, or services
                        you may use, and on other devices you may use.
                        Typically, though not always, the information
                        used for interest-based advertising is collected
                        through tracking technologies, such as cookies,
                        Flash objects, web beacons, embedded scripts,
                        mobile SDKs, location-identifying technologies,
                        and similar technology (collectively, “tracking
                        technologies”), which recognize the device you
                        are using and collect information, including
                        clickstream information, browser type, time
                        and date you visited the site, device ID or
                        AdID, geolocation, and other information. We
                        may share a common account identifier (such
                        as an e-mail address or user ID) or hashed
                        data with our third-party advertising partners
                        to help identify you across devices. We and
                        our third-party partners may use this
                        information to make the advertisements
                        you see online more relevant to your
                        interests, as well as to provide advertising
                        -related services such as reporting,
                        attribution, analytics, and market research.
                    </p><p>
                        We, or our third-party partners,
                        may link your various devices so that
                        content you see on one device can
                        result in relevant advertising on
                        another device. We may share a common
                        account identifier (such as a hashed
                        email address or user ID) or work with third-
                        party partners who use tracking technologies
                        or statistical modeling tools to determine
                        if two or more devices are linked to a single
                        user or household. We, and our partners,
                        can use this cross-device linkage to serve
                        interest-based advertising and other
                        personalized content to you across your
                        devices (including to improve your
                        user experience), to perform analytics,
                        and to measure the performance of our
                        advertising campaigns.

                    </p>
                    <p>
                        In some of our offerings, including our
                        mobile applications, we provide you with
                        the option of logging in using an account
                        that you may have with a social-media
                        service, such as Apple, Google, or Facebook.
                        For this type of log-in, you will be
                        directed to the appropriate social-media
                        log-in page, where you will enter your
                        username and password (or account credentials)
                        for the social-media service. (Through a
                        social-media log-in, the social media service
                        may know when you have used our offering.)
                        The social media-service will then send us
                        a token, which we will use to authenticate
                        you for access. The social-media service may
                        also share your name and email address with us,
                        although we will not have access to your
                        social-media account credentials. We may keep
                        the token and your name and email address for
                        purposes of identifying you, which enables us
                        to maintain your preferences in our offering
                        across different devices. As an example, by
                        identifying you using this information, we can
                        present to you your previous searches and
                        saved properties in our mobile applications,
                        even if you access the applications from
                        different devices.  Keeping this information
                        also makes it possible for us to pre-populate
                        your name and email in certain interfaces,
                        such as log-in fields or contact forms.

                    </p>
                    <p style={{ fontWeight: 'bold' }}> Your Choices
                    </p>
                    <p>
                        As noted above, depending on your browser or
                        mobile device, you may be able to set your
                        browser to delete or notify you of cookies
                        and other tracking technology by actively
                        managing the settings on your browser or
                        mobile device. You may be able to limit
                        interest-based advertising through the settings
                        on your mobile device by selecting “limit ad
                        tracking” (iOS) or “opt-out of interest
                        based ads” (Android).  Please note that
                        opt-outs are browser specific, so opting
                        out on one browser will not affect a second
                        browser, or device. Moreover, if you buy a
                        new device, use a different browser, or clear
                        all your cookies, you will have to opt out
                        all over again. To learn more about
                        interest-based advertising and how you
                        may be able to opt-out of some of this
                        advertising, you may wish to visit the Network
                        Advertising Initiative’s online resources,
                        at http://www.networkadvertising.org/choices,
                        and/or the DAA’s resources at
                        www.aboutads.info/choices. You may
                        also be able to opt-out of some – but
                        not all – interest-based ads served
                        by mobile ad networks by visiting
                        http://youradchoices.com/appchoices
                        and downloading the mobile AppChoices app.
                        Note that some of these opt-outs may not be
                        effective unless your browser is set to
                        accept cookies. If you delete cookies,
                        change your browser settings, switch
                        browsers or computers, or use another
                        operating system, you may need to
                        opt-out again.

                    </p>
                    <p style={{ fontWeight: 'bold' }}>Children’s Personal Data</p>
                    <p>
                        Our websites and online services are not directed to
                        , and we do not intend to or knowingly collect or
                        solicit personal data from children under the
                        age of 13. If you are under the age of 13,
                        please do not use our websites or online services
                        or otherwise provide us with any personal data
                        either directly or by other means. If a child under
                        the age of 13 has provided personal data to us,
                        we encourage the child’s parent or guardian to
                        contact us as described below to request that
                        we remove the personal data from our systems.
                        If we learn that any personal data we collect has
                        been provided by a child under the age of 13,
                        we will promptly delete that personal data.
                    </p>

                    <p style={{ fontWeight: 'bold' }}>Region-Specific Disclosures

                    </p>
                    <p>
                        We may choose or be required by law to provide
                        different or additional disclosures
                        relating to the processing of personal
                        data about residents of certain countries,
                        regions, or states. Please see below for
                        disclosures that may be applicable to you:

                    </p>
                    <ul>
                        <li>If you are a resident of the
                            State of California, Colorado,
                            Connecticut, Utah, or Virginia
                            in the United States, please see
                            below for additional U.S.
                            state-specific privacy disclosures.
                        </li><li>
                            If you are a resident of the State
                            of Nevada in the United States, Chapter
                            603A of the Nevada Revised Statutes permits
                            a Nevada resident to opt out of potential
                            future sales of certain covered
                            information that a website operator has
                            collected or will collect about the
                            resident. To submit such a request,
                            please contact us at privacy@Naxxa.com.

                        </li>
                    </ul>
                    <p style={{ fontWeight: 'bold' }}>Third-Party Websites</p>
                    <p>Our websites and online services may include
                        links to or redirect you to third-party
                        websites, plug-ins and applications,
                        including social media services where you may
                        connect with us. Third-party websites may
                        also reference or link to our websites and
                        online services. Except where we post, link to,
                        or expressly adopt or refer to this Privacy Notice,
                        this Privacy Notice does not apply to, and we
                        are not responsible for, any personal data
                        practices of third-party websites and online
                        services or the practices of other third
                        parties. To learn about the personal data
                        practices of these third parties, please visit

                        their respective privacy notices or policies.
                    </p>




                    <p style={{ fontWeight: 'bold' }}>Updates to this Privacy Notice</p>
                    <p>

                        We will update this Privacy Notice from
                        time to time. When we make changes to this
                        Privacy Notice, we will change the “Last
                        Updated” date at the beginning of this
                        Privacy Notice. If we make material changes
                        to this Privacy Notice, we will notify you.
                        All changes shall be effective from the date
                        of publication unless otherwise provided in
                        the notification.
                    </p>

                    <p style={{ fontWeight: 'bold' }}> Contact Us</p>

                    <p>
                        If you have any questions or requests in connection
                        with this Privacy Notice or other
                        privacy-related matters, please
                        send an email to privacy@Naxxa.com.
                    </p>

                    Alternatively, inquiries may be addressed to:
                    <br></br>
                    Naxxa, Inc.<br></br>
                    Attn: Consumer Care<br></br>
                    1301 Second Avenue<br></br>
                    <br></br>
                    Floor 31<br></br>
                    Seattle, WA 98101<br></br>
                    <br></br>
                    <p style={{ fontWeight: 'bold' }}>Additional U.S. State Privacy Disclosures
                    </p>

                    These Additional U.S. State Privacy Disclosures
                    supplement the above information by providing
                    additional information about our personal data
                    processing practices relating to individual
                    residents of the States of California, Colorado,
                    Connecticut, Utah, and Virginia. Unless
                    otherwise expressly stated, all terms defined
                    in our Privacy Notice retain the
                    same meaning in these Disclosures.

                    <p style={{ fontWeight: 'bold' }}>Additional Personal Data Disclosures
                    </p>
                    Sensitive Information

                    <p>
                        The following personal data elements
                        we collect may be classified as
                        “sensitive” under certain privacy
                        laws (“sensitive information”):
                    </p>
                    <ul>
                        <li>
                            Social Security number, driver’s
                            license number, and passport number;

                        </li>
                        <li>
                            Credit/debit card number plus expiration
                            date and security code (CVV),
                            and financial account number and routing number;

                        </li>
                        <li>
                            Username and password; and

                        </li>
                        <li>
                            Precise geolocation data.
                        </li>
                    </ul>

                    <p>

                        We use this sensitive information for
                        the purposes set forth in the “Our
                        Collection and Use of Personal Data”
                        section of our Privacy Notice, to enter
                        into and perform a contract with you, to
                        comply with legal and regulatory requirements,
                        to protect the life or physical safety
                        of you or others, or as otherwise
                        permissible for our internal business
                        purposes consistent with applicable laws.
                    </p>
                    <p>

                    </p>
                    We do not sell your sensitive information,
                    and we do not process or otherwise share
                    sensitive information for the purpose
                    of targeted advertising.


                    <p style={{ fontWeight: 'bold' }}> De-identified Information
                    </p>

                    We may at times receive or process personal
                    data to create de-identified data that can no
                    longer reasonably be used to infer
                    information about, or otherwise be linked to,
                    a particular individual or household. Where
                    we maintain deidentified data, we will maintain
                    and use the data in de-identified form and not
                    attempt to re-identify the data except as
                    required or permitted by law.
                    <p style={{ fontWeight: 'bold' }}>Your Privacy Choices</p>
                    <p>Depending on your state of residency and
                        subject to certain legal limitations and
                        exceptions, you may be able to exercise
                        some or all of the following rights:
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>The Right to Know</span>
                        The right to confirm whether we are processing your
                        personal data and, under some state laws,
                        to obtain certain personalized details about
                        the personal data we have collected
                        about you, including:
                        The categories of personal data collected;
                        The categories of sources of the personal data;
                        The purposes for which the personal
                        data were collected;
                        The categories of personal data
                        disclosed to third parties (if any),
                        and the categories of recipients to whom
                        the personal data were disclosed;
                        The categories of personal data shared for
                        cross-context behavioral advertising purposes
                        (if any), and the categories of recipients t
                        o whom the personal data were disclosed for
                        those purposes; and
                        The categories of personal data sold (if any),
                        and the categories of third parties to whom the
                        personal data were sold.data and, under some
                        state laws, to obtain certain personalized
                        details about the personal data we have collected
                        about you, including:The categories of personal
                        data collected;The categories of sources of the
                        personal data;The purposes for which the personal
                        data were collected; The categories of personal
                        data disclosed to third parties (if any), and the
                        categories of recipients to whom the personal data
                        were disclosed;The categories of personal data
                        shared for cross-context behavioral advertising
                        purposes (if any), and the categories of recipients
                        to whom the personal data were disclosed for those
                        purposes; andThe categories of personal data sold
                        (if any), and the categories of third parties to
                        whom the personal data were sold.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>The Right to Access & Portability</p>
                    <p>The right to obtain access to the personal
                        data we have collected about you and,
                        where required by law, the right to obtain
                        a copy of the personal data in a portable
                        and, to the extent technically feasible,
                        readily usable format that allows you to
                        transmit the data to another entity
                        without hindrance.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        The Right to Correction
                    </p>
                    <p>The right to correct inaccuracies
                        in your personal data, taking into
                        account the nature of the
                        personal data and the purposes
                        of the processing of the personal data.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>The Right to Deletion</p>
                    <p>The right to have us delete the
                        personal data we maintain about you.</p>
                    <p style={{ fontWeight: 'bold' }}>The Right to Opt-Out of
                        Sales or Sharing of Personal Data</p>
                    <p>The right to direct us not to “sell” your
                        personal data to third parties for monetary
                        or other valuable consideration, or “share”
                        your personal data to third parties for cross-context
                        behavioral advertising purposes
                        and targeted advertising purposes.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>“Shine the Light”</p>
                    <p>California residents that have an
                        established business relationship
                        with us have rights to know how their
                        personal data is disclosed to third
                        parties for their direct marketing
                        purposes under California’s “Shine
                        the Light” law, or the right to opt
                        out of such practices (Civ. Code §1798.83).
                    </p>
                    <p>Depending on your state of residence,
                        you may also have the right to not
                        receive retaliatory or discriminatory
                        treatment in connection with a request
                        to exercise the above rights. However,
                        the exercise of the rights described
                        above may result in a different price,
                        rate, or quality level of product or
                        service where that difference is reasonably
                        related to the impact the right has on our
                        relationship or is otherwise permitted by law.</p>
                    <p style={{ fontWeight: 'bold' }}>Submitting Privacy Rights Requests</p>
                    <p>In certain circumstances,
                        you are permitted by law to use
                        an authorized agent to submit requests
                        on your behalf through the designated
                        methods set forth above where we can
                        verify the authorized agent’s authority
                        to act on your behalf. In order to verify
                        the authorized agent’s authority, we
                        generally require evidence of either:
                        (i) a valid power of attorney; or
                        (ii) a signed letter containing your name
                        and contact information, the name and contact
                        information of the authorized agent, and a
                        statement of authorization for the request.
                        Depending on the evidence provided and your
                        state of residence, we may still need to
                        separately reach out to you to confirm the
                        authorized agent has permission to act on
                        your behalf and to verify your identity in
                        connection with the request. </p>
                    <p style={{ fontWeight: 'bold' }}>
                        Appealing Privacy Rights Decisions
                    </p>
                    <p>
                        Depending on your state of residence,
                        you may be able to appeal a decision we
                        have made in connection with your privacy
                        rights request. All appeal requests
                        should be submitted via email to privacy@Naxxa.com.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>Minors</p>
                    <p>We do not sell the personal data of consumers
                        we know to be less than 16 years of age,
                        unless we receive affirmative authorization
                        (the “Right to Opt In”) from either the minor
                        who is between 13 and 16 years of age, or the
                        parent or guardian of a minor less than
                        13 years of age.</p>
                    <p>If you are under the age of 18 and you want to
                        remove your name or comments from our website
                        or publicly displayed content, please contact
                        us directly at privacy@Naxxa.com. We may not
                        be able to modify or delete your information
                        in all circumstances.</p>
                    <p>If you wish to submit a privacy request on
                        behalf of your minor child in accordance with
                        applicable jurisdictional laws, you must provide
                        sufficient information to allow us to reasonably
                        verify your child is the person about whom we
                        collected personal information and you are
                        authorized to submit the request on your
                        child’s behalf (i.e., you are the child’s
                        legal guardian or authorized representative).</p>
                    <p style={{ fontWeight: 'bold' }}>
                        California-Specific Disclosures
                    </p>
                    The following disclosures only apply to
                    residents of the State of California.<br></br>
                    California Categories of Personal Data
                    <p>California law requires we provide disclosures
                        to you about what personal data we collect
                        by reference to the enumerated categories
                        of personal data set forth within California
                        law. To address this obligation, we have identified
                        the relevant enumerated California personal
                        data category for the personal data, sources
                        and purposes described in the “Our Collection
                        and Use of Personal Data” section of our
                        Privacy Notice below:</p>
                    <ul>

                        <li><span style={{ fontWeight: 'bold' }}>Identifiers,</span> including Account Registration and
                            Profile Information, information
                            related to your feedback,
                            inquiries, and communications, credit and
                            identity information relating to data used
                            to identify you, full name, IP address, and
                            government identification number (including
                            Social Security number, passport number,
                            and driver’s license number).</li>
                        <li><span style={{ fontWeight: 'bold' }}>Customer Records,</span>
                            including Account Registration and Profile
                            Information, Security/Authentication
                            Information, and Payment Information
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Protected Classification Characteristics,</span>
                            including age, date of birth, gender, and sex.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}> Commercial Information,</span>
                            including Account Registration and
                            Profile Information relating to your
                            purchases, Profile Information relating
                            to your transactions, preferences, and
                            interests, and Feedback Information.

                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Internet/Network Information,</span>
                            including information about how you
                            access and use the services, Log Data,
                            and Analytics Data.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Geolocation Data,</span>
                            including general geographic location
                            or more precise location with your
                            consent or when permitted by law.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Sensory Information,</span>
                            including, where permitted by law,
                            recordings of phone calls between us
                            and individuals, and image and video
                            recordings of visitors to our offices or events.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Profession/Employment Information,</span>
                            including the business or organization you
                            represent, your title with that business or
                            organization, and information relating to
                            your role with the business or organization.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Sensitive Information,</span>
                            including Social Security number, driver’s
                            license number, passport number,
                            credit/debit card number plus expiration
                            date and security code (CVV), financial
                            account number and routing number,
                            username and password, and precise geolocation.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Other Personal Data,</span>
                            including any information you provide us
                            in connection with signing up for newsletters,
                            email communications, and surveys, personal
                            data you permit us to see when interacting
                            with us through social media, and personal
                            data you provide us in relation to a
                            question, request, inquiry,
                            survey, contest, or promotion.
                        </li>
                        <li><span style={{ fontWeight: 'bold' }}>Inferences,</span>
                            including our predictions about interests
                            and preferences and
                            related Service Profile Information.
                        </li>
                    </ul>
                    <p>We collect this data from the following sources:
                        directly from you, from our business partners and
                        affiliates, from your browser or device when you
                        visit our mobile app(s) or use our Services, or
                        from third parties that you permit to share
                        information with us. Please see “Our Collection
                        and Use of Personal Data” section of our Privacy
                        Notice for more information about the sources of
                        personal data we collect. We disclose all of
                        these categories of personal data for a business
                        purpose to service providers or other third parties
                        at the consumer’s direction, as outlined in the
                        “Our Disclosure of Personal Data” section
                        of our Privacy Notice.</p>
                    Retention of Personal Data
                    <p>

                        We strive to retain your personal data
                        only for as long as is reasonably necessary
                        to fulfill the purpose for which it was
                        collected. However, if necessary, we may
                        retain your personal data for longer periods
                        of time, until set retention periods and deadlines
                        expire, for instance where we are required to do so
                        in accordance with legal, tax and/or accounting
                        requirements set by a legislature,
                        regulator, or other government authority.
                    </p>
                    <p>

                        To determine the appropriate duration of
                        the retention of personal data, we consider
                        the amount, nature and sensitivity of the
                        personal data, the potential risk of harm
                        from unauthorized use or disclosure of
                        personal data and if we can attain our
                        objectives by other means, as well as
                        our legal, regulatory, tax, accounting,
                        and other applicable obligations.
                    </p>
                    <p>

                        Therefore, we retain personal data for as
                        long as you use our services for the purposes
                        explained in our Privacy Notice, including
                        maintaining a user account. When you discontinue
                        the use of our services, we will retain your
                        personal data for as long as necessary to
                        comply with our legal obligations, to
                        resolve disputes and defend claims, as
                        well as for any additional purpose based
                        on the choices you have made, such as
                        to receive marketing communications.
                    </p>
                    <p>

                        Once retention of your personal data is
                        no longer necessary for the purposes
                        outlined above, we will either delete or
                        de-identify the personal data or, if
                        this is not possible (for example, because
                        personal data has been stored in backup
                        archives), then we will securely store
                        your personal data and isolate it from
                        further processing until deletion or
                        deidentification is possible.
                    </p>
                    <p>

                        “Shine the Light” Disclosures
                    </p>
                    <p>

                        The California “Shine the Light” law gives
                        residents of California the right under
                        certain circumstances to request information
                        from us regarding the manner in which we
                        share certain categories of personal information
                        (as defined in the Shine the Light law)
                        with third parties for their direct marketing
                        purposes. To opt out of this type of sharing,
                        please visit our Privacy Center or
                        email us at privacy@Naxxa.com.
                    </p>
                    <p>
                        Consumer Rights Metrics 2021You can find
                        data that reflects the California consumer
                        rights requests that we have processed in
                        the 2021 calendar year (January 2021 –
                        December 2021) by clicking here.
                    </p>
                </Col>
            </Row>

        </div>
    )
}