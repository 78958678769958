import { Color } from 'components';
import { MoreInfoPopup } from '../MoreInfoPopup';
import { setActiveTabValue, setDataTypeValue, setInfoWindowOpenValue, setInfoWindowPositionValue, setIsSelectedResultDrawerOpenValue, setItem, setViewOnMapParcelItemValue, useCreateParcelBookmarkMutation, useDeleteParcelBookmarkByParcelIdMutation } from 'app/services';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import BookmarkPopover from 'components/BookmarkPopover';
/**
 * Renders a card component specific to parcels based on provided props.
 * Depending on the 'title' and 'from' props, renders different variations of ParcelCard:
 * - When 'title' is 'Parcel' and 'from' includes 'viewAll', 'matching', or 'bookmark-drawer', renders ParcelCard.
 * - Supports various functionalities like showing bookmarks, handling clicks, and updating data.
 */
export interface ParcelCardProps {
  id?: string;
  title: string;
  titleColor: Color;
  data: { name: any; value: any | undefined }[];
  showMore?: boolean;
  allData?: any;
  information?: any;
  showBookmark?: boolean;
  idSerial?: any;
  parcelId?: string;
  formattedParcelId?: string;
  id_serial?: any;
  item?: any;
  from?: string;
  closeViewAll?: any;
  refetchParcels?: () => void;
  onClickResultCard?: () => void;
  setCurrentPage?: (value: any) => any;
  onBookMarkIconClick?: (item: any, type: any) => void
}

export const ParcelCard = ({
  id,
  title,
  titleColor,
  data,
  information,
  showMore,
  allData,
  showBookmark,
  id_serial,
  parcelId,
  formattedParcelId,
  from,
  closeViewAll,
  refetchParcels,
  onClickResultCard,
  setCurrentPage,
  onBookMarkIconClick
}: ParcelCardProps) => {
  const [deleteParcelBookmark] = useDeleteParcelBookmarkByParcelIdMutation();
  const [createParcelBookmark] = useCreateParcelBookmarkMutation();
  const dispatch = useDispatch();
  /**
   * Deletes a bookmark associated with a parcel or project.
   * Sends a request to delete the bookmark using `deleteParcelBookmark` or similar function.
   * Handles success actions such as triggering `onBookMarkIconClick` and displaying a success notification.
   *
   * @param {boolean} isBookmarkDrawer - Flag indicating if the deletion is from a bookmark drawer.
   * @returns {void}
   */
  const deleteBookmark = async (isBookmarkDrawer = false) => {
    deleteParcelBookmark({ id: id_serial, dataArgs: allData?.item?.dataArgs }).finally(() => {
      if (onBookMarkIconClick) {
        onBookMarkIconClick(allData, 'remove')
      }
      notification.success({
        placement: 'topRight',
        message: `Bookmark deleted successfully!`,
      });
    })
    if (refetchParcels) {
      refetchParcels()
    }

    if (setCurrentPage) {
      setCurrentPage(1)
    }
  }
  /**
   * Creates a bookmark for a parcel if the title matches 'Parcel'.
   * Sends a request to create the bookmark using `createParcelBookmark` or similar function.
   * Handles success actions such as triggering `onBookMarkIconClick` and displaying a success notification.
   * 
   * Note: This function assumes specific behavior based on the 'title' and 'allData' props.
   * Adjust the conditions and actions based on your application's requirements.
   * 
   * @returns {void}
   */
  const createBookmark = () => {
    if (title === 'Parcel') {
      createParcelBookmark({ parcel_id: allData?.item.id_serial, dataArgs: allData?.item?.dataArgs })
        .finally(() => {
          if (onBookMarkIconClick) {
            onBookMarkIconClick(allData, 'create')
          }
          notification.success({
            placement: 'topRight',
            message: `${title} bookmarked successfully!`,
          });
        })
    }

  }
  /**
   * Sets the selected parcel item for display on the map and updates relevant state and local storage.
   * Dispatches actions to set the item details and view settings on the map.
   * Invokes onClickResultCard callback if provided.
   */ 
  const showParcelOnMap = () => {
    if (allData?.item?.center?.coordinates?.length) {
      dispatch(setViewOnMapParcelItemValue({
        lat: allData.item.center.coordinates[1],
        lng: allData.item.center.coordinates[0],
        id: allData?.item?.id,
        id_serial: allData?.item?.id_serial,
      }))
    }
    dispatch(
      setItem({
        id: allData?.item?.id,
        type: allData?.type,
        parcelId: allData?.item?.parcelId,
        formattedParcelId: allData?.item?.formattedParcelId,
        id_serial: allData?.item?.id_serial,
        parent_project_id: allData?.item?.parent_project_id,
      }),
    );
    localStorage.setItem('showingParcelsOnMap', 'true')

    if (onClickResultCard) {
      onClickResultCard()
    }
  }
  /**
   * Renders a bookmark button component based on the 'from' and 'title' props.
   * Conditionally displays different variations of BookmarkPopover for adding or removing bookmarks.
   * Executes deleteBookmark or createBookmark functions based on user interaction.
   */ 
  function BookmarkButton() {
    const isMatchingParcel = (from === 'matching' && title === 'Parcel');
    const isViewAllParcel = (from === 'viewAll' && title === 'Parcel');
    return (
      <span>
        {from === 'bookmark-drawer' ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={() => deleteBookmark(true)}
            body={<img
              title='Remove Bookmark'
              src='bookmark_filled.svg'
              alt='bookmark-filled'
              style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
            // onClick={() => deleteBookmark(true)}
            />}
          />
        ) : (isMatchingParcel || isViewAllParcel) && allData.item.existsInBookmarks ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={() => deleteBookmark()}
            body={<img
              title='Remove Bookmark'
              src='bookmark_filled.svg'
              alt='bookmark-filled'
              style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
            // onClick={() => deleteBookmark()}
            />}
          />

        ) : (isMatchingParcel || (((from === 'viewAll' || from === 'bookmark-drawer') && title === 'Parcel'))) ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Add Bookmark'
            onConfirm={() => createBookmark()}
            body={<img
              title='Add Bookmark'
              src='bookmark.svg'
              alt='bookmark'
              style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
            // onClick={createBookmark}
            />}
          />

        ) : null}
      </span>
    );
  }
  /**
   * Renders a metadata detail component with dynamic content based on props.
   * Displays metadata information such as title, bookmarks, and additional details.
   * Handles click events to show on map, delete bookmarks, and trigger result card clicks.
   * Conditionally renders metadata details and actions based on 'from' and 'title' props.
   */ 
  return (
    <div className="naxxa-meta-detail">
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={`meta-title ${titleColor}`}>{title}
          <BookmarkButton />
        </div>
        <div onClick={(e: any) => {
          if (allData?.type !== 'Parcel' && (allData?.type === 'Projects' || from === 'matching' || from === 'parent-project' || (from === 'viewAll' && title === 'Project') || from === 'child-project')) {
            if (!allData?.item?.geom_center?.coordinates && !allData?.item?.geomCenter?.coordinates) {
              notification.error({
                placement: 'topRight',
                message: 'Center coordinates not available for the selected item',
                duration: 10,
              });
              return;
            }
          }
          if (from === 'matching' || (from === 'viewAll' && title === 'Parcel') || from === 'bookmark-drawer') {
            let centerCoords = allData?.item?.geom_center?.coordinates || allData?.item?.geomCenter?.coordinates || allData?.item?.center?.coordinates
            if (!centerCoords) {
              notification.error({
                placement: 'topRight',
                message: 'Center coordinates not available for the selected item',
                duration: 10,
              });
              return;
            }
            e.stopPropagation();
            if (allData?.type === 'Projects') {
              dispatch(setDataTypeValue('Projects'))
              dispatch(setActiveTabValue('Projects'))
              dispatch(setInfoWindowPositionValue({
                lat: allData?.item?.geom_center?.coordinates[1] || allData?.item?.geomCenter?.coordinates[1],
                lng: allData?.item?.geom_center?.coordinates[0] || allData?.item?.geomCenter?.coordinates[0],
              }));
              dispatch(setItem({ id: allData?.item?.id, type: allData?.type, id_serial, parent_project_id: allData?.item?.parent_project_id }));
              dispatch(setIsSelectedResultDrawerOpenValue(false))
              setTimeout(() => {
                dispatch(setInfoWindowOpenValue(true))
              }, 2000);
            } else {
              showParcelOnMap();
            }
          }
          else {
            if (onClickResultCard) {
              onClickResultCard()
            }
          }
        }}>
          {from === 'matching' && title === 'Parcel' || ((from === 'viewAll' || from === 'bookmark-drawer') && title === 'Parcel') ?
            <img title='view on map' src='exportoutlined.svg' alt='Export' style={{ cursor: 'pointer', height: 20, width: 15 }} />
            : null}
        </div>
        {showBookmark ?
          <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} onClick={() => deleteBookmark()} />

          // <TagFilled title='Remove Bookmark' style={{ cursor: 'pointer', zIndex: 999999 }} onClick={deleteBookmark() => }/>()
          : null}
      </div>
      {data &&
        data
          .filter(
            (src: { name: string; value: any }) =>
              src.name !== 'County',
          )
          .map(({ name, value }, idx) => {
            if (['Developer', 'Builder', 'Investor', 'Real Estate Agent'].includes(title) && name === 'City') {
              return null
            }
            return (
              <div className="meta-detail" key={idx}>
                <span className="meta-detail-name">{name}: </span>
                <span className="meta-detail-value"> {name === 'Email' && Array.isArray(value) ?
                  value.map((elem: any, i: any) => {
                    return i > 0 ? <>, <a key={i} href={'mailto:' + elem}>{elem}</a></> : <a key={i} href={'mailto:' + elem}>{elem}</a>
                  }) : name === 'Email' && value ? <a href={'mailto:' + value}>{value}</a> : value}
                </span>
              </div>
            )
          })}
      {showMore &&
        title === 'Parcel' &&
        <div className="popup-more-info">
          <MoreInfoPopup title={title} information={information} allData={allData} id_serial={id_serial} />
        </div>
      }

    </div>
  );
};
