import { Input, Slider } from 'antd';
import { SliderBaseProps } from 'antd/lib/slider';
import React, { useCallback, useRef, useState } from 'react';

interface MinMax {
  min: number;
  max: number;
}

interface SliderSelectFilterProps extends SliderBaseProps {
  step: number;
  minValue?: number;
  maxValue?: number;
  keyValue: string;
  onChange: (value: string[], key: string) => void;
}

// TODO: refactor the onChange handle. simply i dont like what i have done with this
export const SliderSelectFilter = ({
  minValue,
  maxValue,
  keyValue,
  onChange,
  ...rest
}: SliderSelectFilterProps) => {
  const [minMaxInputValue, setMinMaxInputValue] = useState<MinMax>({
    min: minValue || 0,
    max: maxValue || 0,
  });
  const sliderRef = useRef<HTMLDivElement>(null);
  const onChangeHandle = (min: number | string, max: number | string) => {
    onChange([min.toString(), max.toString()], keyValue);
  };
  const handleMinChange = useCallback(
    (value: string) => {
      return Number(value) <= minMaxInputValue.max
        ? Number(value)
        : minMaxInputValue.min;
    },
    [minMaxInputValue],
  );

  return (
    <div className="naxxa-slider-container" ref={sliderRef}>
      <Slider
        range={{ draggableTrack: true }}
        tooltipVisible
        onChange={(value: [number, number]) => {
          setMinMaxInputValue({
            ...minMaxInputValue,
            min: value[0],
            max: value[1],
          });
          onChangeHandle(value[0], value[1]);
        }}
        value={[minMaxInputValue.min, minMaxInputValue.max]}
        tipFormatter={(e) => <span>{e}</span>}
        className="naxxa-slider"
        getTooltipPopupContainer={() => sliderRef?.current as HTMLDivElement}
        // tooltipPlacement="top"
        {...rest}
      />
      <div className="naxxa-slider-inputs-wrapper">
        <Input
          type="number"
          onChange={(e) => {
            setMinMaxInputValue((pv) => ({
              ...pv,
              min: handleMinChange(e.target.value),
            }));
            onChangeHandle(
              handleMinChange(e.target.value),
              minMaxInputValue.max,
            );
          }}
          value={minMaxInputValue.min}
          className="naxxa-slider-input"
        />
        <span>-</span>
        <Input
          type="number"
          onChange={(e) => {
            setMinMaxInputValue((pv) => ({
              ...pv,
              max: Number(e.target.value),
            }));
            onChangeHandle(minMaxInputValue.min, e.target.value);
          }}
          value={minMaxInputValue.max}
          className="naxxa-slider-input"
        />
      </div>
    </div>
  );
};
