import { Tabs, TabsProps } from 'antd';
import { ReactElement } from 'react';

const { TabPane } = Tabs;
interface TabContentProps {
  title: string;
  content: ReactElement;
}

interface TabbedContentProps extends TabsProps {
  maxedLayout?: boolean;
  tabData: TabContentProps[];
}
export const TabbedContent = ({
  tabData,
  centered = true,
  maxedLayout = true,
  ...rest
}: TabbedContentProps) => (
  <Tabs
    defaultActiveKey="0"
    {...rest}
    destroyInactiveTabPane={true}
    className={`naxxa-tabs ${maxedLayout ? 'maxed' : ''}`}
    centered={centered}
    type="card"
  >
    {tabData.map(({ title, content }) => (
      <TabPane destroyInactiveTabPane={true} tab={title} key={title}>
        {content}
      </TabPane>
    ))}
  </Tabs>
);
