import { createApi } from '@reduxjs/toolkit/query/react';
import { AUTHROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';

/**
 * Saves a new user to the database.
 * @param user - The user object to be saved.
 * @returns Promise<number> - The ID of the newly created user.
 */
export interface User {
  id?: number;
  email: string;
  role: string;
}

/**
 * Represents the structure of a response object returned when interacting with user-related operations.
 * - `user`: Details of the user who is the subject of the operation.
 * - `accessToken`: Access token string for authentication purposes.
 * - `loginUser`: Details of the user who performed the login operation.
 */
export interface UserResponse {
  user: User;
  accessToken: string;
  loginUser: User;
}
/**
 * Represents the structure of a login request object.
 * - `email`: Email address of the user attempting to log in.
 * - `password`: Password of the user attempting to log in.
 */
export interface LoginRequest {
  email: string;
  password: string;
}

/**
 * Represents the structure of a forgot password request object.
 * - `email`: Email address of the user who forgot their password.
 */
export interface ForgotPasswordRequest {
  email: string;
}

/**
 * Interface for requesting a password reset.
 * @interface ResetPasswordRequest
 * @property {string} tokenId - The token ID associated with the password reset request.
 * @property {string} newPassword - The new password to be set.
 * @property {string} confirmNewPassword - Confirmation of the new password to ensure accuracy.
 */
export interface ResetPasswordRequest {
  tokenId: string;
  newPassword: string;
  confirmNewPassword: string;
}
export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface RegisterRequest {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

/**
 * API Service for authentication-related operations.
 * 
 * This service provides methods for:
 * - Logging in a user (`login`)
 * - Sending a password reset email (`forgot`)
 * - Resetting a user's password (`reset`)
 * - Changing a user's password (`changePassword`)
 * - Registering a new user (`register`)
 * 
 * Each method utilizes `createApi` from RTK Query (`@reduxjs/toolkit/query`), 
 * integrating with the specified API routes and handling requests via POST method.
 */
export const authApi = createApi({
  baseQuery,
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: AUTHROUTES.login,
        method: 'POST',
        body: credentials,
      }),
    }),
    forgot: builder.mutation<{ message: string }, ForgotPasswordRequest>({
      query: (credentials) => ({
        url: AUTHROUTES.forgot,
        method: 'POST',
        body: credentials,
      }),
    }),
    reset: builder.mutation<boolean, ResetPasswordRequest>({
      query: (credentials) => ({
        url: AUTHROUTES.reset,
        method: 'POST',
        body: credentials,
      }),
    }),
    changePassword: builder.mutation<boolean, ChangePasswordRequest>({
      query: (credentials) => ({
        url: AUTHROUTES.chnagePassword,
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation<boolean, RegisterRequest>({
      query: (credentials) => ({
        url: AUTHROUTES.register,
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotMutation,
  useResetMutation,
  useChangePasswordMutation,
  useRegisterMutation,
} = authApi;
