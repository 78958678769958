import { Button, Modal, Form, Spin, Select, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { useChnageProfileInfoMutation, useGetAllRolesQuery, useUpdateUserRoleMutation } from 'app/services';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const { Option } = Select;

// Interface definition for component props
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  item: any;
  refetch: () => void;
}

const UpdateUser: React.FC<IProps> = ({
  isModalOpen,
  setIsModalOpen,
  item,
  refetch,
}) => {
  const [form] = Form.useForm();

  // Handlers for modal visibility
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Fetch roles data
  const { data: rolesData, isFetching: isFetchingRoles } = useGetAllRolesQuery();

  // Mutations for updating profile and user role
  const [updateProfile, { isLoading }] = useUpdateUserRoleMutation();
  const [updateUserProfile, { data: isSuccess, isError }] = useChnageProfileInfoMutation();

  // Handle form submission
  const onFinish = async (values: any) => {
    const userRoleInfo = {
      id: item.userRoleId,
      userId: item.key,
      roleId: values.role,
    };
    const userNameInfo = {
      id: item.key,
      firstname: values.firstname,
      lastname: values.lastname,
    };
    await updateProfile(userRoleInfo)
      .unwrap()
      .then(async () => {
        await updateUserProfile(userNameInfo)
          .unwrap()
          .then(() => {
            refetch();
            setIsModalOpen(false);
          });
      });
  };

  // Set form fields when item changes
  useEffect(() => {
    form.setFieldsValue({
      firstname: item?.firstname,
      lastname: item?.lastname,
      email: item?.email,
      role: item.role ? item.role : '',
    });
    // eslint-disable-next-line
  }, [item]);

  // Display notification based on success or error
  useEffect(() => {
    if (isSuccess) {
      notification.success({
        placement: 'topRight',
        message: 'Update Successful',
        description: 'You have successfully updated the profile.',
      });
    } else if (isError) {
      notification.error({
        placement: 'topRight',
        message: 'Oops!',
        description: 'Something went wrong while changing your profile. Please try again later.',
      });
    }
  }, [isError, isSuccess]);

  return (
    <>
      {/* Modal for updating user */}
      <Modal
        title="Update Role"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        style={{ zIndex: 200 }}
      >
        {/* Spinner to show loading state */}
        <Spin
          spinning={isLoading || isFetchingRoles}
          indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />}
        >
          {/* Form for updating user details */}
          <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            id="updateuser"
            className='update-user-form'
          >
            {/* Input for first name */}
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* Input for last name */}
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* Select for user role */}
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Please select role!' }]}
            >
              {!isFetchingRoles && rolesData?.length ?
                <Select
                  placeholder="select your role"
                  dropdownStyle={{ zIndex: 1100 }}
                >
                  {rolesData.map((elem: any) =>
                    <Option key={elem.id} value={elem.id}>
                      {elem.name}
                    </Option>
                  )}
                </Select>
                : null}
            </Form.Item>

            {/* Submit button */}
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large">
                Update Role
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default UpdateUser;
