import { Autocomplete } from '@react-google-maps/api';
import { Input, Space } from 'antd';
import { selectInputValue, setAutocompleteInput } from 'app/services';
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: Fix this

export interface AutocompleteSearchInputProps {
  onLoad?: (autoComplete: google.maps.places.Autocomplete) => void;
  onPlaceChanged?: () => void;
}

export const AutocompleteSearchInput = ({
  onPlaceChanged,
  onLoad,
}: AutocompleteSearchInputProps) => {
  const { Search } = Input;

  // Restrict google autocomplete to country only
  const searchRestrictions = {
    componentRestrictions: { country: ['us'] },
  };

  const inputValue = useSelector(selectInputValue);
  const dispatch = useDispatch()  

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      options={searchRestrictions}
    >
      <Space direction="horizontal">
        <Search
          enterButton={false}
          placeholder="Search address"
          size="large"
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            // setInputValue(e.target.value);
            dispatch(setAutocompleteInput(e.target.value))
          }}
        />
      </Space>
    </Autocomplete>
  );
};
