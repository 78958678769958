import { Button, Drawer, Space } from 'antd';
import { useEffect, useState } from 'react';
import { SelectedResultContent } from './SelectedResultContent';
import { useSelector } from 'react-redux';
import { selectCurrentItem } from 'app/services';
import { SelectedProjectResultContent } from './SelectedProjectResultContent ';
import { ViewQueryContent } from './ViewQueryContent';
import TooltipComponent from 'components/Tooltip';

interface SelectedResultDrawerProps {
  isSelectedResultDrawerOpen: boolean;
  closeSelectedResultDrawer: () => void;
  openViewMoreCompany: () => void;
}

export const SelectedResultDrawer = ({
  isSelectedResultDrawerOpen,
  closeSelectedResultDrawer,
  openViewMoreCompany,
}: SelectedResultDrawerProps) => {
  const selectedItem = useSelector(selectCurrentItem);

  const [showSurveyQueries, setShowSurveyQueries] = useState(false);

  const [isAdmin, setIsAdmin] = useState<any>(false)

  useEffect(() => {
    const userDetails = localStorage.getItem('user')
    if (userDetails && JSON.parse(userDetails)?.role && JSON.parse(userDetails)?.role === 'admin') {
        setIsAdmin(true)
    }
}, [])

  const onClose = () => {
    setShowSurveyQueries(false);
  };

  return (
    <div>
      <Drawer
        className="selected-result-drawer"
        placement="left"
        onClose={closeSelectedResultDrawer}
        title={selectedItem?.project_name_processed ? `View Matches For: ${selectedItem?.project_name_processed}` : selectedItem?.ownershipName ? `View Matches For: ${selectedItem?.ownershipName}` : `View All ${selectedItem?.type}s`}
        // visible
        push={false}
        mask={false}
        width={560}
        getContainer={false}
        style={{ position: 'absolute' }}
        visible={isSelectedResultDrawerOpen}
        destroyOnClose
        extra={
          isAdmin && (
            <Space>
              <TooltipComponent
                placement='top'
                title='View Query'
                body={
                  <Button onClick={() => setShowSurveyQueries(true)} className='view-query-btn'>
                    View Query
                  </Button>
                }
              />
            </Space>
          )
        }
      >
        {selectedItem && (selectedItem?.type === 'Project' || selectedItem?.type === 'Projects') ? (
          <SelectedProjectResultContent
            isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
            closeSelectedResultDrawer={closeSelectedResultDrawer}
            openViewMoreCompany={openViewMoreCompany}
          />
        ) : (
          <SelectedResultContent
            isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
            closeSelectedResultDrawer={closeSelectedResultDrawer}
            openViewMoreCompany={openViewMoreCompany}
          />
        )}
      </Drawer>

      <Drawer
        title={`${selectedItem?.type === 'Parcel' ? 'Parcels' : 'Projects'} Matching Queries`}
        placement="right"
        size="large"
        width={'80%'}
        onClose={onClose}
        open={showSurveyQueries}
        destroyOnClose
      >
        <ViewQueryContent selectedItemType={selectedItem?.type} />
      </Drawer>
    </div>
  );
};
