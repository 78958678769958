import { store } from 'app/store'; // Importing the Redux store from the application's store configuration
import { Navigator } from 'navigator'; // Importing the Navigator component for navigation
import React from 'react';
import { Provider } from 'react-redux'; // Importing the Provider component to connect the Redux store with the React application
import 'antd/dist/antd.less'; // Importing Ant Design styles
import './App.scss'; // Importing custom styles for the application
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios'; // Importing Axios for making HTTP requests

// Setting up an Axios interceptor to include the access token in the request headers
axios.interceptors.request.use(
  async (config: any) => {
    let token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

// Defining the main App component wrapped with the Redux Provider
const App = () => (
  <Provider store={store}>
    <Navigator />
  </Provider>
);

export default App; // Exporting the App component as the default export
