import qs from 'query-string';

const SEPARATOR = '|';
export const formatUrlData = (data: string) =>
  data ? data.split(SEPARATOR) : [];

export const stringifySearchObject = (obj: {
  [key: string]: string[] | string | null;
}) =>
  qs.stringify(obj, {
    arrayFormat: 'separator',
    arrayFormatSeparator: SEPARATOR,
    skipEmptyString: true,
    skipNull: true,
  });

export const parseSearchString = (str: string) =>
  qs.parse(str, {
    arrayFormat: 'separator',
    arrayFormatSeparator: SEPARATOR,
  });

export const handleSingleValue = (value: string | string[] | null) => {
  if (!value) return [];
  if (typeof value === 'string') {
    return value.split('|');
  }
  return value;
};
