/* eslint-disable react-hooks/exhaustive-deps */
// Importing necessary components and icons from Ant Design and local files
import { LeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Drawer, Spin } from 'antd';
import {
  selectActiveTabValue,
  selectDrawerWidthValue,
  setActiveTabValue,
  setCoordsPaginationValue,
  setSelectedItemDetailsOnMap,
} from 'app/services';
import { MatchingResultsTotalText, TabbedContent } from 'components';
import { useState } from 'react';
import { TAB } from 'utils';
import { ActiveTabType } from 'types/common';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelBookmarks } from './ParcelBookmarks';
import { ParcelProjectBookmarks } from './ParcelProjectBookmarks';
import { ProjectBookmarks } from './ProjectBookmarks';

// Defining props for the BookmarksDrawer component
export interface ViewAllDrawerProps {
  isViewAllOpen: boolean;
  closeViewAll: () => void;
}

// BookmarksDrawer component definition
export const BookmarksDrawer = ({
  isViewAllOpen,
  closeViewAll,
}: ViewAllDrawerProps) => {
  const dispatch = useDispatch();
  const drawerWidthValue: any = useSelector(selectDrawerWidthValue)

  // State variables for managing active tab, totals, and loading state
  const activeTab: ActiveTabType = useSelector(selectActiveTabValue);
  const [showingTotal, setShowingTotal] = useState(0);
  const [projectsTotal, setProjectsTotal] = useState(0);
  const [parcelsTotal, setParcelsTotal] = useState(0);
  const [isCountLoading, setIsCountLoading] = useState(false);

  // Rendering the Drawer component with specified configurations
  return (
    <Drawer
      width={drawerWidthValue}
      className="view-all-drawer"
      placement="left"
      onClose={closeViewAll}
      open={isViewAllOpen}
      closeIcon={<LeftOutlined />}
      destroyOnClose
      push={false}
      mask={false}
      style={{ position: 'absolute' }}
      getContainer={false}
      title={`Bookmark view of ${activeTab}`} // Dynamic title based on activeTab
    >
      {/* TabbedContent component displaying tabbed view */}
      <TabbedContent
        centered={false}
        activeKey={activeTab}
        onTabClick={(activeKey) => {
          // Dispatching actions to update state on tab change
          dispatch(setActiveTabValue(activeKey as ActiveTabType));
          dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }));
          dispatch(setSelectedItemDetailsOnMap(null));
        }}
        tabBarExtraContent={
          // Customized content for the tab bar, showing totals and loading state
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MatchingResultsTotalText
              projectsTotal={projectsTotal}
              parcelsTotal={parcelsTotal}
              isCountLoading={isCountLoading}
              total={showingTotal}
              tabTitle={activeTab} // Passing activeTab as a prop
            />
          </div>
        }
        // Mapping tab titles to respective content components
        tabData={TAB.map((title) => ({
          title,
          content: (
            // Spinner component wrapping content based on active tab
            <Spin
              spinning={false} // Adjust spinner visibility based on loading state
              indicator={<Loading3QuartersOutlined style={{ fontSize: 50 }} spin />}
            >
              {/* Conditional rendering based on active tab */}
              {activeTab === 'Parcels' && <ParcelBookmarks closeViewAll={closeViewAll} setShowingTotal={setShowingTotal} setIsCountLoading={setIsCountLoading} setParcelsTotal={setParcelsTotal} />}
              {activeTab === 'Projects' && <ProjectBookmarks closeViewAll={closeViewAll} setShowingTotal={setShowingTotal} setIsCountLoading={setIsCountLoading} setProjectsTotal={setProjectsTotal} />}
              {activeTab === 'Both' && <ParcelProjectBookmarks closeViewAll={closeViewAll} setShowingTotal={setShowingTotal} setProjectsTotal={setProjectsTotal} setParcelsTotal={setParcelsTotal} />}
            </Spin>
          ),
        }))}
      />
    </Drawer>
  );
};
