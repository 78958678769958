import { Checkbox, Divider, Input, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { Fragment, useMemo, useState } from 'react';

interface CheckboxGroupProps {
  headerTitle: string;
  options: string[];
  preSelectedList: string[];
  keyValue: string;
  searchValue?: string;
  optionsFetching?: any
  onInputChange?: (value: any) => void;
  onChange: (value: any, key: string) => void;
}

interface CheckboxGroupListProps {
  data: CheckboxGroupProps[];
  optionsFetching?: any;
}

export const CheckboxGroup = ({
  headerTitle,
  options,
  preSelectedList,
  onChange,
  keyValue,
  searchValue,
  optionsFetching
}: CheckboxGroupProps) => {
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(preSelectedList);

  const isCheckedAll = useMemo(
    () =>
      headerTitle === 'None' ? checkedList.length === options.length &&
        checkedList?.includes('None') &&
        searchValue === '' &&
        options.length > 0 :
        checkedList.length === options.length &&
        searchValue === '' &&
        options.length > 0,
    [checkedList, options, searchValue, headerTitle],
  );

  const [localList, setLocalList] = useState<any>(preSelectedList)

  const onChangeHandler = (list: CheckboxValueType[]) => {
    let listFromOtherOptionSet = localList.filter((elem: any) => !options.includes(elem))
    let allList = [...new Set([...listFromOtherOptionSet, ...list])]
    setLocalList(allList)
    setCheckedList(allList);
    onChange(allList as string[], keyValue);
  };

  const onSelectAllHandler = (e: CheckboxChangeEvent) => {
    const list = e.target.checked ? options : [];
    setCheckedList(list);
    onChange(list, keyValue);
  };

  return (
    <div className="properties-checkbox-group">
      {headerTitle && options[0] && (
        <Checkbox
          onChange={onSelectAllHandler}
          checked={isCheckedAll}
          className="header-title font-normal font-semi-bold"
        >
          {headerTitle}
        </Checkbox>
      )}
      {optionsFetching ?
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
          <Spin tip='Loading' size="large"
            spinning={optionsFetching}
          // indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />}
          /> </div> :
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={onChangeHandler}
          className="column-sort"
          value={checkedList}
        >
          {options.length > 0 &&
            options.map((option: string, index: number) => (
              <Checkbox
                key={index + Math.random() * 1000}
                value={option}
                checked={checkedList.some((item) => item === option)}
                style={{
                  display: option
                    .toLowerCase()
                    .includes(searchValue ? searchValue.toLowerCase() : '')
                    ? 'inline-flex'
                    : 'none',
                  margin: '0',
                }}
              >
                {option}
              </Checkbox>
            ))}
          {
            !optionsFetching && options.length === 0 &&
            <div>
              <div className="ant-empty ant-empty-normal">
                <div className="ant-empty-image">
                  <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g className="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path></g></g></svg>
                </div>
                <div className="ant-empty-description">No data</div>
              </div>
            </div>
          }
        </Checkbox.Group>
      }
    </div>
  );
};

export const SearchCheckboxGroup = (props: CheckboxGroupProps) => {
  const { options, searchValue: PropSearch, ...rest } = props;
  const [searchValue, setSearchValue] = useState(PropSearch || '');

  React.useEffect(() => {
    setSearchValue(PropSearch || '')
  }, [PropSearch])
  

  return (
    // <div className="checkbox-content-wrapper">
    <div className="search-checkbox">
      <div className="input-wrapper">
        <Input
          placeholder="Search ..."
          className="search-checkbox-input"
          value={searchValue}
          onChange={(e) => {
            if ((props.keyValue === 'name' || props.keyValue === 'childName') && props.onInputChange) {
              props.onInputChange(e.target.value)
            }
            setSearchValue(e.target.value)
          }
          }
        />
      </div>
      <div className="search-checkbox-wrapper">
        <CheckboxGroup {...rest} options={options} searchValue={searchValue} />
      </div>
    </div>
  );
};

export const SearchCheckboxGroupList = ({ data, optionsFetching }:
  CheckboxGroupListProps) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredData: CheckboxGroupProps[] = useMemo(() => {
    return data.reduce((accumulator, currentItem) => {
      accumulator.push({
        ...currentItem,
        options: currentItem.options,
      });
      return accumulator;
    }, [] as CheckboxGroupProps[]);
  }, [data]);

  return (
    <div className="search-checkbox">
      <div className="input-wrapper">
        <Input
          placeholder="Search ..."
          className="search-checkbox-input"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="search-checkbox-wrapper">
        {filteredData.map(
          (
            { headerTitle, options, preSelectedList, keyValue, onChange },
            index,
          ) => (
            <Fragment key={index}>
              {index !== 0 && <Divider />}

              <CheckboxGroup
                headerTitle={headerTitle}
                options={options}
                preSelectedList={preSelectedList}
                keyValue={keyValue}
                key={index}
                onChange={onChange}
                searchValue={searchValue}
                optionsFetching={optionsFetching}
              />
            </Fragment>
          ),
        )}
      </div>
    </div>
  );
};
