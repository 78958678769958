import React from 'react'
import { Tooltip } from 'antd';

interface TooltipProps {
    title?: string |any;
    body?: any;
    placement?: string | any;
}

const TooltipComponent = ({ title, body, placement }: TooltipProps) => {
    return (
        <Tooltip title={title} placement={placement} >
            {body}
        </Tooltip>
    )
}

export default TooltipComponent