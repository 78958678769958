import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { LogOut } from 'app/baseQuery';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'antd';
import { setAccessToken } from 'app/services';

interface NavbarProps {
    showCriterias?: boolean;
    onViewCriteriaClick?: any;
}

const Navbar = (props: NavbarProps) => {
    const [current, setCurrent] = useState('user');
    const history = useHistory();
    const dispatch = useDispatch()
    const [message, setMessage] = useState('')
    const [popoverDetails, setPopoverDetails] = useState<any>()
    const [surveyTaker, setSurveyTaker] = useState<any>()
    const [isAdmin, setIsAdmin] = useState<any>(false)

    const onLogout = async () => {
        LogOut();
        dispatch(setAccessToken(null));
        history.push('/login');
    }

    useEffect(() => {
        const userDetails = localStorage.getItem('user')
        if (userDetails && JSON.parse(userDetails)?.role && JSON.parse(userDetails)?.role === 'admin') {
            setIsAdmin(true)
        }
    }, [])

    const items: MenuProps['items'] = [
        {
            label: '',
            key: 'user',
            icon: <Avatar icon={<UserOutlined style={{ fontSize: '18px' }} />} style={{ background: '#99c0ce' }} />,
            children: [
                {
                    label: (
                        <button
                            onClick={() => history.push('/profile')}
                            style={{ backgroundColor: 'transparent', border: 'none', }}
                        >
                            Profile
                        </button>
                    ),
                    key: 'profile'
                },
                {
                    label: (
                        <button
                            onClick={() => onLogout()}
                            style={{ backgroundColor: 'transparent', border: 'none', }}
                        >
                            Logout
                        </button>
                    ),
                    key: 'logout'
                }
            ]
        },

    ];

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key)
    }

    const setMessages = () => {
        const surveyMessage = localStorage.getItem('surveyMessage')
        const surveyTakerEmail = localStorage.getItem('surveyEmail')
        setSurveyTaker(surveyTakerEmail)
        setMessage(surveyMessage || '')
        const surveyInnerDetails = localStorage.getItem('surveyInnerDetails')
        if (surveyInnerDetails) {
            setPopoverDetails(JSON.parse(surveyInnerDetails))
        } else {
            setPopoverDetails(null)
        }
    }

    useEffect(() => {
        setMessages()
        window.addEventListener('storage', () => {
            setMessages()
        })
    }, [])

    const content = (
        <div>
            {popoverDetails?.projectName &&
                <p>Project name - {popoverDetails.projectName}</p>}
            {popoverDetails?.acres &&
                <p>Acres - {popoverDetails.acres}</p>}
            {popoverDetails?.address &&
                <p>Address - {popoverDetails.address}</p>}
            {popoverDetails?.city &&
                <p>City - {popoverDetails.city}</p>}
            {popoverDetails?.createdDate &&
                <p>Created Date - {popoverDetails.createdDate}</p>}
        </div>
    );

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() => props.onViewCriteriaClick()}>
                View survey criterias</Menu.Item>
        </Menu>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid #f0f0f0', }}>
            <div className='navbar-header' style={{ margin: 0 }}>
                {surveyTaker && props.showCriterias && isAdmin ?
                    <Dropdown overlay={menu} placement="bottomLeft">
                        <Button style={{ marginRight: 3 }}>
                            Survey Taker - {surveyTaker}</Button>
                    </Dropdown> :
                    surveyTaker ?
                        <Button style={{ marginRight: 3 }}>
                            Survey Taker - {surveyTaker}</Button> : null
                }
                {popoverDetails ?
                    <Popover content={content} title="More Info">
                        <Button>{message}</Button>
                    </Popover> : <Button>{message}</Button>}
            </div>
            <Menu 
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal" 
                items={items}
                style={{ borderBottom: 0 }}
            />
        </div>
    )
}
export default Navbar