import SiteLayout from 'components/SiteLayout'

export const RegisteredUsersHome = () => {
  return (
    <SiteLayout menuShow={true}>
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ fontWeight: 'bold', fontSize: 20 }}>Please contact admin for further access</h1>
      </div>
    </SiteLayout>
  )
}
