// Importing necessary modules and components
// import { TagFilled } from '@ant-design/icons';
// // import { Button } from 'antd';
// import { CriteriaDropdown } from 'components';
// import React from 'react';
// import { BookmarksList } from './BookmarksList';

// Interface for the SavedSearchesList component props
// interface SavedSearchesListProps {
//   title: string;
//   list: { id: number; text: string }[];
// }

// Interface for the AdvancedFilterHeader component props
interface AdvancedFilterHeaderProps {
  onResetClick: () => void;
}

// TODO: Needs refactor all the component with custom styles
// const SavedSearchesList = ({ title, list }: SavedSearchesListProps) => (
//   <div className="popup-container">
//     <div className="popup-wrapper">
//       <div>{title}</div>
//       {list?.map((item) => (
//         <div key={item.id} className="saved-search-item">
//           <span>{item.text}</span>
//         </div>
//       ))}
//     </div>
//     <div className="popup-footer">
//       <Button className="popup-button" type="primary">
//         Save Search
//       </Button>
//     </div>
//   </div>
// );

export const AdvancedFilterHeader = ({
  onResetClick,
}: AdvancedFilterHeaderProps) => {
  // const savedSearches = [
  //   { id: 1, text: '[Saved Searched Title 1]' },
  //   { id: 2, text: '[Saved Searched Title 2]' },
  //   { id: 3, text: '[Saved Searched Title 3]' },
  // ];

  return (
    // Header section for the advanced filters
    <div className="naxxa-advanced-filters-header">
      <div className="title-wrapper">
        <div className="title font-extra-large font-semi-bold">
          <span>Advanced Filters</span>
        </div>

        {/* <CriteriaDropdown
          precedingIcon={<TagFilled />}
          criteriaTitle="Save Search"
          extraClassName="saved-searches font-normal font-semi-bold"
          content={<BookmarksList />}
        /> */}
      </div>
    </div>
  );
};
