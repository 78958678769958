import { Color } from 'components';
import { MoreInfoPopup } from '../MoreInfoPopup';
import { setItem, useDeleteProjectBookmarkByProjectIdMutation, useCreateProjectBookmarkMutation, setIsSelectedResultDrawerOpenValue, setInfoWindowOpenValue, setInfoWindowPositionValue, setActiveTabValue, setDataTypeValue, useDeleteProjectBookmarkByProjectIdFromBookmarkDrawerMutation, setShowParcelsTypeValue, selectSelectedProjectTypeValue, setProjectsInnerActiveTabValue, setSelectedItemDetailsOnMap } from 'app/services';
import { Badge, Drawer, notification } from 'antd';
import TooltipComponent from 'components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import BookmarkPopover from 'components/BookmarkPopover';
import { ViewShowParcelsQueryContent } from 'views/MapPage/MainFilterDrawer/MatchedResultBox/ViewShowParcelsQueryContent';
import { useEffect, useState } from 'react';

// import { Counties } from './CountyInfo';
/**
 * Props for the ParentProjectCard component, which displays detailed information about a parent project.
 */
export interface ParentProjectCardProps {
  id?: string;
  title: string;
  titleColor: Color;
  data: { name: any; value: any | undefined }[];
  showMore?: boolean;
  allData?: any;
  information?: any;
  parcelId?: string;
  from?: string;
  showBookmark?: boolean;
  id_serial?: any;
  item?: any;
  showParcelsLink?: any;
  closeViewAll?: any;
  formattedParcelId?: string;
  setProjectId?: (value: any) => any;
  onParenChildBadgeClick?: (item: any, type: any) => void;
  onClickResultCard?: () => void;
  setCurrentPage?: (value: any) => any;
  closeDrawer?: () => void;
  onBookMarkIconClick?: (item: any, type: any) => void;
  refetchParcels?: () => void;
  showChildProjectsLink?: any;
}

export const ParentProjectCard = ({
  id,
  title,
  titleColor,
  data,
  information,
  showMore,
  allData,
  showBookmark,
  id_serial,
  item,
  from,
  showParcelsLink,
  closeViewAll,
  formattedParcelId,
  setProjectId,
  onParenChildBadgeClick,
  onClickResultCard,
  setCurrentPage,
  closeDrawer,
  onBookMarkIconClick,
  refetchParcels,
  showChildProjectsLink
}: ParentProjectCardProps) => {
  const dispatch = useDispatch();

  const selectedProjectTypeValue = useSelector(selectSelectedProjectTypeValue)
  const [deleteProjectBookmark] = useDeleteProjectBookmarkByProjectIdMutation();

  const [deleteProjectBookmarkDrawer] = useDeleteProjectBookmarkByProjectIdFromBookmarkDrawerMutation();

  const [createProjectBookmark] = useCreateProjectBookmarkMutation();

  const [showParcelsQuery, setShowParcelsQuery] = useState(false);

  const [isAdmin, setIsAdmin] = useState<any>(false)

  useEffect(() => {
    const userDetails = localStorage.getItem('user')
    if (userDetails && JSON.parse(userDetails)?.role && JSON.parse(userDetails)?.role === 'admin') {
        setIsAdmin(true)
    }
}, [])
  const onClose = () => {
    setShowParcelsQuery(false);
  };


  const deleteBookmark = async (isBookmarkDrawer = false) => {
    let projectId, dataArgs, bookmarkType;

    if (title === 'Project') {
      projectId = id;
      dataArgs = allData?.item?.dataArgs || allData?.dataArgs;
      bookmarkType = allData?.parent_project_id || allData?.item?.parent_project_id ? 'child' : 'parent';
    } else if (title === 'Projects') {
      projectId = allData?.item?.id;
      dataArgs = allData?.item?.dataArgs;
      bookmarkType = allData?.parent_project_id || allData?.item?.parent_project_id || allData?.item?.isChild ? 'child' : 'parent';
    } else {
      return; // Exit the function if the title is neither 'Project' nor 'Projects'
    }

    if (isBookmarkDrawer) {
      await deleteProjectBookmarkDrawer({ id: projectId, dataArgs });
    } else {
      await deleteProjectBookmark({ id: projectId, dataArgs, type: bookmarkType });
    }

    if (onBookMarkIconClick) {
      onBookMarkIconClick(allData, 'remove');
    }

    notification.success({
      placement: 'topRight',
      message: `Bookmark deleted successfully!`,
    });

    if (title === 'Projects' && refetchParcels) {
      refetchParcels();
    }

    if (setCurrentPage) {
      setCurrentPage(1);
    }
  }
  /**
   * Creates a bookmark for a project or projects based on the 'title' parameter.
   * If 'title' is 'Project', creates a bookmark for a single project or child project.
   * If 'title' is 'Projects', creates a bookmark for multiple projects or child projects.
   * Displays success notification upon bookmark creation and optionally refetches parcels.
   */
  const createBookmark = () => {
    let projectId, dataArgs, bookmarkType;

    if (title === 'Project') {
      projectId = allData?.id || allData?.item?.id;
      dataArgs = allData?.dataArgs || allData?.item?.dataArgs;
      bookmarkType = allData?.parent_project_id || allData?.item?.parent_project_id ? 'child' : 'parent';
    } else if (title === 'Projects') {
      projectId = allData?.item?.id;
      dataArgs = allData?.item?.dataArgs;
      bookmarkType = allData?.parent_project_id || allData?.item?.parent_project_id || allData?.item?.isChild ? 'child' : 'parent';
    } else {
      return; // Exit the function if the title is neither 'Project' nor 'Projects'
    }

    createProjectBookmark({ project_id: projectId, dataArgs: dataArgs, type: bookmarkType })
      .finally(() => {
        if (onBookMarkIconClick) {
          onBookMarkIconClick(allData, 'create');
        }
        notification.success({
          placement: 'topRight',
          message: 'Bookmark Added!',
          description: `${title} has been bookmarked successfully!`,
        });
        if (refetchParcels) {
          refetchParcels();
        }
      });
  }
  /**
   * Sets up and triggers actions to show a project or child project on the map.
   * Sets item details in the Redux store, local storage, and triggers a custom event.
   * Optionally triggers the onClickResultCard callback.
   */
  const showProjectonMap = () => {
    dispatch(
      setItem({
        id: allData?.item?.id,
        type: allData?.type,
        parent_project_id: allData?.parent_project_id,
        id_serial: allData?.item?.id_serial
      })
    );
    localStorage.setItem('showingChildProjectOnMap', 'true')
    window.dispatchEvent(new CustomEvent("showingChildProjectOnMap"));
    if (onClickResultCard) {
      onClickResultCard()
    }
  }
  /**
   * Component to render bookmark icons based on specific conditions.
   * Handles adding/removing bookmarks through BookmarkPopover component.
   */
  function BookmarkButton() {
    const existsInBookmarks = allData?.existsInBookmarks || allData?.item?.existsInBookmarks || false;
    const isMatchingProjects = from === 'matching' && title === 'Projects';
    const isViewAllProject = from === 'viewAll' && title === 'Project';

    return (
      <span>
        {(from === 'parent-project' && existsInBookmarks) || from === 'bookmark-drawer' ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={() => deleteBookmark(true)}
            body={
              <img
                title='Remove Bookmark'
                src='bookmark_filled.svg'
                alt='bookmark-filled'
                style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
              />
            }
          />
        ) : (isMatchingProjects || isViewAllProject) && existsInBookmarks ? (

          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={() => deleteBookmark()}
            body={
              <img
                title='Remove Bookmark'
                src='bookmark_filled.svg'
                alt='bookmark-filled'
                style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
              />
            }
          />
        ) : (from === 'parent-project' || isMatchingProjects || ((from === 'viewAll' || from === 'bookmark-drawer') && title === 'Project')) ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Add Bookmark'
            onConfirm={createBookmark}
            body={<img
              title='Add Bookmark'
              src='bookmark.svg'
              alt='bookmark'
              style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
            // onClick={createBookmark}
            />}
          />
        ) : null}
      </span>
    );
  }
  /**
   * Renders detailed metadata for a project or parcel, including title, bookmarks, and additional information.
   * Handles bookmarking functionality and displays links for viewing child projects and different types of parcels.
   */ 
  return (
    <div className="naxxa-meta-detail">
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={`meta-title ${titleColor}`}>{title}
          <BookmarkButton />
        </div>
        <div onClick={(e: any) => {
          if (allData?.type === 'Projects' || allData?.type === 'Project') {
            if (!allData?.item?.geom_center?.coordinates && !allData?.item?.geomCenter?.coordinates) {
              notification.error({
                placement: 'topRight',
                message: 'Oops!',
                description: 'Center coordinates not available for the selected item',
                duration: 10,
              });
              return;
            }
          }

          if (from === 'matching' || from === 'bookmark-drawer') {
            let centerCoords = allData?.item?.geom_center?.coordinates || allData?.item?.geomCenter?.coordinates || allData?.item?.center?.coordinates
            if (!centerCoords) {
              notification.error({
                placement: 'topRight',
                message: 'Oops!',
                description: 'Center coordinates not available for the selected item',
                duration: 10,
              });
              return;
            }
            e.stopPropagation();
            if (allData?.type === 'Projects' || allData?.type === 'Project') {
              if (selectedProjectTypeValue === 'child') {
                dispatch(setProjectsInnerActiveTabValue('childProject'))
              } else {
                dispatch(setProjectsInnerActiveTabValue('parentProject'))
              }
              dispatch(setDataTypeValue('Projects'))
              dispatch(setActiveTabValue('Projects'))
              dispatch(setInfoWindowPositionValue({
                lat: centerCoords[1] || centerCoords[1],
                lng: centerCoords[0] || centerCoords[0],
              }));
              dispatch(setItem({ id: allData?.item?.id, type: allData?.type, id_serial, parent_project_id: allData?.item?.parent_project_id }));
              dispatch(setIsSelectedResultDrawerOpenValue(false))
              if (closeViewAll) {
                closeViewAll()
              }
              setTimeout(() => {
                dispatch(setInfoWindowOpenValue(true))
              }, 2000);
            }
          } else if (from === 'parent-project' || (from === 'viewAll' && title === 'Project')) {
            e.stopPropagation();
            showProjectonMap();
          }
          else {
            if (onClickResultCard) {
              onClickResultCard()
            }
          }
        }}>
          {(from === 'parent-project' || (from === 'matching' && title === 'Projects') || ((from === 'viewAll' || from === 'bookmark-drawer') && title === 'Project')) ?
            <img title='view on map' src='exportoutlined.svg' alt='Export' style={{ cursor: 'pointer', height: 20, width: 15 }} />
            : null}
        </div>
        {showBookmark ?
          <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} onClick={() => deleteBookmark()} />
          : null}
        <>
          {showParcelsLink && (title === 'Project' || title === 'Projects')
            && (information?.parcels?.length || allData?.item?.parcels?.length) && <span className="meta-data-show-parcels"
              onClick={(e) => {
                localStorage.removeItem('projectPolygonCenter')
                localStorage.setItem('showParcelsType', 'all')
                dispatch(setShowParcelsTypeValue('all'))
                if (setProjectId) {
                  e.stopPropagation()
                  if (closeDrawer) {
                    const latLng = {
                      lat: allData?.item?.lat,
                      lng: allData?.item?.lng
                    }
                    localStorage.setItem('projectPolygonCenter', JSON.stringify(latLng))
                    closeDrawer()
                  }
                  setProjectId(allData?.id)
                }
              }}>
              <TooltipComponent
                placement='top'
                title='Show Parcels'
                body={` Show Parcels (${(information?.parcels?.length
                  || allData?.item?.parcels?.length ||
                  allData?.parcels?.length)})`}
              />
            </span>}
          {showParcelsLink && (title === 'Project' || title === 'Projects')
            && allData?.buildableParcels ? <span title='Show Buildable Parcels' className="meta-data-show-parcels"
              onClick={(e) => {
                localStorage.removeItem('projectPolygonCenter')
                localStorage.setItem('showParcelsType', 'buildableParcels')
                dispatch(setShowParcelsTypeValue('buildableParcels'))
                if (setProjectId) {
                  setProjectId(null)
                  e.stopPropagation()
                  if (closeDrawer) {
                    const latLng = {
                      lat: allData?.item?.lat,
                      lng: allData?.item?.lng
                    }
                    localStorage.setItem('projectPolygonCenter', JSON.stringify(latLng))
                    closeDrawer()
                  }
                  setProjectId(allData?.id)
                }
              }}>
            <img title='Buildable' src='blue-circle-icon.svg' alt='buildable' style={{ cursor: 'pointer', marginRight: 2, height: 13, width: 13 }} />({allData?.buildableParcels})
          </span> : null}
          {showParcelsLink && (title === 'Project' || title === 'Projects')
            && allData?.alreadyBuildedParcels ? <span title='Show Already Built Parcels' className="meta-data-show-parcels"
              onClick={(e) => {
                localStorage.removeItem('projectPolygonCenter')
                localStorage.setItem('showParcelsType', 'alreadyBuildedParcels')
                dispatch(setShowParcelsTypeValue('alreadyBuildedParcels'))
                if (setProjectId) {
                  setProjectId(null)
                  e.stopPropagation()
                  if (closeDrawer) {
                    const latLng = {
                      lat: allData?.item?.lat,
                      lng: allData?.item?.lng
                    }
                    localStorage.setItem('projectPolygonCenter', JSON.stringify(latLng))
                    closeDrawer()
                  }
                  setProjectId(allData?.id)
                }
              }}>
            <img title='Already Built' src='red-circle-icon.svg' alt='Already Built' style={{ cursor: 'pointer', marginRight: 2, height: 13, width: 13 }} />({allData?.alreadyBuildedParcels})
          </span> : null}
        </>
        {showBookmark ?
          <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} onClick={() => deleteBookmark()} />

          // <TagFilled title='Remove Bookmark' style={{ cursor: 'pointer', zIndex: 999999 }} onClick={deleteBookmark() => }/>()
          : null}
      </div>
      {data &&
        data
          .filter(
            (src: { name: string; value: any }) =>
              src.name !== 'County',
          )
          .map(({ name, value }, idx) => {
            if (['Developer', 'Builder', 'Investor', 'Real Estate Agent'].includes(title) && name === 'City') {
              return null
            }
            if (['Projects'].includes(title) && name === 'Acreage') {
              return null
            }
            return (
              <div className="meta-detail" key={idx}>
                {name === 'View Child Projects' && showChildProjectsLink ?
                  <span style={{ zIndex: 999999 }} onClick={(e) => {
                    e.stopPropagation()
                    dispatch(setSelectedItemDetailsOnMap(null))
                    dispatch(setItem({
                      id: item?.item?.id
                    }))
                    if (onParenChildBadgeClick) {
                      onParenChildBadgeClick(item, 'parent')
                    }
                    dispatch(setInfoWindowOpenValue(false))
                  }}>
                    <Badge style={{
                      backgroundColor: '#1677ff',
                      lineHeight: '20px'
                    }} count={name} /> </span> :
                  null}
                {name === ('View Parent Projects') && showChildProjectsLink ?
                  <span style={{ zIndex: 999999 }} onClick={(e) => {
                    e.stopPropagation()
                    if (onParenChildBadgeClick) {
                      onParenChildBadgeClick(item, 'child')
                    }
                  }} >
                    <Badge style={{
                      backgroundColor: '#1677ff',
                      lineHeight: '20px'
                    }} count={name} /> </span> : (name !== 'View Child Projects' && name !== 'View Parent Projects') ?
                    <span className="meta-detail-name">{name}: </span> : null
                }
                <span className='meta-detail-value-div'>
                  <span className="meta-detail-value"> {name === 'Email' && Array.isArray(value) ?
                    value.map((elem: any, i: any) => {
                      return i > 0 ? <>, <a key={i} href={'mailto:' + elem}>{elem}</a></> : <a key={i} href={'mailto:' + elem}>{elem}</a>
                    }) : name === 'Email' && value ? <a href={'mailto:' + value}>{value}</a> : value}
                  </span>
                  {name === 'Name' && showParcelsLink && isAdmin ?
                    <span className='meta-data-view-query' onClick={() => {
                      setShowParcelsQuery(true)
                    }}>View Query</span> : null
                  }
                </span>
              </div>
            )
          })}
      {showMore &&
        (title === 'Project') &&
        <div className="popup-more-info">
          <MoreInfoPopup title={title} information={information}
            allData={allData} id_serial={id_serial} />
        </div>
      }

      <Drawer
        title={`Show Parcels query`}
        placement="right"
        size="large"
        width={'80%'}
        onClose={onClose}
        open={showParcelsQuery}
        destroyOnClose
        style={{ zIndex: 2000 }}
      >
        <ViewShowParcelsQueryContent count={{ buildableParcels: allData?.buildableParcels || 0, alreadyBuildedParcels: allData?.alreadyBuildedParcels || 0 }} />
      </Drawer>


    </div>
  );
};
