import React from 'react';
// import ivory from 'styles/images/ivory.png';
import { Divider, Rate } from 'antd';
import { FacebookOutlined, LinkedinOutlined } from '@ant-design/icons';
import { Company } from 'types/company';

export const CompanyCard = ({
  data,
  index,
  onSeeMoreClick,
}: {
  data: Company;
  index: number;
  onSeeMoreClick: () => void;
}) => {
  // const { website, totalCityProjects,totalProjects, totalCityParcels } = data;
  const { name, facebookUrl, linkedinUrl, website } = data;
  const [totalCityProjects, totalProjects, totalCityParcels] = [16, 23, 34];
  return (
    <div className="naxxa-company-card">
      <h3 className="company-title">
        {index + 1}. {name}
      </h3>
      <div className="company-main-info">
        {/* <img className="company-image" src={ivory} alt="" /> */}
        {website !== 'None' && (
          <p>
            <a href={website} target="_blank" rel="noreferrer">
              website
            </a>
          </p>
        )}
        <div className="company-rating">
          <p>Google Reviews</p>
          <Rate disabled allowHalf defaultValue={3} />
        </div>
        <div className="company-social-media">
          {facebookUrl !== 'None' && (
            <FacebookOutlined onClick={() => window.open(facebookUrl)} />
          )}
          {linkedinUrl !== 'None' && (
            <LinkedinOutlined onClick={() => window.open(linkedinUrl)} />
          )}
        </div>
      </div>
      <Divider />

      <div className="company-secondary-info">
        <p>
          Matched Project in “City”: <b>{totalCityProjects}</b>
        </p>
        <p>
          Matched Projects: <b>{totalProjects}</b>
        </p>
        <p>
          Matched Parcels: <b>{totalCityParcels}</b>
        </p>
        <div className="company-see-more" onClick={onSeeMoreClick}>
          <span>See more ...</span>
        </div>
      </div>
    </div>
  );
};
