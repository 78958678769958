export const joinHypotheticals = (
  array1: any,
  array2: any,
  array3: any,
  array4: any,
) => {
  const array = [];
  if (array1?.length) {
    array.push(...array1);
  }
  if (array2?.length) {
    array.push(...array2);
  }
  if (array3?.length) {
    array.push(...array3);
  }
  if (array4?.length) {
    array.push(...array4);
  }
  return [...new Set(array)].join(', ');
};
