import { Spin, Button, Empty, Badge } from "antd";
import { useGetAllSingleParcelMatchedDataQuery } from "app/services";
import { ParcelCard } from "components/MetaData/ParcelCard";
import TooltipComponent from "components/Tooltip";
import qs from "query-string";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ParcelPopoverProps {
  information?: any;
  allData?: any;
  onViewAllClick: () => void;
}

export const ParcelPopover = ({
  information,
  allData,
  onViewAllClick
}: ParcelPopoverProps) => {
  const { search } = useLocation();
  const [parcelsMatchingData, setParcelsMatchingData] = useState<any>()
  const [parcelsMatchesLoading, setParcelsMatchesLoading] = useState(true)

  const { data: parcelToParcelMatches, isSuccess: isParcelToParcelSuccess,
    isFetching: isFetchingParcelToParcel,
  } =
    /**
     * Fetches data related to single parcel matched data,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'parcels',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToBuilderMatches, isSuccess: isParcelToBuilderSuccess,
    isFetching: isFetchingParcelToBuilder,
  } =
    /**
     * Fetches data related to single parcel matched data for builders,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'builders',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToInvestorMatches, isSuccess: isParcelToInvestorSuccess,
    isFetching: isFetchingParcelToInvestor,
  } =
    /**
     * Fetches data related to single parcel matched data for investors,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'investors',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToDeveloperMatches, isSuccess: isParcelToDeveloperSuccess,
    isFetching: isFetchingParcelToDeveloper,
  } =
    /**
     * Fetches data related to single parcel matched data for developers,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'developers',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToRealEstateAgentMatches,
    isSuccess: isParcelToRealEstateAgentSuccess,
    isFetching: isFetchingParcelToRealEstateAgent,
  } =
    /**
     * Fetches data related to single parcel matched data for real estate agents,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'realEstateAgents',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToNewBuildHomebuyerMatches,
    isSuccess: isParcelToNewBuildHomebuyerSuccess,
    isFetching: isFetchingParcelToNewBuildHomebuyer,
  } =
    /**
     * Fetches data related to single parcel matched data for new build homebuyers,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'newBuildHomebuyers',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToWaterRightsMatches,
    isSuccess: isParcelToWaterRightsSuccess,
    isFetching: isFetchingParcelToWaterRights,
  } =
    /**
     * Fetches data related to single parcel matched data for water rights,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'waterRights',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  const { data: parcelToProjectMatches,
    isSuccess: isParcelToProjectSuccess,
    isFetching: isFetchingParcelToProject,
  } =
    /**
     * Fetches data related to single parcel matched data for projects,
     * based on the provided ID serial and optional hypothetical conditions.
     * Conditionally skips fetching if ID serial is not available or if the information is not for a parcel.
     */
    useGetAllSingleParcelMatchedDataQuery(
      {
        id: information?.id_serial || '',
        matchingType: 'projects',
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '' || '',
      },
      {
        refetchOnReconnect: true,
        skip: !information?.id_serial || information?.title !== 'Parcel'
      },
    );

  /**
   * Manages loading states and updates parcel matching data based on fetching statuses.
   * Conditionally sets loading state based on whether any parcel match type is currently fetching.
   * Updates `parcelsMatchingData` with fetched data for each parcel match type when fetching completes.
   */
  useEffect(() => {
    if(isFetchingParcelToParcel ||
      isFetchingParcelToBuilder ||
      isFetchingParcelToInvestor ||
      isFetchingParcelToDeveloper ||
      isFetchingParcelToRealEstateAgent ||
      isFetchingParcelToNewBuildHomebuyer ||
      isFetchingParcelToWaterRights ||
      isFetchingParcelToProject) {
        setParcelsMatchesLoading(true)
      } else {
        setParcelsMatchesLoading(false)
      }
    const data = { ...parcelsMatchingData }
    if (!isFetchingParcelToParcel && parcelToParcelMatches?.Parcels) {
      data.Parcels = parcelToParcelMatches?.Parcels
    } else {
      delete data.Parcels
    }
    if (!isFetchingParcelToBuilder && parcelToBuilderMatches?.Builders) {
      data.Builders = parcelToBuilderMatches?.Builders
    } else {
      delete data.Builders
    }
    if (!isFetchingParcelToInvestor && parcelToInvestorMatches?.Investors) {
      data.Investors = parcelToInvestorMatches?.Investors
    } else {
      delete data.Investors
    }
    if (!isFetchingParcelToDeveloper && parcelToDeveloperMatches?.Developers) {
      data.Developers = parcelToDeveloperMatches?.Developers
    } else {
      delete data.Developers
    }
    if (!isFetchingParcelToRealEstateAgent && parcelToRealEstateAgentMatches?.['Real Estate Agents']) {
      data['Real Estate Agents'] = parcelToRealEstateAgentMatches['Real Estate Agents']
    } else {
      delete data['Real Estate Agents'];
    }
    if (!isFetchingParcelToNewBuildHomebuyer && parcelToNewBuildHomebuyerMatches?.['New Build Homebuyers']) {
      data['New Build Homebuyers'] = parcelToNewBuildHomebuyerMatches['New Build Homebuyers']
    } else {
      delete data['New Build Homebuyers'];
    }
    if (!isFetchingParcelToWaterRights && parcelToWaterRightsMatches?.['Water Rights']) {
      data['Water Rights'] = parcelToWaterRightsMatches['Water Rights']
    } else {
      delete data['Water Rights']
    }
    if (!isFetchingParcelToProject && parcelToProjectMatches?.Projects) {
      data.Projects = parcelToProjectMatches.Projects
    } else {
      delete data.Projects
    }
    setParcelsMatchingData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingParcelToParcel,
    isFetchingParcelToBuilder,
    isFetchingParcelToInvestor,
    isFetchingParcelToDeveloper,
    isFetchingParcelToRealEstateAgent,
    isFetchingParcelToNewBuildHomebuyer,
    isFetchingParcelToWaterRights,
    isFetchingParcelToProject
  ])
  /**
   * Manages loading state based on the success statuses of parcel matching queries.
   * Sets `parcelsMatchesLoading` to false when all parcel match queries have successfully completed.
   */
  useEffect(() => {
    if (!isFetchingParcelToParcel && isParcelToParcelSuccess &&
      !isFetchingParcelToBuilder && isParcelToBuilderSuccess &&
      !isFetchingParcelToInvestor && isParcelToInvestorSuccess &&
      !isFetchingParcelToDeveloper && isParcelToDeveloperSuccess &&
      !isFetchingParcelToRealEstateAgent && isParcelToRealEstateAgentSuccess &&
      !isFetchingParcelToNewBuildHomebuyer && isParcelToNewBuildHomebuyerSuccess
      && !isFetchingParcelToWaterRights && isParcelToWaterRightsSuccess
      && !isFetchingParcelToProject && isParcelToProjectSuccess
    ) {
      setParcelsMatchesLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParcelToParcelSuccess,
    isParcelToBuilderSuccess,
    isParcelToInvestorSuccess,
    isParcelToDeveloperSuccess,
    isParcelToRealEstateAgentSuccess,
    isParcelToNewBuildHomebuyerSuccess,
    isParcelToWaterRightsSuccess,
    isParcelToProjectSuccess
  ])

  /**
   * Renders a popover displaying parcel information and its matching data.
   * Manages loading indicators and actions based on parcel matching data availability.
   */
  return (
    <div>
      <div className="matched-item-popover-main font-small">
        {information && (
          <ParcelCard
            title={information?.title}
            titleColor={information?.titleColor}
            data={information?.data}
            information={information}
            allData={allData}
            showMore={true}
            id_serial={information?.id_serial}
          />
        )}
      </div>

      {information?.title === 'Parcel' ?
        parcelsMatchingData &&
          Object.keys(parcelsMatchingData).length ? (
          <>
            <div className="matched-item-popover-list font-small">
              <ul>
                {Object.entries(parcelsMatchingData).map(([key, value]) => (
                  <li key={key}>
                    <p>{key}</p>
                    {/* <p>{value}</p> */}
                    <Badge count={value} color='#1C7A92' overflowCount={100000} />
                  </li>
                ))}
              </ul>
            </div>
            {parcelsMatchesLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
              <Spin
                spinning={parcelsMatchesLoading}
                tip='Loading' size="large"
              ></Spin>
            </div> : null}
            <div className="matched-item-popover-btn font-normal">
              {/* <Button onClick={onViewAllClick} type="primary" disabled={parcelsMatchesLoading}>
                View Matches
              </Button> */}
               <TooltipComponent
                placement='top'
                title='View Matches'
                body={<Button onClick={onViewAllClick} type="primary" disabled={parcelsMatchesLoading}>
                  View Matches
                </Button>}
              />
            </div>
          </>
        ) : !parcelsMatchesLoading ?
          <Empty
            className="matched-item-popover-no-results"
            description="No Matched Results"
          />
          : null : null}

      {( parcelsMatchesLoading && (parcelsMatchingData && !Object.keys(parcelsMatchingData).length)) 
        ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <Spin
          spinning={parcelsMatchesLoading}
          tip='Loading' size="large"
        ></Spin>
      </div> : null}
    </div>
  )
}
