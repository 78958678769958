import { resetSurveyFromLocalStorage } from './resetSurveyFromStorage';
import { useDispatch } from 'react-redux';
import {
  setCoordsPaginationValue,
  setInfoWindowOpenValue,
  setItem,
  setParcelIdValue,
  setProjectIdValue,
  setSelectedItemDetailsOnMap,
  setSelectedProjectTypeValue,
  setShowParcelsTypeValue,
  setShowProjectsTypeValue,
} from 'app/services';
import { useHistory, useLocation } from 'react-router-dom';

export const useResetFilters = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const resetFilters = () => {
    resetSurveyFromLocalStorage();
    window.dispatchEvent(new Event('resetSearch'));

    localStorage.removeItem('parentProjectsData');
    window.dispatchEvent(new Event('viewChildProject'));

    localStorage.removeItem('showingChildProjectOnMap');
    window.dispatchEvent(new Event('showingChildProjectOnMap'));

    localStorage.removeItem('showParcelsType');

    dispatch(setShowProjectsTypeValue(null));
    dispatch(setProjectIdValue(''));

    dispatch(setShowParcelsTypeValue(null));
    dispatch(setParcelIdValue(''));

    dispatch(setSelectedProjectTypeValue(null));
    dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }));

    dispatch(setSelectedItemDetailsOnMap(null));

    dispatch(setItem({}));

    dispatch(setInfoWindowOpenValue(false));
    replace({
      pathname,
      search: '',
    });
  };

  const resetFiltersForViewOnMap = () => {
    localStorage.removeItem('parentProjectsData');
    window.dispatchEvent(new Event('viewChildProject'));

    localStorage.removeItem('showingChildProjectOnMap');
    window.dispatchEvent(new Event('showingChildProjectOnMap'));

    localStorage.removeItem('showParcelsType');

    dispatch(setShowProjectsTypeValue(null));
    dispatch(setProjectIdValue(''));

    dispatch(setShowParcelsTypeValue(null));
    dispatch(setParcelIdValue(''));

    dispatch(setSelectedProjectTypeValue(null));
    dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }));

    dispatch(setSelectedItemDetailsOnMap(null));
  };

  return { resetFilters, resetFiltersForViewOnMap };
};
