import { Badge, Button, Col, Drawer, Row, Space, Spin, Pagination, Empty } from 'antd';
import { setCoordsPaginationValue, setSelectedItemDetailsOnMap, setZoomValue, useGetParentProjectsQuery } from 'app/services';
import React, { useEffect, useMemo, useState } from 'react';
import { ProjectInterface } from 'types/projects';
import { ResultCard } from '../ResultCard';
import { createMetaData } from 'utils/metaDataHelpers';
import { Color } from '../types';
import TooltipComponent from 'components/Tooltip';
import { useDispatch } from 'react-redux';

export const ViewChildProjectsPopup = ({
   isOpen,
   closeDrawer,
   data
}: any) => {
   const dispatch = useDispatch()
   const [page, setPage] = useState(1)
   const [limit, setLimit] = useState(20)

   const [showingItems, setShowingItems] = useState<any>([]);
   const [isLoading, setIsLoading] = useState(false)
   const [totalItems, setTotalItems] = useState<number>(0)


   let { data: parentProjects, isFetching: isParentProjectsFetching,
      isSuccess: isParentProjectsSuccess } =
      useGetParentProjectsQuery({ id: data?.item?.parent_project_id, page, take: limit }, {
         skip: !data?.item?.parent_project_id
      });
   /**
    * Memoizes a transformed list of parent projects.
    * This list includes each project item with a type 'Project'.
    * Logs the parentProjects data to the console.
    */
   const listData = useMemo(() => {
      console.log(parentProjects);
      return parentProjects?.data.map((item: any) => ({ item, type: 'Project' }));
   }, [parentProjects]);
   /**
    * Effect to handle loading state and total items based on parent project data.
    * Sets loading state based on whether parent projects are fetching.
    * Updates total items when parent projects data or fetching status changes.
    */
   useEffect(() => {
      setIsLoading(isParentProjectsFetching)
      if (!isParentProjectsFetching) {
         setTotalItems(parentProjects?.pagination?.totalItems || 0)
      }
   }, [parentProjects?.pagination?.totalItems, isParentProjectsFetching])

   /**
    * Effect to update showing items when parent projects data fetching and success conditions are met.
    * Updates showing items based on listData when parent projects fetching completes successfully.
    * This effect runs when isParentProjectsFetching or isParentProjectsSuccess or listData changes.
    */
   useEffect(() => {
      if ((!isParentProjectsFetching) &&
         (isParentProjectsSuccess)) {
         const allShowingItems = [...new Set([...(listData || [])])];
         setShowingItems(allShowingItems)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      isParentProjectsFetching,
      isParentProjectsSuccess,
      listData
   ]);

   /**
    * Functional component representing a grid of results.
    * Renders ResultCard components based on the provided dataSource.
    * Supports bookmark icon click handling and map view redirection.
    */
   const ResultsGrid = ({
      dataSource,
      closeViewAll,
   }: {
      dataSource:
      | { type: string; item: ProjectInterface }[]
      | undefined;
      closeViewAll: () => void;
   }) => {
      /**
       * Handles the click event on a bookmark icon for a specific item.
       * Updates the state of `showingItems` to reflect changes in bookmark status.
       * 
       * @param item - The item object on which the bookmark icon was clicked.
       * @param type - The type of action to perform ('add' or 'remove') on the bookmark.
       */
      const onBookMarkIconClick = (item: any, type: any) => {
         let prevItems = JSON.parse(JSON.stringify(showingItems))
         let newShowingItems = prevItems.map((prevItem: any) => {
            if (item.id === prevItem?.item?.id) {
               prevItem.item.existsInBookmarks = type === 'remove' ? false : true
            }
            if (item.id === prevItem?.id) {
               prevItem.existsInBookmarks = type === 'remove' ? false : true
            }
            return prevItem
         });
         setShowingItems(newShowingItems)
      }

      return (
         <>
            <Row
               style={{ marginBottom: 30 }}
               className="result-content"
               gutter={[
                  { xs: 6, sm: 12, md: 16, lg: 24 },
                  { xs: 6, sm: 12, md: 16, lg: 24 },
               ]}
            >
               {dataSource &&
                  dataSource.map((result, i) => (
                     <Col key={i}>
                        {/* <Skeleton className="grid-skeleton" height={160} count={1} /> */}
                        <ResultCard
                           onBookMarkIconClick={onBookMarkIconClick}
                           from="child-project"
                           title={result?.type}
                           titleColor={
                              result?.type === 'Parcel' ? Color.navy : Color.green
                           }
                           data={createMetaData(result)}
                           allData={result.item}
                           closeViewAll={closeViewAll}
                           id={result.item?.id}
                           parcelId={result.item?.parcelId}
                           id_serial={result.item?.id_serial}
                           formattedParcelId={result.item?.formattedParcelId}
                        // selectedChildProjectId={selectedChildProjectId}
                        />
                     </Col>
                  ))}
            </Row>
         </>
      );
   };
   console.log('showingItems', showingItems)
   /**
    * Handles click events on the bookmark icon within ResultCard components.
    * Updates showingItems state based on bookmark toggle.
    * @param item - The project item associated with the bookmark icon.
    * @param type - The type of action ('add' or 'remove') to perform on the bookmark.
    */
   const viewOnMapHandler = (e: any) => {
      if (e) {
         e.stopPropagation()
      }
      if (data?.item) {
         localStorage.setItem('childProjectsData', JSON.stringify(data.item))
         window.dispatchEvent(new Event("viewParentProject"));
         dispatch(setCoordsPaginationValue({ page, limit }))
         dispatch(setZoomValue(6))
         dispatch(setSelectedItemDetailsOnMap({ viewType: 'parentOrChildProjects', selectedType: `child project`, id: data?.item?.id, matchingType: `Parent projects`, name: data?.item?.project_name_processed, totalCount: totalItems }))
      }
      // closeDrawer()
   }

   return (
      <Drawer
         open={isOpen}
         placement="left"
         width={1050}
         push={false}
         mask={false}
         // getContainer={false}
         style={{ position: 'absolute' }}
         // title={`Parent Project of ${data?.item?.project_name_processed}`}
         title={
            <>
               Parent Project of {data?.item?.project_name_processed}{' '}
               <Badge showZero count={isLoading ? 'Loading...' : totalItems} color='#1C7A92' overflowCount={100000} />
            </>
         }
         onClose={closeDrawer}
         extra={
            showingItems?.length ?
               <Space>
                  <TooltipComponent
                     title='View all on map'
                     placement='top'
                     body={<Button className='title-btn' type="primary" onClick={viewOnMapHandler}>
                        View all on map
                     </Button>}
                  />
               </Space> : null
         }
      >
         {!isLoading && !showingItems?.length ?
            <Empty className='center-empty' image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            <Spin tip='Loading' size="large" style={{ marginTop: 10 }}
               spinning={isParentProjectsFetching}

            >
               <ResultsGrid
                  dataSource={showingItems}
                  closeViewAll={closeDrawer}
               />
               <div className="pagination-container" style={{ left: "unset", width: 1000 }}>
                  <Pagination
                     current={page}
                     total={totalItems}
                     pageSize={limit}
                     showTotal={(total, range) => isParentProjectsFetching ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
                     onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
                     onChange={(pg) => setPage(pg)} />
               </div>
            </Spin>
         }
      </Drawer>
   );
};