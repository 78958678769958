/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Tabs, TabsProps, Pagination, Button } from 'antd';
import {
  selectCoordsPaginationValue,
  selectDrawerWidthValue,
  selectProjectsInnerActiveTabValue,
  setCoordsPaginationValue,
  setDrawerWidthValue,
  setProjectsInnerActiveTabValue,
  setSelectedItemDetailsOnMap,
  setShowParcelsTypeValue,
  setShowProjectsTypeValue,
  setZoomValue,
  useGetAllProjectBookmarksQuery,
} from 'app/services';
import { Color, ResultCard } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { ProjectInterface } from 'types/projects';
import { createMetaData } from 'utils/metaDataHelpers';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

// Props interface for ProjectBookmarksProps component
export interface ProjectBookmarksProps {
  setProjectsTotal: (total: number) => void;
  setShowingTotal: (total: number) => void;
  setIsCountLoading: (val: boolean) => void;
  closeViewAll: () => void;
}

// ResultsGrid component renders a grid of ResultCard components for projects
const ResultsGrid = ({
  dataSource,
  closeViewAll,
  setCurrentPage
}: {
  dataSource:
  | { type: string; item: ProjectInterface }[]
  | undefined;
  closeViewAll: () => void;
  setCurrentPage?: (val: any) => any;
}) => {
  return (
    <>
      {/* Grid layout for displaying project bookmarks */}
      <Row
        style={{ marginBottom: 30 }}
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={i}>
              {/* Display a ResultCard for each project */}
              {/* <Skeleton className="grid-skeleton" height={160} count={1} /> */}
              <ResultCard
                title={result?.type}
                allData={result}
                titleColor={Color.green}
                from='bookmark-drawer'
                setCurrentPage={setCurrentPage}
                data={createMetaData(result)}
                closeViewAll={closeViewAll}
                id={result.item?.id}
                parcelId={result.item?.parcelId || result.item?.id_serial}
                idSerial={result.item?.id_serial}
                id_serial={result.item?.id_serial}
                formattedParcelId={result.item?.formattedParcelId}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

// ProjectBookmarks component renders project bookmarks with tabs and pagination
export const ProjectBookmarks = ({
  setProjectsTotal,
  setShowingTotal,
  closeViewAll,
  setIsCountLoading
}: ProjectBookmarksProps) => {
  const [currentPage, setCurrentPage] = useState(1); // State for current page number
  const [limit, setLimit] = useState(20); // State for pagination limit
  const { TabPane } = Tabs;
  const [parentProjectsCount, setParentProjectsCount] = useState(''); // State for parent projects count
  const [childProjectsCount, setChildProjectsCount] = useState(''); // State for child projects count
  const drawerWidthValue: any = useSelector(selectDrawerWidthValue)

  // Selecting inner active tab from Redux state
  const innerActiveTab: any = useSelector(selectProjectsInnerActiveTabValue);

  // Selecting coordinates pagination from Redux state and setting current page accordingly
  const coordsPagination: any = useSelector(selectCoordsPaginationValue);
  useEffect(() => {
    setCurrentPage(coordsPagination?.page);
  }, [coordsPagination]);

  // Effect to reset currentPage when limit changes
  useEffect(() => {
    setCurrentPage(1);
  }, [limit]);

  // Dispatching coords pagination values when currentPage changes
  useEffect(() => {
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Memoized query search string for fetching project bookmarks based on innerActiveTab
  const projectPaginatedQuerySearch = useMemo(
    () => `?page=${currentPage}&take=${limit}&isChild=${innerActiveTab === 'childProject'}`,
    [limit, currentPage, innerActiveTab],
  );

  // State for holding displayed project items
  const [showingItems, setShowingItems] = useState<
    { type: string; item: ProjectInterface }[]
  >([]);

  // Fetching project bookmarks data using custom query hook
  const {
    data: projectsData,
    isFetching: isFetchingProjects,
    isSuccess: isSuccessProjects,
  } = useGetAllProjectBookmarksQuery(projectPaginatedQuerySearch, {
    refetchOnReconnect: true,
  });

  // useEffect(() => {
  //   const totalProjects = parseInt(parentProjectsCount || '0') + parseInt(childProjectsCount || '0');
  //   setShowingTotal(totalProjects);
  //   setProjectsTotal(totalProjects);
  // }, [parentProjectsCount, childProjectsCount])

  useEffect(() => {
    if (typeof projectsData?.pagination?.totalItems !== 'undefined') {
      if (innerActiveTab === 'parentProject') {
        setParentProjectsCount(projectsData?.pagination?.totalItems);
      } else {
        setChildProjectsCount(projectsData?.pagination?.totalItems);
      }
    }
    setShowingTotal(projectsData?.totalCount);
    setProjectsTotal(projectsData?.totalCount);
  }, [projectsData, innerActiveTab]);

  // Memoized listData of projects for rendering ResultsGrid
  const listData = useMemo(() => {
    return projectsData?.data.map((item: any) => ({ item, type: 'Project' }));
  }, [projectsData]);

  // Total items count based on innerActiveTab for pagination
  const totalItems: any = useMemo(() => {
    setCurrentPage(1);
    return innerActiveTab === 'parentProject' ? parentProjectsCount : childProjectsCount;
  }, [innerActiveTab, parentProjectsCount, childProjectsCount]);

  // Effect to update showingItems and setIsCountLoading when projects data fetching completes
  useEffect(() => {
    if (!isFetchingProjects && isSuccessProjects) {
      // setShowingItems((pv) => [...new Set([...pv, ...(listData || [])])]);
      let allShowingItems
      allShowingItems = [...new Set([...(listData || [])])];
      setShowingItems(allShowingItems)
    }
    setIsCountLoading(isFetchingProjects);
  }, [
    isFetchingProjects,
    isSuccessProjects,
    listData
  ]);

  // Functional component for custom tab rendering
  const Tab = ({ property }: TabsProps) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
    </div>
  );

  // Redux dispatch function
  const dispatch = useDispatch();

  // Handler function for viewing all items on map
  const viewAllOnMapHandler = () => {
    dispatch(setDrawerWidthValue(680))
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }));
    dispatch(setZoomValue(6));
    dispatch(setShowParcelsTypeValue(null));
    dispatch(setShowProjectsTypeValue('bookmarks'));
    if (innerActiveTab === 'parentProject') {
      dispatch(setSelectedItemDetailsOnMap({ viewType: 'Bookmarks', selectedType: 'parent projects', totalCount: parentProjectsCount }));
    } else {
      dispatch(setSelectedItemDetailsOnMap({ viewType: 'Bookmarks', selectedType: 'child projects', totalCount: childProjectsCount }));
    }
    // closeViewAll()
  };

  return (
    <div>
      {/* Tabs component for switching between parent and child projects */}
      <div className=''>
        <Tabs type='card' activeKey={innerActiveTab} onChange={(e) => {
          dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }));
          dispatch(setProjectsInnerActiveTabValue(e));
          dispatch(setSelectedItemDetailsOnMap(null));
        }}>
          {/* TabPane for parent projects */}
          <TabPane
            key="parentProject"
            tab={
              <Tab
                property={isFetchingProjects && innerActiveTab === 'parentProject' ? `Parent Projects (Loading...)` : parentProjectsCount ? `Parent Projects (${parentProjectsCount})` : `Parent Projects`}
              />
            }
          >
          </TabPane>
          {/* TabPane for child projects */}
          <TabPane
            key="childProject"
            tab={
              <Tab
                property={isFetchingProjects && innerActiveTab === 'childProject' ? `Child Projects (Loading...)` : childProjectsCount ? `Child Projects (${childProjectsCount})` : `Child Projects`}
              />
            }
          >
          </TabPane>
        </Tabs>
      </div>
      {/* Conditional rendering of skeleton loading indicators */}
      {isFetchingProjects ? (
        <>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {/* Placeholder for loading skeleton */}
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          {/* Another placeholder for loading skeleton */}
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {/* Placeholder for loading skeleton */}
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) :
        <div>
          {/* Button to view all items on map */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 18 }}>
            <Button className='title-btn' type="primary" onClick={viewAllOnMapHandler}>
              View all on map
            </Button>
          </div>
          {/* Displaying ResultsGrid component with showingItems */}
          <ResultsGrid
            dataSource={showingItems}
            closeViewAll={closeViewAll}
            setCurrentPage={(val) => {
              setCurrentPage(val)
            }}
          />
          {/* Pagination component for navigating through project bookmarks */}
          <div className="pagination-container" style={{ width: drawerWidthValue }}>
            <Pagination
              current={currentPage}
              total={totalItems || 0}
              pageSize={limit}
              showTotal={(total, range) => isFetchingProjects ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
              onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
              onChange={(pg) => setCurrentPage(pg)} />
          </div>
        </div>}

    </div>
  );
};
