import { Badge, Card, Spin, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import {
  selectCurrentItem,
  useGetAllSingleParcelMatchedDataQuery,
  useGetAllSingleParentProjectMatchedDataQuery,
  useGetParcelMatchingQueriesQuery,
  useGetParentProjectQueriesQuery,
} from 'app/services';
import { useEffect, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { useSelector } from 'react-redux';

type PropsType = {
  selectedItemType: string | undefined;
};

export const ViewQueryContent = ({ selectedItemType }: PropsType): any => {
  const selectedItem = useSelector(selectCurrentItem);
  const [currentTab, setCurrentTab] = useState('');
  const [queryData, setQueryData] = useState({});
  const [matchingAllData, setMatchingAllData] = useState<Array<any>>([]);

  // Parcel matches
  const [parcelsMatchingData, setParcelsMatchingData] = useState<any>();
  const [parcelsMatchesLoading, setParcelsMatchesLoading] = useState(true);

  const getColumnNamesFromQuery = (sql: any) => {
    let trimmedSql = sql.replace('SELECT COUNT(*) AS count FROM "parcels_new" "Parcel" WHERE', '')
    trimmedSql = trimmedSql.replace('SELECT COUNT(*) AS count FROM "contacts_new" "Contact" WHERE', '')
    trimmedSql = trimmedSql.replace('SELECT COUNT(*) AS count FROM "projects_new" "Project" WHERE', '')
    trimmedSql = trimmedSql.replace('as "role"', '')
    const columnRegex = /"([^"]+)"/g;
    const columnMatches = [...trimmedSql?.matchAll(columnRegex)];
    let columnNames = []
    if (columnMatches?.length) {
      columnNames = columnMatches.map(match => match[1].replace(/"/g, ''));
    }
    return [...new Set(columnNames)]
  }

  const {
    data: parcelToParcelMatches,
    isSuccess: isParcelToParcelSuccess,
    isFetching: isFetchingParcelToParcel,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'parcels',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToBuilderMatches,
    isSuccess: isParcelToBuilderSuccess,
    isFetching: isFetchingParcelToBuilder,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'builders',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToInvestorMatches,
    isSuccess: isParcelToInvestorSuccess,
    isFetching: isFetchingParcelToInvestor,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'investors',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToDeveloperMatches,
    isSuccess: isParcelToDeveloperSuccess,
    isFetching: isFetchingParcelToDeveloper,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'developers',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToRealEstateAgentMatches,
    isSuccess: isParcelToRealEstateAgentSuccess,
    isFetching: isFetchingParcelToRealEstateAgent,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'realEstateAgents',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToNewBuildHomebuyerMatches,
    isSuccess: isParcelToNewBuildHomebuyerSuccess,
    isFetching: isFetchingParcelToNewBuildHomebuyer,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'newBuildHomebuyers',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToWaterRightsMatches,
    isSuccess: isParcelToWaterRightsSuccess,
    isFetching: isFetchingParcelToWaterRights,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'waterRights',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  const {
    data: parcelToProjectMatches,
    isSuccess: isParcelToProjectSuccess,
    isFetching: isFetchingParcelToProject,
  } = useGetAllSingleParcelMatchedDataQuery(
    {
      id: selectedItem?.id_serial || '',
      matchingType: 'projects',
      hypothetical: '',
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id_serial || selectedItem?.type !== 'Parcel',
    },
  );

  useEffect(() => {
    if (currentTab === '') {
      setCurrentTab(matchingAllData.length ? matchingAllData[0].key : '');
    }
  }, [currentTab, matchingAllData])

  useEffect(() => {
    const data = { ...parcelsMatchingData };
    if (!isFetchingParcelToParcel && parcelToParcelMatches?.Parcels) {
      data.parcels = parcelToParcelMatches?.Parcels;
    }
    if (!isFetchingParcelToBuilder && parcelToBuilderMatches?.Builders) {
      data.builders = parcelToBuilderMatches?.Builders;
    }
    if (!isFetchingParcelToInvestor && parcelToInvestorMatches?.Investors) {
      data.investors = parcelToInvestorMatches?.Investors;
    }
    if (!isFetchingParcelToDeveloper && parcelToDeveloperMatches?.Developers) {
      data.developers = parcelToDeveloperMatches?.Developers;
    }
    if (
      !isFetchingParcelToRealEstateAgent &&
      parcelToRealEstateAgentMatches?.['Real Estate Agents']
    ) {
      data.realEstateAgents =
        parcelToRealEstateAgentMatches['Real Estate Agents'];
    }
    if (
      !isFetchingParcelToNewBuildHomebuyer &&
      parcelToNewBuildHomebuyerMatches?.['New Build Homebuyers']
    ) {
      data.newBuildHomebuyers =
        parcelToNewBuildHomebuyerMatches['New Build Homebuyers'];
    }
    if (
      !isFetchingParcelToWaterRights &&
      parcelToWaterRightsMatches?.['Water Rights']
    ) {
      data.waterRights = parcelToWaterRightsMatches['Water Rights'];
    }
    if (!isFetchingParcelToProject && parcelToProjectMatches?.Projects) {
      data.projects = parcelToProjectMatches.Projects;
    }
    setParcelsMatchingData(data);
    if (selectedItem?.type === 'Parcel') {
      const tabs = [
        {
          key: 'parcels',
          label: 'parcels',
          count: data?.parcels || 0
        },
        {
          key: 'builders',
          label: 'builders',
          count: data?.builders || 0
        },
        {
          key: 'investors',
          label: 'investors',
          count: data?.investors || 0
        },
        {
          key: 'developers',
          label: 'developers',
          count: data?.developers || 0
        },
        {
          key: 'realEstateAgents',
          label: 'realEstateAgents',
          count: data?.realEstateAgents || 0
        },
        {
          key: 'newBuildHomebuyers',
          label: 'newBuildHomebuyers',
          count: data?.newBuildHomebuyers || 0
        },
        {
          key: 'projects',
          label: 'projects',
          count: data?.projects || 0
        },
        {
          key: 'waterRights',
          label: 'waterRights',
          count: data?.waterRights || 0
        },
      ]
      setMatchingAllData(tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingParcelToParcel,
    isFetchingParcelToBuilder,
    isFetchingParcelToInvestor,
    isFetchingParcelToDeveloper,
    isFetchingParcelToRealEstateAgent,
    isFetchingParcelToNewBuildHomebuyer,
    isFetchingParcelToWaterRights,
    isFetchingParcelToProject,
  ]);

  useEffect(() => {
    if (
      !isFetchingParcelToParcel &&
      isParcelToParcelSuccess &&
      !isFetchingParcelToBuilder &&
      isParcelToBuilderSuccess &&
      !isFetchingParcelToInvestor &&
      isParcelToInvestorSuccess &&
      !isFetchingParcelToDeveloper &&
      isParcelToDeveloperSuccess &&
      !isFetchingParcelToRealEstateAgent &&
      isParcelToRealEstateAgentSuccess &&
      !isFetchingParcelToNewBuildHomebuyer &&
      isParcelToNewBuildHomebuyerSuccess &&
      !isFetchingParcelToWaterRights &&
      isParcelToWaterRightsSuccess &&
      !isFetchingParcelToProject &&
      isParcelToProjectSuccess
    ) {
      setParcelsMatchesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isParcelToParcelSuccess,
    isParcelToBuilderSuccess,
    isParcelToInvestorSuccess,
    isParcelToDeveloperSuccess,
    isParcelToRealEstateAgentSuccess,
    isParcelToNewBuildHomebuyerSuccess,
    isParcelToWaterRightsSuccess,
    isParcelToProjectSuccess,
  ]);

  // Project matches
  const [projectsMatchingData, setProjectsMatchingData] = useState<any>();
  const [projectsMatchesLoading, setProjectsMatchesLoading] = useState(true);

  const {
    data: projectToParcelMatches,
    isSuccess: isProjectToParcelSuccess,
    isFetching: isFetchingProjectToParcel,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'parcels',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToBuilderMatches,
    isSuccess: isProjectToBuilderSuccess,
    isFetching: isFetchingProjectToBuilder,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'builders',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToInvestorMatches,
    isSuccess: isProjectToInvestorSuccess,
    isFetching: isFetchingProjectToInvestor,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'investors',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToDeveloperMatches,
    isSuccess: isProjectToDeveloperSuccess,
    isFetching: isFetchingProjectToDeveloper,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'developers',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToRealEstateAgentMatches,
    isSuccess: isProjectToRealEstateAgentSuccess,
    isFetching: isFetchingProjectToRealEstateAgent,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'realEstateAgents',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToNewBuildHomebuyerMatches,
    isSuccess: isProjectToNewBuildHomebuyerSuccess,
    isFetching: isFetchingProjectToNewBuildHomebuyer,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'newBuildHomebuyers',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToWaterRightsMatches,
    isSuccess: isProjectToWaterRightsSuccess,
    isFetching: isFetchingProjectToWaterRights,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'waterRights',
      isChild: selectedItem?.parent_project_id ? true : false
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  const {
    data: projectToProjectMatches,
    isSuccess: isProjectToProjectSuccess,
    isFetching: isFetchingProjectToProject,
  } = useGetAllSingleParentProjectMatchedDataQuery(
    {
      id: selectedItem?.id || '',
      matchingType: 'projects',
      isChild: selectedItem?.parent_project_id ? true : false,
      parentName: null,
    },
    {
      refetchOnReconnect: true,
      skip: !selectedItem?.id || selectedItem?.type !== 'Project',
    },
  );

  useEffect(() => {
    const data = { ...projectsMatchingData };
    if (!isFetchingProjectToParcel && projectToParcelMatches?.Parcels) {
      data.parcels = projectToParcelMatches?.Parcels;
    }
    if (!isFetchingProjectToBuilder && projectToBuilderMatches?.Builder) {
      data.builders = projectToBuilderMatches?.Builder;
    }
    if (!isFetchingProjectToInvestor && projectToInvestorMatches?.Investors) {
      data.investors = projectToInvestorMatches?.Investors;
    }
    if (!isFetchingProjectToDeveloper && projectToDeveloperMatches?.Developer) {
      data.developers = projectToDeveloperMatches?.Developer;
    }
    if (
      !isFetchingProjectToRealEstateAgent &&
      projectToRealEstateAgentMatches?.['Real Estate Agent']
    ) {
      data.realEstateAgents =
        projectToRealEstateAgentMatches?.['Real Estate Agent'];
    }
    if (
      !isFetchingProjectToNewBuildHomebuyer &&
      projectToNewBuildHomebuyerMatches?.['New Build Homebuyer']
    ) {
      data.newBuildHomebuyers =
        projectToNewBuildHomebuyerMatches?.['New Build Homebuyer'];
    }
    if (
      !isFetchingProjectToWaterRights &&
      projectToWaterRightsMatches?.['Water Rights']
    ) {
      data.waterRights = projectToWaterRightsMatches?.['Water Rights'];
    }
    if (!isFetchingProjectToProject && projectToProjectMatches?.Projects) {
      data.projects = projectToProjectMatches?.Projects;
    }
    setProjectsMatchingData(data);
    if (selectedItem?.type === 'Project') {
      const tabs = [
        {
          key: 'parcels',
          label: 'parcels',
          count: data?.parcels || 0
        },
        {
          key: 'builders',
          label: 'builders',
          count: data?.builders || 0
        },
        {
          key: 'investors',
          label: 'investors',
          count: data?.investors || 0
        },
        {
          key: 'developers',
          label: 'developers',
          count: data?.developers || 0
        },
        {
          key: 'realEstateAgents',
          label: 'realEstateAgents',
          count: data?.realEstateAgents || 0
        },
        {
          key: 'newBuildHomebuyers',
          label: 'newBuildHomebuyers',
          count: data?.newBuildHomebuyers || 0
        },
        {
          key: 'projects',
          label: 'projects',
          count: data?.projects || 0
        },
        {
          key: 'waterRights',
          label: 'waterRights',
          count: data?.waterRights || 0
        },
      ]
      setMatchingAllData(tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingProjectToParcel,
    isFetchingProjectToBuilder,
    isFetchingProjectToInvestor,
    isFetchingProjectToDeveloper,
    isFetchingProjectToRealEstateAgent,
    isFetchingProjectToNewBuildHomebuyer,
    isFetchingProjectToWaterRights,
    isFetchingProjectToProject,
  ]);

  useEffect(() => {
    if (
      !isFetchingProjectToParcel &&
      isProjectToParcelSuccess &&
      !isFetchingProjectToBuilder &&
      isProjectToBuilderSuccess &&
      !isFetchingProjectToInvestor &&
      isProjectToInvestorSuccess &&
      !isFetchingProjectToDeveloper &&
      isProjectToDeveloperSuccess &&
      !isFetchingProjectToRealEstateAgent &&
      isProjectToRealEstateAgentSuccess &&
      !isFetchingProjectToNewBuildHomebuyer &&
      isProjectToNewBuildHomebuyerSuccess &&
      !isFetchingProjectToWaterRights &&
      isProjectToWaterRightsSuccess &&
      !isFetchingProjectToProject &&
      isProjectToProjectSuccess
    ) {
      setProjectsMatchesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProjectToParcelSuccess,
    isProjectToBuilderSuccess,
    isProjectToInvestorSuccess,
    isProjectToDeveloperSuccess,
    isProjectToRealEstateAgentSuccess,
    isProjectToNewBuildHomebuyerSuccess,
    isProjectToWaterRightsSuccess,
    isProjectToProjectSuccess,
  ]);

  const handleClick = (key: string) => {
    setCurrentTab(key);
  };

  const { data: surveyQueries, isFetching } =
    selectedItemType === 'Parcel'
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetParcelMatchingQueriesQuery(
        {
          serialId: selectedItem?.id_serial,
          matchingType: currentTab,
        },
        { skip: !selectedItem },
      )
      : // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetParentProjectQueriesQuery(
        {
          serialId: selectedItem?.id,
          matchingType: currentTab,
          isChild: selectedItem?.parent_project_id ? true : false,
          viewQuery: currentTab === 'projects' ? true : false
        },
        { skip: !selectedItem },
      );

  useEffect(() => {
    const outputObject: any = {};
    const data = Object.entries(surveyQueries || {});
    data.map(([key, value]: any): any => {
      if (key.includes('Companies')) {
        outputObject.Companies = key.includes('Query')
          ? { query: value, ...outputObject.Companies }
          : { count: value, ...outputObject.Companies };
      } else if (key.includes('Contacts')) {
        outputObject.Contacts = key.includes('Query')
          ? { query: value, ...outputObject.Contacts }
          : { count: value, ...outputObject.Contacts };
      } else {
        const keyName = key.includes('Query')
          ? key.replace('Query', '')
          : key.replace('Count', '');
        outputObject[keyName] = key.includes('Query')
          ? { query: value, ...outputObject[keyName] }
          : { count: value, ...outputObject[keyName] };
      }
      return null;
    });
    if (outputObject?.WaterRights) {
      outputObject.WaterRights.columns = ['WRNUM']
    }
    if (outputObject?.Contacts) {
      outputObject.Contacts.columns =
        getColumnNamesFromQuery(outputObject.Contacts.query)
    }
    if (outputObject?.Companies) {
      outputObject.Companies.columns =
        getColumnNamesFromQuery(outputObject.Companies.query)
    }
    if (outputObject?.Parcels) {
      outputObject.Parcels.columns =
        getColumnNamesFromQuery(outputObject.Parcels.query)
    }
    if (outputObject?.Projects) {
      outputObject.Projects.columns =
        getColumnNamesFromQuery(outputObject.Projects.query)
    }
    if (outputObject?.ParentProject) {
      outputObject.ParentProject.columns =
        getColumnNamesFromQuery(outputObject.ParentProject.query)
    }
    if (outputObject?.childProjects) {
      outputObject.childProjects.columns =
        getColumnNamesFromQuery(outputObject.childProjects.query)
    }
    if (outputObject?.ParentProjects) {
      outputObject.ParentProjects.columns =
        getColumnNamesFromQuery(outputObject.ParentProjects
          .query)
    }
    if (outputObject?.ChildProjects) {
      outputObject.ChildProjects.columns =
        getColumnNamesFromQuery(outputObject.ChildProjects
          .query)
    }
    setQueryData(outputObject);
  }, [surveyQueries]);

  function camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    const data = result.charAt(0).toUpperCase() + result.slice(1);
    return data.includes('CompaniesQuery')
      ? 'Companies'
      : data.includes('ContactsQuery')
        ? 'Contacts'
        : data;
  }

  const Tab = ({ property, count }: any) => (
    <div className="tab-title">
      <div className="name">
        <span>
          {camelCaseToWords(property)} {' '}
          <Badge count={count} showZero color='#1C7A92' overflowCount={100000} />
        </span>
        {/* {!count && <span>{camelCaseToWords(property)}</span>} */}
      </div>
    </div>
  );

  return (isFetching && parcelsMatchesLoading) ||
    (isFetching && projectsMatchesLoading) ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spin spinning={isFetching} tip="Loading" size="large"></Spin>
    </div>
  ) : (
    <>
      <Tabs onTabClick={(e) => handleClick(e)} activeKey={currentTab}>
        {matchingAllData.map((item: any) => (
          <TabPane
            tab={<Tab property={item.label} count={item.count} />}
            key={item.key}
          >
            <Tabs type="card">
              {Object.entries(queryData)?.map(([key, value]: any) => (
                <TabPane
                  tab={<Tab property={key} count={value.count} />}
                  key={key}
                >
                  <CopyBlock
                    language="sql"
                    text={value.query}
                    wrapLines
                    theme={dracula}
                  />
                  <div style={{ marginTop: 25 }}>
                    {value?.columns?.length ?
                      <div style={{ marginTop: 25 }}>
                        <Card title="Columns used in query" style={{ width: 500 }}>
                          <ul>
                            {value?.columns?.map((col: any) =>
                              <li style={{ marginBottom: 10 }}
                                key={col}>{col}</li>)}
                          </ul>
                        </Card>
                      </div> : null
                    }
                    {/* <Card title="Columns used in query" style={{ width: 500 }}>
                      <ul>
                        <li style={{ marginBottom: 10 }} key={1}>
                          utahAreaInvestmentTags
                        </li>
                        <li style={{ marginBottom: 10 }} key={2}>
                          whatRealEstateProductDoesYourCompanyInvestIn
                        </li>
                        <li style={{ marginBottom: 10 }} key={3}>
                          Type
                        </li>
                      </ul>
                    </Card> */}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};
