import React, { useState } from 'react';
import { setItem, selectCurrentItem, useCreateParcelBookmarkMutation, useDeleteParcelBookmarkByParcelIdMutation } from 'app/services';
import { useDispatch, useSelector } from 'react-redux';
import { MetaData, MetaDataProps } from './MetaData';
import { Col, Modal, Row, notification, Typography, Card } from 'antd';
import { joinHypotheticals } from 'utils/joinHypotheticals';
import { getHypotheticalsTitle } from 'utils/getHypotheticalsTitle';
import { convertToPascalCase } from 'utils/convertToPascalCase';
import { MoreInfoPopup } from './MoreInfoPopup';
import BookmarkPopover from './BookmarkPopover';

const { Title } = Typography;


interface ResultCardProps extends MetaDataProps { }

export const ResultCard = (props: ResultCardProps) => {
  const dispatch = useDispatch();
  const selectedItem: any = useSelector(selectCurrentItem);

  const [createParcelBookmark] = useCreateParcelBookmarkMutation();
  const [deleteParcelBookmark] = useDeleteParcelBookmarkByParcelIdMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any>()

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedData('')
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedData('')
  };

  const onClickResultCard = () => {
    if (props.from === 'matching') {
      setSelectedData(props.allData)
      showModal()
    } else {
      dispatch(
        setItem({
          id: props.id,
          type: props.title,
          parcelId: props.parcelId,
          formattedParcelId: props.formattedParcelId,
          id_serial: props.id_serial,
          from: props.from,
          parent_project_id: props?.allData?.parent_project_id,
        }),
      );
      if (props.closeViewAll) {
        props.closeViewAll();
      }
    }
  }

  const createBookmark = () => {
    if (props.title === 'Parcel') {
      createParcelBookmark({ parcel_id: props?.id_serial })
        .finally(() => {
          notification.success({
            placement: 'topRight',
            message: `${props.title} bookmarked successfully!`,
          });

          if (props.refetchParcels) {
            props.refetchParcels()
          }
          setIsModalVisible(false)
        })
    }
  }

  const deleteBookmark = () => {
    if (props.title === 'Parcel') {
      deleteParcelBookmark({ id: props?.id_serial }).finally(() => {
        notification.success({
          placement: 'topRight',
          message: `Bookmark deleted successfully!`,
        });
        setIsModalVisible(false)
        if (props.refetchParcels) {
          props.refetchParcels()
        }
      })
    }
  }

  return (
    <>
      {/* <Card> */}
      <Card
        className="naxxa-result-card"
        style={{
          borderColor: (selectedItem?.id && props?.id && selectedItem?.id === props.id) || (props?.selectedChildProjectId && props?.id && props?.selectedChildProjectId === props?.id) ? props.titleColor : '#ddd',
        }}
      >
        <MetaData
          closeDrawer={props.closeDrawer}
          closeViewAll={props.closeViewAll}
          from={props.from} showParcelsLink={props.showParcelsLink}
          {...props} onClickResultCard={onClickResultCard}
          setCurrentPage={(val: any) => {
            if (props.setCurrentPage) {
              props.setCurrentPage(val)
            }
          }} />

        {props.title === 'Project' || props.title === 'Parcel' || props.title === 'Projects' ?
          <div className="popup-more-info">
            <MoreInfoPopup title={props.title} information={props}
              allData={props?.allData?.item || props?.allData || props}
              id_serial={props.id_serial}
            />
          </div> : null}
      </Card>
      {/* </Card> */}
      <Modal
        title=
        {selectedData?.type === 'Parcel' ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{selectedData?.type} {selectedData?.item?.existsInBookmarks ?
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={deleteBookmark}
            body={<img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 8, cursor: 'pointer', height: 15, width: 15 }} />}
          />
          :
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Add Bookmark'
            onConfirm={createBookmark}
            body={<img title='Add Bookmark' src='bookmark.svg' alt='bookmark' style={{ marginLeft: 8, cursor: 'pointer', height: 15, width: 15 }} />
            }
          />
        }</div> : selectedData?.type}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        style={{ marginTop: 50 }}
        bodyStyle={{ maxHeight: 700, overflowY: 'scroll', fontSize: 13 }}
      >
        <>
          {selectedData?.type === 'Parcel' ?
            <Row>
              {selectedData?.item?.parcelId ? (
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Parcel-ID: </span>
                    <span className="meta-detail-value">{selectedData?.item?.parcelId}</span>
                  </div>
                </Col>
              ) : null}
              {selectedData?.item?.parcelId ? (
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">{getHypotheticalsTitle()}: </span>
                    <span className="meta-detail-value">{joinHypotheticals(selectedData?.item?.possibleNumberAndStyleOfLots, selectedData?.item?.possibleNumberAndStyleOfLotsNeighbors,
                      selectedData?.item?.possibleNumberAndStyleOfLotsProxy,
                      selectedData?.item?.possibleNumberAndStyleOfLotsZoning)}
                    </span>
                  </div>
                </Col>
              ) : null}
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Acreage: </span>
                  <span className="meta-detail-value">{selectedData?.item?.acreage?.toFixed(2)}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">City: </span>
                  <span className="meta-detail-value">{selectedData?.item?.city_by_geometry || selectedData?.item?.city}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">County: </span>
                  <span className="meta-detail-value">{selectedData?.item?.county_by_geometry || selectedData?.item?.county}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Area Tags: </span>
                  <span className="meta-detail-value">{selectedData?.item?.areaTags?.join(',')}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Bathrooms: </span>
                  <span className="meta-detail-value">{selectedData?.item?.bathrooms}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Bedrooms: </span>
                  <span className="meta-detail-value">{selectedData?.item?.bedrooms}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">State: </span>
                  <span className="meta-detail-value">{selectedData?.item?.state}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Owner Location: </span>
                  <span className="meta-detail-value">{selectedData?.item?.ownerLocation}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Ownership Name: </span>
                  <span className="meta-detail-value">{selectedData?.item?.ownershipName}</span>
                </div>
              </Col>
            </Row> : null}

          {selectedData?.type !== 'Parcel' && selectedData?.type !== 'Water Right' && selectedData?.type !== 'Projects' ?
            <>
              <Row>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Name: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.name &&
                        convertToPascalCase(selectedData?.item?.name)} {' '}
                      {/* {selectedData?.item?.lastname &&
                      convertToPascalCase(selectedData?.item?.lastname)} */}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Email: </span>
                    <span className="meta-detail-value">{selectedData?.item?.email?.length &&
                      selectedData?.item?.email?.map((elem: any, i: any) => {
                        return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                      })}</span>
                  </div>
                </Col>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Job Title: </span>
                    <span className="meta-detail-value">{selectedData?.item?.jobtitle}</span>
                  </div>
                </Col>
              </Row>
              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Work Email: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.workEmail}
                  </span>
                </div>
              </Col> */}
              <Title level={5}>Location :</Title>
              <Row>
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Address: </span>
                    <span className="meta-detail-value">{selectedData?.item?.address}</span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">City: </span>
                    <span className="meta-detail-value">{selectedData?.item?.city?.length ? selectedData?.item?.city?.join(', ') : null}</span>
                  </div>
                </Col>

                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">County: </span>
                    <span className="meta-detail-value">{selectedData?.item?.county?.length ? selectedData?.item?.county?.join(', ') : null}</span>
                  </div>
                </Col>
              </Row>

              <Title level={5}>Contact :</Title>
              <Row>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Phone: </span>
                    <span className="meta-detail-value">{selectedData?.item?.phoneNumber?.length &&
                      selectedData?.item?.phoneNumber?.
                        map((elem: any, i: any) => {
                          return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></>
                            : <a key={elem} href={'tel:' + elem}>{elem}</a>
                        })}</span>
                  </div>
                </Col>


                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Phone Number1: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.phoneNumber1?.length &&
                        selectedData?.item?.phoneNumber1?.
                          map((elem: any, i: any) => {
                            return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></>
                              : <a key={elem} href={'tel:' + elem}>{elem}</a>
                          })}
                    </span>
                  </div>
                </Col>


                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Phone Number2: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.phoneNumber2?.length &&
                        selectedData?.item?.phoneNumber2?.
                          map((elem: any, i: any) => {
                            return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></>
                              : <a key={elem} href={'tel:' + elem}>{elem}</a>
                          })}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Website: </span>
                    <span className="meta-detail-value">{selectedData?.item?.website?.length &&
                      selectedData?.item?.website?.
                        map((elem: any, i: any) => {
                          return i > 0 ? <>, <a target='blank' key={elem}
                            href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                        })}</span>
                  </div>
                </Col>


                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Facebook Url: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.facebookUrl?.length &&
                        selectedData?.item?.facebookUrl?.
                          map((elem: any, i: any) => {
                            return i > 0 ? <>, <a target='blank' key={elem}
                              href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                          })}
                    </span>
                  </div>
                </Col>


                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Twitter Url: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.twitterUrl?.length &&
                        selectedData?.item?.twitterUrl?.
                          map((elem: any, i: any) => {
                            return i > 0 ? <>, <a target='blank' key={elem}
                              href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                          })}
                    </span>
                  </div>
                </Col>

                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Website Projects Url:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.websiteProjectsUrl?.length &&
                        selectedData?.item?.websiteProjectsUrl?.
                          map((elem: any, i: any) => {
                            return i > 0 ? <>, <a target='blank' key={elem} href={elem}>{elem}</a></> :
                              <a target='blank' key={elem} href={elem}>{elem}</a>
                          })}
                    </span>
                  </div>
                </Col>
              </Row>

              <Title level={5}>Others :</Title>
              <Row>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Contact Type: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.Type &&
                        selectedData?.item?.Type?.join(', ')}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      What Real Estate Product Does Your Company Invest In: {' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.
                        whatRealEstateProductDoesYourCompanyInvestIn?.join(', ')}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Investment Range: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.investmentRange?.join(', ')}
                    </span>
                  </div>
                </Col>


                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      How Many Acres Is Your Land:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.howManyAcresIsYourLand}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Land Owner General Zoning Question:
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.landOwnerGeneralZoningQuestion}
                    </span>
                  </div>
                </Col>


                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Real Estate License Type And Focus:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.realEstateLicenseTypeAndFocus &&
                        selectedData?.item?.
                          realEstateLicenseTypeAndFocus?.join(', ')}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      New Build Home Buyer Desired Cities:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.newBuildHomeBuyerDesiredCities}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      End Product Price Ranges:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.endProductPriceRanges?.join(', ')}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Home Buyer Price Range:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.homeBuyerPriceRange}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Number And Style Of Lots Purchased:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.numberAndStyleOfLotsPurchased?.join(', ')}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Builder Focus Specialty:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.builderFocusSpecialty?.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Utah Area Tags: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.utahAreaTags &&
                        selectedData?.item?.utahAreaTags?.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">
                      Utah Area Investment Tags:{' '}
                    </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.utahAreaInvestmentTags &&
                        selectedData?.item?.utahAreaInvestmentTags.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Developer Tags: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.developerTags &&
                        selectedData?.item?.developerTags?.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Owner Tags: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.ownerTags}
                    </span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Parcels: </span>
                    <span className="meta-detail-value">{selectedData?.item?.parcels?.join(', ')}</span>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Hs Lead Status: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.hsLeadStatus
                        && selectedData?.item?.hsLeadStatus?.toString()}
                    </span>
                  </div>
                </Col>
              </Row>

              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Country: </span>
                  <span className="meta-detail-value">{selectedData?.item?.country && convertToPascalCase(selectedData?.item?.country)}</span>
                </div>
              </Col> */}



              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Zip: </span>
                  <span className="meta-detail-value">{selectedData?.item?.zip && convertToPascalCase(selectedData?.item?.zip)}</span>
                </div>
              </Col> */}

              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Company: </span>
                  <span className="meta-detail-value">{selectedData?.item?.company && convertToPascalCase(selectedData?.item?.company)}</span>
                </div>
              </Col> */}

              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Created By: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.createdBy &&
                      convertToPascalCase(selectedData?.item?.createdBy)}
                  </span>
                </div>
              </Col> */}

              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Date Of Birth: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.dateOfBirth}
                  </span>
                </div>
              </Col> */}



              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Fax: </span>
                  <span className="meta-detail-value">{selectedData?.item?.fax?.toLowerCase()}</span>
                </div>
              </Col> */}


              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Garage Size: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.garageSize}
                  </span>
                </div>
              </Col> */}


              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">How Many Beds: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.howManyBeds}
                  </span>
                </div>
              </Col> */}





              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Market Sell Bulk: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.marketSellBulk}
                  </span>
                </div>
              </Col> */}
              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Mobile Phone: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.mobilephone}
                  </span>
                </div>
              </Col> */}



              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Projects: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.projects &&
                      selectedData?.item?.projects.toString()}
                  </span>
                </div>
              </Col> */}







              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">State: </span>
                  <span className="meta-detail-value">
                    {selectedData?.
                      item?.state && selectedData?.item?.state?.toString() &&
                      convertToPascalCase(selectedData?.item?.state)}
                  </span>
                </div>
              </Col> */}


              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Temp Land Owner Number And Style Of Lots:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.tempLandOwnerNumberAndStyleOfLots
                      && selectedData?.item?.
                        tempLandOwnerNumberAndStyleOfLots.join(', ')}
                  </span>
                </div>
              </Col> */}
              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Type Style Of Home: </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.typeStyleOfHome &&
                      selectedData?.item?.typeStyleOfHome.join(', ')}
                  </span>
                </div>
              </Col> */}

              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Vendor Id: </span>
                  <span className="meta-detail-value">{selectedData?.item?.vendorId}</span>
                </div>
              </Col> */}


              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Number Of Bathrooms:{' '}

                  </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.numberOfBathrooms}
                  </span>
                </div>
              </Col> */}


              {/* <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    What Size Of Lot Do You Want:
                  </span>
                  <span className="meta-detail-value">
                    {selectedData?.item?.whatSizeOfLotDoYouWant}
                  </span>
                </div>
              </Col> */}
            </> : null
          }

          {selectedData?.type === 'Water Right' ?
            <>
              <Row>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">WRCHEX: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.WRCHEX}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Name: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.OWNER_FIRST_NAME +
                        selectedData?.item?.OWNER_LAST_NAME}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Email: </span>
                    <span className="meta-detail-value"> {selectedData?.item?.OWNER_EMAIL_ADDRESS?.length &&
                      Array.isArray(selectedData?.item.OWNER_EMAIL_ADDRESS) ?
                      selectedData?.item?.OWNER_EMAIL_ADDRESS?.
                        map((elem: any, i: any) => {
                          return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                        }) : selectedData?.item?.OWNER_EMAIL_ADDRESS ? <a href={'mailto:' + selectedData?.item.OWNER_EMAIL_ADDRESS}>{selectedData?.item.OWNER_EMAIL_ADDRESS}</a> : null}</span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Phone: </span>
                    <span className="meta-detail-value">    {selectedData?.OWNER_PHONE?.length &&
                      selectedData?.OWNER_PHONE?.map((elem: any, i: any) => {
                        return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                      })}</span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Address: </span>
                    <span className="meta-detail-value">{selectedData?.item?.OWNER_ADDRESS1}, {selectedData?.item?.OWNER_ADDRESS2}</span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Owner Interest: </span>
                    <span className="meta-detail-value">{selectedData?.item?.OWNER_INTEREST}</span>
                  </div>
                </Col>
              </Row>

            </> : null
          }

          {selectedData?.type === 'Projects' ?
            <>
              <Row>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Project Name: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.project_name_processed}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">State: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.state}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Street: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.address}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">City: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.city}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">County: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.county}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Zip: </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.zip}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Number and Style of Lots:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.numberAndStyleOfLots?.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Desired Number and Style of Lots:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.desiredNumberAndStyleOfLots?.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Special Product Types:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.specialProductTypes?.length ? [...new Set(selectedData?.item?.specialProductTypes)]?.join(', ') : ''}

                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Lot Sizes:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.lotSize?.join(', ')}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Project Status:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.project_status}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">House Style:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.housestyle?.length ? selectedData?.item?.housestyle?.join(', ') : selectedData?.item?.housestyle}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">House Square Footage:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.houseSquareFootage}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Bedrooms:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.bedrooms?.length ? selectedData?.item?.bedrooms?.join(', ') : selectedData?.item?.bedrooms}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Bathrooms:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.bathrooms?.length ? selectedData?.item?.bathrooms?.join(', ') : selectedData?.item?.bathrooms}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Garage Size:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.garages?.length ? selectedData?.item?.garages?.join(', ') : selectedData?.item?.garages}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Zoning:{' '} </span>
                    <span className="meta-detail-value">
                      {selectedData?.item?.zoning?.length ? selectedData?.item?.zoning?.join(', ') : selectedData?.item?.garages}
                    </span>
                  </div>
                </Col>
              </Row>

            </> : null
          }
        </>
      </Modal>
    </>
  );
};
