import { Empty, Spin, Tabs, Typography } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { selectResultsPaginationValue, useGetParcelsQueryQuery, useGetParentProjectsQueryQuery, useGetProjectsQueryQuery } from "app/services";
import { useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const { Title } = Typography;


export const ViewQueryContentOpp = () => {

  const [currentTab, setCurrentTab] = useState('Parcels')
  const [innerActiveTab, setInnerActiveTab] = useState('parentProject')
  const { search } = useLocation();
  const paginationValue: any = useSelector(selectResultsPaginationValue)

  const {
    data: ParcelsQuery,
    isSuccess: isParcelsQuerySuccess,
    isFetching: isParcelsQueryFetching,
  } = useGetParcelsQueryQuery(`?page=${paginationValue?.page}&take=${paginationValue?.limit}&${search.substring(1)}`, {
    refetchOnReconnect: true,
    skip: currentTab === 'Projects',
  });

  const {
    data: ParentProjectsQuery,
    isSuccess: isParentProjectsQuerySuccess,
    isFetching: isParentProjectsQueryFetching,
  } = useGetParentProjectsQueryQuery(`?page=${paginationValue?.page}&take=${paginationValue?.limit}&${search.substring(1)}`, {
    refetchOnReconnect: true,
    skip: currentTab === 'Parcels' || innerActiveTab === 'childProject',
  });

  const {
    data: ProjectsQuery,
    isSuccess: isProjectsQuerySuccess,
    isFetching: isProjectsQueryFetching,
  } = useGetProjectsQueryQuery(`?page=${paginationValue?.page}&take=${paginationValue?.limit}&${search.substring(1)}`, {
    refetchOnReconnect: true,
    skip: currentTab === 'Parcels' || innerActiveTab === 'parentProject',
  });

  function camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    const data = result.charAt(0).toUpperCase() + result.slice(1);
    return data.includes('CompaniesQuery')
      ? 'Companies'
      : data.includes('ContactsQuery')
        ? 'Contacts'
        : data;
  }

  const Tab = ({ property, count }: any) => (
    <div className="tab-title">
      <div className="name">
        <span>
          {/* {camelCaseToWords(property)} ({count}) */}
          {camelCaseToWords(property)}
        </span>
        {/* {!count && <span>{camelCaseToWords(property)}</span>} */}
      </div>
    </div>
  );

  const handleClick = (key: string) => {
    setCurrentTab(key)
  };
  return (isParcelsQueryFetching || isParentProjectsQueryFetching || isProjectsQueryFetching) ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spin spinning={isParcelsQueryFetching} tip="Loading" size="large"></Spin>
    </div>
  ) : (
    <>
      <Tabs onChange={(e) => handleClick(e)} activeKey={currentTab}>
        <TabPane
          tab={<Tab property="Parcels" />}
          key="Parcels"
        >
          {isParcelsQuerySuccess && ParcelsQuery?.countQuery ?
            <>
              <div style={{ marginTop: 10 }}>
                <Title level={5}>Count Query</Title>
                <CopyBlock
                  language="sql"
                  text={isParcelsQuerySuccess ? ParcelsQuery?.countQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <Title level={5}>Data Query</Title>

                <CopyBlock
                  language="sql"
                  text={isParcelsQuerySuccess ? ParcelsQuery?.dataQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
            </> : <div className="view-query-empty"><Empty
              className="matched-item-popover-no-results"
              description="No Query Available"
            />
            </div>
          }
        </TabPane>
        <TabPane
          tab={<Tab property="Projects" />}
          key="Projects"
        >
          <Tabs onChange={(e) => setInnerActiveTab(e)} activeKey={innerActiveTab}>
            <TabPane
              tab={<Tab property="Parents" />}
              key="parentProject"
            >
              <div style={{ marginTop: 10 }}>
                <Title level={5}>Count Query</Title>
                <CopyBlock
                  language="sql"
                  text={isParentProjectsQuerySuccess ? ParentProjectsQuery?.countQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <Title level={5}>Data Query</Title>
                <CopyBlock
                  language="sql"
                  text={isParentProjectsQuerySuccess ? ParentProjectsQuery?.dataQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
            </TabPane>
            <TabPane
              tab={<Tab property="Children" />}
              key="childProject"
            >
              <div style={{ marginTop: 10 }}>
                <Title level={5}>Count Query</Title>
                <CopyBlock
                  language="sql"
                  text={isProjectsQuerySuccess ? ProjectsQuery?.countQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <Title level={5}>Data Query</Title>
                <CopyBlock
                  language="sql"
                  text={isProjectsQuerySuccess ? ProjectsQuery?.dataQuery : ''}
                  wrapLines
                  theme={dracula}
                />
              </div>
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </>
  );
}
