import { Badge, Button, Col, Drawer, Empty, Pagination, Row, Space, Spin } from 'antd';
import { selectCoordsPaginationValue, setCoordsPaginationValue, setSelectedItemDetailsOnMap, setZoomValue, useGetChildProjectsQuery } from 'app/services';
import React, { useEffect, useMemo, useState } from 'react';
import { ProjectInterface } from 'types/projects';
import { ResultCard } from '../ResultCard';
import { createMetaData } from 'utils/metaDataHelpers';
import { Color } from '../types';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import TooltipComponent from 'components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';


export const ViewParentProjectsPopup = ({
   isOpen,
   closeDrawer,
   data
}: any) => {
   const [page, setPage] = useState(1)
   const [limit, setLimit] = useState(20)

   const [showingItems, setShowingItems] = useState<any>([]);
   const [isLoading, setIsLoading] = useState(false)
   const [totalItems, setTotalItems] = useState<number>(0)

   const dispatch = useDispatch()

   const coordsPagination: any = useSelector(selectCoordsPaginationValue)
   /**
    * React hook `useEffect` to handle changes in `coordsPagination`.
    * Updates the `page` state based on `coordsPagination.page`.
    */
   useEffect(() => {
      setPage(coordsPagination?.page)
   }, [coordsPagination])

   /**
    * React hook `useEffect` to handle changes in `limit`.
    * Sets the `page` state to 1 whenever `limit` changes.
    */
   useEffect(() => {
      setPage(1)
   }, [limit])
   /**
    * React hook `useEffect` to dispatch `setCoordsPaginationValue` action.
    * Dispatches the action with updated `page` value whenever `page` changes.
    */
   useEffect(() => {
      dispatch(setCoordsPaginationValue({ page, limit }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [page])

   const { data: childProjects, isFetching: isChildProjectsFetching,
      isSuccess: isChildProjectsSuccess } =
      useGetChildProjectsQuery({ id: data?.item?.id, page, take: limit }, {
         skip: !data?.item?.id
      });

   /**
    * Memoized selector `listData`.
    * Maps `childProjects.data` to an array of objects, each containing an `item` and a constant `type` ('Project').
    * Memoizes the result to optimize performance by recalculating only when `childProjects` changes.
    */
   const listData = useMemo(() => {
      return childProjects?.data.map((item: any) => ({ item, type: 'Project' }));
   }, [childProjects]);
   /**
    * React hook `useEffect` to manage loading state and total items.
    * Sets `isLoading` based on `isChildProjectsFetching` and updates `totalItems`
    * if `childProjects.pagination.totalItems` is available and fetching is complete.
    */
   useEffect(() => {
      setIsLoading(isChildProjectsFetching)
      if (!isChildProjectsFetching) {
         setTotalItems(childProjects?.pagination?.totalItems || 0)
      }
   }, [childProjects?.pagination?.totalItems, isChildProjectsFetching])
   /**
    * React hook `useEffect` to handle updating `showingItems` after successful fetching.
    * Sets `showingItems` to a unique list derived from `listData` when fetching of child projects
    * is completed (`!isChildProjectsFetching`) and successful (`isChildProjectsSuccess`).
    */
   useEffect(() => {
      if ((!isChildProjectsFetching) &&
         (isChildProjectsSuccess)) {
         const allShowingItems = [...new Set([...(listData || [])])];
         setShowingItems(allShowingItems)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      isChildProjectsFetching,
      isChildProjectsSuccess,
      listData
   ]);

   /**
    * Handles the click event on a bookmark icon for a specific item.
    * Updates the `existsInBookmarks` property of the item in `showingItems`
    * based on the provided `type` ('add' or 'remove').
    * 
    * @param item - The item object on which the bookmark icon was clicked.
    * @param type - The type of action to perform ('add' or 'remove') on the bookmark.
    */
   const onBookMarkIconClick = (item: any, type: any) => {
      let prevItems = JSON.parse(JSON.stringify(showingItems))
      let newShowingItems = prevItems.map((prevItem: any) => {
         if (item.id === prevItem?.item?.id) {
            prevItem.item.existsInBookmarks = type === 'remove' ? false : true
         }
         if (item.id === prevItem?.id) {
            prevItem.existsInBookmarks = type === 'remove' ? false : true
         }
         return prevItem
      });
      setShowingItems(newShowingItems)
   }
   /**
    * Functional component `ResultsGrid` renders a grid of result cards based on `dataSource`.
    * Each result card displays information from `dataSource`, with optional bookmarking and metadata.
    * 
    * @param dataSource - An array containing objects with `type` and `item` properties representing the data source for result cards.
    * @param closeViewAll - Callback function to close the view of all results.
    */
   const ResultsGrid = ({
      dataSource,
      closeViewAll,
   }: {
      dataSource:
      | { type: string; item: ProjectInterface }[]
      | undefined;
      closeViewAll: () => void;
   }) => {

      return (
         <>
            <Row
               style={{ marginBottom: 30 }}
               className="result-content"
               gutter={[
                  { xs: 6, sm: 12, md: 16, lg: 24 },
                  { xs: 6, sm: 12, md: 16, lg: 24 },
               ]}
            >
               {dataSource &&
                  dataSource.map((result, i) => (
                     <Col key={i}>
                        {/* <Skeleton className="grid-skeleton" height={160} count={1} /> */}
                        <ResultCard
                           onBookMarkIconClick={onBookMarkIconClick}
                           from="parent-project"
                           title={result?.type}
                           titleColor={
                              result?.type === 'Parcel' ? Color.navy : Color.green
                           }
                           data={createMetaData(result)}
                           allData={result.item}
                           closeViewAll={closeViewAll}
                           id={result.item?.id}
                           parcelId={result.item?.parcelId}
                           id_serial={result.item?.id_serial}
                           formattedParcelId={result.item?.formattedParcelId}
                        // selectedChildProjectId={selectedChildProjectId}
                        />
                     </Col>
                  ))}
            </Row>
         </>
      );
   };
   /**
    * Handles the click event to view the parent project on the map.
    * Stores parent project data in localStorage, triggers an event to view child projects,
    * and dispatches actions to set pagination, zoom level, and selected item details on the map.
    */ 
   const viewOnMapHandler = (e: any) => {
      if (e) {
         e.stopPropagation()
      }
      if (data?.item) {
         localStorage.setItem('parentProjectsData', JSON.stringify(data.item))
         window.dispatchEvent(new Event("viewChildProject"));
         dispatch(setCoordsPaginationValue({ page, limit }))
         dispatch(setZoomValue(6))
         dispatch(setSelectedItemDetailsOnMap({ viewType: 'parentOrChildProjects', selectedType: `parent project`, id: data?.item?.id, matchingType: `Child projects`, name: data?.item?.project_name_processed, totalCount: totalItems }))
      }
      // closeDrawer()
   }

   return (
      <Drawer
         open={isOpen}
         placement="left"
         width={1050}
         push={false}
         mask={false}
         // getContainer={false}
         style={{ position: 'absolute' }}
         title={
            <>
               Child Projects of {data?.item?.project_name_processed}{' '}
               <Badge count={isLoading ? 'Loading...' : totalItems} color='#1C7A92' overflowCount={100000} showZero />
            </>
         }
         // title={`Child Projects of ${data?.item?.project_name_processed} (${totalItems || 'Loading...'})`}
         onClose={closeDrawer}
         extra={
            showingItems?.length ?
               <Space>
                  <TooltipComponent
                     title='View all on map'
                     placement='top'
                     body={<Button className='title-btn' type="primary" onClick={viewOnMapHandler}>
                        View all on map
                     </Button>}
                  />
               </Space> : null
         }
      >
         {!isLoading && !showingItems?.length ?
            <Empty className='center-empty' image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            <Spin
               spinning={false}
               indicator={
                  <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
               }
            >
               {isChildProjectsFetching ? (
                  <>
                     <Row
                        style={{ marginBottom: 15 }}
                        className="result-content"
                        gutter={[
                           { xs: 6, sm: 12, md: 16, lg: 24 },
                           { xs: 6, sm: 12, md: 16, lg: 24 },
                        ]}
                     >
                        {Array(7)
                           .fill('')
                           .map(() => {
                              return (
                                 <Col key={1} style={{ flex: 1 }}>
                                    <Skeleton
                                       className="grid-skeleton"
                                       height={160}
                                       count={1}
                                    />
                                 </Col>
                              );
                           })}
                     </Row>
                     <Row
                        style={{ marginBottom: 15 }}
                        className="result-content"
                        gutter={[
                           { xs: 6, sm: 12, md: 16, lg: 24 },
                           { xs: 6, sm: 12, md: 16, lg: 24 },
                        ]}
                     >
                        {Array(7)
                           .fill('')
                           .map(() => {
                              return (
                                 <Col key={1} style={{ flex: 1 }}>
                                    <Skeleton
                                       className="grid-skeleton"
                                       height={160}
                                       count={1}
                                    />
                                 </Col>
                              );
                           })}
                     </Row>
                  </>
               ) : <ResultsGrid
                  dataSource={showingItems}
                  closeViewAll={closeDrawer}
               />}
               <div className="pagination-container" style={{ left: "unset", width: 1000 }}>
                  <Pagination
                     current={page}
                     total={totalItems}
                     pageSize={limit}
                     showTotal={(total, range) => isChildProjectsFetching ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
                     onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
                     onChange={(pg) => setPage(pg)} />
               </div>
            </Spin>}
      </Drawer>
   );
};