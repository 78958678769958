export const convertPointToGooglePoint = (point: any) => {
  if (point[0]?.length) {
    return {
      lat: point[0][1],
      lng: point[0][0],
    };
  } else {
    return {
      lat: point[1],
      lng: point[0],
    };
  }
};
