import { Color } from 'components';
import React, { Fragment, useMemo } from 'react';
import { ActiveTabType } from 'types/common';

interface TotalResultsTextProps {
  data: { word: string; color?: Color }[];
}

export const TotalResultsText = ({ data }: TotalResultsTextProps) => {
  const text = data.map((item, idx) => {
    if (item.color) {
      return (
        <span key={idx} className={item.color}>
          {item.word}&nbsp;
        </span>
      );
    }
    return <Fragment key={idx}>{item.word}&nbsp;</Fragment>;
  });
  return (
    <div className="naxxa-total-results font-smaller">
      <span>{text}</span>
    </div>
  );
};

export const MatchingResultsTotalText = ({
  total,
  tabTitle,
  isCountLoading,
  parcelsTotal,
  projectsTotal,
  from
}: {
  total: number | undefined;
  projectsTotal?: number | undefined;
  parcelsTotal?: number | undefined;
  tabTitle: ActiveTabType;
  isCountLoading?: boolean;
  from?: string
}) => {
  const createTotalText = useMemo(() => {
    if (tabTitle !== 'Both' || isCountLoading || from === 'viewAll') {
      const baseText = {
        word: isCountLoading ? 'Loading...' : `${!!total ? total : 0} Matching`,
      };
      const tabMap = {
        Parcels: [{ word: 'Parcels', color: Color.navy }],
        Projects: [{ word: 'Projects', color: Color.green }],
        Both: [
          { word: 'Parcels', color: Color.navy },
          { word: '&' },
          { word: 'Projects', color: Color.green },
        ],
      };
      return [baseText, ...tabMap[tabTitle]];
    } else {
      const data = [
        { word: `${parcelsTotal} Matching` },
        { word: 'Parcels', color: Color.navy },
        { word: `& ${projectsTotal} Matching` },
        { word: 'Projects', color: Color.green },
      ]
      return data
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabTitle, total, isCountLoading, parcelsTotal, projectsTotal]);

  return <TotalResultsText data={createTotalText} />;
};
