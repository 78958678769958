/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { useGetAllParcelBookmarksQuery, useGetAllProjectBookmarksQuery } from 'app/services';
import {
  Color,
  ResultCard,
} from 'components';

import { useEffect, useMemo, useState } from 'react';
import { ParcelInterface } from 'types/parcels';
import { createMetaData } from 'utils/metaDataHelpers';
import { Waypoint } from 'react-waypoint';
import Skeleton from 'react-loading-skeleton';
import { ProjectInterface } from 'types/projects';

// Props interface for ParcelBookmarksProps component
export interface ParcelBookmarksProps {
  setParcelsTotal: (total: number) => void;
  setProjectsTotal: (total: number) => void;
  setShowingTotal: (total: number) => void;
  closeViewAll: () => void;
}

// ResultsGrid component renders a grid of ResultCard components with optional Waypoint for lazy loading
const ResultsGrid = ({
  dataSource,
  hasMore,
  onWaypointEnter,
  closeViewAll,
  setCurrentPage
}: {
  dataSource:
  | { type: string; item: ParcelInterface | ProjectInterface }[]
  | undefined;
  hasMore: boolean;
  onWaypointEnter: () => void;
  closeViewAll: () => void;
  setCurrentPage?: (val: any) => any;
}) => {

  return (
    <>
      <Row
        style={{ marginBottom: 15 }}
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={i}>
              {/* Display a ResultCard for each dataSource item */}
              <ResultCard
                title={result?.type}
                allData={result}
                titleColor={ result?.type === 'Parcel' ? Color.navy : Color.green}
                from='bookmark-drawer'
                setCurrentPage={setCurrentPage}
                data={createMetaData(result)}
                closeViewAll={closeViewAll}
                id={result.item?.id}
                parcelId={result.item?.parcelId || result.item?.id_serial}
                idSerial={result.item?.id_serial}
                id_serial={result.item?.id_serial}
                formattedParcelId={result.item?.formattedParcelId}
              />
              {/* Render Waypoint component if hasMore is true and it's the last item */}
              {hasMore && i === dataSource?.length - 1 && (
                <Waypoint
                  onEnter={(args) => {
                    onWaypointEnter();
                  }}
                />
              )}
            </Col>
          ))}
      </Row>
    </>
  );
};

// ParcelProjectBookmarks component renders combined parcel and project bookmarks with pagination
export const ParcelProjectBookmarks = ({
  setParcelsTotal,
  setProjectsTotal,
  setShowingTotal,
  closeViewAll,
}: ParcelBookmarksProps) => {
  const LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(1); // State for current page number

  // Memoized query search string for pagination
  const paginatedQuerySearch = useMemo(
    () => `?page=${currentPage}&take=${LIMIT}`,
    [LIMIT, currentPage],
  );

  // State for holding displayed items
  const [showingItems, setShowingItems] = useState<
    { type: string; item: ParcelInterface | ProjectInterface }[]
  >([]);

  // Fetching parcels data using custom query hook
  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
  } = useGetAllParcelBookmarksQuery(paginatedQuerySearch);

  // Fetching projects data using custom query hook
  const {
    data: projectsData,
    isFetching: isFetchingProjects,
    isSuccess: isSuccessProjects,
  } = useGetAllProjectBookmarksQuery(paginatedQuerySearch, {
    refetchOnReconnect: true,
  });

  // Effect to update totals when parcelsData or projectsData changes
  useEffect(() => {
    if (
      typeof projectsData?.pagination?.totalItems !== 'undefined' &&
      typeof parcelsData?.pagination?.totalItems !== 'undefined'
    ) {
      // Calculate and set total count for showing items
      setShowingTotal(
        (parcelsData &&
          projectsData &&
          projectsData?.pagination?.totalItems +
          parcelsData?.pagination?.totalItems) ||
        undefined,
      );
      // Set total count for parcels and projects separately
      setParcelsTotal(parcelsData?.pagination?.totalItems || 0);
      setProjectsTotal(projectsData?.pagination?.totalItems || 0);
    }
  }, [parcelsData, projectsData]);

  // Memoized listData combining parcels and projects data
  const listData = useMemo(() => {
    return [
      ...(parcelsData?.data?.map((item: any) => ({ item, type: 'Parcel' })) || []),
      ...(projectsData?.data.map((item: any) => ({ item, type: 'Project' })) || []),
    ];
  }, [parcelsData, projectsData]);

  // Effect to update showingItems when parcels or projects data fetching completes
  useEffect(() => {
    if ((!isFetchingParcels || !isFetchingProjects) &&
      (isSuccessParcels || isSuccessProjects)) {
      // Update showingItems with unique items from listData
      let allShowingItems
      if (+currentPage === 1) {
        allShowingItems = [...new Set([...(listData || [])])];
      } else {
        allShowingItems = [...new Set([...showingItems,
        ...(listData || [])])];
      }

      setShowingItems(allShowingItems)
    }
  }, [
    isFetchingParcels,
    isFetchingProjects,
    isSuccessParcels,
    isSuccessProjects,
    listData
  ]);

  return (
    <div>
      {/* Render ResultsGrid with showingItems, lazy loading using Waypoint, and skeleton loading indicators */}
      <ResultsGrid
        dataSource={showingItems}
        hasMore={false} // No more items to load in this example
        onWaypointEnter={() => setCurrentPage((page) => page + 1)}
        closeViewAll={closeViewAll}
        setCurrentPage={(val) => {
          setCurrentPage(val)
        }}
      />
      {/* Conditional rendering of skeleton loading indicators */}
      {isFetchingParcels || isFetchingProjects ? (
        <>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) : null}
    </div>
  );
};
