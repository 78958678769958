import { Collapse, message, Drawer } from 'antd';
import { CriteriaDropdown, SelectedFilterOption } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { parseSearchString, stringifySearchObject } from 'utils';
import { filterLabelHelper } from 'utils/filterLabelHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectInputValue, setZoomValue } from 'app/services';
import { ViewQueryContentOpp } from './ViewQueryContentOpp';
import { BookmarksList } from 'views/MapPage/AdvancedFilterDrawer/BookmarksList';
import { useResetFilters } from 'utils/resetFilters';

export const SelectedFilters = (props: any) => {
  const { Panel } = Collapse;
  const { replace } = useHistory();
  const dispatch = useDispatch()
  const { pathname, search } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  const [showQuery, setShowQuery] = useState(false);

  const { resetFilters } = useResetFilters()
  const [isAdmin, setIsAdmin] = useState<any>(false)


  const onClose = () => {
    setShowQuery(false);
  };

  useEffect(() => {
    const userDetails = localStorage.getItem('user')
    if (userDetails && JSON.parse(userDetails)?.role && JSON.parse(userDetails)?.role === 'admin') {
        setIsAdmin(true)
    }
}, [])

  const [collapseActiveKey, setCollapseActiveKey] = useState('0');
  const collapseHeader = (
    <div className="collapse-header font-normal">
      <span>
        {collapseActiveKey !== '1' ? 'See all' : 'Hide all'} selected filters
      </span>
    </div>
  );
  const [removedOptions, setRemovedOptions] = useState<string[]>([]);

  const selectedFilters = useMemo(() => {
    const params = qs.parse(search, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    });
    return Object.entries(params).reduce((finalValue: any, [key, value]) => {
      if (typeof value === 'string') {
        finalValue.push({
          text: value, label: key?.includes('currentZoningCity') ? 'Parcels Current Zoning City' :
            key?.includes('currentZoningCounty') ? 'Parcels Current Zoning County' : filterLabelHelper[key]
        });
      } else if (Array.isArray(value)) {
        value.forEach(item => {
          finalValue.push({
            text: item, label: key?.includes('currentZoningCity') ? 'Parcels Current Zoning City' :
              key?.includes('currentZoningCounty') ? 'Parcels Current Zoning County' : filterLabelHelper[key]
          });
        });
      }
      return finalValue;
    }, []
    );
  }, [search]);

  const [showingItems, setShowingItems] = useState(selectedFilters);
  useEffect(() => setShowingItems(selectedFilters), [selectedFilters]);

  // const isVisible = useCallback(
  //   (name: string) => !removedOptions.includes(name),
  //   [removedOptions],
  // );
  const handleApplyOnClick = useCallback(() => {
    let url = decodeURIComponent(search);
    removedOptions.forEach((value) => {
      url = url.replace(value, '');
    });
    const params: any = parseSearchString(url);
    replace({
      pathname,
      search: stringifySearchObject(params),
    });
    // setCollapseActiveKey('0');
  }, [pathname, removedOptions, replace, search]);

  useEffect(() => {
    handleApplyOnClick();
    // eslint-disable-next-line
  }, [removedOptions]);

  const onResetClick = () => {
    resetFilters()
    dispatch(setZoomValue(6))
    messageApi.info('All Filters Removed!');
  };
  const inputValue = useSelector(selectInputValue);

  // useEffect(() => {
  //   setCollapseActiveKey(props.showAllSelectedFilters)
  // }, [props]);

  const onDeleteClick = (text: string) => {
    setRemovedOptions([text])
    messageApi.info(`${text} removed`);
  }

  return (
    <Collapse
      accordion
      className="refine-criteria-selected-filters"
      style={collapseActiveKey === '1' ? { marginTop: 24 } : {}}
      onChange={(key) => setCollapseActiveKey(key as string)}
      activeKey={collapseActiveKey}
    >
      {contextHolder}
      <Panel header={collapseHeader} key="1">
        <div className="header font-large font-bold top-resetbtn">
          <span>Selected Filters</span>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <div className="reset-filters font-normal font-semi-bold" style={{ marginRight: 20 }}>
              <CriteriaDropdown
                criteriaTitle="Save Search"
                extraClassName="saved-searches font-normal font-semi-bold"
                content={<BookmarksList />}
              />
            </div>
            {isAdmin && (
              <div className="reset-filters font-normal font-semi-bold" onClick={() => setShowQuery(true)}>
               <span style={{ marginRight: 20 }}>View Query</span>
              </div>
            )}
            {showingItems && showingItems.length > 0 && (
              <div
                className="reset-filters font-normal font-semi-bold"
                onClick={onResetClick}
                role="button"
                tabIndex={0}
              >
                <span style={{ marginRight: 20 }}>Reset filters</span>
              </div>
            )}
          </div>
        </div>

        <div className="refine-criteria-selected-filters-body font-small">
          {showingItems.map((elem: any, idx: any) => (
            <SelectedFilterOption
              key={idx}
              text={elem.text}
              label={elem.label}
              onDeleteClick={() => onDeleteClick(elem.text)}
              // visible={isVisible(text)}
              visible={true}
              inputValue={inputValue}
            />
          ))}
        </div>

        <Drawer
          title={`Parcels and projects query`}
          placement="right"
          size="large"
          width={'80%'}
          onClose={onClose}
          open={showQuery}
          destroyOnClose
        >
          <ViewQueryContentOpp />
        </Drawer>

        {/* {!!removedOptions?.length && (
          <div className="refine-criteria-selected-filters-apply font-large">
            <span onClick={() => setRemovedOptions([])}>Cancel</span>
            <span onClick={handleApplyOnClick}>Apply</span>
          </div>
        )} */}
      </Panel>
    </Collapse>
  );
};
