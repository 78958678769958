import { createApi } from '@reduxjs/toolkit/query/react';
import { COUNTIESROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';

/**
 * API slice for interacting with county-related endpoints.
 * This slice includes queries to retrieve county data based on state or all counties.
 */
export const countiesApi = createApi({
  baseQuery,
  reducerPath: 'countiesApi',
  endpoints: (builder) => ({
    /**
     * Retrieves all counties for a specific state.
     * @param state - The state for which counties are requested.
     * Usage:
     * ```typescript
     * const { data } = useGetAllCountiesByStateQuery(state);
     * ```
     */
    getAllCountiesByState: builder.query<string[], string>({
      query: (state) => ({
        url: `${COUNTIESROUTES.base}/${state}`,
      }),
    }),
    /**
     * Retrieves all counties.
     * Usage:
     * ```typescript
     * const { data } = useGetAllCountiesQuery();
     * ```
     */
    getAllCounties: builder.query<string[], string>({
      query: () => ({
        url: `${COUNTIESROUTES.base}`,
      }),
    }),
  }),
});

export const { useGetAllCountiesByStateQuery, useGetAllCountiesQuery } =
  countiesApi;
