import { createApi } from '@reduxjs/toolkit/query/react';
import { CONTACTSROUTES } from 'app/apiConstants';
import { baseQuery } from 'app/baseQuery';
import {
  ContactsDataResponse,
} from 'types/contacts';
/**
 * API slice for interacting with contact-related endpoints.
 * This slice includes queries to retrieve contacts data.
 */
export const contactApi = createApi({
  baseQuery,
  reducerPath: 'contactApi',
  endpoints: (builder) => ({
     /**
      * Retrieves all contacts based on search criteria.
      * @param search - Optional search criteria to filter contacts.
      * Usage:
      * ```typescript
      * const { data } = useGetAllContactsQuery(search);
      * ```
      */
    getAllContacts: builder.query<ContactsDataResponse, string>({
      query: (search) => ({
        url: `${CONTACTSROUTES.base}${search}`,
      }),
    })
  }),
});

export const {
  useGetAllContactsQuery
} = contactApi;
