import React from 'react';
import { render } from 'react-dom';
import App from './App';

// Rendering the main application component inside a strict mode component to enable additional checks and warnings for the React components
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'), // Mounting the application to the DOM element with the id 'root'
);
