/* eslint-disable eqeqeq */
import { List, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetSurveyResultsAllQuery } from 'app/services';

interface IItem {
  items: any;
  count: any;
  surveyid: string;
  matchingtype: string;
}

const Item: React.FC<IItem> = ({ items, count, surveyid, matchingtype }) => {
  // State variables for managing the list, pagination, and fetching status
  const [listItem, setListItem] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isFetch, fetFetch] = useState(true);
  const limit = 10;

  // Fetch survey results using the query hook
  const { data: surveyResult, isSuccess } = useGetSurveyResultsAllQuery(
    { surveyid, page, limit, matchingtype },
    {
      skip: isFetch || !surveyid,
    },
  );

  // Set fetch status based on success of query
  useEffect(() => {
    isSuccess && fetFetch(true);
  }, [isSuccess]);

  // Update list items when survey results change
  useEffect(() => {
    fetFetch(true);
    if (
      surveyResult &&
      surveyResult?.length > 0 &&
      surveyResult[0][matchingtype]
    ) {
      setTimeout(() => {
        setListItem(listItem.concat(surveyResult[0][matchingtype]));
      }, 3000);
    }
  }, [surveyResult && surveyResult[0].page, isSuccess]); // eslint-disable-line

  // Update fetch status when page changes
  useEffect(() => {
    page > 1 && fetFetch(false);
  }, [page]); // eslint-disable-line

  // Determine if there are more items to load
  useEffect(() => {
    setHasMore(
      count == listItem.length || count < listItem.length ? false : true,
    );
  }, [listItem, count]);

  // Function to fetch more data (increment page number)
  const fetchMoreData = () => {
    setPage(page + 1);
  };

  // Initialize list items and set hasMore state
  useEffect(() => {
    setListItem(items);
    setHasMore(count > 10 ? true : false);
  }, [count, items]);

  return (
    <List>
      {/* Infinite scroll component to load more data when scrolled */}
      <InfiniteScroll
        dataLength={listItem.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        height={310}
        endMessage={''}
      >
        {listItem && listItem.length > 0 ? (
          // Display list items if available
          listItem.map((item: any, index: number) => (
            <List.Item key={index}>
              <List.Item.Meta
                title={
                  <div>
                    <strong>
                      {item.firstname} {item.lastname}
                    </strong>
                  </div>
                }
                description={
                  <div>
                    <div>
                      <strong>Email: </strong>
                      {item.email}
                    </div>
                    <div>
                      <strong>Company: </strong>
                      {item.company}
                    </div>
                    <div>
                      <strong>Website: </strong>
                      <a href={item.website} target="_blank" rel="noreferrer">
                        {item.website}
                      </a>
                    </div>
                    <div>
                      <strong>Address: </strong>
                      {item.address}
                    </div>
                    <div>
                      <strong>Phone: </strong>
                      {item.phone?.length > 6 && item.phone}
                      {item.phoneNumber1?.length > 6 &&
                        `, ${item.phoneNumber1}`}
                      {item.phoneNumber2?.length > 6 &&
                        `, ${item.phoneNumber2}`}
                    </div>
                  </div>
                }
              />
            </List.Item>
          ))
        ) : (
          // Display empty state if no items
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </InfiniteScroll>
    </List>
  );
};

export default Item;
